import styled, { css } from 'styled-components';
import { FONT, GREYS, FUNCTIONAL } from 'UI/globals/colours';

export const BSSMD = styled.div`
  width: 40%;
  border: 1px solid
    ${({ highlighterror }) =>
      (highlighterror && FUNCTIONAL.negative) || FONT.lightMute};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 4px;
  background-color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.white)};
  input {
    border-radius: 4px;
  }
`;

export const BI = styled.input`
  width: 80%;
  padding: 12px 8px;
  font-size: 12px;
  border: none;
  background-color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.white)};
`;

export const BetslipContentBetBoxPot = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.silverD)};
  font-size: 12px;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      color: ${FONT.dark};
    `};
`;
