import React from 'react';
import PropTypes from 'prop-types';
import { MSSD, M2, MSB } from 'UI/apps/BetslipUKApp/BetslipContainer/Error';
import Constants from '../../../../../../../../project';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 @param {string} message
 @param {func} setErrorObj
 */
export const ErrorComponent = ({
  message,
  setErrorObj,
  isMaxBetExceed,
  useChatWithBroker,
}) => {
  if (message.indexOf(Constants.currency) > -1) {
    // handle currency code from API error message
    message = message.replace(
      Constants.currency,
      CurrencyFormat(Constants.currency, true)
    );
    if (isMaxBetExceed && useChatWithBroker) {
      message = `${Translations.get(
        'text.msg.your.stake'
      )} ${isMaxBetExceed} ${Translations.get('text.msg.broker.help')}`;
    }
    if (isMaxBetExceed && !useChatWithBroker) {
      message = `${Translations.get(
        'text.msg.your.stake'
      )} ${isMaxBetExceed} ${Translations.get('text.msg.stake.exceed')}`;
    }
  }
  return (
    message && (
      <MSSD>
        <M2>{message}</M2>
        <MSB
          onClick={() =>
            setErrorObj({
              isError: false,
              message: '',
            })
          }
        >
          &times;
        </MSB>
      </MSSD>
    )
  );
};
ErrorComponent.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  setErrorObj: PropTypes.func,
  isMaxBetExceed: PropTypes.bool,
  useChatWithBroker: PropTypes.bool,
};
