import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../core__betslip-constants';
import {
  BetSlipBonus,
  BetslipContentBooster,
  CrossButton,
  Description,
  BetslipEW,
  BetslipFreeBet,
  UserFreeBetStake,
  OddName,
  PotReturn,
  Price,
  BetslipUserStake,
  Text,
} from './elements';
import {
  BU,
  BD,
  BCLC,
  BCL,
  BCSM as BCSM_LOCAL,
  BSMD as BSMD_LOCAL,
  BMD,
  FBPR,
  ENS,
  ERR,
} from 'UI/apps/BetslipUKApp/BetslipContainer/BetBox';
import { BH } from 'UI/apps/BetslipUKApp/BetslipContainer/Price';

import { Translations } from '../../../../core__betslip-UK-app';
import project from '../../../../../../../project';
import { getTranslation } from '../../../../core__betslip-utils';
import { StakeNotification } from './elements/core__stakeNotification';
import {
  BCCM,
  MATCH_RESULT,
  PERIOD_TEXT,
  BCSM as BCSM_CORE,
  BSMD as BSMD_CORE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__betBox';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { useInterval } from 'Services/hooks/core__interval';
import { getComponent } from 'Services/core__imports';

const BCSM = getComponent(BCSM_LOCAL, BCSM_CORE);
const BSMD = getComponent(BSMD_LOCAL, BSMD_CORE);

/**
  @param {*} bet object data of Bet,
  @param {*} handleDelete function of delete Bet,
  @param {*} actTab string of active tab,
  @param {*} priceFractional string of fractional amount,
  @param {*} setUserStake function of handle user stake data 
  @param {*} userStakeBet string of user stake data,
  @param {*} potReturn number of calculated Pot. Return ,
  @param {*} currencySymbol string of currency code which come to customer api,
  @param {*} handleBooster function of handling booster, 
  @param {*} bbFlag boolen of booster flag of user ,
  @param {*} handleFreeBet function of handleing Free bet
  @param {*} userfreeBet object of freebet Data of user
  @param {*} wallet object data of wallet which come to customer api
  @param {*} handleBonus function of handling Bonus data,
  @param {*} bonus Boolen of bonus 
  @param {*} balance object of balance data which come to customer api 
  
*/

export const BetslipContentBetBox = ({
  bet,
  handleDelete,
  actTab,
  priceFractional,
  setUserStake,
  userStakeBet,
  potReturn,
  currencySymbol,
  handleBooster,
  bbFlag,
  handleFreeBet,
  userfreeBet,
  newWallet,
  handleBonus,
  bonus,
  balance,
  betMf,
  handleEwData,
  handleDropDownChange,
  price,
  oddsFormat,
  isLogin,
  setShowFB,
  countAccaBet,
  hideNextBetBonus,
  allowFreebetsOnEachway,
  handleFocusOut,
  errorObj = {},
  setErrorObj,
  hideBottomBorder,
  secondaryOddsBoostButton,
}) => {
  const minStake = bet?.limit?.find(item => {
    if (
      item.type === Constants.SINGLEMINSTAKE ||
      item.type === Constants.MULTIPLEMINSTAKE
    ) {
      return item.value;
    }
  });

  const [soccerData, setSoccerData] = useState();
  const [iceHockeyData, setIceHockeyData] = useState();
  const [basketballData, setBasketballData] = useState();
  const [tennisData, setTennisData] = useState();
  const [cricketData, setCricketData] = useState();
  const [rugbyLeagueData, setRugbyLeagueData] = useState();
  const [rugbyUnionData, setRugbyUnionData] = useState();
  const [tableTennisData, setTableTennisData] = useState();
  const [americanFootballData, setAmericanFootballData] = useState();
  const [snookerData, setSnookerData] = useState();
  const [dartsData, setDartsData] = useState();
  const [handballData, setHandballData] = useState();
  const [esportsData, setEsportsData] = useState();
  const [volleyballData, setVolleyballData] = useState();
  const [australianRuesData, setAustralianRulesData] = useState();
  const [baseballData, setBaseballData] = useState();
  const [gameData, setGameData] = useState({});
  const [inPlayTime, setInPlayTime] = useState(null);
  const [timerActive, setTimerActive] = useState(false);
  const [eventSocketListener, setEventSocketListener] = useState(null);

  useEffect(() => {
    subscribeEventSocket();
    listenEventSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      eventSocketListener && eventSocketListener.unsubscribe();
    };
  }, [eventSocketListener]);

  const subscribeEventSocket = () => {
    const subscriptionObject = {
      categoryRef: bet.catRef,
      providerRef: bet.eventProviderRef,
      sourceKey: bet.sourceKey,
    };
    PubSub.emit(PubsubEvents.SOCKET_EVENT_SUBSCRIBER, subscriptionObject);
  };

  const listenEventSocket = () => {
    const listener = PubSub.listen(PubsubEvents.SOCKET_EVENT_LISTEN, wsData => {
      if (wsData.sourceKey === bet.sourceKey) {
        if (wsData.catRef === Constants.SOCCER_REF) {
          setSoccerData(wsData);
        }
        if (wsData.catRef === Constants.BASKETBALL_REF) {
          setBasketballData(wsData);
        }
        if (wsData.catRef === Constants.ICE_HOCKEY_REF) {
          setIceHockeyData(wsData);
        }
        if (wsData.catRef === Constants.TENNIS_REF) {
          setTennisData(wsData);
        }
        if (wsData.catRef === Constants.CRICKET_REF) {
          setCricketData(wsData);
        }
        if (wsData.catRef === Constants.RUGBY_LEAGUE_REF) {
          setRugbyLeagueData(wsData);
        }
        if (wsData.catRef === Constants.RUGBY_UNION_REF) {
          setRugbyUnionData(wsData);
        }
        if (wsData.catRef === Constants.TABLE_TENNIS_REF) {
          setTableTennisData(wsData);
        }
        if (wsData.catRef === Constants.AMERICAN_FOOTBALL_REF) {
          setAmericanFootballData(wsData);
        }
        if (wsData.catRef === Constants.SNOOKER_REF) {
          setSnookerData(wsData);
        }
        if (wsData.catRef === Constants.DARTS_REF) {
          setDartsData(wsData);
        }
        if (wsData.catRef === Constants.HANDBALL_REF) {
          setHandballData(wsData);
        }
        if (wsData.catRef === Constants.ESPORTS_REF) {
          setEsportsData(wsData);
        }
        if (wsData.catRef === Constants.VOLLEYBALL_REF) {
          setVolleyballData(wsData);
        }
        if (wsData.catRef === Constants.AUSTRALIAN_RULES_REF) {
          setAustralianRulesData(wsData);
        }
        if (wsData.catRef === Constants.BASEBALL_REF) {
          setBaseballData(wsData);
        }

        if (bet.live) {
          //for some data there's minutes instead of minute
          if (wsData?.minutes) {
            wsData.minute = wsData?.minutes;
          }
          if (wsData?.seconds) {
            wsData.second = wsData?.seconds;
          }
          if (wsData?.minute !== undefined && wsData?.second !== undefined) {
            setTimerActive(true);

            if (
              wsData.catRef === Constants.SOCCER_REF &&
              wsData.extratime &&
              wsData?.minute > 90
            ) {
              setInPlayTime(
                parseInt(wsData?.minute - 90) * 60 + parseInt(wsData.second)
              );
            } else {
              setInPlayTime(
                parseInt(wsData.minute) * 60 + parseInt(wsData.second)
              );
            }
          }
        }

        //set game data for period and state
        const updatedGameData = {};
        if (wsData.period) {
          updatedGameData['period'] = wsData.period;
        }
        if (wsData.state) {
          updatedGameData['state'] = wsData.state;
        }

        setGameData(updatedGameData);
      }
    });

    setEventSocketListener(listener);
  };

  useInterval(() => {
    if (
      inPlayTime !== null &&
      gameData.period !== GLOBAL_CONSTANTS.BREAK_TIME &&
      gameData.state !== GLOBAL_CONSTANTS.COMPLETED
    ) {
      if (bet.catRef === Constants.BASKETBALL_REF) {
        inPlayTime > 0 && setInPlayTime(inPlayTime - 1);
      } else {
        setInPlayTime(inPlayTime + 1);
      }
    }
  }, 1000);

  const date =
    timerActive &&
    ('0' + Math.floor(inPlayTime / 60)).slice(-2) +
      ':' +
      ('0' + Math.floor(inPlayTime % 60)).slice(-2);

  const checkMaxStakeExceeded = (err, returnMaxLimit, findBetId) => {
    const errMsg = err?.message?.toLowerCase() || '';
    let checked = errMsg.includes(Constants.MAXSTAKE);
    if (returnMaxLimit && checked) {
      const regExpToMatchFloat = '[+-]?([0-9]*[.])?[0-9]+';
      const matches = errMsg.match(regExpToMatchFloat);
      return (matches?.length && matches[0]) || null;
    }
    //return true, if max bet error matches the bet id
    if (findBetId && checked) {
      const arr = err?.betMsg.split(',');
      if (arr.length) {
        checked = false;
        if (arr.includes(bet.betTypeRef)) {
          checked =
            (bet.betTypeRef === 'SGL' &&
              Number(arr[arr.length - 1]) === bet.id) ||
            (bet.betTypeRef !== 'SGL' && true);
        }
      }
    }
    return checked;
  };

  const translateSet = setString => {
    if (!setString) return '';
    const [firstPart, secondPart] = setString.split(' ');

    if (firstPart?.includes('{')) {
      const res =
        firstPart[0] +
        Translations.get(firstPart.slice(2, -1)) +
        ' ' +
        secondPart.toString();
      return res;
    } else if (firstPart?.length > 0 && secondPart?.length > 0) {
      const pattern = /^[a-zA-Z]+$/;
      const suffix = firstPart.slice(1);
      const res =
        firstPart[0] +
        (pattern.test(suffix.toLowerCase())
          ? Translations.get(`number.${suffix}`)
          : suffix) +
        ' ' +
        secondPart.toString();
      return res;
    }

    return '';
  };

  const showResultForCategory = (category, data) => {
    const { 2: eventName } = bet?.description?.split('|') || [];
    const teamReversed =
      category !== Constants.AMERICAN_FOOTBALL_REF &&
      eventName?.indexOf(' @ ') !== -1;

    if (!data) return null;
    let categoryData;
    switch (category) {
      case Constants.SOCCER_REF:
      case Constants.BASKETBALL_REF:
      case Constants.ICE_HOCKEY_REF:
      case Constants.CRICKET_REF:
      case Constants.RUGBY_LEAGUE_REF:
      case Constants.RUGBY_UNION_REF:
      case Constants.AMERICAN_FOOTBALL_REF:
      case Constants.SNOOKER_REF:
      case Constants.DARTS_REF:
      case Constants.HANDBALL_REF:
      case Constants.ESPORTS_REF:
      case Constants.AUSTRALIAN_RULES_REF:
      case Constants.BASEBALL_REF:
        return (
          <React.Fragment>
            {data.sourceKey === bet.sourceKey && data.game && (
              <MATCH_RESULT>
                {teamReversed
                  ? `${data.game.away}-${data.game.home}`
                  : `${data.game.home}-${data.game.away}`}
              </MATCH_RESULT>
            )}
            {category === Constants.DARTS_REF && data?.dartsData?.gameScore && (
              <PERIOD_TEXT>{data.dartsData.gameScore}</PERIOD_TEXT>
            )}
          </React.Fragment>
        );
      case Constants.TENNIS_REF:
      case Constants.TABLE_TENNIS_REF:
      case Constants.VOLLEYBALL_REF:
        if (category === Constants.TENNIS_REF) categoryData = data.tennisData;
        if (category === Constants.TABLE_TENNIS_REF)
          categoryData = data?.scoreSummary?.tableTennisData;
        if (category === Constants.VOLLEYBALL_REF)
          categoryData = data.volleyballData;
        return (
          <React.Fragment>
            {data?.score && <MATCH_RESULT>{data.score}</MATCH_RESULT>}
            {categoryData?.set && (
              <PERIOD_TEXT>{translateSet(categoryData.set)}</PERIOD_TEXT>
            )}
            {categoryData?.currentSetScore && (
              <PERIOD_TEXT>{categoryData.currentSetScore}</PERIOD_TEXT>
            )}
            {category === Constants.TENNIS_REF && categoryData?.gameScore && (
              <PERIOD_TEXT>{categoryData.gameScore}</PERIOD_TEXT>
            )}
          </React.Fragment>
        );

      default:
        break;
    }
  };

  return (
    <BMD
      key={bet.id}
      disappear={bet.disappear || false}
      betBonus={bet?.betBonus}
      hideBottomBorder={hideBottomBorder}
    >
      {bet?.nextBetBonus &&
        !bet?.hideBetBonus &&
        !hideNextBetBonus?.includes(bet.betTypeRef) && (
          <BSMD showBonusText={true}>
            {getTranslation(
              bet,
              Translations.get('text.bonus.info'),
              countAccaBet
            )}
            <BSMD arrow={true}></BSMD>
          </BSMD>
        )}

      <BU multiple={bet.multiple}>
        {/* display odd name */}
        <OddName bet={bet} betMf={betMf} />
        {/* display price of odd */}
        <Price
          secondaryOddsBoostButton={secondaryOddsBoostButton}
          handleDropDownChange={handleDropDownChange}
          pF={priceFractional}
          oddsFormat={oddsFormat}
          {...bet}
        />
        {bet?.betBonus && (
          <BCSM accaBonus={bet?.betBonus}>
            +{bet.betBonus?.percentage}% {Translations.get('text.acca.bonus')}
          </BCSM>
        )}

        {/* display cross button  */}
        {actTab === Constants.SINGLE && (
          <CrossButton
            handleDelete={handleDelete}
            selectionId={bet.selectionId}
            betTypeRef={bet.betTypeRef}
          />
        )}
      </BU>

      {/* when data come to bet builder */}
      {bet.betTypeRef === Constants.BBB && (
        <BD>
          <ENS>{bet.eventName}</ENS>
        </BD>
      )}

      {/* UL 2 for Betslip */}
      {(bet?.newMarketSequence && !isNaN(bet.newMarketSequence) && (
        <ERR>
          <BH>{Translations.get('text.market.change')}</BH>
        </ERR>
      )) ||
        (actTab === Constants.SINGLE && (
          <React.Fragment>
            <BD>
              {bet?.bogAvailable && bet.hasFreebet === false && (
                /* Display BOG Tab */
                <BCLC>
                  <BCL>{Translations.get('text.bog')}</BCL>
                </BCLC>
              )}

              {/* Display Discription */}
              {bet.description && (
                <Description
                  desc={bet.description}
                  active={bet.active}
                  catRef={bet.catRef}
                  schedule={bet.scheduledStart}
                />
              )}

              {bet.text && (
                <Text text={bet.text} active={bet.active} catRef={bet.catRef} />
              )}
            </BD>
            <BD>
              {bet.live && (
                /* Display Live Tab */
                <BCLC>
                  <BCL>{Translations.get('text.live')}</BCL>
                </BCLC>
              )}

              <React.Fragment>
                {showResultForCategory(Constants.SOCCER_REF, soccerData)}
                {showResultForCategory(
                  Constants.BASKETBALL_REF,
                  basketballData
                )}
                {showResultForCategory(Constants.ICE_HOCKEY_REF, iceHockeyData)}
                {showResultForCategory(Constants.TENNIS_REF, tennisData)}
                {showResultForCategory(
                  Constants.TABLE_TENNIS_REF,
                  tableTennisData
                )}
                {showResultForCategory(Constants.CRICKET_REF, cricketData)}
                {showResultForCategory(
                  Constants.RUGBY_LEAGUE_REF,
                  rugbyLeagueData
                )}
                {showResultForCategory(
                  Constants.RUGBY_UNION_REF,
                  rugbyUnionData
                )}
                {showResultForCategory(
                  Constants.AMERICAN_FOOTBALL_REF,
                  americanFootballData
                )}
                {showResultForCategory(Constants.SNOOKER_REF, snookerData)}
                {showResultForCategory(Constants.DARTS_REF, dartsData)}
                {showResultForCategory(Constants.HANDBALL_REF, handballData)}
                {showResultForCategory(Constants.ESPORTS_REF, esportsData)}
                {showResultForCategory(
                  Constants.VOLLEYBALL_REF,
                  volleyballData
                )}
                {showResultForCategory(
                  Constants.AUSTRALIAN_RULES_REF,
                  australianRuesData
                )}
                {showResultForCategory(Constants.BASEBALL_REF, baseballData)}
                {date && <ENS>{date}</ENS>}
              </React.Fragment>
            </BD>
          </React.Fragment>
        ))}

      {bet.active !== false && (
        <BSMD accaBonus={bet?.betBonus}>
          <BCSM>
            {/* next conditon if user select free bet then it show first one other wise it show second one */}
            {isLogin && userfreeBet && userfreeBet.hasFreebet ? (
              <UserFreeBetStake
                currencySymbol={currencySymbol}
                freebetCredit={userfreeBet.freebetCredit}
                isEachWay={betMf.type === Constants.EACHWAY}
              />
            ) : (
              // display user stake box
              <BetslipUserStake
                handleFocusOut={handleFocusOut}
                userStakeBet={userStakeBet}
                setUserStake={setUserStake}
                betMf={betMf}
                price={price}
                bet={bet}
                isMaxBetError={
                  errorObj.isError &&
                  checkMaxStakeExceeded(errorObj, false, true)
                }
              />
            )}

            <FBPR
              freebet={
                bet.allowCreditWallet === false ||
                bbFlag === true ||
                bonus === true ||
                newWallet.length === 0 ||
                betMf.betCount !== 1
              }
            >
              {/**
 * handle Free Bet stuff or Wallet stuff
 @allowCreditWallet this flag from template api depend freebet show or not 
 @bbFlag this flag for booster if user already select booster then free bet not show
 @bonus this flag for BetSlip bonus if user already selected bonus then free bet not show
 @newWallet this array where all data of wallet store 
 @betCount betModifier content BetCount which is 1 
*/}
              {isLogin &&
                bet.allowCreditWallet &&
                bbFlag === false &&
                bonus === false &&
                newWallet.length > 0 &&
                (betMf.betCount === 1 ||
                  newWallet.some(
                    wallet => wallet.minSelectionsRestriction <= betMf.betCount
                  ) ||
                  (allowFreebetsOnEachway &&
                    betMf.type === Constants.EACHWAY)) && (
                  <BetslipFreeBet
                    betId={bet.id}
                    wallet={newWallet}
                    freeBetData={userfreeBet ? userfreeBet : bet}
                    currencySymbol={currencySymbol}
                    handleFreeBet={handleFreeBet}
                    betCat={bet.catRef}
                    setShowFB={setShowFB}
                    betSubCat={bet.subcatRef}
                  />
                )}
              {/* Handle the value of potential return  */}
              <PotReturn
                potReturn={potReturn}
                currencySymbol={currencySymbol}
                bet={bet}
              />
            </FBPR>
          </BCSM>
          {/**Hanlde low stake problems */}
          {((!bet.isValid && bet.isValid !== undefined && minStake?.value) ||
            (checkMaxStakeExceeded(errorObj) &&
              checkMaxStakeExceeded(errorObj, false, true))) && (
            <StakeNotification
              setErrorObj={setErrorObj}
              isActive={errorObj.isError ? false : bet.isValid}
              userStake={
                errorObj.isError
                  ? checkMaxStakeExceeded(errorObj, true) &&
                    parseFloat(checkMaxStakeExceeded(errorObj, true))
                  : minStake?.value
              }
              maxBetExceedId={checkMaxStakeExceeded(errorObj, false, true)}
              currencySymbol={currencySymbol}
              textMsg={errorObj.isError ? Constants.MAX : Constants.MIN}
              handleFocusOut={handleFocusOut}
              betMf={betMf}
              price={price}
              bet={bet}
            />
          )}

          <BCCM>
            {/* 
EW Terms  checkbox only for horse and dog races
*/}
            {bet.catRef &&
              bet.betTypeRef !== Constants.BBB &&
              bet.betModifier.length > 1 &&
              bet.betModifier?.[1]?.ewFraction !== 1 &&
              bet.betModifier?.[1]?.ewPlaces !== 1 && (
                <BetslipEW
                  bet={bet}
                  handleEwData={handleEwData}
                  price={price}
                  actTab={actTab}
                />
              )}

            {/**
*handle use Bonus stuff 
@allowBonusFunds this flag from template api depend Bonus show or not 
@bbFlag this flag for booster if user already select booster then use bonus not show
@betCount betModifier content BetCount which is 1 
@balance its comes to api if balance
@priceBookType if price booking type is SP then bonus not show
*/}

            {bet.allowBonusFunds &&
              bbFlag === false &&
              bet.hasFreebet === false &&
              balance &&
              balance.sportsbookBalance > 0 && (
                <BetSlipBonus
                  handleBonus={handleBonus}
                  bonus={bonus}
                  bet={bet}
                />
              )}
          </BCCM>
          {/**
 *handle use Booster stuff 
 @allowBetBoost this flag from template api depend Booster show or not 
 @bonus this flag for bonus if user already select bonus then use Booster not show
 @betCount betModifier content BetCount which is 1 
 @boostedReturns this flag to check boostedReturns key exist in betModifier object
  @priceBookType if price booking type is SP then bonus not show
*/}
          {isLogin &&
            bet.allowBetBoost &&
            bonus === false &&
            bet.hasFreebet === false &&
            betMf['boostedReturns'] &&
            bet.priceBookType !== 'SP' && (
              <BetslipContentBooster
                {...betMf}
                key={betMf['boosterkey']}
                handleBooster={handleBooster}
                bbFlag={bbFlag}
                oddsFormat={oddsFormat}
                betBooster={project?.betBooster}
                price={price}
                betModifier={bet.betModifier}
                betModifierFlag={bet.betModifierFlag}
                secondaryOddsBoostButton={secondaryOddsBoostButton}
              />
            )}
        </BSMD>
      )}
    </BMD>
  );
};

BetslipContentBetBox.propTypes = {
  bet: PropTypes.object,
  handleDelete: PropTypes.func,
  actTab: PropTypes.string,
  priceFractional: PropTypes.string,
  setUserStake: PropTypes.func,
  userStakeBet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  potReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencySymbol: PropTypes.string,
  handleBooster: PropTypes.func,
  bbFlag: PropTypes.bool,
  handleFreeBet: PropTypes.func,
  userfreeBet: PropTypes.object,
  newWallet: PropTypes.array,
  handleBonus: PropTypes.func,
  bonus: PropTypes.bool,
  balance: PropTypes.object,
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleEwData: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  oddsFormat: PropTypes.string,
  isLogin: PropTypes.bool,
  setShowFB: PropTypes.func,
  schedule: PropTypes.string,
  countAccaBet: PropTypes.number,
  allowFreebetsOnEachway: PropTypes.bool,
  handleFocusOut: PropTypes.func,
  hideNextBetBonus: PropTypes.array,
  errorObj: PropTypes.object,
  setErrorObj: PropTypes.func,
  hideBottomBorder: PropTypes.bool,
  secondaryOddsBoostButton: PropTypes.bool,
};
