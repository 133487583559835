import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from 'UI/globals/theme';
import { Radio, L, RC, SurroundingButton } from 'UI/forms/InputRadioStyles';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/core__services';

const InputRadio = ({
  name,
  label,
  radioId,
  showInfoIcon,
  infoPopup,
  ...props
}) => {
  return (
    <RC>
      <ThemeProvider theme={theme}>
        <Radio
          {...props}
          name={name}
          id={radioId}
          data-testid={radioId || 'input-radio-id'}
        ></Radio>
        <L className="r-l" text={label} htmlFor={radioId} />
        {showInfoIcon && (
          <Fragment>
            <p>&nbsp;</p>{' '}
            <SurroundingButton
              data-testid="Information Popup Button"
              onClick={infoPopup}
            >
              <I iconName={getIcon('INFORMATION')} />
            </SurroundingButton>
          </Fragment>
        )}
      </ThemeProvider>
    </RC>
  );
};

InputRadio.propTypes = {
  /**
   * disabled?
   */
  disabled: PropTypes.bool,
  /**
   * checked?
   */
  checked: PropTypes.bool,

  /**
   * Label content
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Group name
   */
  name: PropTypes.string,
  /**
   * Radio Id - UNIQUE
   */
  radioId: PropTypes.string,

  /**
   * For Animation
   */
  animation: PropTypes.bool,

  /**
   * Allows Infomation Icon to be shown if needed
   */
  showInfoIcon: PropTypes.bool,

  /**
   * Controls when the infoPopup should appear
   */
  infoPopup: PropTypes.func,
};

InputRadio.defaultProps = {
  disabled: false,
  label: 'Label',
  name: null,
  animation: true,
  showInfoIcon: false,
};

export default InputRadio;
