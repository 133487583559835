import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { Header } from './components/BetSlipContainer/BetslipHeader';
import { NoBetComponent } from './components/BetSlipContainer/BetslipNobet';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import { getCookie, removeCookie } from 'Services/core__services';
import {
  checkSuspendedBet,
  dataFromHashParams,
  dataFromQueryParams,
  genrateUkBetTemplate,
  getSelectionIds,
  mergeDataWithLocalStorage,
  placingBetUser,
  removeUserDependentItems,
  validateFreeBet,
  wsSocketDataManipulation,
  getDataOfPrice,
  findMarket,
  suspendSelectedMarket,
  mergeBuilderDataInTemplate,
  handleSortCreateBet,
  genrateSlips,
  upcomingFavouritesAmount,
  updateAmountComeToUrl,
  retainCleanupBet,
  CalCount,
  detectMob,
  setBetslipHeight,
  getSelectionIdsWithActiveStatus,
  notificationValues,
  validateBetBooster,
  getMinimalBetModifierBetCount,
} from '../core__betslip-utils';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { connect } from 'react-redux';
import {
  removeFromLocalStorage,
  setterGetterFromLocalstorage,
  setUserBetInlocalStorage,
  removeFromLocalStorageDeleteSuspended,
} from 'Services/localstorage/core__localstorage';
import { userBetInfo, poststoreData } from '../core__betslip-store';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import ProductRestriction from 'Services/productRestriction/core__product-restriction';
import { Constants } from '../core__betslip-constants';
import { BS, B } from 'UI/apps/BetslipUKApp/BetslipContainer/BetslipReact';
import { ReceiptContainer } from './components/ReceiptContainer';
import { BetSlipContainer } from './components/BetSlipContainer';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { FSBTheme } from 'Services/core__fsb-theme';
import { Translations } from '../core__betslip-UK-app';
import project from '../../../../project';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';

/**
 Data via Props and Store 
@param apiRes Object which represent Api returning some of response
@param appConfig Object which represent congfig data which come to CMS
@param betTemplate Array object which represent data of bets which come via API
@param cleanBets Function which handle to update store value when BetSlip deleted 
@param fetchData Function which handle a GET requests 
@param placedBet Object which handle data of placing Bets , which is through API
@param postData Function which handle a POST requests 
@param poststoreData Function which store posting Bet data
@param url Object which store urls of apis
@param useBet Object which store useBet data
@param userBetInformation Function which handle user Bets info.
*/

let betInterval;
let awaitingPreviousBet = false;
export class BetslipReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionId: [],
      oldBuilderData: [],
      builderParams: [],
      builderData: [],
      actTab: Constants.SINGLE,
      betTemplate: [],
      useBet: {},
      isLogin: false,
      betSlipRecipt: {},
      errorObj: {
        isError: false,
        message: '',
      },
      oddsFormat: props?.oddsFormat || Constants.FRACTIONAL,
      notificationParam: props?.appConfig?.notificationParam || Constants.BLOCK,
      amtObj: [],
      postApiCall: false,
      card: false,
      upcomingFavAmt: '',
      render: false,
      openBS: false,
      theme: props.appConfig?.theme,
      isShowless: props.appConfig?.ismultiplehidden,
      userAuthData: FSBCustomerSession.getSession(),
      isSafari: false,
      oddsMovementFirstOpened: false,
      bottomNavData: {},
      betslipExpand: false,
    };

    if (props?.appConfig?.virtuals) {
      Constants.LSKEY_UK = 'betslip_virtual_uk';
    }
  }

  componentDidMount() {
    this.setState({ render: true });
    getCookie('openedFirst') && removeCookie('openedFirst');
    try {
      this.setState(prev => ({
        ...prev,
        isSafari:
          /Safari/.test(window?.navigator.userAgent) &&
          /Apple Computer/.test(window?.navigator.vendor),
      }));
    } catch (error) {
      //no handling required
    }

    // handle Place bet function if user directly come via login popup
    this.callBetslip = PubSub.listen(PubsubEvents.CallBetSlip, wsData => {
      if (wsData && wsData['place'] === true && !awaitingPreviousBet) {
        awaitingPreviousBet = true;
        this.handlePlaceBet();
        setTimeout(() => {
          awaitingPreviousBet = false;
        }, 2000);
      }
    });

    // handle where data comes from events apps & market apps
    this.betslipUpdate = PubSub.listen(PubsubEvents.BetslipUpdate, wsData => {
      if (!ProductRestriction?.getIsCurrentProductRestricted(true)) {
        const { betSlipRecipt } = this.state;

        if (wsData?.upcomingFavAmt && wsData?.selectionId) {
          this.setState({ upcomingFavAmt: wsData?.upcomingFavAmt });
        }

        if (Object.keys(betSlipRecipt).length > 0) {
          // if betslip recipt open and user click on bet button .
          this.handleReceptCloseButton(false);
          this.props.cleanBets(
            { type: 'STORE_DATA', data: [] },
            { type: 'POST_STORE_DATA', data: [] }
          );
          this.setState(
            {
              betTemplate: [],
              selectionId: [],
            },
            () => {
              this.ApiCall(wsData);
            }
          );
        } else {
          // default condition
          this.ApiCall(wsData);
        }
      }
    });

    // handle where data comes from delete button
    this.betslipDelete = PubSub.listen(PubsubEvents.BetslipDelete, wsData => {
      this.ApiCall(wsData);
    });

    // handle page refresh initial load
    const localData = setterGetterFromLocalstorage({
      keyName: Constants.LSKEY_UK,
      action: 'get',
    });
    this.setState({ isShowless: this.props.appConfig?.ismultiplehidden });
    // check any old record exist in localStorage
    if (localData && localData.length > 0) {
      let { bsid, bbid } = getSelectionIds(localData);
      if (window && window?.location?.hash) {
        // if any id in Url and data is in hash params
        const hashData = dataFromHashParams(window);
        if (hashData?.amtObj && Array.isArray(hashData.amtObj)) {
          this.setState({ amtObj: hashData.amtObj });
        }
        bsid = bsid.concat(hashData.sid);
      } else if (
        window &&
        window?.location?.search &&
        dataFromQueryParams(window)
      ) {
        // if any id in Url and data is in Query params
        const queryString = dataFromQueryParams(window);
        if (queryString?.amtObj && Array.isArray(queryString.amtObj)) {
          this.setState({ amtObj: queryString.amtObj });
        }
        bsid = bsid.concat(queryString.sid);
        if (
          queryString.betslipOpen &&
          this.props?.appConfig?.isbetslipopenfromurl
        ) {
          this.handleOpenBS(true);
        }
      }
      // on default case and || with LS selection ids
      const data = { selectionId: bsid };
      if (bsid.length > 0) {
        this.ApiCall(data);
      }
      if (bbid.length > 0) {
        this.betbulderApiCall(bbid);
      }
    } else if (
      localData.length === 0 &&
      window &&
      window?.location?.hash &&
      dataFromHashParams(window)
    ) {
      // if no old record and selection come to URL as Hash params ids
      const hashData = dataFromHashParams(window);
      const data = { selectionId: hashData.sid };
      if (hashData?.amtObj && Array.isArray(hashData.amtObj)) {
        this.setState({ amtObj: hashData.amtObj });
      }
      this.ApiCall(data);
    } else if (
      localData.length === 0 &&
      window &&
      window?.location?.search &&
      dataFromQueryParams(window)
    ) {
      // if no old record and selection come to URL as Query params ids
      const queryString = dataFromQueryParams(window);
      if (queryString?.amtObj && Array.isArray(queryString.amtObj)) {
        this.setState({ amtObj: queryString.amtObj });
      }
      const data = { selectionId: queryString.sid };
      this.ApiCall(data);
      setTimeout(() => {
        if (queryString.betslipOpen) {
          this.handleOpenBS(true);
        }
      }, 1000);
    }

    // when user login
    this.sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      this.userAuthStuff(UserAuthData);
    });

    // when user relogin or reload page
    this.sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      this.userAuthStuff(UserAuthData);
    });

    this.bottomNavData = PubSub.listen(PubsubEvents.BOTTOM_NAV_DATA, data => {
      this.setState({ bottomNavData: data });
    });

    this.expandBetslip = PubSub.listen(PubsubEvents.BETSLIP_EXPAND, data => {
      this.setState({ betslipExpand: data });
    });

    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();
    this.userAuthStuff(UserAuthData);

    // when WS data comes
    this.getMarketDataToWS = PubSub.listen(
      PubsubEvents.getMarketDataToWS,
      wsData => {
        if (wsData?.selections && wsData.selections.length > 0) {
          let isTrue = false;
          const updateSelection = wsData.selections;
          // check data exist in WS and also check selection exist in that
          for (const itm of updateSelection) {
            const selectionId = this.state.selectionId;
            if (selectionId.includes(itm.id) && !isTrue) {
              isTrue = true;
            }
          }

          if (isTrue) {
            const data11 = wsSocketDataManipulation(
              wsData,
              this.state.betTemplate,
              this.state.notificationParam
            );
            // set old data in LS
            setterGetterFromLocalstorage({
              keyName: Constants.LSKEY_UK,
              keyValue: data11,
              action: 'set',
            });
            // set updated data in store
            const { bsid } = getSelectionIdsWithActiveStatus(data11);
            if (bsid.length > 0) {
              // api call
              this.props.fetchData(
                this.props.url['betTemplate'] + '?br=[' + bsid + ']',
                null,
                this.props.appConfig
              );
            }
          }
        } else if (
          wsData.selections.length === 0 &&
          wsData.state === 'CLOSED' &&
          findMarket(this.state.betTemplate, wsData)
        ) {
          const data11 = suspendSelectedMarket(wsData, this.state.betTemplate);
          const data = { betTemplate: data11 };
          this.props.cleanBets({ type: 'STORE_DATA', data: data });
        }
      }
    );

    //listen to pubsub for odds change
    this.oddsValue = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      if (wsData === Constants.DECIMAL) {
        this.setState({ oddsFormat: Constants.DECIMAL });
      } else if (wsData === Constants.AMERICAN) {
        this.setState({ oddsFormat: Constants.AMERICAN });
      } else {
        this.setState({ oddsFormat: Constants.FRACTIONAL });
      }
    });

    // handle when user logout
    this.sessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      // remove all userdependent elements from betslip object
      //  for removeing Receipt
      this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });

      const updateBetTemplate = removeUserDependentItems(
        this.state.betTemplate,
        Constants.LSKEY_UK
      );

      this.setState({
        isLogin: false,
        betTemplate: updateBetTemplate,
        betSlipRecipt: {},
      });
    });

    //for betslip height
    this.resetHeight = PubSub.listen(PubsubEvents.RESET_HEIGHT, () => {
      setTimeout(() => {
        const isMobile = detectMob();
        // checking mobile device and betlip open state
        if (isMobile && !this.state.openBS) {
          this.setBetslipHeight();
        }
      }, 2000);
    });

    this.resetBetslipView = PubSub.listen(
      PubsubEvents.RESET_BETSLIP_VIEW,
      () => {
        const isMobile = detectMob();
        // checking mobile device and betlip open state
        if (isMobile && this.state.openBS) {
          this.setBetslipHeight();
        }
      }
    );

    // bet builder
    this.addBetBuilder = PubSub.listen(PubsubEvents.addBetBuilder, data => {
      let eventId = '';
      let sentence = '';
      const params = [];
      if (Object.keys(data).length > 0) {
        eventId = data.eventId;
        for (const bs of data.sentence) {
          sentence += '&sentence=' + bs;
        }
        params.push(eventId + sentence);
        this.betbulderApiCall(params);
      }
    });

    // check customer card
    this.cusCard = PubSub.listen(PubsubEvents.CUS_CARD, data => {
      let card = false;
      if (data && data.length > 0) {
        card = true;
        this.setState({
          card: card,
        });
      }
    });

    this.openBetslip = PubSub.listen(PubsubEvents.OPEN_BETSLIP, wsData => {
      this.handleOpenBS(wsData);
    });

    // handle odd moment refresh initial load
    const oddMoment = setterGetterFromLocalstorage({
      keyName: Constants.ODDMOMENT,
      action: 'get',
    });
    if (oddMoment && oddMoment.length > 0) {
      this.handleActTabNotificationPopup(oddMoment, false);
    }

    this.resizeBetslip = () => {
      if (detectMob()) {
        this.setBetslipHeight();
      }
    };

    window.addEventListener('resize', this.resizeBetslip);

    this.setBetSlip(this.state.openBS);
  }

  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.callBetslip.unsubscribe();
    this.betslipUpdate.unsubscribe();
    this.betslipDelete.unsubscribe();
    this.sessionCreated.unsubscribe();
    this.sessionUpdated.unsubscribe();
    this.getMarketDataToWS.unsubscribe();
    this.oddsValue.unsubscribe();
    this.sessionDestroy.unsubscribe();
    this.resetHeight.unsubscribe();
    this.resetBetslipView.unsubscribe();
    this.addBetBuilder.unsubscribe();
    this.cusCard.unsubscribe();
    this.openBetslip.unsubscribe();
    this.bottomNavData.unsubscribe();
    this.betslipExpand.unsubscribe();

    //Unmounting event listeners
    window.removeEventListener('resize', this.resizeBetslip);
    window.removeEventListener('resize', this.resizeBetSlipOnUpdate);
  }
  resizeBetSlipOnUpdate = () => {
    /* mannage selection id , extra params */
    const localData = setterGetterFromLocalstorage({
      keyName: Constants.LSKEY_UK,
      action: 'get',
    });
    if (CalCount(localData)) {
      PubSub.emit(PubsubEvents.BET_COUNT, CalCount(localData));
    }
  };
  setBetslipHeight = () => {
    const isMobile = detectMob();
    const betslipContainer = document.getElementById('betslip_container');
    const betslipBottom = document.getElementById('betslip-bottom');
    const betSlipContent = document.getElementById('bet_content');
    const IFrameHeight = document.querySelector('main')?.clientHeight;
    if (this.props.appConfig.hidebetslip && betslipContainer) {
      if (!isMobile) {
        betslipContainer.style.position = 'initial';
        betslipContainer.style.height = `initial`;
        if (betSlipContent) {
          betSlipContent.style.overflow = 'hidden';
        }
        if (IFrameHeight < betslipContainer.clientHeight) {
          PubSub.emit(
            PubsubEvents.RESET_IFRAME_HEIGHT,
            betslipContainer.clientHeight
          );
        }
        if (betslipBottom) {
          betslipBottom.style.position = 'relative';
        }
      }
      if (isMobile && betslipBottom) {
        if (betslipBottom) {
          betslipBottom.style.position = 'absolute';
        }
        if (this.state.openBS) {
          setBetslipHeight(true, this.state.isLogin);
        }
      }
    }
  };
  /**
  ApiCall for getting data Via APi 
  @param data {*} type , selectionId
  type is deletesuspended [deleting suspended bets] || deleteall  [deleting all bets]
  if user click 2 nd time of selected bet it also handle there
*/
  ApiCall = data => {
    let lsd = [];
    let selectionId = new Array();
    let builderParams = new Array();
    selectionId = [...this.state.selectionId];
    selectionId = selectionId.filter(item => item);
    builderParams = [...this.state.builderParams];
    if (
      data['type'] !== Constants.Deletesuspended &&
      !selectionId.includes(data['selectionId']) &&
      data.betTypeRef !== Constants.BBB
    ) {
      selectionId.push(data['selectionId']);
      if (data['selectionId']) {
        PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
          event: TRACKING_CONSTANTS.BET_ADD_NEW,
          data: {
            customerId: getCookie('custId'),
            selectionId: data['selectionId'],
            location: this.props.appConfig?.requestURL,
            product: this.props.appConfig?.requestURL
              .split('/')
              .filter(v => v)[0],
          },
        });
      }
    } else {
      /* remove cond. (when your click 2 time )
      click on X btn */
      if (data.betTypeRef !== Constants.BBB)
        selectionId.splice(selectionId.indexOf(data['selectionId']), 1);
      if (data.betTypeRef === Constants.BBB)
        builderParams.splice(builderParams.indexOf(data['selectionId']), 1);

      lsd = removeFromLocalStorage(data, Constants.LSKEY_UK);
      this.props.cleanBets({ type: 'STORE_DATA', data: { betTemplate: lsd } });
      // when selectionId length is 0 it mean on one bet selected
      if (
        Array.isArray(selectionId) &&
        selectionId.length === 0 &&
        builderParams.length === 0
      ) {
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_UK,
          action: 'set',
          keyValue: [],
        });
        this.props.cleanBets({ type: 'STORE_DATA', data: [] });
        PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
      }
    }

    if (selectionId.length > 0) selectionId = selectionId.filter(item => item);

    if (selectionId.length === 1) {
      setterGetterFromLocalstorage({
        keyName: 'retainbettemplate',
        keyValue: ['CHECKED'],
        action: 'set',
      });
    }

    if (
      data['type'] !== 'deleteall' &&
      selectionId.length >= 1 &&
      selectionId.length <= 15
    ) {
      this.setState({
        isShowless: this.props.appConfig?.ismultiplehidden || false,
      });
      this.props.fetchData(
        this.props.url['betTemplate'] + '?br=[' + selectionId + ']',
        null,
        this.props.appConfig,
        data
      );
    } else if (
      data['deleteBet'] &&
      data['betTypeRef'] !== 'BBB' &&
      selectionId.length === 0
    ) {
      this.setState({
        selectionId: selectionId,
        betTemplate: lsd,
      });
      this.props.cleanBets({ type: 'STORE_DATA', data: { betTemplate: lsd } });
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
      this.forceUpdate();
    } else if (
      (data['type'] === Constants.Deletesuspended &&
        selectionId.length === 0) ||
      data['type'] === 'deleteall'
    ) {
      /*  handle for last recoed
      no api hit ,clear localstorage */
      setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_UK,
        action: 'set',
        keyValue: [],
      });
      setterGetterFromLocalstorage({
        keyName: 'buideruseData',
        action: 'set',
        keyValue: [],
      });
      this.props.cleanBets({ type: 'STORE_CLEAR_BETSLIP', data: [] });
      this.setState({
        isShowless: this.props.appConfig?.ismultiplehidden || false,
      });
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
      this.forceUpdate();
    }
  };

  /**
  getDerivedStateFromProps lifecycle method when ever state set of props change this life cycle call
  @param nextProps Object which represent updated property of component  
  @param prevState Object which represent previous property of component
  function returns update value of State
*/
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.apiRes && Object.keys(nextProps.apiRes).length > 0) {
      if (nextProps.placedBet && Object.keys(nextProps.placedBet).length > 0) {
        /*
        all logic of successfully/fail Bet place
        */
        if (nextProps.placedBet.inError) {
          if (
            nextProps.placedBet.statusText === GLOBAL_CONSTANTS.UNAUTHORIZED ||
            nextProps.placedBet.statusText === 'AUTHENTICATION'
          ) {
            // if statusText  comes Unauthorized or status text come 'AUTHENTICATION'
            nextProps.cleanBets({ type: 'POST_STORE_DATA', data: [] });
            PubSub.emit(PubsubEvents.CallLoginPopUp, {
              hitting: true,
            });
            return prevState;
          } else {
            const placeResponse = nextProps.placedBet.response;
            if (
              nextProps.apiRes.priceChangedForBetId &&
              prevState.betTemplate.find(
                b => b.id === nextProps.apiRes.priceChangedForBetId
              )?.priceChangeNotification !== '' &&
              (nextProps.placedBet.response?.status?.messageKey ===
                Constants.PRICE_NOT_CURRENT_ERROR_CODE ||
                nextProps.placedBet.response?.status?.messageKey ===
                  Constants.PRICE_CHANGE_ERROR_CODE)
            ) {
              prevState.betTemplate = prevState.betTemplate.map(b => {
                if (b.id === nextProps.apiRes.priceChangedForBetId) {
                  return {
                    ...b,
                    priceChangeNotification: true,
                  };
                }

                return b;
              });
            }
            return {
              errorObj: {
                isError: true,
                message:
                  // eslint-disable-next-line ternaries/no-empty-ternary
                  nextProps.placedBet.response?.status?.messageKey ===
                    Constants.PRICE_NOT_CURRENT_ERROR_CODE ||
                  nextProps.placedBet.response?.status?.messageKey ===
                    Constants.PRICE_CHANGE_ERROR_CODE
                    ? ''
                    : placeResponse?.status?.devMessage ||
                      placeResponse?.status?.value ||
                      Translations.get('text.server.error'),
                betMsg: placeResponse?.status?.messageArg || '',
              },
              betTemplate: prevState.betTemplate,
              isLogin: prevState.isLogin,
              postApiCall: false,
            };
          }
        } else {
          // here betSlip receipt work
          return {
            betTemplate: prevState.betTemplate,
            betSlipRecipt: nextProps.placedBet.customer,
            isLogin: prevState.isLogin,
            postApiCall: false,
            errorObj: {
              isError: false,
              message: '',
            },
          };
        }
      } else if (
        prevState.selectionId &&
        prevState.selectionId.length === 0 &&
        nextProps.betTemplate.length === 0 &&
        prevState.builderParams.length === 0 &&
        nextProps.builderData.length === 0
      ) {
        const betTemplate = checkSuspendedBet([], Constants.LSKEY_UK);
        const retainBetTemplate = setterGetterFromLocalstorage({
          keyName: 'retainbettemplate',
          action: 'get',
        });
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_UK,
          keyValue:
            retainBetTemplate[0] === 'UNCHECKED' &&
            nextProps?.appConfig?.dismissbettemplateonrefresh
              ? []
              : betTemplate,
          action: 'set',
        });
        return {
          betTemplate: betTemplate,
          selectionId: [],
          isLogin: prevState.isLogin,
          postApiCall: false,
          errorObj: {
            isError: false,
            message: '',
          },
        };
      } else if (
        nextProps.useBet &&
        Object.keys(nextProps.useBet).length !==
          Object.keys(prevState.useBet).length
      ) {
        if (
          Array.isArray(nextProps.placedBet) &&
          nextProps.placedBet.length === 0
        ) {
          //when user click on cross button of api error
          prevState.errorObj = {
            isError: false,
            message: '',
          };
        }
        return prevState;
      } else if (nextProps.betTemplate || nextProps.builderData) {
        const selectionId = new Set();
        const builderParams = new Set();
        let { betTemplate } = nextProps;
        const { builderData } = nextProps;

        if (builderData && builderData.length > 0) {
          betTemplate = mergeBuilderDataInTemplate(betTemplate, builderData);
        }

        if (betTemplate.length > 0) {
          betTemplate = mergeDataWithLocalStorage(
            betTemplate,
            Constants.LSKEY_UK
          );
        }

        betTemplate = checkSuspendedBet(betTemplate, Constants.LSKEY_UK);

        if (Array.isArray(betTemplate)) {
          for (const item of betTemplate) {
            if (item['betTypeRef'] === Constants.BBB) {
              builderParams.add(item.selectionId);
            }
            if (item['betTypeRef'] !== Constants.BBB) {
              selectionId.add(item.selectionId);
            }
            // if amount come in URL then it update in useramt
            if (prevState?.amtObj) {
              const { amtObj } = prevState;
              amtObj.forEach(element => {
                if (element[item['id']]) {
                  const priceData = getDataOfPrice(item.price);
                  updateAmountComeToUrl(item, priceData, element);
                }
              });
            }
            // if amount come form upcoming fav.
            if (prevState?.upcomingFavAmt && item.betModifierBetCount === 1) {
              const { upcomingFavAmt } = prevState;
              upcomingFavouritesAmount(item, upcomingFavAmt);
            }
          }
        }

        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_UK,
          keyValue: betTemplate,
          action: 'set',
        });

        return {
          betTemplate: betTemplate,
          selectionId: [...selectionId],
          builderParams: [...builderParams],
          builderData: builderData,
          isLogin: prevState.isLogin,
          upcomingFavAmt: '',
          errorObj: {
            isError: false,
            message: '',
          },
        };
      } else {
        const betTemplate = checkSuspendedBet([], Constants.LSKEY_UK);
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_UK,
          keyValue: betTemplate,
          action: 'set',
        });
        return {
          ...prevState,
          betTemplate: betTemplate,
          selectionId: [],
          errorObj: {
            isError: false,
            message: '',
          },
        };
      }
    } else {
      return prevState;
    }
  }

  /**
  componentDidUpdate lifecycle method which is call when state change
  @param prevProps Object which represent previous property of component
  logic of emit selectionids to event app and market app 
  */
  componentDidUpdate(prevProps, prevState) {
    if (prevState?.isShowless !== this.state.isShowless) {
      this.showMoreLess(this.state.isShowless);
    }

    if (this.props.betTemplate !== prevProps.betTemplate) {
      this.setBetslipData();
      window.addEventListener('resize', this.resizeBetSlipOnUpdate);
    }
    if (this.props.builderData !== prevProps.builderData) {
      PubSub.emit(PubsubEvents.BUILDER_BET_UPDATE, {});
    }
    if (
      prevProps.betTemplate.length > 0 &&
      this.state.betTemplate.length === 0 &&
      this.props.appConfig.hidebetslip &&
      this.state.openBS
    ) {
      this.handleOpenBS(false);
    }
    if (prevState.openBS !== this.state.openBS) {
      this.setBetSlip(this.state.openBS);
      if (this.props.appConfig.hidebetslip && detectMob()) {
        betInterval = setInterval(() => {
          if (this.state.openBS) {
            PubSub.emit(PubsubEvents.FORCE_PAGE_SCROLL);
          } else {
            clearInterval(betInterval);
          }
        }, 500);
        if (!this.state.openBS && betInterval) {
          clearInterval(betInterval);
        }
      }
    }
    if (window.retainBet && this.props.appConfig.hidebetslip) {
      this.setBetslipData();
    }
  }

  setBetslipData() {
    const localData = setterGetterFromLocalstorage({
      keyName: Constants.LSKEY_UK,
      action: 'get',
    });

    if (localData && localData.length > 0) {
      const { bsid } = getSelectionIds(localData);
      //Pubsub to calculate the available bet count
      PubSub.emit(PubsubEvents.BET_COUNTS, CalCount(localData));
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, bsid);
      if (
        this.props.appConfig.hidebetslip &&
        this.state.betSlipRecipt &&
        Object.keys(this.state.betSlipRecipt).length > 0
      ) {
        PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
      }
    } else {
      PubSub.emit(PubsubEvents.BET_COUNTS, 0);
    }
  }

  //set betslip open close on local storage
  setBetSlip(value) {
    setterGetterFromLocalstorage({
      keyName: Constants.OPEN_BETSLIP,
      action: 'set',
      keyValue: value,
    });
  }

  /** 
  handleing active Tabs 
  @param type string which contain [single,multiple,system]  
  */
  handleActTab = type => {
    this.setState({ actTab: type });
  };

  /**
    handleDelete for handling bet delete
    also it handle for Localstorage 
    @param data object contain    
      @param data['deleteBet'] boolen 
      @param data['selectionId'] number
   */
  handleDelete = data => {
    if (data?.type === GLOBAL_CONSTANTS.DELETESUSPENDED) {
      const updateBets = removeFromLocalStorageDeleteSuspended(
        'deletesuspended',
        Constants.LSKEY_UK
      );
      const selectionId = new Set();
      if (Array.isArray(updateBets)) {
        for (const item of updateBets) {
          if (item.selectionId) selectionId.add(item.selectionId);
        }
      }

      const data = { betTemplate: updateBets };
      this.props.cleanBets({ type: 'STORE_DATA', data: data });
      this.setState(
        {
          selectionId: [...selectionId],
        },
        () => {
          if (
            Array.isArray(this.state.selectionId) &&
            this.state.selectionId.length > 0
          ) {
            this.props.fetchData(
              this.props.url['betTemplate'] +
                '?br=[' +
                this.state.selectionId.filter(item => item) +
                ']',
              null,
              this.props.appConfig
            );
          }
        }
      );
    } else if (data?.deleteBet && data?.betTypeRef === Constants.BBB) {
      const { builderParams, betTemplate, builderData } = this.state;
      // remove data from builder param
      builderParams.splice(builderParams.indexOf(data['selectionId']), 1);
      betTemplate.map((bets, key) => {
        if (
          data['selectionId'] &&
          bets['selectionId'] === data['selectionId']
        ) {
          betTemplate.splice(key, 1);
        }
      });
      // remove data from builderData array
      builderData.map((bets, key) => {
        if (
          data['selectionId'] &&
          bets &&
          bets['selectionId'] === data['selectionId']
        ) {
          builderData.splice(key, 1);
        }
      });
      // set data in store
      const data2 = {};
      data2['betTemplate'] = betTemplate;
      data2['dataB'] = builderData;
      this.props.cleanBets({ type: 'STORE_CLEAR_BETSLIP', data: data2 });
      if (builderParams.length > 0) {
        // when builder bet is more then 1
        this.betbulderApiCall(builderParams);
      } else {
        // when builder bet is 0
        setterGetterFromLocalstorage({
          keyName: Constants.LSKEY_UK,
          keyValue: betTemplate,
          action: 'set',
        });
        setterGetterFromLocalstorage({
          keyName: 'buideruseData',
          keyValue: builderData,
          action: 'set',
        });

        PubSub.emit(PubsubEvents.BUILDER_BET_UPDATE, {});
      }
    } else {
      removeFromLocalStorage(data, Constants.LSKEY_UK);
      PubSub.emit(PubsubEvents.BetslipDelete, { ...data });
    }
  };

  /**
    callSetUserBetInLocalStorage for seting user bet data in localstorage 
    @param data object contain    
      @param data['userStake'] number  
      @param data['totalStake'] number
      @param data['_returns'] number
      @param data['returnsText'] number
      @param data['_returnsFormatted'] number
      @param data['lastModified'] date
      @param data['priceDecimal'] number
      @param data['priceFractional'] number
      @param data['bbFlag'] boolen
      @param data['hasFreebet'] boolen
      @param data['selectedFreebetId'] number
      @param data['freebetCredit'] number
      @param data['freebetCreditFormatted'] number
      @param data['freebetRestrictions'] number
   */
  callSetUserBetInLocalStorage = data => {
    if (data && data['hasFreebet']) {
      // validate free Bet
      validateFreeBet(data, Constants.LSKEY_UK);
    }
    if (data && data['bbFlag']) {
      // validate bet boosters
      validateBetBooster(data, Constants.LSKEY_UK);
    }
    const betSlipData = setUserBetInlocalStorage(data, Constants.LSKEY_UK);
    this.setState(
      {
        betTemplate: betSlipData,
      },
      () => {
        this.props.userBetInformation(data);
      }
    );
  };

  /**
   * handlePlacebet
   * @param {}
   * Posting data to Place Bet api
   */
  handlePlaceBet = (type = null) => {
    const UserAuthData = FSBCustomerSession.getSession();
    if (!UserAuthData) {
      PubSub.emit(PubsubEvents.CallLoginPopUp, {
        hitting: true,
      });
    } else if (type && type === GLOBAL_CONSTANTS.DEPOSIT) {
      if (project?.redirectRestriction) {
        PubSub.emit(PubsubEvents.OPEN_DEPOSIT);
      } else {
        setterGetterFromLocalstorage({
          keyName: 'tabname',
          keyValue: 'deposit',
          action: 'set',
        });
        window.location.replace(accountRedirectUrls.deposit);
      }
    } else if (type && type === GLOBAL_CONSTANTS.LOWBALANCE) {
      const { betTemplate } = this.state;
      const calc = genrateSlips(betTemplate, this.state.tax);
      PubSub.emit(PubsubEvents.OPE_QD, {
        hitting: true,
        fundReq: calc['totalStake'] - UserAuthData.balance.balance,
      });
    } else if (UserAuthData && UserAuthData['accessToken']) {
      this.setState(
        {
          postApiCall: true,
          userAuthData: UserAuthData,
        },
        () => {
          const { betTemplate } = this.state;
          const betsArray = placingBetUser(betTemplate);
          const postObj = {
            br: JSON.stringify(betsArray),
            state: 'Pending',
          };

          this.props.postData(this.props.url['betPost'], postObj);
        }
      );
    } else {
      PubSub.emit(PubsubEvents.CallLoginPopUp, {
        hitting: true,
      });
    }
  };

  /**
   * setErrorObj when error get from post api
   * @param data object content isError , message
   */
  setErrorObj = data => {
    this.setState({ errorObj: data }, () => {
      this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });
    });
  };

  /**
  *when user click on Close button on recept 
   @param {boolean}  retainOtp
   */
  handleReceptCloseButton = retainOtp => {
    if (!retainOtp) {
      window.retainBet = false;
      this.setState(
        {
          selectionId: [],
          betTemplate: [],
        },
        () => {
          this.props.cleanBets({ type: 'STORE_CLEAR_BETSLIP', data: [] });
          setterGetterFromLocalstorage({
            keyName: Constants.LSKEY_UK,
            keyValue: [],
            action: 'set',
          });
        }
      );
      // remove form Event APp
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
    } else {
      window.retainBet = true;
      // if retain option is true then remove userbets
      const { betTemplate, builderData } = this.state;

      for (const bet of betTemplate) {
        retainCleanupBet(bet);
      }
      for (const bet of builderData) {
        retainCleanupBet(bet);
      }
      setterGetterFromLocalstorage({
        keyName: Constants.LSKEY_UK,
        keyValue: betTemplate,
        action: 'set',
      });
      //on retain clear builder user stake
      setterGetterFromLocalstorage({
        keyName: Constants.BUILDERUSEDATA,
        action: 'set',
        keyValue: [],
      });

      this.setState(
        {
          betTemplate: betTemplate,
        },
        () => {
          const data = {};
          data['betTemplate'] = betTemplate;
          data['builderData'] = builderData;
          this.props.cleanBets({ type: 'STORE_DATA', data: data });
        }
      );
    }
    this.props.cleanBets({ type: 'POST_STORE_DATA', data: [] });

    PubSub.emit(PubsubEvents.CLOSE_RECEIPT, false);
    this.setState({
      useBet: {},
      betSlipRecipt: {},
    });
  };
  /**
   * handle WS accept and decline
   */
  handleWsAcceptDecline = (type, bet) => {
    if (type === GLOBAL_CONSTANTS.ACCEPT) {
      // update localstorage according to new prices which is come to Web socket
      if (bet) {
        // when user click accept all button from summary section
        let retAmt = '';
        let totalAmt = '';

        if (bet?.betModifierBetCount && bet.priceDecimal) {
          totalAmt = bet.betModifierBetCount * parseFloat(bet.userStake);
          retAmt = totalAmt * bet.priceDecimal;
        }

        bet['priceChangeNotification'] = false;
        bet['updateNewPrice'] = true;
        bet['totalStake'] = totalAmt;
        bet['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
        bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
        this.callSetUserBetInLocalStorage(bet);
      } else {
        // when user click accept all button from summary section
        const { betTemplate } = this.state;
        for (const bet of betTemplate) {
          let retAmt = '';
          let totalAmt = '';

          if (bet?.betModifierBetCount) {
            totalAmt = bet.betModifierBetCount * parseFloat(bet.userStake);
            retAmt = totalAmt * bet.priceDecimal;
          }

          bet['priceChangeNotification'] = false;
          bet['totalStake'] = totalAmt;
          bet['updateNewPrice'] = true;
          bet['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
          bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
          this.callSetUserBetInLocalStorage(bet);
        }
      }

      let selectionId = new Array();

      selectionId = [...this.state.selectionId];
      selectionId = selectionId.filter(item => item);
      this.setState({ isShowless: this.props.appConfig?.ismultiplehidden });
      this.props.fetchData(
        this.props.url['betTemplate'] + '?br=[' + selectionId + ']',
        null,
        this.props.appConfig
      );
    } else if (type === GLOBAL_CONSTANTS.DECLINE) {
      this.handleDelete({ deleteBet: true, selectionId: bet.selectionId });
    }
  };
  /**
   * handle Notification Pop up
   * @param {string} type
   */
  handleActTabNotificationPopup = (type, apiCall = true) => {
    this.setState(
      {
        notificationParam: type,
      },
      () => {
        // set odd moment in LS
        setterGetterFromLocalstorage({
          keyName: Constants.ODDMOMENT,
          keyValue: type,
          action: 'set',
        });
        if (apiCall) {
          this.props.postUserOddPreferences(
            this.props.url['priceAcceptance'],
            type
          );
        }
      }
    );
  };
  /**
   * handle call of bet builder api async.
   * @param {array} params
   */
  betbulderApiCall = async params => {
    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();

    let text = '?eventId=';

    if (UserAuthData && UserAuthData?.['accessToken']) {
      text = '?access_token=' + UserAuthData['accessToken'] + '&eventId=';
    }

    for (let i = 0; i < params.length; i++) {
      setTimeout(async () => {
        await this.props.postDataAsync(
          this.props.url['betBuilder'] + text + params[i]
        );
      }, 500);
    }
  };
  /**
   * handle Toggle in Mobile
   * @param {bool} type
   */

  handleOpenBS = type => {
    this.setState(
      {
        openBS: type,
      },
      () => {
        const isMobile = detectMob();
        // betslip open/close state
        if (type) {
          document.querySelector('.main-right').classList.remove('mr-inactive');
          document.querySelector('.main-right').classList.add('mr-active');
          document.querySelector('.betslip').style.height = '100%';
          if (this.props.appConfig.hidebetslip && isMobile) {
            setBetslipHeight(true, this.state.isLogin);
          }
        } else {
          if (this.props.appConfig.hidebetslip && isMobile) {
            setBetslipHeight(false, this.state.isLogin);
          }
          document.querySelector('.main-right').classList.remove('mr-active');
        }
        PubSub.emit(PubsubEvents.BETSLIP_EXPAND, type);
      }
    );
  };

  // show more show less for multiple Bet
  showMoreLess = flag => {
    const minBetCount = getMinimalBetModifierBetCount(
      this.state.betTemplate || []
    );
    const betTemplate = this.state.betTemplate.map(bet => {
      const betObj = {
        ...bet,
      };
      if (
        bet.multiple &&
        (bet.betModifierBetCount > minBetCount ||
          (bet.betModifier?.length > 0 &&
            bet.betModifier[0]?.betCount > minBetCount))
      ) {
        betObj.disappear = flag;
      }
      return betObj;
    });
    this.setState({ isShowless: flag, betTemplate: betTemplate }, () => {
      betTemplate.forEach(bet => {
        this.callSetUserBetInLocalStorage(bet);
      });
    });
  };
  // call UserAuth
  userAuthStuff = UserAuthData => {
    if (UserAuthData && UserAuthData['accessToken']) {
      this.setState(
        {
          isLogin: true,
          userAuthData: UserAuthData,
        },
        () => {
          this.ApiCall({});
          this.handleActTabNotificationPopup(
            (UserAuthData?.['platformConfig']?.['value'] &&
              notificationValues(UserAuthData['platformConfig']['value'])) ||
              Constants.BLOCK,
            false
          );
        }
      );
    } else {
      this.handleActTabNotificationPopup(Constants.BLOCK, false);
    }
  };

  render() {
    if (!this.state.render) {
      return <React.Fragment />;
    }
    const openBS = this.state.openBS;
    const bottomNavLinks = this.state.isLogin
      ? this.state.bottomNavData?.loginLinks
      : this.state.bottomNavData?.logoutLinks;
    const isBetslipInBottomNav =
      bottomNavLinks?.findIndex(link => link === GLOBAL_CONSTANTS.BETSLIP) > 0;
    const adjustBottomHeight = bottomNavLinks && !isBetslipInBottomNav;
    if (
      Object.keys(this.props.apiRes).length > 0 &&
      Object.keys(this.state.betTemplate).length > 0
    ) {
      const betSlipRecipt =
        this.state.betSlipRecipt && this.state.betSlipRecipt;
      let suspended = false;
      let betTemplate = this.state.betTemplate && [...this.state.betTemplate];

      const errorObj = this.state.errorObj;
      if (betTemplate && betTemplate.length === 0) {
        betTemplate = checkSuspendedBet(betTemplate, Constants.LSKEY_UK);
      }
      for (const bet of betTemplate) {
        if (bet.active === false) {
          suspended = true;
        }
      }
      /* 
      WHEN REGION IS uk &   displaymultiplesfirst 
       */
      betTemplate = genrateUkBetTemplate(
        betTemplate,
        this.props?.appConfig?.displaymultiplesfirst || false,
        this.props?.appConfig?.showmultipleexpandable || false,
        this.props.appConfig?.translatemultiple
      );

      if (this.state.builderParams.length > 0) {
        betTemplate = handleSortCreateBet(betTemplate);
      }

      /* what properties you need to pass just put in Object */
      const properties = {
        betTemplate: betTemplate,
        handleActTab: this.handleActTab,
        actTab: this.state.actTab,
        handleDelete: this.handleDelete,
        callSetUserBetInLocalStorage: this.callSetUserBetInLocalStorage,
        suspended: suspended,
        handlePlaceBet: this.handlePlaceBet,
        isLogin: this.state.isLogin,
        UserAuthData: this.state.userAuthData,
        region: this.props.appConfig.region,
        isError: errorObj.isError,
        tax: this.props.appConfig.tax,
        handleWsAcceptDecline: this.handleWsAcceptDecline,
        oddsFormat: this.state.oddsFormat,
        postApiCall: this.state.postApiCall,
        boosterinfoextended: this.props?.appConfig?.boosterinfoextended,
        hidenextbetbonus: this.props?.appConfig?.hidenextbetbonus?.split(','),
        taxbycountry: this.props.appConfig.taxbycountry,
        handleActTabNotificationPopup: this.handleActTabNotificationPopup,
        showOddMovements: this.props?.appConfig?.showoddmovements || false,
        card: this.state.card,
        quickLink: this.props?.appConfig?.quicklink,
        quickName: this.props?.appConfig?.quickname,
        notificationParam: this.state.notificationParam,
        openBS: openBS,
        handleOpenBS: this.handleOpenBS,
        showMoreLess: this.showMoreLess,
        isShowless: this.state.isShowless,
        hidebetslip: this.props?.appConfig?.hidebetslip,
        bottomNavData: this.state.bottomNavData,
      };
      if (betSlipRecipt && Object.keys(betSlipRecipt).length > 0) {
        return (
          <FSBTheme theme={this.state.theme}>
            <B
              id="betslip_container"
              openBS={openBS}
              adjustBottomHeight={adjustBottomHeight}
              betslipExpand={this.state.betslipExpand}
            >
              <ReceiptContainer
                appConfig={this.props?.appConfig}
                oddsFormat={this.state.oddsFormat}
                betSlipRecipt={betSlipRecipt}
                handleReceptCloseButton={this.handleReceptCloseButton}
                oddsMovementFirstOpened={this.state.oddsMovementFirstOpened}
                bottomNavData={this.state.bottomNavData}
              />
            </B>
          </FSBTheme>
        );
      } else {
        return (
          <FSBTheme theme={this.state.theme}>
            <B
              id="betslip_container"
              openBS={openBS}
              adjustBottomHeight={adjustBottomHeight}
              betslipExpand={this.state.betslipExpand}
            >
              <BetSlipContainer
                errorObj={errorObj}
                betTemplate={this.state.betTemplate}
                properties={properties}
                UserAuthData={this.state.userAuthData}
                setErrorObj={this.setErrorObj}
                appConfig={this.props?.appConfig}
                countAccaBet={this.props?.countAccaBet}
              />
            </B>
          </FSBTheme>
        );
      }
    } else {
      return (
        <FSBTheme theme={this.state.theme}>
          <B
            id="betslip_container"
            openBS={openBS}
            adjustBottomHeight={adjustBottomHeight}
            betslipExpand={this.state.betslipExpand}
          >
            <BS
              isSafari={this.state.isSafari}
              oddsMovementFirstOpened={this.state.oddsMovementFirstOpened}
              openBS={openBS}
            >
              <Header
                betTemplate={[]}
                isLogin={this.state.isLogin}
                handleOpenBS={this.handleOpenBS}
                openBS={openBS}
                appConfig={this.props?.appConfig}
              />
              <NoBetComponent
                quickLink={this.props?.appConfig?.quicklink}
                quickName={this.props?.appConfig?.quickname}
              />
            </BS>
          </B>
        </FSBTheme>
      );
    }
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    userBetInformation: bet => {
      dispatch(userBetInfo(bet));
    },
    cleanBets: betObj => {
      dispatch(betObj);
    },
    poststoreData: data => {
      dispatch(poststoreData(data));
    },
  };
};

BetslipReact.propTypes = {
  apiRes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placedBet: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  betTemplate: PropTypes.array,
  builderData: PropTypes.array,
  useBet: PropTypes.object,
  url: PropTypes.object,
  fetchData: PropTypes.func,
  postData: PropTypes.func,
  userBetInformation: PropTypes.func,
  cleanBets: PropTypes.func,
  oddsFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  appConfig: PropTypes.object,
  postDataAsync: PropTypes.func,
  countAccaBet: PropTypes.number,
  postUserOddPreferences: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(BetslipReact);
