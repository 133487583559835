import React from 'react';
import PropTypes from 'prop-types';
import { getCookie } from 'Services/cookie/core__cookies';

//To manage displaying number of racecards based on current screen size
export const BP = {
  BREAKPOINT_L: 2,
  BREAKPOINT_M: 1,
  BREAKPOINT_S: 1,
  BREAKPOINT_XS: 0,
};

export const RACING_CONSTANTS = {
  NULL: 'null',
  NOT_AVAILABLE: 'Not Available',
  TICK: 'Tick',
  CROSS: 'Cross',
  TURF: 'Turf',
  STRAIGHT: 'Straight',
  LEFT_HANDED: 'Left-handed',
  RIGHT_HANDED: 'Right-handed',
  FIGURE_EIGHT: 'Figure-eight',
  HURDLE: 'Hurdle',
  CHASE: 'Chase',
  N_H_FLAT: 'N_H_Flat',
  FLAT: 'Flat',
  WIN_ONLY: 'Win Only',
  FLUMEN_AVAILABILITY: 'flumen_availability',
  WEIGHT: 'weight',
  ATR: 'ATR',
  RMG: 'RMG',
  DOGS_TRAPS: 'Win by trap',
  AT: 'at',
  ODDS: 'odds',
  JAPANESE_LANGUAGE: 'ja',
  WIN_BY_NAME: 'win by name',
  RACE_WINNER: 'Race Winner',
  PERFORM: 'perform',
  PERFORMWB: 'performwb',
};

export const images = {
  TICK: 'https://assets.fsbtech.com/fitzdares/images/racecard_light/tick.png',
  CROSS: 'https://assets.fsbtech.com/fitzdares/images/racecard_light/cross.png',
  YELLOW_STAR:
    'http://assets.fsbtech.com/fitzdares/images/racecard/star-yellow.png',
  GREY_STAR:
    'http://assets.fsbtech.com/fitzdares/images/racecard/star-grey.png',
  DISTANCE:
    'https://assets.fsbtech.com/fitzdares/images/racecard_light/post.png',
  TURF: 'https://assets.fsbtech.com/fitzdares/images/racecard_light/turf.png',
};

//constructs the image source url for racing surface
//surface - (type:string) - It is the surface of race course as coming from metadata
export const getSurfaceImgSrc = surface => {
  const base = 'https://assets.fsbtech.com/fitzdares/images/racecard_light/';
  if (surface === RACING_CONSTANTS.TURF) {
    return base + 'turf.png';
  } else {
    return base + 'dirt.png';
  }
};

//constructs the image source url of the race course weather condition
//weatherType - (type:string) - It is the weather condition of the race course as coming from metadata
export const getWeatherImgSrc = weatherType => {
  //below are the weather image mappings as per documentation
  const weatherMappings = [
    { type: 'Bright', img: 'Sunny.png' },
    { type: 'Bright but Breezy', img: 'Sunny.png' },
    { type: 'Bright but Cold', img: 'Sunny.png' },
    { type: 'Bright but Windy', img: 'Sunny.png' },
    { type: 'Cloudy', img: 'White_cloud.png' },
    { type: 'Cloudy & Windy', img: 'White_cloud.png' },
    { type: 'Drizzle', img: 'Drizzle.png' },
    { type: 'Dry', img: 'Sunny.png' },
    { type: 'Dry & Bright', img: 'Sunny.png' },
    { type: 'Dry & Cloudy', img: 'Sunny_intervals.png' },
    { type: 'Dry & Windy', img: 'Sunny.png' },
    { type: 'Fine', img: 'Sunny.png' },
    { type: 'Fine & Blustery', img: 'Sunny.png' },
    { type: 'Fine & Bright', img: 'Sunny.png' },
    { type: 'Fine but Cloudy', img: 'Sunny_intervals.png' },
    { type: 'Fine & Cold', img: 'Sunny.png' },
    { type: 'Fine & Dry', img: 'Sunny.png' },
    { type: 'Fine & Sunny', img: 'Sunny.png' },
    { type: 'Fine & Warm', img: 'Sunny.png' },
    { type: 'Fine & Windy', img: 'Sunny.png' },
    { type: 'Foggy', img: 'Fog.png' },
    { type: 'Foggy & Cold', img: 'Fog.png' },
    { type: 'Hail Showers', img: 'Hail_shower.png' },
    { type: 'Hazy', img: 'Hazy.png' },
    { type: 'Heavy Rain', img: 'Heavy_rain.png' },
    { type: 'Heavy Rain Showers', img: 'Heavy_rain_shower.png' },
    { type: 'Heavy Snow', img: 'Heavy_snow.png' },
    { type: 'Heavy Snow Showers', img: 'Heavy_snow_shower.png' },
    { type: 'Light Rain', img: 'Light_rain.png' },
    { type: 'Light Rain Showers', img: 'Light_rain_shower.png' },
    { type: 'Light Snow', img: 'Light_snow.png' },
    { type: 'Light Snow Showers', img: 'Light_snow_shower.png' },
    { type: 'Misty', img: 'Mist.png' },
    { type: 'Misty & Cold', img: 'Mist.png' },
    { type: 'Mostly Cloudy', img: 'White_cloud.png' },
    { type: 'Overcast', img: 'White_cloud.png' },
    { type: 'Overcast & Cold', img: 'White_cloud.png' },
    { type: 'Overcast & Showers', img: 'Light_rain_shower.png' },
    { type: 'Overcast & Warm', img: 'Dark_cloud.png' },
    { type: 'Overcast & Windy', img: 'Dark_cloud.png' },
    { type: 'Raining', img: 'Light_rain.png' },
    { type: 'Raining & Cold', img: 'Light_rain.png' },
    { type: 'Raining & Windy', img: 'Light_rain.png' },
    { type: 'Showers', img: 'Light_rain_shower.png' },
    { type: 'Snow & Sleet', img: 'Sleet_shower.png' },
    { type: 'Snowing', img: 'Heavy_snow.png' },
    { type: 'Sunny', img: 'Sunny.png' },
    { type: 'Sunny Intervals', img: 'Sunny_intervals.png' },
    { type: 'Sunny & Cold', img: 'Sunny.png' },
    { type: 'Sunny & Warm', img: 'Sunny.png' },
    { type: 'Sunny & Windy', img: 'Sunny.png' },
    { type: 'Thundery Showers', img: 'Thundery_shower.png' },
    { type: 'Unsettled', img: 'Sunny_intervals.png' },
    { type: 'Unsettled & Cold', img: 'Sunny_intervals.png' },
    { type: 'Unsettled & Showers', img: 'Light_rain_shower.png' },
    { type: 'Unsettled & Warm', img: 'Sunny_intervals.png' },
    { type: 'Unsettled & Windy', img: 'Sunny_intervals.png' },
    { type: 'Windy', img: 'Windy.png' },
    { type: 'Windy with Showers', img: 'Light_rain_shower.png' },
  ];
  const base = 'https://assets.fsbtech.com/fitzdares/images/weather_for_light/';
  let img;
  if (weatherType) {
    weatherMappings.map(weather => {
      if (weatherType === weather.type) {
        img = weather.img;
      }
    });
  }
  return base + img;
};

//constructs the image source url for race course orientation
//course - (type:string) - It is the course orientation of race course as coming from metadata
export const getCourseImgSrc = course => {
  const base = 'https://assets.fsbtech.com/fitzdares/images/racecard_light/';
  switch (course) {
    case RACING_CONSTANTS.STRAIGHT:
      return base + 'straight.png';
    case RACING_CONSTANTS.LEFT_HANDED:
      return base + 'left-handed.png';
    case RACING_CONSTANTS.RIGHT_HANDED:
      return base + 'right-handed.png';
    case RACING_CONSTANTS.FIGURE_EIGHT:
      return base + 'figure%20eight.png';
  }
};

//this function removes any units that are 0 in the race track distance.
//distance - (type: string) - It is the distance of the race track as coming from the metadata
export const getDistanceWithoutZero = distance => {
  const stringSplitArr = distance.split(' ');
  const trimmed = stringSplitArr.filter(x => {
    if (!x.includes(0) || x.length > 2) {
      return x;
    }
  });
  return trimmed.join(' ');
};

//Constructs the flag image of trip and going
//flag - (type:string) - Flag status as coming from metadata
export const getFlagImgSrc = flag => {
  switch (flag) {
    case RACING_CONSTANTS.TICK:
      return images.TICK;
    case RACING_CONSTANTS.CROSS:
      return images.CROSS;
  }
};

//returns the race type as per metadata field - raceType
//type - (type:string) - the race type as per metadata
//Translations - (type:func) - for translating static text
export const getRaceType = (type, Translations) => {
  switch (type) {
    case RACING_CONSTANTS.HURDLE:
      return type;
    case RACING_CONSTANTS.CHASE:
      return type;
    case RACING_CONSTANTS.N_H_FLAT:
      return Translations.get('racing.national_hunt_flat');
  }
};

//returns the starting time in hours if more than 1 hour to go and in mins if less than 1 hour to go for a race to start
//scheduledTimeInMs - (type:num) - the race scheduled time as per metadata
//Translations - (type:func) - for translating static text
export const getTimeToStart = (scheduledTimeInMs, Translations) => {
  const currentTimeInHours = Date.now() / 3600000;
  const scheduledTimeInHours = scheduledTimeInMs / 3600000;
  const timeDifferenceInHours = scheduledTimeInHours - currentTimeInHours;
  if (currentTimeInHours > scheduledTimeInHours) {
    return;
  } else if (timeDifferenceInHours > 1) {
    const roundedHours = Math.round(timeDifferenceInHours);
    return `${Translations.get('racing.starting_in')} ${
      roundedHours === 1
        ? roundedHours + ' ' + Translations.get('racing.hour')
        : roundedHours + ' ' + Translations.get('racing.hours')
    }`;
  } else {
    return `${Translations.get('racing.starting_in')} 
      ${Math.round(timeDifferenceInHours * 60)} ${Translations.get(
      'racing.mins'
    )}`;
  }
};

export const checkInfoAvailability = outcomes => {
  const {
    jockeyStars,
    trainerStars,
    tripFlag,
    goingFlag,
    owner,
    dam,
    sire,
    breeder,
  } = outcomes;
  if (
    jockeyStars ||
    trainerStars ||
    tripFlag ||
    goingFlag ||
    owner ||
    dam ||
    sire ||
    breeder
  ) {
    return true;
  } else {
    return false;
  }
};

export const getInfoBasedOnProvider = (
  isLoggedIn,
  provider,
  raceTime,
  Translations
) => {
  if (isLoggedIn) {
    if (provider === RACING_CONSTANTS.ATR) {
      return `${Translations.get('racing.atr_info')} ${raceTime}`;
    } else if (provider === RACING_CONSTANTS.RMG) {
      return Translations.get('racing.rmg_info');
    } else {
      return Translations.get('racing.watch');
    }
  } else {
    return Translations.get('racing.watch');
  }
};

//Format key for translation
export const formatTranslationKey = text => {
  if (text) {
    return text.toLowerCase().split(' ').join('_');
  }
  return text;
};

//Format key for trap translation
export const formatTrapTranslationKey = (text, Translations) => {
  if (text) {
    const splitedText = text.split(' ');
    if (splitedText.length === 2) {
      return Translations.get(`text.${splitedText[0]}`) + ` ${splitedText[1]}`;
    }
    return text;
  }
};

//Format key for trap translation
export const formatPlaceTermsTranslationKey = (text, Translations) => {
  const language = getCookie('localeKey');
  if (text) {
    if (language !== RACING_CONSTANTS.JAPANESE_LANGUAGE) {
      return text;
    }
    const splitedText = text.split(' ');
    if (splitedText.length === 4) {
      if (
        splitedText[1] === RACING_CONSTANTS.AT &&
        splitedText[3] === RACING_CONSTANTS.ODDS
      ) {
        return (
          Translations.get(`text.${splitedText[3]}`) +
          ` ${splitedText[2]} ${splitedText[0]} ` +
          Translations.get(`text.${splitedText[1]}`)
        );
      }
    }
    return formatTranslationKey(text);
  }
};

/**
 * This function is used to get the url parameters.
 * @param {String} location
 */
export const getUrlPathParams = url => {
  try {
    const location = url ? url.trim() : window.location.pathname;

    const params = location.split('/').filter(function (i) {
      return i;
    });
    return params;
  } catch (err) {
    return null;
  }
};

export const SortIcon = ({ theme }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.8 3.73333V15.4667H2.66667V3.73333H0L3.73333 0L7.46667 3.73333H4.8ZM9.06667 12.2667V0.533333H11.2V12.2667H13.8667L10.1333 16L6.4 12.2667H9.06667Z"
      fill={theme === 'dark' ? 'white' : 'black'}
      fillOpacity="0.87"
    />
  </svg>
);

SortIcon.propTypes = {
  theme: PropTypes.string,
};

export const createRmgScript = newRmgMetadata => {
  const scriptElement = document.createElement('script');

  scriptElement.type = 'text/javascript';
  scriptElement.src = newRmgMetadata.src;
  scriptElement.async = true;

  scriptElement.setAttribute('data-uuid', newRmgMetadata['data-uuid']);
  scriptElement.setAttribute(
    'data-outletkey',
    newRmgMetadata['data-outletKey']
  );
  scriptElement.setAttribute('data-apptype', 'csb');
  scriptElement.setAttribute('data-env', newRmgMetadata['data-env']);
  scriptElement.setAttribute(
    'data-streamuuid',
    newRmgMetadata['data-streamuuid']
  );
  scriptElement.setAttribute(
    'data-streamuser',
    newRmgMetadata['data-streamuser']
  );
  scriptElement.setAttribute(
    'data-oauthtoken',
    newRmgMetadata['data-oauthtoken']
  );
  scriptElement.setAttribute('data-rmg', true);
  scriptElement.setAttribute('data-width', '100%');
  scriptElement.setAttribute('data-height', '100%');
  scriptElement.setAttribute('data-flyaway', true);
  scriptElement.setAttribute('data-flyaway-draggable', true);

  return scriptElement;
};
