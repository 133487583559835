import {
  BSI,
  BSM,
  BAMY,
  BA,
  BS,
  BHLF,
  BHL,
  BHU,
  SUMMARY_PRICE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__head';
import styled from 'styled-components';
import { GREYS, BRAND, FONT } from '../../../globals/colours';
import { BREAKPOINT_L } from '../../../globals/breakpoints';

const BAMYJN = styled(BAMY)`
  display: none;
`;
const BHUJN = styled(BHU)`
  background-color: ${BRAND.primary};
  border-top: 1px solid ${GREYS.silverD};
  box-shadow: none;
  border-bottom: none;
  @media (max-width: ${BREAKPOINT_L}) {
    background-color: ${BRAND.primaryD};
  }
`;
const BAJN = styled(BA)`
  color: ${GREYS.white};
  border: 0;
`;
const BSJN = styled(BS)`
  width: 13px;
  height: 12px;
  box-shadow: none;
  border-radius: 6px;
  background-color: ${BRAND.highlight};
  color: ${FONT.light};
  top: 0px;
  font-size: 10px;
  font-weight: 700;
  right: -16px;
  top: 3.2px;
`;
const BSIJN = styled(BSI)`
  border-bottom: 2px solid ${GREYS.white};
  border-right: 2px solid ${GREYS.white};
`;

const BSMJN = styled(BSM)`
  background-color: ${BRAND.highlight};
  color: ${GREYS.white};
`;
const BHLJN = styled(BHL)`
  padding-right: 4px;
`;
const SUMMARY_PRICE_EX = styled(SUMMARY_PRICE)`
  color: ${BRAND.secondary};
`;
export {
  BSIJN as BSI,
  BSMJN as BSM,
  BAMYJN as BAMY,
  BAJN as BA,
  BSJN as BS,
  BHLF,
  BHLJN as BHL,
  BHUJN as BHU,
  SUMMARY_PRICE_EX as SUMMARY_PRICE,
};
