import {
  HD,
  RU,
  SD,
  RCD,
  RBB,
  CD,
  ED,
  DCD,
  DCN,
  SEAr,
  RDC,
  HDEP,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesSelection';
import styled from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const HD_EX = styled(HD)`
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 16px;
  }
`;

const RCD_EX = styled(RCD)`
  color: ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 16px;
  }
`;

const RBB_EX = styled(RBB)`
  border-right: 1px solid ${GREYS.white};
`;

const DCD_EX = styled(DCD)`
  border-bottom: 1px solid ${FONT.primary};
`;

const CD_EX = styled(CD)`
  background-color: ${GREYS.silverD};
`;

const DCN_EX = styled(DCN)`
  color: ${GREYS.white};
`;

const RU_EX = styled(RU)`
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 16px;
  }
`;

const HDEP_EX = styled(HDEP)`
  color: ${GREYS.black};
  padding: 16px;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${BRAND.secondary};
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  b {
    margin: 4px;
    color: ${GREYS.black};
  }
  div {
    color: ${GREYS.black};
    white-space: nowrap;
  }
`;

export {
  HDEP_EX as HDEP,
  HD_EX as HD,
  RU_EX as RU,
  SD,
  RCD_EX as RCD,
  RBB_EX as RBB,
  CD_EX as CD,
  ED,
  DCD_EX as DCD,
  DCN_EX as DCN,
  SEAr,
  RDC,
};
