import React from 'react';
import PropTypes from 'prop-types';
import {
  BE,
  MAXBETUPDATE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__stakeNotification';
import { Translations } from '../../../../../core__betslip-UK-app';
import { BetslipCurrency } from './core__currency';
import { Constants } from '../../../../../core__betslip-constants';
export const StakeNotification = ({
  isActive,
  userStake,
  currencySymbol,
  textMsg,
  setErrorObj,
  handleFocusOut,
  betMf,
  price,
  bet,
}) => {
  return (
    <React.Fragment>
      {userStake && (
        <section>
          <BE active={isActive}>
            {Translations.get(`text.${textMsg}.stake`)}
            &nbsp;
            <BetslipCurrency symbol={currencySymbol} />
            {userStake}
            &nbsp;
            {textMsg === Constants.MAX && (
              <MAXBETUPDATE
                onClick={() => {
                  setErrorObj({
                    isError: false,
                    message: '',
                  });
                  bet.betTypeRef !== Constants.BBB
                    ? handleFocusOut(userStake, betMf, price, bet.bbFlag)
                    : handleFocusOut(
                        userStake,
                        { betTypeRef: Constants.BBB, retn: price },
                        price,
                        false
                      );
                }}
              >
                {Translations.get('text.update')}
              </MAXBETUPDATE>
            )}
          </BE>
        </section>
      )}
    </React.Fragment>
  );
};
StakeNotification.propTypes = {
  isActive: PropTypes.bool,
  userStake: PropTypes.number,
  currencySymbol: PropTypes.string,
  textMsg: PropTypes.string,
  setErrorObj: PropTypes.func,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bet: PropTypes.object,
  handleFocusOut: PropTypes.func,
};
