import {
  B,
  PC,
  BCS,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__header';
import styled from 'styled-components';
import { FONT, BRAND } from 'UI/globals/colours';
const BJN = styled(B)`
  border-top: 1px solid ${BRAND.primary};
  font-size: 14px;
  color: ${FONT.secondary};
  width: 100%;
`;
const PCJN = styled(PC)`
  border-top: 1px solid ${BRAND.primary};
`;
const BCSJN = styled(BCS)`
  color: ${FONT.light};
`;
export { BJN as B, PCJN as PC, BCSJN as BCS };
