import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorComponent, NotificationComponent } from './elements';

/**
 @param {array} betTemplate
 @param {string} message
 @param {func} setErrorObj
 @param {bool} setErrorObj
*/

export const MessageComponent = ({
  betTemplate,
  message,
  setErrorObj,
  isError,
  isValidStake,
  isMaxBetExceed,
  useChatWithBroker,
}) => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    let nf = false;
    for (const bet of betTemplate) {
      if (bet.priceChangeNotification) {
        nf = true;
      }
    }
    setDisplay(nf);
  }, [betTemplate]);

  return (
    <React.Fragment>
      {isError && (
        <ErrorComponent
          useChatWithBroker={useChatWithBroker}
          setErrorObj={setErrorObj}
          message={message}
          isMaxBetExceed={isMaxBetExceed}
        />
      )}
      {(display || (!isValidStake && isValidStake !== undefined)) && (
        <NotificationComponent showStakePopup={!(display || isValidStake)} />
      )}
    </React.Fragment>
  );
};

MessageComponent.propTypes = {
  betTemplate: PropTypes.array,
  message: PropTypes.string,
  setErrorObj: PropTypes.func,
  isError: PropTypes.bool,
  isValidStake: PropTypes.bool,
  isMaxBetExceed: PropTypes.bool,
  useChatWithBroker: PropTypes.bool,
};
