import {
  ADIV,
  AS,
  AD,
  Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__topSection';
import styled from 'styled-components';
import { FONT, GREYS } from '../../../globals/colours';

const Ah3JN = styled(Ah3)`
  color: ${FONT.light};
`;

const ApdateJN = styled(Apdate)`
  color: ${GREYS.doveD};
`;
const Ch6JN = styled(Ch6)`
  color: ${GREYS.white};
`;
const Ch5JN = styled(Ch5)`
  color: ${GREYS.white};
`;
const ASJN = styled(AS)`
  color: ${FONT.lightMute};
`;
const ADJN = styled(AD)`
  background-color: initial;
`;
export {
  ADIV,
  ASJN as AS,
  ADJN as AD,
  Ah3JN as Ah3,
  ApdateJN as Apdate,
  C3,
  C31,
  CL,
  Ch6JN as Ch6,
  Ch5JN as Ch5,
};
