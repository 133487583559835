import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BetslipHeader } from './elements';
import { CalCount, detectMob } from '../../../../core__betslip-utils';
import { ERR } from 'UI/apps/BetslipUKApp/BetslipContainer/BetBox';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
/* 
  betslip header
that show header of betslip
params  
betTemplate (for count )

*/

const Header = ({
  betTemplate,
  isLogin,
  handleOpenBS,
  openBS,
  appConfig,
  oddsFormat,
}) => {
  const count = CalCount(betTemplate);
  const [downIcon, handledownIcon] = useState(openBS);
  const [isMobile, setIsMobile] = useState(false);
  // hide empty betslip on mobile
  const hideBetSlip = isMobile && count === 0;

  useEffect(() => {
    const isMobileValue = detectMob();
    setIsMobile(isMobileValue);

    const resizeHandler = () => {
      const isMobileValue = detectMob();
      setIsMobile(isMobileValue);
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  //change icon up,down based on betslip if collapsed or expanded
  useEffect(() => {
    handledownIcon(openBS);
  }, [openBS]);

  useEffect(() => {
    // for closing betslip popup in mobile view
    if (hideBetSlip) {
      handleOpenBS(false);
      handledownIcon(false);
    }
  }, [hideBetSlip]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  handle mobile expend and collapse menu  
   */
  const expendandcollapse = () => {
    if (isMobile) {
      if (openBS) {
        handleOpenBS(false);
        handledownIcon(false);
        PubSub.emit(PubsubEvents.CLOSE_BETSLIP);
      } else {
        handleOpenBS(true);
        setTimeout(() => {
          handledownIcon(true);
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (appConfig.hidebetslip) {
      if (!openBS) {
        //Pusbsub event for making the parent scroll fixed
        PubSub.emit(PubsubEvents.SCROLL_LOCK, false);
      } else {
        //Pusbsub event for making the betlsip scroll up
        PubSub.emit(PubsubEvents.SCROLL_UP, true);
        PubSub.emit(PubsubEvents.SCROLL_LOCK, true);
      }
    }
  }, [openBS]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ERR
      betslip={true}
      hide={appConfig?.hidebetslip}
      betOpen={openBS}
      isMobile={isMobile}
      id="betslip-header"
    >
      {(!hideBetSlip || appConfig.hidebetslip) && (
        <BetslipHeader
          count={count}
          actbet="bet"
          isLogin={isLogin}
          expendandcollapse={expendandcollapse}
          downIcon={downIcon}
          betTemplate={betTemplate}
          oddsFormat={oddsFormat}
          appConfig={appConfig}
        />
      )}
    </ERR>
  );
};

export { Header };

Header.propTypes = {
  betTemplate: PropTypes.array,
  isLogin: PropTypes.bool,
  openBS: PropTypes.bool,
  handleOpenBS: PropTypes.func,
  appConfig: PropTypes.object,
  oddsFormat: PropTypes.string,
};
Header.defaultProps = {
  openBS: true,
};
