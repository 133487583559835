import {
  BSSMD,
  BI,
  BetslipContentBetBoxPot,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__stake';
import styled from 'styled-components';
import { GREYS, BRAND, FUNCTIONAL } from '../../../globals/colours';

const BIJN = styled(BI)`
  background-color: ${BRAND.primary};
  ::placeholder {
    color: ${GREYS.dove};
  }
  color: ${GREYS.dove};
`;
const BetslipContentBetBoxPotJN = styled(BetslipContentBetBoxPot)`
  color: ${GREYS.dove};
`;
const BSSMDJN = styled(BSSMD)`
  background-color: ${BRAND.primary};
  border: 1px solid
    ${({ highlighterror }) =>
      (highlighterror && FUNCTIONAL.negative) || BRAND.primary};
`;

export {
  BSSMDJN as BSSMD,
  BIJN as BI,
  BetslipContentBetBoxPotJN as BetslipContentBetBoxPot,
};
