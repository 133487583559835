import {
  WSPOPUP,
  WSP,
  WSUL,
  WSLI1BTN,
  WSLI2BTN,
  TDIV,
  TLI,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__socketMessage';
import styled from 'styled-components';
import { GREYS, FUNCTIONAL, BRAND } from '../../../globals/colours';

const WSPOPUPJN = styled(WSPOPUP)`
  background-color: ${BRAND.primary};
  border: initial;
  height: 66px;
`;
const TDIVJN = styled(TDIV)`
  border-top: 12px solid ${BRAND.primary};
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
`;
const WSLI1BTNJN = styled(WSLI1BTN)`
  border-radius: 4px;
  background-color: ${FUNCTIONAL.positive};
  color: ${GREYS.black};
`;
const WSLI2BTNJN = styled(WSLI2BTN)`
  border-radius: 4px;
  background-color: ${FUNCTIONAL.negative};
  color: ${GREYS.black};
`;
const WSPJN = styled(WSP)`
  color: ${GREYS.white};
  padding-top: 8px;
`;
const WSULJN = styled(WSUL)`
  padding-bottom: 8px;
`;
export {
  WSPOPUPJN as WSPOPUP,
  WSPJN as WSP,
  WSULJN as WSUL,
  WSLI1BTNJN as WSLI1BTN,
  WSLI2BTNJN as WSLI2BTN,
  TDIVJN as TDIV,
  TLI,
};
