import {
  MSSD,
  M2,
  MSB,
  M,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__error';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const M2JN = styled(M2)`
  background-color: ${GREYS.doveD};
`;
const MSSDJN = styled(MSSD)`
  background-color: ${GREYS.doveD};
`;
export { MSSDJN as MSSD, M2JN as M2, MSB, M };
