import styled, { css } from 'styled-components';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

export const NoBet = styled.h3`
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  user-select: none;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  text-align: center;
  margin: 80px 0px;
  i {
    font-size: 100px;
    display: block;
    margin: 8px auto;
  }
`;

export const NB = styled.div`
@media (max-width: ${BREAKPOINT_L}) {
  ${({ openBS }) =>
    !openBS &&
    css`
      height: 0;
    `}

`;
