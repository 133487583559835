import styled, { css } from 'styled-components';
import Label from 'UI/globals/Label';
import { GREYS, FUNCTIONAL } from 'UI/globals/colours';

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  width: 0px;
  opacity: 0;
  margin: 0;
  + .r-l {
    &:before {
      content: '';
      background-color: white;
      border-radius: 100%;
      border: 1px solid ${GREYS.doveD};
      display: flex;
      width: 20px;
      height: 20px;
      position: relative;
      margin-right: 8px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      ${({ animation }) =>
        animation &&
        css`
          transition: all 250ms ease;
        `}
    }
  }
  &:checked {
    + .r-l {
      &:before {
        box-shadow: inset 0 0 0 4px ${GREYS.white};
        border-color: ${FUNCTIONAL.guide};
        background-color: ${FUNCTIONAL.guide};
      }
    }
  }
  &:hover {
    + .r-l {
      &:before {
        border-color: ${GREYS.silver};
      }
    }
  }
  &:focus {
    + .r-l {
      &:before {
        outline: none;
        border-color: ${FUNCTIONAL.guide};
      }
    }
  }
  &:disabled {
    + .r-l {
      &:before {
        box-shadow: inset 0 0 0 4px ${GREYS.dove};
        border-color: ${GREYS.doveD};
        background-color: ${GREYS.dove};
      }
    }
  }
`;

export const L = styled(Label)`
  font-size: 14px;
  margin-bottom: 0;
  display: flex;
`;
export const RC = styled.div`
  display: flex;
`;

export const SurroundingButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  display: inline-flex;
  padding: 0;
  cursor: pointer;
  top: 2px;
  margin-left: 4px;
`;
