import styled, { css } from 'styled-components';
import theme from 'UI/globals/theme';
import { GREYS, FONT, BRAND, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_M } from 'CORE__UI/globals/core__breakpoints';

// "button" container
export const RB = styled.div`
  display: flex;
  width: 76px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) =>
    theme.dark ? `1px solid ${FONT.lightMute}` : `1px solid ${GREYS.dove}`};
  border-radius: 4px;
  font-family: ${theme.fontFamily};
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.dark)};
  background-color: ${({ theme }) => (theme.dark ? `none` : GREYS.white)};
  cursor: pointer;
  transition: all 250ms ease;
  margin-right: 4px;
  margin-bottom: 4px;
  @media (hover) {
    &:hover {
      background-color: ${({ theme }) =>
        theme.dark ? FONT.lightMute : GREYS.dove};
    }
  }
  &:active,
  &:focus {
    outline: none;
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: transparent;
      border: 1px solid ${FONT.lightMute};
      color: ${GREYS.white};
    `}
`;

export const SRB = styled(RB)`
  border-color: ${({ theme }) => (theme.dark ? FONT.primary : GREYS.silver)};
  background-color: ${({ theme }) => theme.dark && `none`};
  @media (hover) {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const SMRB = styled(SRB)`
  margin-left: 8px;
  margin-bottom: 0px;
  border: none;
    &:hover {
      background-color:background-color: ${({ theme }) => theme.dark && `none`};
    }
`;

export const ILR = styled.div`
  margin-left: auto;
  display: flex;
  @media (max-width: ${BREAKPOINT_M}) {
    width: min-content;
    font-size: 12px;
  }
`;

export const SMRBD = styled(RB)`
  color: ${FONT.secondary};
  border: none;
  flex: 0.5;
  @media (hover) {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const HRB = styled(RB)`
  border: ${({ theme }) =>
    theme.dark
      ? `1px solid ${FONT.lightMute}`
      : `1px solid ${BRAND.secondary}`};
  color: ${({ theme }) => theme.dark && GREYS.white};
  background-color: ${({ theme }) => theme.dark && `none`};
`;

// race concluded/abandoned
export const RC = styled.span`
  display: inline-flex;
  margin: 0 2px;
  position: relative;
  justify-content: center;
  align-items: center;
  :before {
    content: '';
    width: 1px;
    height: 4px;
    position: absolute;
    top: 50%;
    background-color: ${GREYS.silver};
    z-index: 1;
  }
  :after {
    content: '';
    width: 3px;
    height: 3px;
    position: relative;
    background-color: transparent;
    border: 1px solid ${FUNCTIONAL.negative};
    border-radius: 50%;
    transform: translateY(-2px);
    z-index: 2;
  }
`;
// has TV broadcast
export const TV = styled.span`
  margin: 0 2px;
  width: 10px;
  height: 10px;
  opacity: 0.75;
  background-image: url('https://assets.fsbtech.com/video_stream/icon-tv.svg');
  background-size: contain;
  background-repeat: no-repeat;
`;

// early price
export const EP = styled.span`
  margin: 0 2px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.primary)};
  font-size: 9px;
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;
