import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

export const BBS = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
export const BB = styled.span`
  margin-right: 0px;
`;

export const BBC = styled.p`
  display: flex;
  align-items: center;
`;
export const BBA = styled.span`
  font-size: 28px;
  margin-inline: 8px;
  margin-bottom: 4px;
`;
export const BBP = styled.span`
  color: ${({ boost }) => (boost ? BRAND.secondary : GREYS.black)};
`;
