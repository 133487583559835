import React from 'react';
import PropTypes from 'prop-types';
import { getComponent } from 'Services/core__imports';

import {
  OutcomesWrapperDiv as OutcomesWrapperDiv_Core,
  OutcomesFlex as OutcomesFlex_Core,
  OutcomesContainer,
  Star,
  Tick,
  OutcomesFlexWrapper,
  OV as OV_Core,
  OT as OT_Core,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesAdditionalInfo';

import {
  OutcomesWrapperDiv as OutcomesWrapperDiv_Local,
  OutcomesFlex as OutcomesFlex_Local,
  OV as OV_Local,
  OT as OT_Local,
} from 'UI/apps/RacingEventsApp/RaceOutcomesAdditionalInfo';

const OutcomesWrapperDiv = getComponent(
  OutcomesWrapperDiv_Local,
  OutcomesWrapperDiv_Core
);
const OutcomesFlex = getComponent(OutcomesFlex_Local, OutcomesFlex_Core);
const OT = getComponent(OT_Local, OT_Core);
const OV = getComponent(OV_Local, OV_Core);

import { Translations } from '../../../core__racing-events-app';
import {
  getFlagImgSrc,
  images,
  RACING_CONSTANTS,
} from '../../../core__racing-events-utils';

/**
 * this component dispays list of metadata
 * @param {*} metadata array of each additional information
 * */

const RaceOutcomesAdditionalInfo = ({ metadata, extraInfo }) => {
  const metadataObject = metadata.reduce((acc, val) => {
    const { name, value } = val;
    return { ...acc, [name]: value };
  }, {});
  const stars = {
    jockeyStars: [],
    trainerStars: [],
  };

  //This function creates an array for jockeyStars and trainerStars that consists of the icon images which reflects the rating system
  // sample output for 3/5 rating : [★,★,★,☆,☆]
  const getStars = () => {
    for (let i = 0; i < metadataObject.jockeyStars; i++) {
      stars.jockeyStars.push(<Star src={images.YELLOW_STAR} />);
    }
    for (let i = 0; i < 5 - metadataObject.jockeyStars; i++) {
      stars.jockeyStars.push(<Star src={images.GREY_STAR} />);
    }
    for (let i = 0; i < metadataObject.trainerStars; i++) {
      stars.trainerStars.push(<Star src={images.YELLOW_STAR} />);
    }
    for (let i = 0; i < 5 - metadataObject.trainerStars; i++) {
      stars.trainerStars.push(<Star src={images.GREY_STAR} />);
    }
  };
  if (extraInfo) {
    getStars();
    return (
      <OutcomesWrapperDiv>
        {metadataObject.summary && (
          <OutcomesContainer>
            <p>{metadataObject.summary}</p>
          </OutcomesContainer>
        )}
        {metadataObject.PA_PIN_STICKER && (
          <OutcomesContainer>
            <OutcomesFlex
              isContent={metadataObject.PA_PIN_STICKER !== undefined}
            >
              {metadataObject.PA_PIN_STICKER}
            </OutcomesFlex>
          </OutcomesContainer>
        )}
        {/* jockey, trainer, trip, going*/}
        <OutcomesContainer>
          {metadataObject.jockeyStars && (
            <OutcomesFlex>
              <span>{Translations.get('racing.jockey')}</span>
              <span>{stars.jockeyStars.map(star => star)}</span>
            </OutcomesFlex>
          )}
          {metadataObject.trainerStars && (
            <OutcomesFlex>
              <span>{Translations.get('racing.trainer')}</span>
              <span>{stars.trainerStars.map(star => star)}</span>
            </OutcomesFlex>
          )}
          {(metadataObject.tripFlag === RACING_CONSTANTS.TICK ||
            metadataObject.tripFlag === RACING_CONSTANTS.CROSS) && (
            <OutcomesFlex>
              <span>{Translations.get('racing.trip')}</span>
              <Tick src={getFlagImgSrc(metadataObject.tripFlag)} />
            </OutcomesFlex>
          )}
          {(metadataObject.goingFlag === RACING_CONSTANTS.TICK ||
            metadataObject.goingFlag === RACING_CONSTANTS.CROSS) && (
            <OutcomesFlex>
              <span>{Translations.get('racing.going')}</span>
              <Tick src={getFlagImgSrc(metadataObject.goingFlag)} />
            </OutcomesFlex>
          )}
        </OutcomesContainer>
        {/*owner, sire, dam, breeder*/}
        <OutcomesContainer>
          {metadataObject.owner &&
            metadataObject.owner !== RACING_CONSTANTS.NOT_AVAILABLE && (
              <OutcomesFlex>
                <OT>{Translations.get('racing.owner')}</OT>
                <OV>{metadataObject.owner}</OV>
              </OutcomesFlex>
            )}
          {metadataObject.sire &&
            metadataObject.sire !== RACING_CONSTANTS.NOT_AVAILABLE && (
              <OutcomesFlex>
                <OT>{Translations.get('racing.sire')}</OT>
                <OV>{metadataObject.sire}</OV>
              </OutcomesFlex>
            )}
          {metadataObject.dam &&
            metadataObject.dam !== RACING_CONSTANTS.NOT_AVAILABLE && (
              <OutcomesFlex>
                <OT>{Translations.get('racing.dam')}</OT>
                <OV>{metadataObject.dam}</OV>
              </OutcomesFlex>
            )}
          {metadataObject.breeder &&
            metadataObject.breeder !== RACING_CONSTANTS.NOT_AVAILABLE && (
              <OutcomesFlex>
                <OT>{Translations.get('racing.breeder')}</OT>
                <OV>{metadataObject.breeder}</OV>
              </OutcomesFlex>
            )}
        </OutcomesContainer>
      </OutcomesWrapperDiv>
    );
  } else {
    return (
      <OutcomesWrapperDiv>
        <OutcomesContainer
          isContent={metadataObject.PA_PIN_STICKER !== undefined}
        >
          <OutcomesFlex>{metadataObject.PA_PIN_STICKER}</OutcomesFlex>
        </OutcomesContainer>
        <OutcomesContainer>
          <OutcomesFlex>
            <OT>{Translations.get('racing.owner')}</OT>
            <OV>{metadataObject.owner}</OV>
          </OutcomesFlex>
          <OutcomesFlexWrapper>
            <OutcomesFlex>
              <OT>{Translations.get('racing.sire')}</OT>
              <OV>{metadataObject.sire}</OV>
            </OutcomesFlex>
            <OutcomesFlex>
              <OT>{Translations.get('racing.dam')}</OT>
              <OV>{metadataObject.dam}</OV>
            </OutcomesFlex>
          </OutcomesFlexWrapper>
        </OutcomesContainer>
      </OutcomesWrapperDiv>
    );
  }
};

export default RaceOutcomesAdditionalInfo;

RaceOutcomesAdditionalInfo.propTypes = {
  metadata: PropTypes.array,
  metadataObject: PropTypes.object,
  extraInfo: PropTypes.bool,
};

RaceOutcomesAdditionalInfo.defaultProps = {
  metadata: [],
  metadataObject: {},
  extraInfo: false,
};
