import {
  Ch6,
  C,
  CL,
  Ch5,
  MDC,
  Ch5E,
  Ch5F,
  MULD,
  BCLC,
  BCL,
  TCD,
  CDOWN,
  Ch6S,
  FbImg,
  Ch5N,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__container';
import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND } from '../../../globals/colours';
const MDCJN = styled(MDC)`
  border-top: 1px solid ${BRAND.primary};
`;
const TCDJN = styled(TCD)`
  color: ${GREYS.white};
`;
const Ch6JN = styled(Ch6)`
  color: ${GREYS.white};
  letter-spacing: 0.25px;
`;
const Ch6SJN = styled(Ch6S)`
  color: ${GREYS.white};
`;
const Ch5JN = styled(Ch5)`
  color: ${props => {
    return props.GW ? FONT.secondary : FONT.light;
  }} ;
  ${({ booster }) =>
    booster &&
    css`
      color: ${FONT.dark};
    `}}
`;
const Ch5EJN = styled(Ch5E)`
  color: ${GREYS.white};
`;
const Ch5NJN = styled(Ch5N)`
  color: ${GREYS.white};
`;

export {
  Ch6JN as Ch6,
  C,
  CL,
  Ch5JN as Ch5,
  MDCJN as MDC,
  Ch5EJN as Ch5E,
  Ch5F,
  MULD,
  BCLC,
  BCL,
  TCDJN as TCD,
  CDOWN,
  Ch6SJN as Ch6S,
  FbImg,
  Ch5NJN as Ch5N,
};
