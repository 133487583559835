import project from '../../../project';
import Constants from '../../../project';

export const momentFormat = {
  UK: project.timeFormats.accounts.primary || 'DD/MM/YYYY h:mm',
};
export const kycStatus = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',
};

export const bonusValues = {
  PENDING: 'PENDING',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  FORECAST: 'Forecast',
  TRICAST: 'Tricast',
  DECLINED: 'declined',
  DOUBLE: 'Double',
  APPROVED: 'approved',
  DEC: 'DECLINED',
  APPROVE: 'APPROVED',
  PENDING_STATE: 'Pending',
  APPROVE_STATE: 'Approved',
  DECLINE_STATE: 'Declined',
  ALL: 'All',
  ONE_MORE: 'One or more',
  ONE: 'One',
  SELECT: 'Select',
  PENDINGL: 'pending',
  CHECKBOX: 'checkbox',
  STR_s: 'str',
};
export const Tabs = {
  SIM: 'sim',
  MY_BET: 'my-bets',
  FREE_BONUS: 'free-bonus',
  HISTORY: 'history',
  ACCOUNT: 'account',
  FREE: 'free',
  SPORTSBOOK: 'sportbook',
  CASINO: 'casino',
  CASHOUT: 'CASHOUT',
  CASHOUT_TAB: 'Cashout',
  CONFIRM_CASHOUT: 'CONFIRM CASHOUT',
  PARTIAL_CASHOUT: 'PARTIAL CASHOUT',
  GAMBLING: 'gambling',
  DEPOSITE_LIMIT: 'Deposit Limits',
  REALITY_CHECK: 'Reality Check',
  CASINO_LIMIT: 'Casino Limit',
  TIMEOUT: 'Timeout',
  SELF_EXCLUSION: 'Self-Exclusion',
  PERIOD: 'period',
  AMOUNT: 'amount',
  KYC: 'kyc',
  PROOF: 'proof',
  All: 'All',
  REFERENCE: 'reference',
  NOTE: 'note',
  BANKING: 'banking',
  HISTORY_TAB: 'Account History',
  WITHDRAW_TAB: 'Withdrawal History',
  BET_HISTORY_TAB: 'Bet History',
  GAME_HISTORY_TAB: 'Game History',
  TRUSTLY: 'TRUSTLY',
  OPEN_BET: 'Open Bets',
  SETTLED_BET: 'Settled Bets',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  SELECTED_BET_TYPE: 'selectedBetType',
  DEFAULT_VALUE: '',
  PENDING: 'Pending',
  LOST: 'Lost',
  GAMBLING_TYPE: 'Select Deposit Limit',
  GAMBLING_AMOUNT: 'Select Desposit Amount',
  SHOW: 'SHOW',
  PROCESSING_DATA: 'PROCESSING...',
  EACHWAY: 'EACHWAY',
  N_A: 'N/A',
  VOID: 'Void',
  ACCOUNT_LIMITS: 'Account Limits',
  LOSS_LIMITS: 'Loss Limits',
};

export const KycDocs = [
  {
    name: 'text.proof.identity',
    status: 'Pending',
    active: false,
    required: 'One',
    proofs: [
      {
        docName: 'Passport',
        status: 'Select',
        active: true,
        type: 'PASSPORT',
        document: [],
      },
      {
        docName: 'Driving Licence',
        status: 'Select',
        active: false,
        type: 'DRIVING_LICENSE',
        document: [],
      },
      {
        docName: 'National identification Card',
        status: 'Select',
        active: false,
        type: 'IDENTITY_CARD',
        document: [],
      },
    ],
  },
  {
    name: 'text.proof.address',
    required: 'One',
    status: 'Pending',
    proofs: [
      {
        docName: 'Utility Bill',
        status: 'Select',
        active: true,
        type: 'UTILITY_BILL',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Council Tax',
        status: 'Select',
        active: false,
        type: 'COUNCIL_TAX',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Copy of Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_REFERENCE',
        document: [],
        trans: 'text.utility.bill',
      },
    ],
  },
  {
    name: 'text.source.funds',
    status: 'Pending',
    required: 'All',
    proofs: [
      {
        docName: 'Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_STATEMENT',
        document: [],
        trans: 'text.bank.statement',
      },
      {
        docName: 'Debit Card',
        status: 'Select',
        active: false,
        type: 'DEBIT_CREDIT_CARD',
        document: [],
        trans: 'text.kyc_credit.card',
      },
      {
        docName: 'Additional Payment Methods',
        status: 'Select',
        active: false,
        type: 'ADDITIONAL_PAYMENT_METHODS',
        document: [],
        trans: 'text.additional.payment.method',
      },
    ],
  },
  {
    name: 'text.further.verification',
    required: 'One or more',
    status: 'Pending',
    proofs: [
      {
        docName: 'Payslip',
        status: 'Select',
        active: false,
        type: 'PAYSLIP',
        document: [],
      },
      {
        docName: 'Certificate of Employment',
        status: 'Select',
        active: false,
        type: 'CERTIFICATE_OF_EMPLOYMENT',
        document: [],
      },
      {
        docName: 'Tax return / P60',
        status: 'Select',
        active: false,
        type: 'TAX_RETURN_P60',
        document: [],
      },

      {
        docName: 'Other Documents*',
        status: 'Select',
        active: false,
        type: 'OTHERS',
        document: [],
      },
    ],
  },
];

export const DebitCreditCardPositionChangeKycDocs = [
  {
    name: 'text.proof.identity',
    status: 'Pending',
    active: false,
    required: 'One',
    proofs: [
      {
        docName: 'Passport',
        status: 'Select',
        active: true,
        type: 'PASSPORT',
        document: [],
      },
      {
        docName: 'Driving Licence',
        status: 'Select',
        active: false,
        type: 'DRIVING_LICENSE',
        document: [],
      },
      {
        docName: 'National identification Card',
        status: 'Select',
        active: false,
        type: 'IDENTITY_CARD',
        document: [],
      },
    ],
  },
  {
    name: 'text.proof.address',
    required: 'One',
    status: 'Pending',
    proofs: [
      {
        docName: 'Utility Bill',
        status: 'Select',
        active: true,
        type: 'UTILITY_BILL',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Council Tax',
        status: 'Select',
        active: false,
        type: 'COUNCIL_TAX',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Copy of Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_REFERENCE',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Debit Card',
        status: 'Select',
        active: false,
        type: 'DEBIT_CREDIT_CARD',
        document: [],
        trans: 'text.kyc_credit.card',
      },
    ],
  },
  {
    name: 'text.source.funds',
    status: 'Pending',
    required: 'All',
    proofs: [
      {
        docName: 'Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_STATEMENT',
        document: [],
        trans: 'text.bank.statement',
      },
      {
        docName: 'Additional Payment Methods',
        status: 'Select',
        active: false,
        type: 'ADDITIONAL_PAYMENT_METHODS',
        document: [],
        trans: 'text.additional.payment.method',
      },
    ],
  },
  {
    name: 'text.further.verification',
    required: 'One or more',
    status: 'Pending',
    proofs: [
      {
        docName: 'Payslip',
        status: 'Select',
        active: false,
        type: 'PAYSLIP',
        document: [],
      },
      {
        docName: 'Certificate of Employment',
        status: 'Select',
        active: false,
        type: 'CERTIFICATE_OF_EMPLOYMENT',
        document: [],
      },
      {
        docName: 'Tax return / P60',
        status: 'Select',
        active: false,
        type: 'TAX_RETURN_P60',
        document: [],
      },

      {
        docName: 'Other Documents*',
        status: 'Select',
        active: false,
        type: 'OTHERS',
        document: [],
      },
    ],
  },
];

export const clientURL = {
  baseUrl: Constants.staticPath.basePath,
  image: `${
    Constants.staticPath.bucketImages + Constants.staticPath.folderPayments
  }`,
  fsb: '/fsb-api-rest',
  contactUs: '/contact-us/',
};

export const actionType = {
  KYC_ERROR_EXCEED_SIZE: 'KYC_ERROR_EXCEED_SIZE',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  UPLOADED_FILES: 'UPLOADED_FILES',
  DOCUMENT_TYPES: 'DOCUMENT_TYPES',
  KYC_ERROR_INVALID_TYPE: 'KYC_ERROR_INVALID_TYPE',
  REF_VALIDATION_ERROR: 'REF_VALIDATION_ERROR',
  FIELD_REFERNCE_KYC: 'FIELD_REFERNCE_KYC',
  FIELD_NOTE_KYC: 'FIELD_NOTE_KYC',
  UPDATE_LIMIT_VALUE: 'UPDATE_LIMIT_VALUE',
  OPEN_KYC: 'OPEN_KYC',
  SET_ALLOW_UPLOAD: 'SET_ALLOW_UPLOAD',
};

/ default value of max file size /;
export const initalMaxFileSize = 5000000;

export const FILE_FORMAT = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
};

export const initialState = {
  data: {
    allowUpload: true,
  },
};
