import React from 'react';
import PropTypes from 'prop-types';
import { EventsLink } from 'UI/navigation/TopLink';
import { connect } from 'react-redux';
import { dateFilterSelection } from '../../../core__racing-events-store';
import { TD } from 'UI/apps/RacingEventsApp/RaceListingDayFilters';
import { Translations } from '../../../core__racing-events-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

export class RaceListingDayFilters extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      dateFilters,
      activeFilter,
      showFutureRaces,
      isHideRacingLegends,
      appConfig,
    } = this.props;
    return (
      <React.Fragment>
        <TD>
          {dateFilters &&
            dateFilters.map((obj, ind) => {
              return (
                <React.Fragment key={obj.filter}>
                  {obj.filter === GLOBAL_CONSTANTS.FUTURE_RACES &&
                  showFutureRaces &&
                  obj.filter !== GLOBAL_CONSTANTS.VIRTUALS ? (
                    <EventsLink
                      data-test={'tab-futureraces'}
                      key={obj.filter}
                      active={obj.filter === activeFilter}
                      label={Translations.get(
                        'racing.filter.' + obj.label?.toLowerCase()
                      )}
                      onClick={() => {
                        this.props.dateFilterSelection(
                          obj.filter,
                          null,
                          null,
                          appConfig
                        );
                      }}
                    />
                  ) : obj.filter !== GLOBAL_CONSTANTS.FUTURE_RACES &&
                    !isHideRacingLegends &&
                    obj.filter === GLOBAL_CONSTANTS.RACING_LEGENDS &&
                    obj.filter !== GLOBAL_CONSTANTS.VIRTUALS ? (
                    <EventsLink
                      data-test={ind === 0 && 'tab-datefilter'}
                      key={obj.filter}
                      active={obj.filter === activeFilter}
                      label={Translations.get(
                        'racing.filter.' + obj.label?.toLowerCase()
                      )}
                      onClick={() => {
                        this.props.dateFilterSelection(
                          obj.filter,
                          null,
                          null,
                          appConfig
                        );
                      }}
                    />
                  ) : obj.filter !== GLOBAL_CONSTANTS.FUTURE_RACES &&
                    obj.filter !== GLOBAL_CONSTANTS.RACING_LEGENDS &&
                    obj.filter !== GLOBAL_CONSTANTS.VIRTUALS ? (
                    <EventsLink
                      data-test={ind === 0 && 'tab-datefilter'}
                      key={obj.filter}
                      active={obj.filter === activeFilter}
                      label={Translations.get(
                        'racing.filter.' + obj.label?.toLowerCase()
                      )}
                      onClick={() => {
                        this.props.dateFilterSelection(
                          obj.filter,
                          null,
                          null,
                          appConfig
                        );
                      }}
                    />
                  ) : (
                    obj.filter === GLOBAL_CONSTANTS.VIRTUALS &&
                    appConfig?.showvirtuals && (
                      <EventsLink
                        data-test={ind === 0 && 'tab-datefilter'}
                        key={obj.filter}
                        active={obj.filter === activeFilter}
                        label={Translations.get(
                          'racing.filter.' + obj.label?.toLowerCase()
                        )}
                        onClick={() => {
                          this.props.dateFilterSelection(
                            obj.filter,
                            null,
                            appConfig?.virtualsurl,
                            appConfig
                          );
                        }}
                      />
                    )
                  )}
                </React.Fragment>
              );
            })}
        </TD>
      </React.Fragment>
    );
  }
}

RaceListingDayFilters.propTypes = {
  dateFilterSelection: PropTypes.func,
  activeFilter: PropTypes.string,
  dateFilters: PropTypes.array,
  showFutureRaces: PropTypes.bool,
  isHideRacingLegends: PropTypes.bool,
  appConfig: PropTypes.object,
};

export const mapStateToProps = state => {
  return {
    dateFilters: state.horseRacingData.dateFilters,
    activeFilter: state.horseRacingData.activeFilter,
    showFutureRaces: state.horseRacingData.showFutureRaces,
  };
};

export const mapDispatchToProps = {
  dateFilterSelection: dateFilterSelection,
};

RaceListingDayFilters.defaultProps = {
  activeFilter: 'TODAY',
  dateFilters: [
    {
      label: 'Today',
      filter: 'TODAY',
    },
    {
      label: 'Tomorrow',
      filter: 'TOMORROW',
    },
    {
      label: 'Future Races',
      filter: 'FUTURE_RACES',
    },
    {
      label: 'Racing Legends',
      filter: 'RACING_LEGENDS',
    },
    {
      label: 'Virtuals',
      filter: 'VIRTUALS',
    },
  ],
  showFutureRaces: false,
  isHideRacingLegends: false,
};

export const RaceDayFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceListingDayFilters);
