import React from 'react';
import PropTypes from 'prop-types';
import { M } from 'UI/apps/BetslipUKApp/BetslipContainer/TopSection';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 @param {bool} suspended
 */

export const TopSection = ({ suspended }) => {
  return (
    <React.Fragment>
      {suspended && (
        <M>
          {Translations.get('text.no.available')}
          <br />
          {Translations.get('text.review.bet')}
        </M>
      )}
    </React.Fragment>
  );
};
TopSection.propTypes = {
  suspended: PropTypes.bool,
};
