import styled, { css } from 'styled-components';
import theme from 'UI/globals/theme';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from '../core__breakpoints';

export const StyledLabel = styled.label`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin-bottom: 8px;
  margin-left: 0;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  ${({ showColor }) =>
    showColor &&
    css`
      color: ${GREYS.silver};
    `}
  ${({ infoMessage }) =>
    infoMessage &&
    css`
      margin-bottom: 40px;
      width: 100%;
      text-align: center;
    `}
`;

export const LC = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;
export const Wrap = styled.div`
  display: flex;
  position: relative;
`;
export const InfoIcon = styled.span`
  margin-top: 12px;
  margin-left: 4px;
  color: ${GREYS.white};
  cursor: pointer;
`;
export const HoverBox = styled.div`
  background-color: ${GREYS.secondary || GREYS.black};
  position: absolute;
  z-index: 9;
  color: ${GREYS.white};
  width: 30%;
  font-size: 12px;
  padding: 12px;
  @media screen and (max-width: ${BREAKPOINT_S}) {
    width: 60%;
  }
`;
export const LeftPoint = styled.div`
  width: 0;
  height: 0;
  border-inline: 10px solid transparent;
  border-top: 20px solid ${GREYS.secondary || GREYS.black};
  position: absolute;
  top: -8px;
  transform: rotate(165deg);
`;
