import styled, { css } from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const BTU = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-around;
`;

export const BTL = styled.li`
  cursor: pointer;
  user-select: none;
  color: ${GREYS.silver};
  ${({ active }) =>
    active &&
    css`
      font-weight: 800;
      color: ${FONT.primary};
    `};
  ${({ c2 }) =>
    c2 &&
    css`
      cursor: auto;
    `}
`;
export const BTSS = styled(BTU)`
  padding: 12px;
  justify-content: space-between;
`;

export const BTCB = styled.li`
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  padding-right: 8px;
`;

export const BTCBSB = styled.button`
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid rgba(0, 87, 163, 0.1);
  border-right: none;
  border-left: none;
  cursor: pointer;
  width: 100%;
  height: 52px;
  svg,
  i {
    margin-right: 10px;
  }
`;
