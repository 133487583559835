import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  callBetslip,
  gotoRaceListPage,
  getOddsFormat,
} from '../../../core__racing-events-store';
import PageTitle from 'UI/globals/PageTitle';
import moment from 'moment';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import {
  RL,
  FT,
  FRMD,
  FRTE,
  FB,
  MKN,
  FRSO,
  FROT,
  FROM,
  FRO,
  B,
  FRF,
  BC as BC_LOCAL,
} from 'UI/apps/RacingEventsApp/FutureRacesOutcomes';
import { BC as BC_CORE } from 'CORE__UI/apps/RacingEventsApp/core__futureRacesOutcomes';
import { Translations } from '../../../core__racing-events-app';
import project from '../../../../../../project';
import { GLOBAL_CONSTANTS } from 'Services/core__services';
import { replaceSpecialCharacter } from 'Services/globalfunctions/core__global-functions';
import { getComponent } from 'Services/core__imports';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
const BC = getComponent(BC_LOCAL, BC_CORE);

export class FutureRacesOutcomes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (Array.isArray(this.props.futureRacingOutcomesData.market)) {
      this.props.updateFutureRacingOdds(
        this.props.futureRacingOutcomesData,
        this.props.subscriptionEvents
      );
    }
    // PubSub event listen to get selections added in betslip
    this.subBetslipUpdate = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.props.getBetslipSelections(wsData);
      }
    );
    // Get Odds format
    this.props.getOddsFormat();
    //listen to pubsub for odds change
    this.subOddsValue = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      this.props.getOddsFormat(wsData);
    });
  }

  componentWillUnmount() {
    this.subBetslipUpdate?.unsubscribe();
    this.subOddsValue?.unsubscribe();
  }

  render() {
    const {
      futureRacingOutcomesData,
      getBetslipSelectionList,
      oddsFormat,
      requestSport,
    } = this.props;
    return (
      <React.Fragment>
        <FRMD>
          <FB>
            <FT
              data-test="title-back"
              onClick={() => {
                this.props.gotoRaceListPage('futureRaces');
              }}
            >
              <I iconName={getIcon('BACK')} size={24} marginR={8} />
            </FT>
            <BC onClick={() => this.props.gotoRaceListPage('futureRaces')}>
              {requestSport}
            </BC>
            &nbsp;/&nbsp;
            <a href="">
              {replaceSpecialCharacter(futureRacingOutcomesData?.name)}
            </a>
          </FB>
          <FRTE>
            {moment(futureRacingOutcomesData?.scheduledStart).format(
              project.timeFormats.racing.tertiary || 'DD/MM/YYYY HH:mm'
            )}
          </FRTE>
        </FRMD>
        <PageTitle
          title={replaceSpecialCharacter(futureRacingOutcomesData?.name)}
        />
        <RL>
          {futureRacingOutcomesData?.market?.map((markets, mIndex) => {
            return (
              <React.Fragment key={mIndex}>
                <MKN>
                  {markets.name}
                  {markets.placeTerms && <span>{markets.placeTerms}</span>}
                </MKN>
                <FROM>
                  {markets?.selection?.map((outcomes, oIndex) => {
                    return (
                      <React.Fragment key={oIndex}>
                        {outcomes?.activeSelection && (
                          <FRSO>
                            <FROT>{outcomes?.name}</FROT>
                            <FRO>
                              <B
                                data-test={oIndex === 0 && 'btn-oddsclick'}
                                size={'default'}
                                odds={
                                  oddsFormat?.toLowerCase() ===
                                  GLOBAL_CONSTANTS.DECIMAL
                                    ? outcomes?.decimalOdds
                                    : outcomes?.fractionalOdds
                                }
                                inactive={
                                  futureRacingOutcomesData?.state ===
                                  GLOBAL_CONSTANTS.COMPLETED
                                }
                                selected={
                                  getBetslipSelectionList.indexOf(outcomes.id) >
                                  -1
                                }
                                onClick={() => {
                                  futureRacingOutcomesData?.state !==
                                    GLOBAL_CONSTANTS.COMPLETED &&
                                    this.props.callBetslip(outcomes.id);
                                }}
                                oddsTrend={outcomes?.oddsStatus}
                                disabled={
                                  futureRacingOutcomesData?.state ===
                                  'COMPLETED'
                                }
                              ></B>
                            </FRO>
                          </FRSO>
                        )}
                      </React.Fragment>
                    );
                  })}
                </FROM>
              </React.Fragment>
            );
          })}
          {futureRacingOutcomesData?.placeTerms && (
            <FRF>
              {Translations.get('racing.each.way.pays.positions')}{' '}
              {futureRacingOutcomesData.placeTerms}
            </FRF>
          )}
        </RL>
      </React.Fragment>
    );
  }
}

FutureRacesOutcomes.propTypes = {
  futureRacingOutcomesData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  callBetslip: PropTypes.func,
  getBetslipSelectionList: PropTypes.array,
  oddsFormat: PropTypes.string,
  gotoRaceListPage: PropTypes.func,
  requestSport: PropTypes.string,
  updateFutureRacingOdds: PropTypes.func,
  subscriptionEvents: PropTypes.array,
  getBetslipSelections: PropTypes.func,
  getOddsFormat: PropTypes.func,
};

export const mapStateToProps = state => {
  return {
    futureRacingOutcomesData: state.futureRacesData.futureRacingOutcomesData,
    getBetslipSelectionList: state.horseRacesList.getBetslipSelectionList,
    oddsFormat: state.horseRacingData.oddsFormat,
    requestSport: state.horseRacingData.requestSport,
  };
};

export const mapDispatchToProps = {
  callBetslip: callBetslip,
  gotoRaceListPage: gotoRaceListPage,
  getOddsFormat: getOddsFormat,
};

FutureRacesOutcomes.defaultProps = {
  futureRacingOutcomesData: {},
  getBetslipSelectionList: [],
  oddsFormat: '',
  requestSport: '',
};

export const FutureRacingOutcomes = connect(
  mapStateToProps,
  mapDispatchToProps
)(FutureRacesOutcomes);
