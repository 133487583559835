import React from 'react';
import PropTypes from 'prop-types';
import RacingButton from 'UI/buttons/RacingButton';
import { connect } from 'react-redux';
import {
  getRaceDetails,
  gotoRaceListPage,
  onChangeSelectedRacecourse,
  hideAndShowRacingDropdown,
} from '../../../core__racing-events-store';
import moment from 'moment';
import {
  HD,
  RU,
  RCD,
  CD,
  ED,
  DCD,
  DCN,
  SEAr,
  RDC,
  EHD as EHD_LOCAL,
  RCDC as RCDC_LOCAL,
  Back as Back_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceOutcomesSelection';
import {
  EHD as EHD_CORE,
  RCDC as RCDC_CORE,
  Back as Back_CORE,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesSelection';
import Constants from '../../../../../../project';
import { getAppConfig as getAppConfig_LOCAL } from '../../helper/racingAppConfig';
import { getAppConfig as getAppConfig_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';

import project from '../../../../../../project';
import { Translations } from '../../../core__racing-events-app';
import { getComponent } from 'Services/core__imports';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';

const EHD = getComponent(EHD_LOCAL, EHD_CORE);
const RCDC = getComponent(RCDC_LOCAL, RCDC_CORE);
const Back = getComponent(Back_LOCAL, Back_CORE);
const getAppConfig = getComponent(getAppConfig_LOCAL, getAppConfig_CORE);

export class RaceOutcomesSelection extends React.Component {
  constructor(props) {
    super(props);
    this.hasExtraPlaces = this.hasExtraPlaces.bind(this);
  }

  hasExtraPlaces(sourceKey) {
    const allRaces = this.props.initialProps.nextRacesData;

    for (const race of allRaces) {
      if (race.sourceKey === sourceKey) {
        if (
          race.market.some(market =>
            market.book.some(
              book =>
                book.placeTermsType === 'MANUAL' && market.typeRef === 'FPTP'
            )
          )
        ) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const {
      raceListInfo,
      raceSourceKey,
      showAllRaceOutcomes,
      raceCourseInfo,
      prevStateEventData,
      requestPage,
      countryRaceCoursesList,
      isShowDropdownContent,
      raceListingEventsObject,
      activeFilter,
      raceCountry,
      subscriptionEvents,
      sessionInfo,
      raceCoursesList,
      showSATimeZone,
    } = this.props;

    return (
      <React.Fragment>
        <HD>
          <RCDC>
            <Back
              onClick={() => {
                this.props.gotoRaceListPage();
              }}
            >
              <I iconName={getIcon('BACK')} size={24} marginR={8} />
            </Back>
            <RCD>
              {raceCourseInfo?.countryCode && (
                <img
                  alt={raceCountry || 'race'}
                  src={`${Constants.staticPath.basePath}${
                    Constants.staticPath.bucketImages
                  }${
                    Constants.staticPath.folderFlags
                  }${raceCourseInfo?.countryCode?.toLowerCase()}.svg`}
                />
              )}
              <EHD
                data-test="btn-hideandshowdropdown"
                onClick={() => {
                  this.props.hideAndShowRacingDropdown(isShowDropdownContent);
                }}
              >
                <RDC>
                  {raceCourseInfo?.raceCourseName} -{' '}
                  {raceCourseInfo?.raceCourseDate}
                </RDC>
                {countryRaceCoursesList?.length > 0 && <SEAr />}
              </EHD>
            </RCD>
          </RCDC>
          <RU>
            {!getAppConfig().sourceid && (
              <RacingButton
                data-test="btn-allraceoutcomes"
                onClick={() => {
                  this.props.getRaceDetails(
                    raceListInfo,
                    prevStateEventData,
                    requestPage,
                    null,
                    null,
                    raceCoursesList
                  );
                }}
                time={
                  (getAppConfig().jptranslation &&
                    Translations.get('text.all')) ||
                  'All'
                }
                selected={!!showAllRaceOutcomes}
              />
            )}
            {raceListInfo &&
              raceListInfo?.map((obj, rIndex) => {
                return (
                  <RacingButton
                    data-test={rIndex === 0 && 'btn-raceoutcomes'}
                    id={
                      !showAllRaceOutcomes && raceSourceKey === obj.sourceKey
                        ? 'activeRaceButton'
                        : 'raceButton'
                    }
                    onClick={() => {
                      this.props.getRaceDetails(
                        obj.sourceKey,
                        prevStateEventData,
                        requestPage,
                        null,
                        null,
                        raceCoursesList
                      );
                    }}
                    key={rIndex}
                    flagged={obj.completed}
                    ep={obj.earlyPrice}
                    tv={
                      sessionInfo?.accessToken &&
                      !obj.completed &&
                      getAppConfig().displaystreamifavailable &&
                      obj?.streamingAvailable
                    }
                    time={
                      showSATimeZone
                        ? moment(obj.scheduledStart).format(
                            project.timeFormats.racing.quaternary || 'HH:mm'
                          )
                        : moment(obj.scheduledStart).hours() > 12
                        ? moment(obj.scheduledStart).format(
                            project.timeFormats.racing.quaternary || 'HH:mm'
                          )
                        : obj.raceTime
                    }
                    selected={
                      !showAllRaceOutcomes && raceSourceKey === obj.sourceKey
                    }
                    extraPlace={
                      getAppConfig().showextraplacesui &&
                      this.hasExtraPlaces(obj.sourceKey)
                    }
                  />
                );
              })}
          </RU>
        </HD>

        {isShowDropdownContent && (
          <CD>
            <ED>
              {countryRaceCoursesList?.map((data, dIndex) => {
                return (
                  <React.Fragment key={dIndex}>
                    <DCD
                      data-test={dIndex === 0 && 'select-dropdowndata'}
                      onClick={() =>
                        this.props.onChangeSelectedRacecourse(
                          data,
                          raceListingEventsObject,
                          activeFilter,
                          raceCountry,
                          requestPage,
                          subscriptionEvents
                        )
                      }
                      addActiveClass={
                        raceCourseInfo?.raceCourseName === data?.label
                      }
                    >
                      <DCN>{data.label}</DCN>
                    </DCD>
                  </React.Fragment>
                );
              })}
            </ED>
          </CD>
        )}
      </React.Fragment>
    );
  }
}

RaceOutcomesSelection.propTypes = {
  gotoRaceListPage: PropTypes.func,
  getRaceDetails: PropTypes.func,
  raceSourceKey: PropTypes.string,
  raceListInfo: PropTypes.array,
  showAllRaceOutcomes: PropTypes.bool,
  raceCourseInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  prevStateEventData: PropTypes.array,
  requestPage: PropTypes.string,
  countryRaceCoursesList: PropTypes.array,
  isShowDropdownContent: PropTypes.bool,
  raceListingEventsObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  activeFilter: PropTypes.string,
  raceCountry: PropTypes.string,
  subscriptionEvents: PropTypes.array,
  hideAndShowRacingDropdown: PropTypes.func,
  onChangeSelectedRacecourse: PropTypes.func,
  sessionInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  raceCoursesList: PropTypes.array,
  showSATimeZone: PropTypes.bool,
  initialProps: PropTypes.object,
};

export const mapStateToProps = state => {
  return {
    raceSourceKey: state.horseRacesList.raceSourceKey,
    raceListInfo: state.horseRacesList.raceListInfo,
    raceCourseInfo: state.horseRacesList.raceCourseInfo,
    showAllRaceOutcomes: state.horseRacesList.showAllRaceOutcomes,
    prevStateEventData: state.horseRacesList.prevStateEventData,
    requestPage: state.horseRacingData.requestPage,
    countryRaceCoursesList: state.horseRacesList.countryRaceCoursesList,
    isShowDropdownContent: state.horseRacesList.isShowDropdownContent,
    raceListingEventsObject: state.horseRacingData.raceListingEventsObject,
    activeFilter: state.horseRacingData.activeFilter,
    raceCountry: state.horseRacesList.raceCountry,
    subscriptionEvents: state.horseRacesList.subscriptionEvents,
    raceCoursesList: state.horseRacesList.raceCoursesList,
  };
};

export const mapDispatchToProps = {
  getRaceDetails: getRaceDetails,
  gotoRaceListPage: gotoRaceListPage,
  onChangeSelectedRacecourse: onChangeSelectedRacecourse,
  hideAndShowRacingDropdown: hideAndShowRacingDropdown,
};

RaceOutcomesSelection.defaultProps = {
  raceSourceKey: '',
  raceListInfo: [],
  showAllRaceOutcomes: false,
  raceCourseInfo: {},
  prevStateEventData: [],
  requestPage: '',
  countryRaceCoursesList: [],
  isShowDropdownContent: false,
  raceListingEventsObject: {},
  activeFilter: 'Today',
  raceCountry: '',
  subscriptionEvents: [],
  sessionInfo: {},
  raceCoursesList: [],
  eventData: [],
};

export const RaceSelections = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceOutcomesSelection);
