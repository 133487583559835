import React, { useState } from 'react';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; //caution: don't use local import
import { BetslipCurrency } from './core__currency';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import {
  BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  FBI,
  BC,
  X,
  OVERLAY_LOCAL,
  BCDS as BCDS_LOCAL,
} from 'UI/apps/BetslipUKApp/BetslipContainer/FreeBet';
import InputRadio from 'UI/forms/InputRadio';
import { Translations } from '../../../../../core__betslip-UK-app';
import project from '../../../../../../../../project';
import {
  JPStyles,
  OVERLAY_CORE,
  BCDS as BCDS_CORE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__freebet';
import { getComponent } from 'Services/core__imports';

const BCDS = getComponent(BCDS_LOCAL, BCDS_CORE);
const OVERLAY = getComponent(OVERLAY_LOCAL, OVERLAY_CORE);

/**
 * @param {*}  wallet object of wallet data which come customer api
 * @param {*} freeBetData object of user selected free bet
 * @param {*} currencySymbol string of currency
 * @param {*} handleFreeBet function handle data of Free Bet
 * @param {*} betCat string of bet catagory
 */
export const BetslipFreeBet = ({
  betId,
  wallet,
  freeBetData,
  currencySymbol,
  handleFreeBet,
  betCat,
  setShowFB,
  betSubCat,
}) => {
  //handle Model opne/close
  const [open, setOpen] = useState(false);
  const onOpenModal = () => {
    setShowFB(true);
    setOpen(true);
  };
  const onCloseModal = () => {
    setShowFB(false);
    setOpen(false);
  };

  const [freebetopt, handleFreebetopt] = useState({ ...freeBetData });
  const handleConfirm = () => {
    handleFreeBet(freebetopt);
    onCloseModal();
  };
  return (
    <React.Fragment>
      <BCFR
        onClick={() => {
          onOpenModal();
        }}
      >
        <FBI
          src={Constants.STATICICONPATH + 'freebets/freebet.svg'}
          alt={'Free Bet'}
        />
      </BCFR>

      <Modal
        containerStyle={
          (screen.width > 900 && { position: 'absolute' }) || null
        }
        open={open}
        styles={
          screen.width < 900 &&
          (project?.betSlipMobileView || project.useJPStyle)
            ? JPStyles
            : MStyles
        }
        showCloseIcon={false}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        onClose={onCloseModal}
        onEscKeyDown={onCloseModal}
        onOverlayClick={onCloseModal}
        cantEscape={true}
        overlayStyle={OVERLAY}
      >
        <BCFWC>
          <React.Fragment>
            <BCWCH>
              {Translations.get('text.your.free.bets')}
              <X
                onClick={() => {
                  onCloseModal();
                }}
              >
                &#10005;
              </X>
            </BCWCH>

            <BCDS walletLength={wallet.length}>
              <BCFSS>
                <InputRadio
                  radioId={'not_seleted' + betId}
                  label={Translations.get('text.not.selected')}
                  name={'freebetopt_' + betId}
                  onChange={() => handleFreebetopt({})}
                  animation={false}
                  defaultChecked={
                    !freebetopt?.['selectedFreebetId'] && 'checked'
                  }
                />
              </BCFSS>
              <BC>
                {wallet.map(itm => {
                  const { catRefRestriction } = itm;
                  let catRefRes, subCatRefRes;
                  if (
                    catRefRestriction &&
                    catRefRestriction.indexOf('/') > -1
                  ) {
                    const catSubcatArr = catRefRestriction.split('/');
                    catRefRes = catSubcatArr[0];
                    subCatRefRes = catSubcatArr[1];
                  }
                  if (!catRefRestriction) {
                    itm['selectedFreebetId'] = itm.id;
                    return (
                      <BCFSS key={betId + '_' + itm.id}>
                        <InputRadio
                          radioId={'freebet_' + betId + '_' + itm.id}
                          name={'freebetopt_' + betId}
                          label={generateName(
                            itm.credit,
                            itm.description,
                            currencySymbol
                          )}
                          onChange={() => handleFreebetopt(itm)}
                          defaultChecked={
                            freebetopt['selectedFreebetId'] === itm.id &&
                            'checked'
                          }
                          animation={false}
                        />
                      </BCFSS>
                    );
                  } else if (betCat === catRefRestriction) {
                    return (
                      <BCFSS key={itm.id}>
                        <InputRadio
                          radioId={'freebet_' + betId + '_' + itm.id}
                          label={generateName(
                            itm.credit,
                            itm.description,
                            currencySymbol
                          )}
                          name={'freebetopt_' + betId}
                          onChange={() => handleFreebetopt(itm)}
                          defaultChecked={
                            freebetopt['selectedFreebetId'] === itm.id &&
                            'checked'
                          }
                        />
                      </BCFSS>
                    );
                  } else if (
                    betCat === catRefRes &&
                    betSubCat === subCatRefRes
                  ) {
                    return (
                      <BCFSS key={itm.id}>
                        <InputRadio
                          radioId={'freebet_' + betId + '_' + itm.id}
                          label={generateName(
                            itm.credit,
                            itm.description,
                            currencySymbol
                          )}
                          name={'freebetopt_' + betId}
                          onChange={() => handleFreebetopt(itm)}
                          defaultChecked={
                            freebetopt['selectedFreebetId'] === itm.id &&
                            'checked'
                          }
                        />
                      </BCFSS>
                    );
                  }
                })}
              </BC>
            </BCDS>

            <BCFBTH>
              <CAN
                onClick={() => {
                  onCloseModal();
                }}
              >
                {Translations.get('text.cancel')}
              </CAN>
              <CONF
                onClick={() => {
                  handleConfirm();
                }}
              >
                {Translations.get('text.confirm')}
              </CONF>
            </BCFBTH>
          </React.Fragment>
        </BCFWC>
      </Modal>
    </React.Fragment>
  );
};
BetslipFreeBet.propTypes = {
  currencySymbol: PropTypes.string,
  wallet: PropTypes.array,
  handleFreeBet: PropTypes.func,
  setShowFB: PropTypes.func,
  freeBetData: PropTypes.object,
  betCat: PropTypes.string,
  betId: PropTypes.any,
  betSubCat: PropTypes.string,
};

const generateName = (credit, description, currencySymbol) => {
  return (
    <React.Fragment>
      <BetslipCurrency symbol={currencySymbol} />
      {credit} {description}
    </React.Fragment>
  );
};
