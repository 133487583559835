export const translationString = [
  'text.betslip',
  'text.mybet',
  'text.empty',
  'text.quicklinks',
  'text.aaom',
  'text.aaoc',
  'text.awopi',
  'text.clear',
  'text.chat.with.broker',
  'text.msg.broker.help',
  'text.single',
  'text.stake',
  'text.ocf',
  'text.to',
  'text.accept',
  'text.decline',
  'text.oba',
  'text.yhqf',
  'text.ttext',
  'text.read.more',
  'text.singles',
  'text.no.available',
  'text.review.bet',
  'text.tfs',
  'text.ts',
  'text.fee',
  'text.tax',
  'text.trn',
  'text.gross.winning.amount',
  'text.horse.race.amount',
  'text.notification.horse.racetax',
  'text.gross.returns',
  'text.potential.returns.net',
  'text.of.withholding.tax',
  'text.remove.suspended',
  'text.odd.change.review',
  'text.accept.odd.changes',
  'text.login.place.bet',
  'text.place.bet',
  'text.deposit.place.bet',
  'text.deposit',
  'text.na',
  'text.trg',
  'text.pr',
  'text.odd.change.review',
  'text.live',
  'text.bog',
  'text.use.bonus.balance',
  'text.unboost.odds.to',
  'text.boost.odds.to',
  'text.any',
  'text.st',
  'text.nd',
  'text.rd',
  'text.th',
  'text.ew.terms',
  'text.your.free.bets',
  'text.not.selected',
  'text.free.bet',
  'text.create.a.bet',
  'text.pt.rtn',
  'text.suspended',
  'text.odd.change.from',
  'text.retain.selections.on.the.betslip',
  'text.close.receipt',
  'text.remaining.balance',
  'text.betslip.receipt',
  'text.total.stake',
  'text.total.potential.returns',
  'text.token',
  'text.potential.returns',
  'text.place.bet',
  'text.login',
  'text.and',
  'text.total.return',
  'text.ifb',
  'text.bonus.fund',
  'text.confirm',
  'text.cancel',
  'text.update',
  'text.close.receipt',
  'text.market.change',
  'text.server.error',
  'text.show.more',
  'text.show.less',
  'text.multiple',
  'bet.type.single',
  'text.my.bets',
  'help',
  'bet.type.double',
  'bet.type.treble',
  'bet.type.fourfold',
  'bet.type.fivefold',
  'bet.type.sixfold',
  'bet.type.sevenfold',
  'bet.type.eightfold',
  'bet.type.ninefold',
  'bet.type.10-fold',
  'bet.type.11-fold',
  'bet.type.12-fold',
  'bet.type.13-fold',
  'bet.type.14-fold',
  'bet.type.15-fold',
  'bet.type.16-fold',
  'bet.type.17-fold',
  'bet.type.18-fold',
  'bet.type.19-fold',
  'bet.type.20-fold',
  'bet.type.21-fold',
  'bet.type.22-fold',
  'bet.type.23-fold',
  'bet.type.24-fold',
  'bet.type.25-fold',
  'bet.type.26-fold',
  'bet.type.27-fold',
  'bet.type.28-fold',
  'bet.type.29-fold',
  'bet.type.30-fold',
  'bet.type.31-fold',
  'bet.type.32-fold',
  'bet.type.33-fold',
  'bet.type.34-fold',
  'bet.type.35-fold',
  'bet.type.36-fold',
  'bet.type.37-fold',
  'bet.type.38-fold',
  'bet.type.39-fold',
  'bet.type.40-fold',
  'bet.type.41-fold',
  'bet.type.42-fold',
  'bet.type.43-fold',
  'bet.type.44-fold',
  'bet.type.yankee',
  'bet.type.super.yankee',
  'bet.type.lucky.15',
  'bet.type.lucky.31',
  'bet.type.heinz',
  'bet.type.lucky.63',
  'bet.type.super.heinz',
  'bet.type.goliath',
  'bet.type.trixie',
  'bet.type.patent',
  'bet.type.create.a.bet',
  'bet.type.bet.builder',
  'number.nd',
  'number.st',
  'number.th',
  'number.rd',
  'month.jan',
  'month.feb',
  'month.mar',
  'month.apr',
  'month.may',
  'month.jun',
  'month.jul',
  'month.aug',
  'month.sep',
  'month.oct',
  'month.nov',
  'month.dec',
  'bet.type.straight.forecast',
  'bet.type.reverse.forecast',
  'bet.type.forecast',
  'bet.type.combination.forecast',
  'bet.type.combination.tricast',
  'bet.type.straight.tricast',
  'text.horse_racing',
  'bet.type.tricast',
  'text.acca.bonus',
  'text.add',
  'text.bonus',
  'text.bonus.info',
  'text.bonus.amount',
  'bet.type.scorecast',
  'text.total.est',
  'text.amount',
  'text.off',
  'text.minimum.stake.of',
  'text.min.stake',
  'text.max.stake',
  'text.review.stake',
  'text.show.more.multiplies',
  'text.show.less.multiplies',
  'text.msg.your.stake',
  'text.msg.stake.exceed',
  'text.msg.double',
  'text.chat.with.broker.msg',
  'text.unboost',
  'text.boost',
  'text.use.bonus',
  'text.st.alt',
  'text.nd.alt',
  'text.rd.alt',
  'text.th.alt',
  'bet.type.forecast.tricast',
  'text.bet.forecast',
  'text.bet.tricast',
];
