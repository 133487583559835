import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BCBD,
  BCBC,
  BCBS,
  L,
  S,
} from 'UI/apps/BetslipUKApp/BetslipContainer/EW';

import { Translations } from '../../../../../core__betslip-UK-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
/**
@param {func} handleEwData
@param {object} bet 
@param {object} price 
@param {string} actTab 

*/
export const BetslipEW = ({ handleEwData, bet, price, actTab, universal }) => {
  const genraterName = betModifier => {
    if (actTab === GLOBAL_CONSTANTS.SINGLE) {
      const places = { 1: 'Win Only', 2: '1-2', 3: '1-2-3' };
      const fractions = { 1: '1/1' };
      let checkboxTitle, getPlaces, getFractions;
      let p1 = betModifier.filter(bmf => {
        return bmf.type === 'EACHWAY';
      });
      if (Array.isArray(p1)) {
        p1 = p1[0];
        getPlaces =
          p1.ewPlaces > 3 ? '1 - ' + p1.ewPlaces : places[p1.ewPlaces];
        getFractions =
          p1.ewFraction > 1 ? '1/' + p1.ewFraction : fractions[p1.ewFraction];
        checkboxTitle =
          Translations.get('text.ew.terms') +
          ' ' +
          getPlaces +
          ' @ ' +
          getFractions;
        return checkboxTitle;
      } else {
        return '';
      }
    } else {
      return `EW `;
    }
  };

  useEffect(() => {
    handleEwData(bet.betModifierFlag, bet.betModifier, price);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section>
      {(bet.betModifier[1].ewFraction ||
        bet.betModifier[1].type === 'EACHWAY') && (
        <>
          {(!universal && (
            <L>
              {genraterName(bet.betModifier)}
              <BCBC
                type="checkbox"
                onClick={e =>
                  handleEwData(e.target.checked, bet.betModifier, price)
                }
                defaultChecked={
                  bet.betModifierFlag ? bet.betModifierFlag : false
                }
              />
              <S dc={bet.betModifierFlag ? bet.betModifierFlag : false} />
            </L>
          )) || (
            <BCBD>
              <BCBC
                type="checkbox"
                onClick={e =>
                  handleEwData(e.target.checked, bet.betModifier, price)
                }
                defaultChecked={
                  bet.betModifierFlag ? bet.betModifierFlag : false
                }
              />
              <BCBS>{genraterName(bet.betModifier)}</BCBS>
            </BCBD>
          )}
        </>
      )}
    </section>
  );
};

BetslipEW.propTypes = {
  handleEwData: PropTypes.func,
  bonus: PropTypes.bool,
  bet: PropTypes.object,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  actTab: PropTypes.string,
  universal: PropTypes.bool,
};
BetslipEW.defaultProps = {
  universal: true,
};
