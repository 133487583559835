import React from 'react';
import PropTypes from 'prop-types';
import { CBLB } from 'UI/apps/BetslipUKApp/BetslipContainer/CrossButton';
/**
   CrossButton:
   Return JSX for beting stuff
  ** that for close button 
  
   @params {*} handleDelete handle click,
   @params {*} selectionId id of bet 
   */

export const CrossButton = ({ handleDelete, selectionId, betTypeRef }) => {
  return (
    <li>
      <CBLB
        type="button"
        onClick={() =>
          handleDelete({
            deleteBet: true,
            betTypeRef: betTypeRef,
            selectionId: selectionId,
          })
        }
      >
        &times;
      </CBLB>
    </li>
  );
};
CrossButton.propTypes = {
  handleDelete: PropTypes.func,
  betTypeRef: PropTypes.string,
  selectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
