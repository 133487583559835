import {
  getEventDetails,
  futureRacesOutcomesData,
} from './core__racing-events-store';
import { MARKET_CONST as MARKET_CONST_LOCAL } from './component/helper/racingAppConfig';
import { MARKET_CONST as MARKET_CONST_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import { getComponent } from 'Services/core__imports';

const MARKET_CONST = getComponent(MARKET_CONST_LOCAL, MARKET_CONST_CORE);
let oddsStatusTimeoutId;

/**
 * function to update odds and dispatch to store
 */
export function updateOdds(updatedData) {
  return (dispatch, getState) => {
    const stateData = getState();
    const eventDetails = stateData.horseRacesList.eventData;
    let isUpdated = false;
    for (const event of eventDetails) {
      for (const market of event?.market) {
        if (
          market?.name === updatedData?.name &&
          market?.typeRef !== 'EFPTP' &&
          updatedData?.name !== 'Enhanced Race Winner' &&
          market?.name === event?.selectedRaceMarket
        ) {
          for (const selection of market?.selection) {
            for (const updatedSel of updatedData?.selections) {
              if (
                selection?.id === updatedSel?.id ||
                selection?.name === updatedSel?.name
              ) {
                if (
                  updatedSel?.price?.bookType === 'PRICE' &&
                  updatedSel?.price?.decimal
                ) {
                  isUpdated = true;
                  if (selection?.decimalOdds > updatedSel?.price?.decimal) {
                    selection['oddsStatus'] =
                      MARKET_CONST.ODDS_UPDATE.ODDS_DECREASE;
                  } else if (
                    selection?.decimalOdds < updatedSel?.price?.decimal
                  ) {
                    selection['oddsStatus'] =
                      MARKET_CONST.ODDS_UPDATE.ODDS_INCREASE;
                  } else if (
                    selection?.decimalOdds === updatedSel?.price?.decimal
                  ) {
                    selection['oddsStatus'] = null;
                  }
                  selection['fractionalOdds'] = updatedSel?.price?.fractional;
                  selection['decimalOdds'] = updatedSel?.price?.decimal;
                }
              }
            }
          }
        }
      }
      if (updatedData?.name === 'Enhanced Race Winner') {
        for (const market of event?.market) {
          if (
            market?.typeRef === 'FPTP' &&
            Array.isArray(market?.selectionEW)
          ) {
            for (const selection of market?.selectionEW) {
              for (const updatedSel of updatedData?.selections) {
                if (
                  selection?.id === updatedSel?.id ||
                  selection?.name === updatedSel?.name
                ) {
                  if (
                    updatedSel?.price?.bookType === 'PRICE' &&
                    updatedSel?.price?.decimal
                  ) {
                    isUpdated = true;
                    if (selection?.decimalOdds > updatedSel?.price?.decimal) {
                      selection['oddsStatus'] =
                        MARKET_CONST.ODDS_UPDATE.ODDS_DECREASE;
                    } else if (
                      selection?.decimalOdds < updatedSel?.price?.decimal
                    ) {
                      selection['oddsStatus'] =
                        MARKET_CONST.ODDS_UPDATE.ODDS_INCREASE;
                    } else if (
                      selection?.decimalOdds === updatedSel?.price?.decimal
                    ) {
                      selection['oddsStatus'] = null;
                    }
                    selection['fractionalOdds'] = updatedSel?.price?.fractional;
                    selection['decimalOdds'] = updatedSel?.price?.decimal;
                  }
                }
              }
            }
          }
        }
      }
    }
    if (isUpdated) {
      dispatch(getEventDetails(JSON.parse(JSON.stringify(eventDetails))));
      if (oddsStatusTimeoutId) {
        clearTimeout(oddsStatusTimeoutId);
      }
      oddsStatusTimeoutId = setTimeout(() => {
        for (const updatedSel of updatedData?.selections) {
          for (const event of eventDetails) {
            for (const market of event?.market) {
              if (
                market?.name === updatedData?.name &&
                market?.name === event?.selectedRaceMarket
              ) {
                if (Array.isArray(market?.selection)) {
                  for (const selection of market?.selection) {
                    if (
                      updatedSel?.id === selection?.id ||
                      selection?.name === updatedSel?.name
                    ) {
                      if (updatedSel?.price?.bookType === 'PRICE') {
                        selection['oddsStatus'] = null;
                      }
                    }
                  }
                }
              }
            }
            if (updatedData?.name === 'Enhanced Race Winner') {
              for (const market of event?.market) {
                if (market?.typeRef === 'FPTP') {
                  if (Array.isArray(market?.selectionEW)) {
                    for (const selection of market?.selectionEW) {
                      if (
                        updatedSel?.id === selection?.id ||
                        selection?.name === updatedSel?.name
                      ) {
                        if (updatedSel?.price?.bookType === 'PRICE') {
                          selection['oddsStatus'] = null;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        dispatch(getEventDetails(JSON.parse(JSON.stringify(eventDetails))));
      }, 1000);
    }
  };
}
/**
 * function to update ouright odds and dispatch to store
 */
export function updateOutrightOdds(updatedData) {
  return (dispatch, getState) => {
    const stateData = getState();
    const eventDetails = stateData.futureRacesData.futureRacingOutcomesData;
    for (const market of eventDetails?.market) {
      for (const selection of market?.selection) {
        for (const updatedSel of updatedData?.selections) {
          if (selection?.id === updatedSel?.id) {
            if (updatedSel?.price?.bookType === 'PRICE') {
              if (selection?.decimalOdds > updatedSel?.price?.decimal) {
                selection['oddsStatus'] =
                  MARKET_CONST.ODDS_UPDATE.ODDS_DECREASE;
              } else if (selection?.decimalOdds < updatedSel?.price?.decimal) {
                selection['oddsStatus'] =
                  MARKET_CONST.ODDS_UPDATE.ODDS_INCREASE;
              } else if (
                selection?.decimalOdds === updatedSel?.price?.decimal
              ) {
                selection['oddsStatus'] = null;
              }
              selection['fractionalOdds'] = updatedSel?.price?.fractional;
              selection['decimalOdds'] = updatedSel?.price?.decimal;
            }
          }
        }
      }
    }
    dispatch(futureRacesOutcomesData(JSON.parse(JSON.stringify(eventDetails))));
    setTimeout(() => {
      for (const updatedSel of updatedData?.selections) {
        for (const market of eventDetails?.market) {
          for (const selection of market?.selection) {
            if (selection?.id === updatedSel?.id) {
              selection['oddsStatus'] = null;
            }
          }
        }
      }
      dispatch(
        futureRacesOutcomesData(JSON.parse(JSON.stringify(eventDetails)))
      );
    }, 1000);
  };
}
