import styled, { css } from 'styled-components';
import {
  CARD,
  BRAND,
  FONT,
  GREYS,
  SHADOW,
  SUPPLEMENTARY,
} from 'UI/globals/colours';
import theme from 'UI/globals/theme';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { EAr } from 'CORE__UI/globals/ExpandArrow/core__expandArrowStyles';

// Top level div
export const TD = styled.div`
  position: relative;
`;
// Div to display selected event
export const SE = styled.div`
  color: ${FONT.primary};
  font-weight: 700;
  line-height: 24px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    align-items: center;
  }
`;
// Main div to display selected event
export const MED = styled.div`
  display: flex;
  font-family: ${theme.fontFamily};
  padding: 16px;
  background-color: ${GREYS.white};
  margin: 16px 0;
  font-size: 18px;
  color: ${FONT.primary};
  font-weight: bold;
  box-shadow: ${SHADOW};
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  span {
    font-size: 12px;
  }
`;

// Div to display content
export const CD = styled.div`
  width: 300px;
  box-shadow: ${CARD.boxShadow};
  border-radius: 4px;
  background-color: ${GREYS.white};
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 8px;
`;
// Div to event/subcat content
export const ED = styled.div`
  overflow: auto;
  max-height: 400px;
`;
// Div to display content data
export const DCD = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid ${BRAND.tertiary};
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
  }
  font-weight: ${props => props?.addActiveClass && 'bold'};
`;
// Div to display names
export const DCN = styled.div`
  color: ${GREYS.black};
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
  width: 92%;
`;
export const TM = styled.div`
  color: ${GREYS.black};
`;
// styled component for back arrow
export const BA = styled.a`
  margin-right: 12px;
  font-size: 22px;
`;

export const KOC = styled.div`
  flex: auto;
  font-weight: normal;
  min-width: fit-content;
  margin-left: 16px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const EA = styled(EAr)`
  margin-right: 16px;
  &:after {
    border-color: ${BRAND.primary};
  }
  ${({ theme }) =>
    theme?.dark &&
    css`
      &:after {
        border-color: ${FONT.lightMute};
      }
      &:hover {
        &::after {
          border-color: ${SUPPLEMENTARY
            ? SUPPLEMENTARY.casino
            : FONT.secondary};
        }
      }
    `}
`;
