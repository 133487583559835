import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIcon } from 'Services/icons/core__icons';
import { MI, HD, SD } from 'UI/apps/RacingEventsApp/RaceStreaming';
import Icon from 'UI/globals/Icons';
import { Translations } from '../../../core__racing-events-app';
import StreamPlayer from './core__stream-player';

const RaceStreaming = ({ icon }) => {
  const { sessionInfo } = useSelector(state => state.horseRacingData);
  const {
    videoStreamObj,
    streamProviderObject,
    isStreamDisplayed,
  } = useSelector(state => state.horseRacesList);

  const {
    provider,
    userQualified,
    flumenResource,
    sessionAvaiable,
    videoStreamingAvailable,
  } = streamProviderObject;

  const { backgroundimage, showstreaminginfobox, stakeamount } = videoStreamObj;

  const isStreamViewPortMounted =
    (sessionAvaiable || sessionInfo?.accessToken) &&
    userQualified &&
    videoStreamingAvailable &&
    Array.isArray(flumenResource);

  return (
    <MI
      imageUrl={backgroundimage}
      isStreamViewPortMounted={isStreamViewPortMounted}
    >
      <React.Fragment>
        {!isStreamDisplayed && showstreaminginfobox !== 'false' && (
          <HD>
            <Icon
              iconName={icon ? getIcon(icon) : getIcon('TVICON')}
              size={24}
            />{' '}
            {Translations.get('racing.live.tv.available')}
          </HD>
        )}
        {showstreaminginfobox !== 'false' ? (
          !sessionAvaiable && !sessionInfo?.accessToken ? (
            <SD>{Translations.get('racing.streaming.messagelogin')}</SD>
          ) : !userQualified ? (
            <React.Fragment>
              <div>
                {Translations.get('racing.streaming.videoqualifymessage1')}
                {stakeamount}{' '}
                {Translations.get('racing.streaming.videoqualifymessage2')}
              </div>
              <div>
                {Translations.get('racing.streaming.videoqualifymessage3')}
                {stakeamount}.
                {Translations.get('racing.streaming.videoqualifymessage4')}
              </div>
              <div>
                {Translations.get('racing.streaming.videoqualifymessage5')}
              </div>
            </React.Fragment>
          ) : !videoStreamingAvailable ? (
            <div>{Translations.get('racing.streaming.available.message')}</div>
          ) : (
            !Array.isArray(flumenResource) && (
              <div>{Translations.get('racing.streaming.videoqualified2')}</div>
            )
          )
        ) : (
          !sessionAvaiable &&
          !sessionInfo?.accessToken &&
          !userQualified &&
          !videoStreamingAvailable &&
          !Array.isArray(flumenResource) && (
            <div>{Translations.get('racing.streaming.videoqualified1')}</div>
          )
        )}

        {isStreamViewPortMounted && <StreamPlayer provider={provider} />}
      </React.Fragment>
    </MI>
  );
};

RaceStreaming.propTypes = {
  icon: PropTypes.string,
  videoStreamObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sessionInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  streamProviderObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  isStreamDisplayed: PropTypes.bool,
};

RaceStreaming.defaultProps = {
  icon: '',
  videoStreamObj: {},
  sessionInfo: {},
  streamProviderObject: {},
  isStreamDisplayed: false,
};

export const RaceStream = RaceStreaming;
