import styled from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const BBS = styled.span`
  font-family: ${theme.fontFamily};
  color: ${({ active }) => (active ? FONT.dark : GREYS.silver)};
  font-size: 12px;
  user-select: none;
`;
