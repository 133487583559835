import {
  RB,
  SRB,
  SMRB,
  RC,
  TV,
  EP,
  HRB,
} from 'CORE__UI/buttons/RacingButton/core__racingButtonStyles';
import styled, { css } from 'styled-components';
import { GREYS, BRAND, FONT, FUNCTIONAL } from 'UI/globals/colours';

const RB_EX = styled(RB)`
  background-color: ${GREYS.black};
  border: unset;
  color: ${GREYS.white};
  @media (hover) {
    &:hover {
      background-color: transparent;
      border-color: ${BRAND.secondaryL};
    }
  }
`;

const HRB_EX = styled(HRB)`
  background-color: ${GREYS.black};
  color: ${FONT.darkMute};
  border: unset;

  @media (hover) {
    &:hover {
      background-color: transparent;
      border-color: ${BRAND.secondaryL};
    }
  }
`;

const SRB_ST = css`
  border-color: ${BRAND.secondary};
  color: ${BRAND.secondary};
  background-color: transparent;
  @media (hover) {
    &:hover {
      background-color: transparent;
      border-color: ${BRAND.secondaryL};
    }
  }
`;

const SRB_EX = styled(SRB)`
  ${SRB_ST}
`;

const SMRB_EX = styled(SMRB)`
  ${SRB_ST}
`;

const EP_EX = styled(EP)`
  color: ${BRAND.secondary};
`;

const RC_EX = styled(RC)`
  &:before {
    background-color: ${BRAND.secondary};
    border: 1px solid ${FUNCTIONAL.negative};
  }
  &:after {
    border-color: ${BRAND.secondary};
    border: 1px solid ${FUNCTIONAL.negative};
    width: 5px;
    height: 5px;
  }
`;

export {
  RB_EX as RB,
  SRB_EX as SRB,
  SMRB_EX as SMRB,
  RC_EX as RC,
  TV,
  EP_EX as EP,
  HRB_EX as HRB,
};
