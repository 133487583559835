import React from 'react';
import PropTypes from 'prop-types';
import { SYMBOLS } from 'Services/session/core__session.utils';
import project from '../../../../../../../../project';

let defaultSymbol = project?.defaultCurrency || 'GBP';
if (typeof defaultSymbol === 'object') {
  defaultSymbol = defaultSymbol[0]['label'];
}
/**
 * this component contains the table for each category
 * @param {*} symbol string of currency code
 */

export const BetslipCurrency = ({ symbol }) => {
  return symbol ? (
    <span>{SYMBOLS[symbol]}</span>
  ) : (
    <span>{SYMBOLS[defaultSymbol]}</span>
  );
};

BetslipCurrency.propTypes = {
  symbol: PropTypes.string,
};
