import React from 'react';
import PropTypes from 'prop-types';
import {
  B,
  PC as PC_Local,
  BCS as BCS_Local,
} from 'UI/apps/BetslipUKApp/ReceiptContainer/Header';
import {
  H,
  AB,
  ABC,
  BCS as BCS_Core,
  PC as PC_Core,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__header';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-UK-app';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import project from '../../../../../../../../project';
import { getComponent } from 'Services/core__imports';

const BCS = getComponent(BCS_Local, BCS_Core);
const PC = getComponent(PC_Local, PC_Core);
/**
 this for genrate header tab section
@param {string} hedingName 
*/
export const ReceiptHeader = ({
  hedingName,
  betBonusPercent,
  oddsFormat,
  bet,
}) => {
  return (
    <>
      <H>
        <B>{hedingName}</B>
        {bet?.betCount && bet.betCount === 1 && (
          <PC>
            <BCS>
              {(oddsFormat === Constants.DECIMAL && (
                <BCS>
                  {bet?.indicativeDecimalPrice &&
                    (bet?.indicativeDecimalPrice > 0
                      ? parseDecimalPlaces(bet?.indicativeDecimalPrice)
                      : Constants.N_A)}
                </BCS>
              )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS>{getOdds(bet?.indicativeDecimalPrice, true)}</BCS>
                )) || (
                  <BCS>
                    {['-1/1', '0/0'].includes(bet.indicativeFractionalPrice)
                      ? Constants.N_A
                      : bet?.indicativeFractionalPrice}
                  </BCS>
                )}
            </BCS>
          </PC>
        )}
        {project.JpTimeFormat && betBonusPercent && (
          <ABC>
            <AB>
              +{betBonusPercent}% {Translations.get('text.acca.bonus')}
            </AB>
          </ABC>
        )}
      </H>
    </>
  );
};
ReceiptHeader.propTypes = {
  bet: PropTypes.object,
  oddsFormat: PropTypes.string,
  hedingName: PropTypes.string,
  betBonusPercent: PropTypes.number,
};
