import styled from 'styled-components';
import { BRAND, FONT, GREYS, CARD } from 'UI/globals/colours';

export const B = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: ${FONT.primary};
  margin-top: 20px;
  padding: 8px;
  margin-left: 4px;
  border-top: 1px solid ${BRAND.tertiary};
`;

export const H = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

//acc bonus container
export const ABC = styled.div`
  flex: 3;
  border-top: 1px solid ${GREYS.doveD};
  margin-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AB = styled.h5`
  font-size: 9px;
  font-weight: 500;
  color: ${FONT.secondary};
  padding: 8px;
  letter-spacing: 0.32px;
  background: ${CARD.lightYellow};
  border-radius: 4px;
  padding: 4px;
  align-self: center;
`;

export const PC = styled.div`
  margin-top: 20px;
  padding: 8px;
  border-top: 1px solid ${GREYS.doveD};
`;

export const BCS = styled.span`
  font-weight: bold;
  font-size: 14px;
  user-select: none;
  color: ${FONT.dark};
`;
