import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';

export const QLM = styled.div`
  border-top: 1px solid ${GREYS.dove};
  margin: 0px 20px;
`;
export const QLMH4 = styled.h4`
  color: ${BRAND.primary};
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  margin-top: 32px;
`;

export const QLUL = styled.ul`
  list-style: none;
  margin: 12px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`;
export const QLLI = styled.li`
  margin: 0;
  padding: 4px 0px;
`;
export const QLA = styled.a`
  border-radius: 4px;
  background-color: ${BRAND.tertiary};
  padding: 16px;
  text-decoration: none;
  display: block;
  color: ${BRAND.primary};
  font-size: 12px;
  font-weight: 700;
  text-align: left;
`;
