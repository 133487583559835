import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BSSMD,
  BI,
  BetslipContentBetBoxPot,
} from 'UI/apps/BetslipUKApp/BetslipContainer/Stake';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-UK-app';
import { detectMob } from '../../../../../core__betslip-utils';
import project from '../../../../../../../../project';
/**
 *Stack File
 * @param {number} userStakeBet
 * @param {Function} setUserStake // remove due to bet validation
 * @param {object} betMf
 * @param {object} price
 * @param {object} bet
 */
export const BetslipUserStake = ({
  userStakeBet,
  betMf,
  price,
  bet,
  handleFocusOut,
  isMaxBetError,
}) => {
  const [inputType, setInputType] = useState('text');
  betMf['multiple'] = bet.multiple;

  useEffect(() => {
    if (detectMob()) {
      setInputType('number');
      // sending a pubsub event to parent site for calculating the height
      if (project?.betSlipMobileView) {
        const betSlipInput = document.getElementsByClassName('betSlip_input');
        if (betSlipInput && betSlipInput.length > 0) {
          for (let i = 0; i < betSlipInput.length; i++) {
            betSlipInput[i].addEventListener('focus', () => {
              if (detectMob()) {
                const contentBetslipFooter = document.getElementById(
                  'betslip-bottom'
                );
                const betslipView = document.getElementById('betslip_view');
                if (contentBetslipFooter) {
                  contentBetslipFooter.style.visibility = 'hidden';
                }
                if (betslipView) {
                  betslipView.style.background = Constants.COLOR;
                }
              }
            });
            betSlipInput[i].addEventListener('blur', () => {
              if (
                document.activeElement.tagName === null ||
                document.activeElement.tagName === Constants.BODY
              ) {
                if (detectMob()) {
                  const contentBetslipFooter = document.getElementById(
                    'betslip-bottom'
                  );
                  if (contentBetslipFooter) {
                    contentBetslipFooter.style.visibility = 'initial';
                  }
                }
              }
            });
          }
        }
      }
    }
  }, []);

  const keyDownStake = event => {
    const isMobile = detectMob();
    if (
      event &&
      event.keyCode === 13 &&
      project?.betSlipMobileView &&
      isMobile
    ) {
      const betSlipInput = document.getElementsByClassName('betSlip_input');
      if (betSlipInput && betSlipInput.length > 0) {
        for (let i = 0; i < betSlipInput.length; i++) {
          betSlipInput[i]?.blur();
        }
        if (detectMob()) {
          const contentBetslipFooter = document.getElementById(
            'betslip-bottom'
          );
          if (contentBetslipFooter) {
            contentBetslipFooter.style.visibility = 'initial';
          }
        }
      }
    }
  };
  return (
    <BSSMD
      accaBonus={bet?.betBonus}
      highlighterror={
        isMaxBetError || (bet?.isValid !== undefined && !bet.isValid)
      }
    >
      <BI
        className="betSlip_input"
        type={inputType}
        placeholder={Translations.get('text.stake')}
        value={userStakeBet}
        tabIndex="0"
        inputMode="decimal"
        onKeyDown={keyDownStake}
        onChange={
          bet.betTypeRef !== Constants.BBB
            ? e => {
                handleFocusOut(
                  e.target.value,
                  betMf,
                  price,
                  bet?.priceChangeNotification,
                  bet.bbFlag
                );
              }
            : e => {
                handleFocusOut(
                  e.target.value,
                  { betTypeRef: Constants.BBB, retn: price },
                  price,
                  bet?.priceChangeNotification,
                  false
                );
              }
        }
        onBlur={e =>
          bet.betTypeRef !== Constants.BBB
            ? handleFocusOut(
                e.target.value,
                betMf,
                price,
                bet?.priceChangeNotification,
                bet.bbFlag
              )
            : handleFocusOut(
                e.target.value,
                { betTypeRef: Constants.BBB, retn: price },
                price,
                false
              )
        }
      />
      <BetslipContentBetBoxPot>
        {betMf && betMf.betCount && betMf.betCount > 1 && ` x` + betMf.betCount}
      </BetslipContentBetBoxPot>
    </BSSMD>
  );
};

BetslipUserStake.propTypes = {
  userStakeBet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setUserStake: PropTypes.func,
  bet: PropTypes.object,
  catRef: PropTypes.string,
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  handleFocusOut: PropTypes.func,
  isMaxBetError: PropTypes.bool,
};
