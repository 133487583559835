import styled, { css } from 'styled-components';
import { GREYS, FUNCTIONAL } from 'UI/globals/colours';

export const BCBD = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

export const BCBC = styled.input`
  width: 20px;
  height: 20px;
  line-height: inherit;
`;

export const BCBS = styled.span`
  font-size: 12px;
  margin-left: 4px;
  color: ${GREYS.silver};
`;

export const S = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid ${GREYS.doveD};
  background-color: ${GREYS.silver};
  margin-left: 8px;
  width: 20px;
  height: 20px;
  :after {
    content: '';
    position: absolute;
    display: none;
  }
  ${({ dc }) =>
    dc &&
    css`
      :after {
        left: 6px;
        top: 1px;
        width: 6px;
        height: 14px;
        border: solid ${FUNCTIONAL.guide};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: ${GREYS.silver};
      }
    `}
`;

export const L = styled.label`
  margin: 12px 8px 20px 8px;
  color: ${GREYS.doveD};
  font-size: 12px;
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ ${S} {
    background-color: ${GREYS.silver};
    border: 1px solid ${FUNCTIONAL.guide};
  }

  input:checked ~ ${S}:after {
    display: block;
  }
`;
