import {
  TIJ,
  HIAP,
  OIO,
  HRI,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesHorsesDogs';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

const TIJ_EX = styled(TIJ)`
  color: ${GREYS.dove};
`;

const HIAP_EX = styled(HIAP)`
  color: ${GREYS.dove};
`;

const OIO_EX = styled(OIO)`
  color: ${GREYS.dove};
`;

const HRI_EX = styled(HRI)`
  background-color: ${GREYS.silverD};
`;

export { TIJ_EX as TIJ, HIAP_EX as HIAP, OIO_EX as OIO, HRI_EX as HRI };
