import {
  BS,
  B,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__betslipReact';
import styled, { css } from 'styled-components';
import { GREYS, FONT } from '../../../globals/colours';
import { BREAKPOINT_M } from '../../../globals/breakpoints';

const BS_EX = styled(BS)`
  background-color: ${GREYS.silverD};
`;

const B_EX = styled(B)`
  @media (min-width: ${BREAKPOINT_M}) {
    border: 4px solid ${FONT.black};
  }
  @media (max-width: ${BREAKPOINT_M}) {
    ${({ openBS }) =>
      openBS &&
      css`
        border: 4px solid ${FONT.black};
        position: fixed;
      `}
  }
`;

export { BS_EX as BS, B_EX as B };
