import {
  MD,
  RP,
  RIDM,
  RID,
  RIR,
  IU,
  IL,
  MI,
  TI,
  HI,
  OI,
  TIH,
  TIJ,
  TII,
  TBB,
  HIF,
  HIA,
  OIO,
  ED,
  EDB,
  RS,
  RA,
  OIF,
  HRI,
  RST,
  TLD,
  HIAP,
  OB,
  H,
  HR,
  HD,
  HM,
  HRB,
  HV,
  WB,
  OT,
  OMD,
  ST,
  SO,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesPage';
import styled from 'styled-components';
import { GREYS, FONT, FUNCTIONAL, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const MD_EX = styled(MD)`
  box-shadow: none;
`;

const RP_EX = styled(RP)`
  color: ${GREYS.white};
`;

const RID_EX = styled(RID)`
  color: ${BRAND.secondary};
`;

const IL_EX = styled(IL)`
  color: ${GREYS.white};
`;

const HRB_EX = styled(HRB)`
  color: ${GREYS.white};
`;

const TIH_EX = styled(TIH)`
  color: ${GREYS.white};
  font-weight: 700;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const TIJ_EX = styled(TIJ)`
  color: ${GREYS.dove};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const HIF_EX = styled(HIF)`
  color: ${FONT.lightMute};
`;

const HIAP_EX = styled(HIAP)`
  color: ${GREYS.dove};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const OIO_EX = styled(OIO)`
  color: ${FONT.lightMute};
`;

const MI_EX = styled(MI)`
  border-bottom: 1px solid ${FONT.primary};
  &:last-of-type {
    border-bottom: none;
  }
`;

const EDB_EX = styled(EDB)`
  border-top: 1px solid ${FONT.primary};
  border-bottom: 1px solid ${FONT.primary};
`;

const TII_EX = styled(TII)`
  color: ${GREYS.white};
  font-weight: bold;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const WB_EX = styled(WB)`
  color: ${FONT.secondary};
`;

const RIR_EX = styled(RIR)`
  background-color: ${FUNCTIONAL.warningD};
  color: ${GREYS.white};
`;

const RS_EX = styled(RS)`
  background-color: ${FUNCTIONAL.positiveD};
  color: ${GREYS.white};
`;

const OT_EX = styled(OT)`
  color: ${GREYS.white};
`;

const HM_EX = styled(HM)`
  border-bottom: 1px solid ${FONT.primary};
  background-color: ${GREYS.silverD};
`;

const HRI_EX = styled(HRI)`
  background-color: ${GREYS.silverD};
`;

export {
  MD_EX as MD,
  RP_EX as RP,
  RIDM,
  RID_EX as RID,
  RIR_EX as RIR,
  IU,
  IL_EX as IL,
  MI_EX as MI,
  TI,
  HI,
  OI,
  TIH_EX as TIH,
  TIJ_EX as TIJ,
  TII_EX as TII,
  TBB,
  HIF_EX as HIF,
  HIA,
  OIO_EX as OIO,
  ED,
  EDB_EX as EDB,
  RS_EX as RS,
  RA,
  OIF,
  HRI_EX as HRI,
  RST,
  TLD,
  HIAP_EX as HIAP,
  OB,
  H,
  HR,
  HD,
  HM_EX as HM,
  HRB_EX as HRB,
  HV,
  WB_EX as WB,
  OT_EX as OT,
  OMD,
  ST,
  SO,
};
