import {
  StyledSelect,
  styles1,
} from 'CORE__UI/lines/dropdowns/PriceSelect/core__priceSelectStyles';

import styled from 'styled-components';
import { BRAND, GREYS } from '../../globals/colours';

const StyledSelectJN = styled(StyledSelect)`
  background-color: ${BRAND.primary};
  border: none;
  div[class*='singleValue'] {
    color: ${BRAND.secondary};
    font-weight: 700;
  }
  div[class*='option'] {
    color: ${BRAND.secondary};
    background-color: transparent;
  }
  div[class*='option']:hover {
    color: ${BRAND.secondary};
    background-color: ${GREYS.black};
    font-weight: 700;
  }
`;

let styles1JN = styles1;

styles1JN = {
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
    backgroundColor: BRAND.primary,
    border: 'none',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: BRAND.primary,
    color: BRAND.secondary,
  }),
};

export { StyledSelectJN as StyledSelect, styles1JN as styles1 };
