import React from 'react';
import PropTypes from 'prop-types';
import { BetBoxLayout } from './index';
import { BetslipTabsUK } from '../BetslipTabs/elements';
import { BetBoxLayoutHorseDog } from './index';
import { CastOrderComponent } from './elements';
import { Constants } from '../../../../core__betslip-constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { ShowExpand } from '../BetslipContent/elements/core__showExpand';

/**
Content:
main container of Content 

@param {*} betTemplate (whole object of bet)
@param {*} actTab (active tab from bet header) 
@param {*} handleDelete function of delete Bet
@param {*} callSetUserBetInLocalStorage function of set data in Ls
@param {*} UserAuthData object of data which come to session 
@param {*} region string of Region come through AppConfig via CMS
@param {*} handleWsAcceptDecline function which handle websocket data
 */

export const Content = ({
  betTemplate,
  actTab,
  handleDelete,
  callSetUserBetInLocalStorage,
  UserAuthData,
  handleWsAcceptDecline,
  oddsFormat,
  isLogin,
  setShowFB,
  setErrorObj,
  isError,
  showMoreLess,
  isShowless,
  hidebetslip,
  countAccaBet,
  hidenextbetbonus,
  allowFreebetsOnEachway,
  errorObj,
  displayMultiplesFirst,
  secondaryOddsBoostButton,
}) => {
  let ukBetTemplate = new Array();
  ukBetTemplate = [...betTemplate];

  const showMultipleExpandObj = betTemplate?.find(
    bet => bet.tab && bet.lessMore
  );

  return (
    <React.Fragment>
      {Array.isArray(ukBetTemplate) &&
        ukBetTemplate.map((bet, i) => {
          actTab = bet['multiple'] ? Constants.MULTIPLE : Constants.SINGLE;
          const betBoxStuff = {
            bet,
            i,
          };
          const whenMultiplesFirstHideBottomBorder =
            i + 1 < ukBetTemplate.length &&
            bet.multiple &&
            !ukBetTemplate[i + 1]?.multiple;

          const whenMultiplesLastHideBottomBorder =
            i + 1 === ukBetTemplate.length && bet.multiple;

          const whenMultiplesCollapsedHideBottomBorder =
            isShowless && !bet.disappear && bet.multiple;

          const hideBottomBorder =
            showMultipleExpandObj &&
            (whenMultiplesFirstHideBottomBorder ||
              whenMultiplesLastHideBottomBorder ||
              whenMultiplesCollapsedHideBottomBorder);

          return (
            <React.Fragment key={i}>
              {bet.tab ? (
                <React.Fragment>
                  {bet.actTab === Constants.SINGLE_C &&
                    displayMultiplesFirst && (
                      <ShowExpand
                        showMultipleExpandObj={showMultipleExpandObj}
                        showMoreLess={showMoreLess}
                        isShowless={isShowless}
                      />
                    )}
                  {/*Horse and Dog Layout show tab of forcast and tricast and
                  multiple tab genrater*/}
                  <BetslipTabsUK
                    tabs={[bet]}
                    props={{
                      actTab: bet.actTab,
                      handleDelete: handleDelete,
                      showMoreLess: showMoreLess,
                      isShowless: isShowless,
                    }}
                  />
                </React.Fragment>
              ) : bet.type === GLOBAL_CONSTANTS.CASTORDER ? (
                // Horse and Dog Layout show Up and Down Arrow of cast System,
                <CastOrderComponent
                  callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                  betTemplate={betTemplate}
                  description={bet.description}
                  catref={bet.catref}
                />
              ) : [Constants.HORSES, Constants.DOGS].includes(bet.catRef) ? (
                // Horse and Dog Layout Multiple ,Single
                <BetBoxLayoutHorseDog
                  {...betBoxStuff}
                  key={bet.id}
                  handleDelete={handleDelete}
                  actTab={actTab}
                  callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                  wallet={UserAuthData ? UserAuthData.wallet : []}
                  currencySymbol={UserAuthData?.currencyCode || null}
                  balance={UserAuthData?.balance || null}
                  handleWsAcceptDecline={handleWsAcceptDecline}
                  oddsFormat={oddsFormat}
                  isLogin={isLogin}
                  setShowFB={setShowFB}
                  setErrorObj={setErrorObj}
                  isError={isError}
                  hidebetslip={hidebetslip}
                  countAccaBet={countAccaBet}
                  hideNextBetBonus={hidenextbetbonus}
                  allowFreebetsOnEachway={allowFreebetsOnEachway}
                  errorObj={errorObj}
                  hideBottomBorder={hideBottomBorder}
                  secondaryOddsBoostButton={secondaryOddsBoostButton}
                  betTemplate={betTemplate}
                />
              ) : (
                // remain all Layout Multiple ,Single
                <BetBoxLayout
                  {...betBoxStuff}
                  key={bet.id}
                  handleDelete={handleDelete}
                  actTab={actTab}
                  callSetUserBetInLocalStorage={callSetUserBetInLocalStorage}
                  wallet={UserAuthData.wallet ? UserAuthData.wallet : []}
                  currencySymbol={UserAuthData?.currencyCode || null}
                  balance={UserAuthData?.balance || null}
                  handleWsAcceptDecline={handleWsAcceptDecline}
                  oddsFormat={oddsFormat}
                  isLogin={isLogin}
                  setShowFB={setShowFB}
                  setErrorObj={setErrorObj}
                  isError={isError}
                  hidebetslip={hidebetslip}
                  countAccaBet={countAccaBet}
                  hideNextBetBonus={hidenextbetbonus}
                  errorObj={errorObj}
                  hideBottomBorder={hideBottomBorder}
                  secondaryOddsBoostButton={secondaryOddsBoostButton}
                  betTemplate={betTemplate}
                />
              )}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

Content.propTypes = {
  betTemplate: PropTypes.array,
  hideNextBetBonus: PropTypes.array,
  hidenextbetbonus: PropTypes.string,
  actTab: PropTypes.string,
  handleDelete: PropTypes.func,
  callSetUserBetInLocalStorage: PropTypes.func,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  region: PropTypes.string,
  handleWsAcceptDecline: PropTypes.func,
  oddsFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isLogin: PropTypes.bool,
  setShowFB: PropTypes.func,
  setErrorObj: PropTypes.func,
  isError: PropTypes.bool,
  isShowless: PropTypes.bool,
  hidebetslip: PropTypes.bool,
  showMoreLess: PropTypes.func,
  countAccaBet: PropTypes.number,
  allowFreebetsOnEachway: PropTypes.bool,
  errorObj: PropTypes.object,
  displayMultiplesFirst: PropTypes.bool,
  secondaryOddsBoostButton: PropTypes.bool,
};

export const ContentTab = Content;
