import {
  BCBD,
  BCBC,
  BCBS,
  S,
  L,
  T,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bonus';
import styled from 'styled-components';
import { FONT } from '../../../globals/colours';

const BCBDFK = styled(BCBD)`
  margin: 8px;
  border-radius: 4px;
`;
const BCBSFK = styled(BCBS)`
  color: ${FONT.lightMute};
`;

const LJN = styled(L)`
  margin: 8px 8px 8px 8px;
`;

export { BCBDFK as BCBD, BCBC, BCBSFK as BCBS, S, LJN as L, T };
