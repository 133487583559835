import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { DH5 } from 'UI/apps/BetslipUKApp/BetslipContainer/Description';
import { formatTimeStamp } from '../../../../../core__betslip-utils';
/** 
genrateDescription:
split by (|) and print the description

@param {*} desc (this is coming via api bets.description and data is | seprated)
@param {bool} active 
@param {string} catRef 
@param {string} schedule
*/

export const Description = ({ desc, active, catRef, schedule }) => {
  const descArr = desc && desc.split('|');
  return (
    <DH5 active={active}>
      {([Constants.HORSES, Constants.DOGS].includes(catRef) &&
        formatTimeStamp(schedule) +
          ' ' +
          descArr[descArr.length - 3] +
          ' ' +
          descArr[descArr.length - 1]) ||
        descArr[descArr.length - 1] + '- ' + descArr[descArr.length - 2]}
    </DH5>
  );
};

Description.propTypes = {
  desc: PropTypes.string,
  active: PropTypes.bool,
  catRef: PropTypes.string,
  schedule: PropTypes.number,
};
