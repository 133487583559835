import styled, { css } from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';

export const C = styled.ul`
  list-style: none;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const CL = styled.li`
  margin: 0;
  padding: 0;
`;
export const Ch5 = styled.h5`
  font-size: 14px;
  font-weight: bold;
`;

export const D = styled.div`
  bottom: 0;
  background-color: ${GREYS.doveL};
  padding: 8px;
  border-top: 1px solid ${BRAND.tertiary};
  position: relative;
  width: 100%;
  ${({ bottomNavEnabled }) =>
    bottomNavEnabled &&
    css`
      @media (max-width: ${BREAKPOINT_M}) {
        bottom: 40px;
      }
    `}
`;

export const DBTN = styled.button`
  background-color: ${BRAND.secondary};
  border: 1px solid ${BRAND.secondary};
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  margin: 8px 0px;
`;
export const DS = styled.span`
  margin-left: 8px;
  position: absolute;
  margin-top: 4px;
  width: 50vh;
`;
export const DChekBx = styled.input`
  width: 20px;
  height: 20px;
`;
