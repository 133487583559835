import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BSI,
  BSM,
  BAMY,
  BA,
  BS,
  BHL,
  BHU,
  SUMMARY_TEXT as SUMMARY_TEXT_LOCAL,
  SUMMARY_PRICE as SUMMARY_PRICE_LOCAL,
} from 'UI/apps/BetslipUKApp/BetslipContainer/Head';
import { Translations } from '../../../../../core__betslip-UK-app';
import { Constants } from '../../../../../core__betslip-constants';
import {
  SUMMARY_PRICE as SUMMARY_PRICE_CORE,
  SUMMARY_TEXT as SUMMARY_TEXT_CORE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__head';
import { genraterName, detectMob } from '../../../../../core__betslip-utils';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getComponent } from 'Services/core__imports';

const SUMMARY_TEXT = getComponent(SUMMARY_TEXT_LOCAL, SUMMARY_TEXT_CORE);
const SUMMARY_PRICE = getComponent(SUMMARY_PRICE_LOCAL, SUMMARY_PRICE_CORE);

/**
@param {string} actbet   
@param {number} count 
@param {bool} isLogin 
@param {bool} dowmIcon 
@param {func} expendandcollapse
@param {array} betTemplate
@param {string} oddsFormat

*/

export const BetslipHeader = ({
  actbet,
  count,
  isLogin,
  expendandcollapse,
  downIcon,
  appConfig,
  betTemplate,
  oddsFormat,
}) => {
  useEffect(() => {
    if (appConfig?.hidebetslip && count === 0) {
      const betslip = document.getElementById('betslip_container');
      if (betslip) {
        betslip.style.height = `initial`;
      }
    }
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  const selections = betTemplate?.filter(b => b.selectionId) || [];

  let summary;
  if (selections.length === 1) {
    summary = (
      <BHL>
        <SUMMARY_TEXT>{Translations.get('text.single')}</SUMMARY_TEXT>
        <SUMMARY_PRICE>
          {oddsFormat === Constants.DECIMAL
            ? parseDecimalPlaces(selections[0].priceDecimal)
            : selections[0].priceFractional}
        </SUMMARY_PRICE>
      </BHL>
    );
  } else if (selections.length > 1) {
    const bet = betTemplate.find(b => {
      return (
        b.multiple && b.betModifier.length && b.betModifier[0].betCount === 1
      );
    });
    const name =
      bet &&
      Translations.get(
        `bet.type.${genraterName(bet.name, bet.betModifier)
          .toLowerCase()
          .replace(/ /g, '.')}`
      );

    const price =
      bet &&
      bet.betModifier.length &&
      (oddsFormat === Constants.DECIMAL
        ? parseDecimalPlaces(bet.betModifier[0].returns)
        : bet.betModifier[0].fractionalReturns);

    summary = name && price && (
      <BHL>
        <SUMMARY_TEXT>{name}</SUMMARY_TEXT>
        <SUMMARY_PRICE>{price}</SUMMARY_PRICE>
      </BHL>
    );
  }

  return (
    <BHU
      onClick={() => {
        expendandcollapse();
      }}
    >
      <BHL>
        <BA href="#" active={actbet === Constants.BET}>
          <span>{Translations.get('text.betslip')}</span>
          <BS>{count && count}</BS>
        </BA>
        <BSM>{count && count}</BSM>
      </BHL>

      {detectMob() && !downIcon && summary}

      <BHL>
        <BSI downIcon={downIcon} />
      </BHL>
      <BHL>
        {!appConfig?.hidemybetsonheader && (
          <BAMY href="#" active={actbet === Constants.MYBET} disable={isLogin}>
            {Translations.get('text.mybet')}
          </BAMY>
        )}
      </BHL>
    </BHU>
  );
};

BetslipHeader.propTypes = {
  count: PropTypes.number,
  actbet: PropTypes.string,
  isLogin: PropTypes.bool,
  expendandcollapse: PropTypes.func,
  downIcon: PropTypes.bool,
  appConfig: PropTypes.bool,
  betTemplate: PropTypes.array,
  oddsFormat: PropTypes.string,
};
