import {
  M,
  MSSDNF,
  M3,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__notification';
import styled from 'styled-components';
import { GREYS, FUNCTIONAL } from '../../../globals/colours';

const M3JN = styled(M3)`
  background-color: ${FUNCTIONAL.negative};
  color: ${GREYS.black};
`;
export { M, MSSDNF, M3JN as M3 };
