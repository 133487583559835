import {
  NoBet,
  NB,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__noBet';
import styled from 'styled-components';
import { FONT, GREYS } from '../../../globals/colours';

const NoBetJN = styled(NoBet)`
  line-height: initial;
  color: ${FONT.lightMute};
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  text-align: center;
  margin: 80px 0px;
  i {
    font-size: 100px;
    display: block;
    margin: 8px auto;
  }
`;
const NBJN = styled(NB)`
  background-color: ${GREYS.silverD};
`;

export { NoBetJN as NoBet, NBJN as NB };
