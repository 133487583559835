import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  futureRaceCollapse,
  gotoFutureRaceOutcomesPage,
} from '../../../core__racing-events-store';
import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from 'UI/apps/RacingEventsApp/FutureRaces';
import { ED } from 'UI/apps/RacingEventsApp/RaceListingPage';
import { Translations } from '../../../core__racing-events-app';

export class FutureRaces extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { futureRacingEvents, requestPage } = this.props;
    return (
      <RL>
        <FRT>{Translations.get('racing.filter.future races')}</FRT>
        {futureRacingEvents.length ? (
          futureRacingEvents.map((nextRace, nIndex) => {
            // sort by event time
            nextRace?.eventsArray.sort(function (x, y) {
              return x.scheduledStart - y.scheduledStart;
            });
            return (
              <React.Fragment key={nIndex}>
                <FRMD
                  data-test={nIndex === 0 && 'tab-futureracecollapse'}
                  onClick={() =>
                    this.props.futureRaceCollapse(futureRacingEvents, nIndex)
                  }
                >
                  <FRD>{nextRace.startDate}</FRD>
                  <FRA expanded={nextRace.isCollapsed}></FRA>
                </FRMD>
                {!nextRace.isCollapsed && (
                  <FRSM>
                    {nextRace?.eventsArray?.map((event, eIndex) => {
                      return (
                        <FRS
                          data-test={eIndex === 0 && 'tab-futureraceevent'}
                          key={eIndex}
                          onClick={() =>
                            this.props.gotoFutureRaceOutcomesPage(
                              event.id,
                              requestPage
                            )
                          }
                        >
                          {event.name}
                        </FRS>
                      );
                    })}
                  </FRSM>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <ED>{Translations.get('text.nodata')}</ED>
        )}
      </RL>
    );
  }
}

FutureRaces.propTypes = {
  futureRacingEvents: PropTypes.array,
  futureRaceCollapse: PropTypes.func,
  gotoFutureRaceOutcomesPage: PropTypes.func,
  requestPage: PropTypes.string,
};

export const mapStateToProps = state => {
  return {
    futureRacingEvents: state.horseRacingData.futureRacingEvents,
    requestPage: state.horseRacingData.requestPage,
  };
};

export const mapDispatchToProps = {
  futureRaceCollapse: futureRaceCollapse,
  gotoFutureRaceOutcomesPage: gotoFutureRaceOutcomesPage,
};

FutureRaces.defaultProps = {
  futureRacingEvents: [],
  requestPage: '',
};

export const FutureRacing = connect(
  mapStateToProps,
  mapDispatchToProps
)(FutureRaces);
