import styled, { css } from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const BL = styled.li`
  margin: 0;
  padding: 0;
  margin-top: 4px;
  &:first-child {
    flex-grow: 1;
  }
`;

export const BH = styled.h3`
  font-weight: bold;
  font-size: 14px;
  user-select: none;
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.dark)};
  ${({ active }) =>
    !active &&
    css`
      color: ${GREYS.silver};
    `};
`;
