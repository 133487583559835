import { BL, BH } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__name';
import styled, { css } from 'styled-components';
import { GREYS } from '../../../globals/colours';

const BHJN = styled(BH)`
  color: ${GREYS.white};
  margin: 0px 0px 0px 8px;
  ${({ active }) =>
    !active
      ? css`
          color: ${GREYS.silver};
        `
      : css`
          color: ${GREYS.white};
        `};
`;
export { BL, BHJN as BH };
