import { BREAKPOINT_L } from 'UI/globals/breakpoints';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import styled from 'styled-components';

export const ADIV = styled.div`
  text-align: center;
  margin: 0px auto;
  font-size: 14px;
  color: ${FONT.primary};
  position: relative;
  font-weight: bold;
  width: 100%;
`;
export const AS = styled.span`
  position: absolute;
  right: 12px;
  color: ${FONT.dark};
  font-size: 28px;
  top: 8px;
  font-weight: 100;
`;

export const AD = styled.div`
  height: 56px;
  @media (max-width: ${BREAKPOINT_L}) {
    background-color: ${BRAND.primary};
    pointer-events: all;
  }
`;
export const Ah3 = styled.h3`
  top: 18px;
  color: ${FONT.primary};
  font-weight: bold;
  font-size: 14px;
  @media (max-width: ${BREAKPOINT_L}) {
    color: ${FONT.light};
  }
`;
export const Apdate = styled.p`
  color: ${GREYS.silver};
  font-size: 12px;
  font-weight: 400;
`;
export const C3 = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 8px;
`;
export const C31 = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 8px;
`;
export const CL = styled.li`
  margin: 0;
  padding: 0;
`;
export const Ch6 = styled.h6`
  font-size: 12px;
  color: ${FONT.dark};
  margin: 4px 0px;
`;

export const Ch5 = styled.h5`
  font-size: 14px;
  font-weight: bold;
`;
