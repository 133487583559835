import {
  RL,
  CD,
  RCD,
  RC,
  RU,
  ED,
} from 'CORE__UI/apps/RacingEventsApp/core__raceListingPage';
import styled from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { EAR } from 'UI/globals/ExpandArrowStyles';

const RL_EX = styled(RL)`
  box-shadow: none;
`;

const CD_EX = styled(CD)`
  color: ${GREYS.white};
  border-bottom: 1px solid ${FONT.primary};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const RC_EX = styled(RC)`
  color: ${GREYS.white};
`;

const RCD_EX = styled(RCD)`
  border-bottom: 1px solid ${FONT.primary};
`;

const ED_EX = styled(ED)`
  color: ${GREYS.white};
`;
// colouring the expandable arrow
const SEAR = styled(EAR)`
  &:after {
    border-color: ${BRAND.favouriteL} !important;
  }
`;

export {
  RL_EX as RL,
  CD_EX as CD,
  RCD_EX as RCD,
  RC_EX as RC,
  RU,
  ED_EX as ED,
  SEAR,
};
