import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { PriceDropDown } from 'UI/lines/dropdowns/PriceSelect';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { RI } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__price';
import {
  BL,
  BCS,
  BSSEL,
  OI,
  BLS,
  BH,
} from 'UI/apps/BetslipUKApp/BetslipContainer/Price';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import { Translations } from '../../../../../core__betslip-UK-app';
import { isAnySPbet } from '../../../../../core__betslip-utils';

/** 
display prices of odds 
@pF {String} "contain price fractional value",
@priceBookType {String} "contain booking type of price",
@price {Array} "actual array of Price",
@handleDropDownChange {Function} "handle Dropdown in Horses and dogs",
@catRef {String} "check for bet category",
@priceId {String | Number} "Default Priceing",
@oddsFormat {String} "format of odd (fractional & decimal)",
@priceDecimal {Number} "value of decimal Price",
@bbFlage {Bool} "check of booster",
*/
export const Price = ({
  pF,
  priceBookType,
  oddsFormat,
  price,
  handleDropDownChange,
  catRef,
  priceId,
  priceDecimal,
  bbFlag,
  notificationPriceChangeType,
  active,
  betTypeRef,
  fractional,
  betModifier,
  multiple,
  secondaryOddsBoostButton,
}) => {
  betModifier = betModifier[0];
  const iconName = getIcon('PRICE_BOOST');
  const iconBack = getIcon('OPEN');
  if (priceDecimal && !isNaN(priceDecimal) && priceDecimal !== Constants.N_A) {
    priceDecimal = parseDecimalPlaces(priceDecimal);
  }
  // for horse and dog bets when price more then 2
  let mapData = {};
  let selectedPriceValue = '';
  let selectedPriceLabel = '';
  if (price && price.length > 1) {
    mapData = price.map(data => {
      // for select default value in horse and dog bets
      if (priceId === data.id) {
        selectedPriceValue = data.id;

        if (oddsFormat === Constants.DECIMAL)
          selectedPriceLabel =
            (data.decimal && parseDecimalPlaces(data.decimal)) || data.bookType;
        else if (oddsFormat === Constants.AMERICAN)
          selectedPriceLabel =
            (data.decimal && getOdds(data.decimal, true)) || data.bookType;
        else
          selectedPriceLabel =
            (data.fractional && data.fractional) || data.bookType;
      }

      // manipulate array according to react-select
      if (oddsFormat === Constants.DECIMAL) {
        return {
          label:
            (data.decimal && parseDecimalPlaces(data.decimal)) || data.bookType,
          value: data.id,
        };
      } else if (oddsFormat === Constants.AMERICAN) {
        return {
          label: (data.decimal && getOdds(data.decimal, true)) || data.bookType,
          value: data.id,
        };
      } else {
        return {
          label: (data.fractional && data.fractional) || data.bookType,
          value: data.id,
        };
      }
    });
  }
  // dropdown c end

  return (
    <React.Fragment>
      {active ? (
        (betTypeRef === Constants.BBB && (
          /* for Bet Builder */
          <BL bbFlag={bbFlag}>
            <BCS>
              {(oddsFormat === Constants.DECIMAL && (
                <BCS>{parseDecimalPlaces(price)}</BCS>
              )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS>{getOdds(price, true)}</BCS>
                )) || <BCS>{fractional}</BCS>}
            </BCS>
          </BL>
        )) ||
        (multiple && betModifier['betCount'] === 1 && !isAnySPbet() && (
          <BL bbFlag={bbFlag}>
            <BCS>
              {(oddsFormat === Constants.DECIMAL && (
                <BCS>{parseDecimalPlaces(betModifier['returns'])}</BCS>
              )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS>{getOdds(betModifier['returns'], true)}</BCS>
                )) || <BCS>{betModifier['fractionalReturns']}</BCS>}
            </BCS>
          </BL>
        )) ||
        /* for normal bets */
        (!multiple && (
          <BL bbFlag={bbFlag && !secondaryOddsBoostButton}>
            {/* when Price change for Increases|decress odds value */}
            {notificationPriceChangeType === Constants.DEC && (
              <OI src={Constants.STATICMISCPATH + 'odds_decreased.svg'}></OI>
            )}
            {notificationPriceChangeType === Constants.INC && (
              <OI src={Constants.STATICMISCPATH + 'odds_increased.svg'}></OI>
            )}

            {/* when bbFlag is true */}
            {bbFlag ? (
              secondaryOddsBoostButton ? (
                (oddsFormat === Constants.DECIMAL && (
                  <BCS bbFlag={bbFlag}>
                    <span>{betModifier?.returns}</span>
                    <I iconName={iconBack} size={8} marginL={4} marginR={4} />
                    <RI iconName={iconName} size={16} />
                    {priceDecimal
                      ? parseDecimalPlaces(betModifier.boostedReturns)
                      : priceBookType}
                  </BCS>
                )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS bbFlag={bbFlag}>
                    <span>{betModifier?.returns}</span>
                    <I
                      iconName={iconBack}
                      size={8}
                      marginL={4}
                      marginR={4}
                      marginTop={4}
                    />
                    <RI iconName={iconName} size={16} />
                    {priceDecimal
                      ? getOdds(betModifier.boostedReturns, true)
                      : priceBookType}
                  </BCS>
                )) || (
                  <BCS bbFlag={bbFlag}>
                    <span>{betModifier?.fractionalReturns}</span>
                    <I
                      iconName={iconBack}
                      size={8}
                      marginL={4}
                      marginR={4}
                      marginTop={4}
                    />
                    <RI iconName={iconName} size={16} />
                    {pF ? betModifier.boostedFractionalReturns : priceBookType}
                  </BCS>
                )
              ) : (
                (oddsFormat === Constants.DECIMAL && (
                  <BCS bbFlag={bbFlag}>
                    <I iconName={iconName} marginR={4} betBooster={true} />
                    {priceDecimal
                      ? parseDecimalPlaces(betModifier.boostedReturns)
                      : priceBookType}
                  </BCS>
                )) ||
                (oddsFormat === Constants.AMERICAN && (
                  <BCS bbFlag={bbFlag}>
                    <I iconName={iconName} marginR={4} betBooster={true} />
                    {priceDecimal
                      ? getOdds(betModifier.boostedReturns, true)
                      : priceBookType}
                  </BCS>
                )) || (
                  <BCS bbFlag={bbFlag}>
                    <I iconName={iconName} marginR={4} />
                    {pF ? betModifier.boostedFractionalReturns : priceBookType}
                  </BCS>
                )
              )
            ) : [Constants.HORSES, Constants.DOGS].includes(catRef) &&
              (priceBookType === 'PRICE' || priceBookType === Constants.SP) &&
              price &&
              price.length > 1 ? (
              // when category is HORSES | DOGS type and price length > 1
              <BSSEL>
                <PriceDropDown
                  data-test="odds-price-change"
                  defaultLabel={selectedPriceLabel}
                  defaultValue={selectedPriceValue}
                  onChange={e => handleDropDownChange(e?.value)}
                  options={mapData}
                  key={Math.random()}
                  menuPlacement={'bottom'}
                />
              </BSSEL>
            ) : (
              // default case show data for remain all category .
              (oddsFormat === Constants.DECIMAL && (
                <BCS>
                  {price?.length && price[0]?.decimal !== Constants.N_A
                    ? parseDecimalPlaces(price[0].decimal)
                    : price[0]?.bookType}
                </BCS>
              )) ||
              (oddsFormat === Constants.AMERICAN && (
                <BCS>
                  {price?.length && price[0]?.decimal !== Constants.N_A
                    ? getOdds(price[0].decimal, true)
                    : price[0]?.bookType}
                </BCS>
              )) || (
                <BCS>
                  {price?.length && price[0].fractional !== Constants.N_A
                    ? price[0].fractional
                    : price[0]?.bookType}
                </BCS>
              )
            )}
          </BL>
        ))
      ) : (
        <BLS>
          <BH suspended={true}>{Translations.get('text.suspended')}</BH>
        </BLS>
      )}
    </React.Fragment>
  );
};

Price.propTypes = {
  pF: PropTypes.string,
  priceBookType: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  handleDropDownChange: PropTypes.func,
  catRef: PropTypes.string,
  priceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oddsFormat: PropTypes.string,
  priceDecimal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bbFlag: PropTypes.bool,
  notificationPriceChangeType: PropTypes.string,
  active: PropTypes.bool,
  betTypeRef: PropTypes.string,
  fractional: PropTypes.string,
  betModifier: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  multiple: PropTypes.bool,
  suspended: PropTypes.bool,
  secondaryOddsBoostButton: PropTypes.bool,
};
