import styled from 'styled-components';
import { FUNCTIONAL, GREYS } from 'UI/globals/colours';
export const BE = styled.div`
  display: ${({ active }) => (active ? 'none' : 'block')};
  font-size: 14px;
  top: -12px;
  position: relative;
  margin-left: 4px;
  color: ${FUNCTIONAL.negative ? FUNCTIONAL.negative : GREYS.black};
  text-align: left;
`;

export const MAXBETUPDATE = styled.span`
  text-decoration: underline;
`;
