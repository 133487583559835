import { BS } from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__receiptContainer';
import styled, { css } from 'styled-components';
import { GREYS } from '../../../globals/colours';
import { BREAKPOINT_M } from 'CORE__UI/globals/core__breakpoints';

const BS_EX = styled(BS)`
  background-color: ${GREYS.silverD};
  position: relative;
  height: 100%;
  @media (min-width: ${BREAKPOINT_M}) {
    ${({ isSafari }) => {
      if (isSafari) {
        return css`
          width: 100%;
          position: absolute;
        `;
      }
    }}
  }
`;
export { BS_EX as BS };
