import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ReceiptTopSection,
  ReceiptHeader,
  Container,
  BottomSection,
} from './elements';
import { makeArrayOfRecept } from '../../../../core__betslip-utils';
import { SC } from 'UI/apps/BetslipUKApp/ReceiptContainer/Receipt';
import { Translations } from '../../../../core__betslip-UK-app';
/**
  Receipt main page of Bet Receipt 
  @param betSlipRecipt {object} betSlipRecipt
  @param handleReceptCloseButton {function} for closing bet receipt section

*/
export const Receipt = ({
  oddsFormat,
  betSlipRecipt,
  handleReceptCloseButton,
  appConfig,
  bottomNavData,
}) => {
  const { bet } = betSlipRecipt;
  const [ArrRecept, handleArrRecept] = useState([]);
  const [bottomMargin, setBottomMargin] = useState(0);
  const bottomRef = useRef(null);
  const { potentialBetBonusPercentage, potentialBetBonusAmount } =
    bet?.find(b => b.potentialBetBonusAmount > 0) || {};

  useEffect(() => {
    const ArrReceptTemp = makeArrayOfRecept(bet);
    handleArrRecept(ArrReceptTemp);
    setBottomMargin(bottomRef.current.clientHeight || 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {/* Receipt Top section */}
      <SC bottomMargin={bottomMargin}>
        <ReceiptTopSection
          appConfig={appConfig}
          betSlipRecipt={betSlipRecipt}
          handleReceptCloseButton={handleReceptCloseButton}
          betBonusPercent={potentialBetBonusPercentage}
          betBonusAmount={potentialBetBonusAmount}
        />

        {/* For single type bets */}
        {ArrRecept && ArrRecept['single'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={Translations.get('bet.type.single')}
            />

            {ArrRecept['single'].map(bet => (
              <Container
                bet={bet}
                key={'Single_' + bet.id}
                oddsFormat={oddsFormat}
                appConfig={appConfig}
                betSlipRecipt={betSlipRecipt}
              />
            ))}
          </React.Fragment>
        )}

        {/* For multiple and all type bets */}
        {ArrRecept && ArrRecept['multiple'] && (
          <React.Fragment>
            {ArrRecept['multiple'].map(bet => (
              <React.Fragment key={bet.betTypeName + bet.id}>
                <ReceiptHeader
                  bet={bet}
                  oddsFormat={oddsFormat}
                  hedingName={Translations.get(
                    `bet.type.${bet.betUntranslatedTypeName
                      .toLowerCase()
                      .replace(/ /g, '.')}`
                  )}
                  betBonusPercent={bet?.potentialBetBonusPercentage}
                />
                <Container
                  bet={bet}
                  key={bet.betTypeName}
                  oddsFormat={oddsFormat}
                  appConfig={appConfig}
                  betSlipRecipt={betSlipRecipt}
                  betBonusAmount={bet?.potentialBetBonusAmount}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}

        {/* For forcast type bets */}
        {ArrRecept && ArrRecept['forcast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={Translations.get(`bet.type.forecast`)}
            />

            {ArrRecept['forcast'].map(bet => (
              <Container
                bet={bet}
                key={'Forcast' + bet.id}
                oddsFormat={oddsFormat}
                appConfig={appConfig}
                betSlipRecipt={betSlipRecipt}
              />
            ))}
          </React.Fragment>
        )}

        {/* For tricast type bets */}
        {ArrRecept && ArrRecept['tricast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={Translations.get(`bet.type.tricast`)}
            />

            {ArrRecept['tricast'].map(bet => (
              <Container
                bet={bet}
                key={'Tricast' + bet.id}
                oddsFormat={oddsFormat}
                appConfig={appConfig}
                betSlipRecipt={betSlipRecipt}
              />
            ))}
          </React.Fragment>
        )}

        {/* For forcasttricast type bets */}
        {ArrRecept && ArrRecept['forcasttricast'] && (
          <React.Fragment>
            <ReceiptHeader
              bet={bet}
              oddsFormat={oddsFormat}
              hedingName={Translations.get(`bet.type.forecast.tricast`)}
            />

            {ArrRecept['forcasttricast'].map(bet => (
              <Container
                bet={bet}
                key={'Forcasttricast' + bet.id}
                oddsFormat={oddsFormat}
                appConfig={appConfig}
                betSlipRecipt={betSlipRecipt}
              />
            ))}
          </React.Fragment>
        )}
      </SC>
      {/* Recept bottom section */}
      <BottomSection
        bottomRef={bottomRef}
        betSlipRecipt={betSlipRecipt}
        handleReceptCloseButton={handleReceptCloseButton}
        hideSpinnerOnReceipt={appConfig?.hidespinneronreceipt}
        bottomNavData={bottomNavData}
      />
    </React.Fragment>
  );
};

Receipt.propTypes = {
  betSlipRecipt: PropTypes.object,
  appConfig: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  oddsFormat: PropTypes.string,
  bottomNavData: PropTypes.object,
};
