import React from 'react';
import {
  QLM as QLM_LOCAL,
  QLMH4 as QLMH4_LOCAL,
  QLUL as QLUL_LOCAL,
  QLLI as QLLI_LOCAL,
  QLA as QLA_LOCAL,
} from 'UI/apps/BetslipUKApp/BetslipContainer/QuickLink';
import {
  QLM as QLM_CORE,
  QLMH4 as QLMH4_CORE,
  QLUL as QLUL_CORE,
  QLLI as QLLI_CORE,
  QLA as QLA_CORE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__quickLink';
import PropTypes from 'prop-types';
import { Translations } from '../../../../../core__betslip-UK-app';

const QLM = QLM_LOCAL ? QLM_LOCAL : QLM_CORE;
const QLMH4 = QLMH4_LOCAL ? QLMH4_LOCAL : QLMH4_CORE;
const QLUL = QLUL_LOCAL ? QLUL_LOCAL : QLUL_CORE;
const QLLI = QLLI_LOCAL ? QLLI_LOCAL : QLLI_CORE;
const QLA = QLA_LOCAL ? QLA_LOCAL : QLA_CORE;

/**
BetSlip Quick Link
 @param {string} quickLink comman seprated strings of local links
 @param {string} quickName comman seprated strings of name

 */

export const QuickLink = ({ quickLink, quickName }) => {
  let links = [];
  links = getDataQuickLink(quickLink, quickName);
  return (
    links &&
    links.length > 0 && (
      <QLM>
        <QLMH4>{Translations.get('text.quicklinks')}</QLMH4>
        <QLUL>
          {links.map((itm, key) => {
            return (
              <QLLI key={key}>
                <QLA href={itm.link} target={itm.target}>
                  {itm.name}
                </QLA>
              </QLLI>
            );
          })}
        </QLUL>
      </QLM>
    )
  );
};

QuickLink.propTypes = {
  quickLink: PropTypes.string,
  quickName: PropTypes.string,
};

export const getDataQuickLink = (link, name) => {
  const links = link.split(',').map(item => {
    return item.trim();
  });
  const names = name.split(',').map(item => {
    return item.trim();
  });
  const quickLinkData = [];
  for (let i = 0; i < names?.length; i++) {
    if (names[i]) {
      const name = names[i];
      let link = links[i] || '';
      let target = '_self';
      if (link && link.indexOf('---') >= 0) {
        const s = link.split('---');
        target = s[1];
        link = s[0];
      }
      quickLinkData.push({ name, link, target });
    }
  }
  return quickLinkData;
};
