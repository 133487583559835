import {
  CSUDAU,
  CSUDAD,
  CSUDAM,
  CS2,
  CS,
  CID,
  CMD,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__castOrder';
import styled from 'styled-components';
import { FONT, GREYS } from '../../../globals/colours';

const CSJN = styled(CS)`
color: ${FONT.lightMute};
}
`;
const CS2JN = styled(CS2)`
  color: ${GREYS.white};
`;

const CSUDADJN = styled(CSUDAD)`
  border-bottom: 1px solid ${FONT.lightMute};
  border-right: 1px solid ${FONT.lightMute};
`;
const CSUDAUJN = styled(CSUDAU)`
  border-bottom: 1px solid ${FONT.lightMute};
  border-right: 1px solid ${FONT.lightMute};
`;
const CMDJN = styled(CMD)`
  border-bottom: 1px solid ${GREYS.doveD};
`;
export {
  CSUDAUJN as CSUDAU,
  CSUDADJN as CSUDAD,
  CSUDAM,
  CS2JN as CS2,
  CSJN as CS,
  CID,
  CMDJN as CMD,
};
