import styled from 'styled-components';
import { GREYS, BRAND, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Main div to display non runner horses/dogs
export const MI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  opacity: ${props => props?.addDisabled && '0.4'};
  padding: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px;
    position: relative;
    padding-bottom: 24px;
  }
`;
// Name/trainer info display main div
export const TI = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  justify-content: flex-start;
`;
// Horses/dogs last runs info display main div
export const HI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 200px;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    position: absolute;
    bottom: -2px;
    left: 12px;
  }
`;
// Horses/dogs odds info display main div
export const OI = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  flex: 1;
`;
// Horse name display div
export const TIH = styled.div`
  font-weight: 500;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Jockey name display div
export const TIJ = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
`;
// Silk image display div
export const TII = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  width: 30px;
  img {
    width: 36px;
    padding-bottom: 8px;
  }
`;
// Dogs position display div
export const TBB = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  justify-content: center;
  border: 1px solid
    ${props =>
      !props?.positionStyle?.color &&
      !props?.positionStyle?.backgroundColor &&
      props?.apptheme === 'dark'
        ? GREYS.white
        : GREYS.black};
  background-color: ${props => props?.positionStyle?.backgroundColor};
  color: ${props =>
    props?.positionStyle?.color || props?.positionStyle?.backgroundColor
      ? props?.positionStyle?.color
      : props?.apptheme === 'dark'
      ? GREYS.white
      : GREYS.black};
  border-color: ${props => props?.positionStyle?.borderColor};
  margin-right: 12px;
  background-image: ${props =>
    props?.positionStyle?.backgroundImage &&
    props?.positionStyle?.backgroundImage};
`;
// Horse last runs display div
export const HIF = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  min-width: 100px;
  max-width: 100px;
  word-break: break-all;
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: unset;
    max-width: unset;
    margin-right: 8px;
  }
`;
// Horse age display div
export const HIA = styled.div`
  color: ${GREYS.silver};
  min-width: 100px;
  max-width: 100px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    min-width: unset;
    max-width: unset;
    padding-right: 4px;
  }
`;
// Horses/dogs odds info display div
export const OIO = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  margin-right: 8px;
  text-align: right;
`;
// Horse/dog info display empty div
export const ED = styled.div`
  color: ${GREYS.black};
`;
// Outcomes display top level div
export const TLD = styled.div`
  display: flex;
  align-items: center;
  flex: 3 1 0%;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
// Horse age and weight display paragraph
export const HIAP = styled.p`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.black)};
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

// History values display
export const HV = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.35;
`;

// Odds display main div
export const OMD = styled.div`
  display: flex;
  flex: 1.5;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column-reverse;
    flex: 2.6;
  }
`;
