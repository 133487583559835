import React from 'react';
import PropTypes from 'prop-types';
import OddsButton from 'UI/buttons/OddsButton';
import { connect } from 'react-redux';
import { callBetslip } from '../../../core__racing-events-store';
import {
  MI,
  TI,
  TIH,
  H,
  HR,
  HD,
  FR,
  MD,
  NR,
  NRH,
  NRR,
  OI,
} from 'UI/apps/RacingEventsApp/RaceOutcomesFavourite';
import { getOdds } from 'Services/global/core__american-format';
import { Translations } from '../../../core__racing-events-app';
import {
  TII,
  OMD,
  HV,
  OB,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesFavourite';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

export class RaceOutcomesFavourite extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { getBetslipSelectionList, eventDetails, oddsFormat } = this.props;
    return (
      <React.Fragment>
        {eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED ? (
          eventDetails?.market.length > 0 &&
          eventDetails?.market.map(marketName => {
            return (
              marketName?.typeRef === GLOBAL_CONSTANTS.UFAV &&
              eventDetails.selectedRaceMarket !==
                GLOBAL_CONSTANTS.UNNAMED_FAVOURITE &&
              marketName?.selection.map((outcomes, oIndex) => {
                return (
                  <React.Fragment key={oIndex}>
                    <MI>
                      <TI>
                        <TIH>{outcomes.name}</TIH>
                      </TI>
                      <TII></TII>
                      <OMD>
                        <HV></HV>
                        <OI>
                          <OB>
                            <OddsButton
                              data-test={
                                oIndex === 0 && 'btn-favmarketoddsclick'
                              }
                              size={'default'}
                              odds={
                                oddsFormat?.toLowerCase() ===
                                GLOBAL_CONSTANTS.DECIMAL
                                  ? outcomes.decimalOdds
                                  : oddsFormat?.toLowerCase() ===
                                    GLOBAL_CONSTANTS.FRACTIONAL
                                  ? outcomes.fractionalOdds
                                  : outcomes.decimalOdds !== GLOBAL_CONSTANTS.SP
                                  ? getOdds(outcomes.decimalOdds, true)
                                  : outcomes.decimalOdds
                              }
                              inactive={
                                eventDetails.state ===
                                  GLOBAL_CONSTANTS.COMPLETED ||
                                !outcomes?.active
                              }
                              selected={
                                getBetslipSelectionList.indexOf(outcomes.id) >
                                -1
                              }
                              onClick={() => {
                                eventDetails.state !==
                                  GLOBAL_CONSTANTS.COMPLETED &&
                                  this.props.callBetslip(outcomes.id);
                              }}
                              oddsTrend={outcomes?.oddsStatus}
                              disabled={
                                eventDetails.state === 'COMPLETED' ||
                                !outcomes?.active
                              }
                            />
                          </OB>
                        </OI>
                      </OMD>
                    </MI>
                  </React.Fragment>
                );
              })
            );
          })
        ) : (
          <React.Fragment>
            {eventDetails.forecastWinOrder && (
              <MD>
                <H>
                  <HR>{Translations.get('racing.result')}</HR>
                  <HD>{Translations.get('racing.dividend')}</HD>
                </H>
                <H>
                  <FR>{Translations.get('racing.forecast')}</FR>
                  <HR>{eventDetails.forecastWinOrder}</HR>
                  <HD>{eventDetails.forecastWinValue}</HD>
                </H>
                {eventDetails.tricastWinOrder && (
                  <H>
                    <FR>{Translations.get('racing.tricast')}</FR>
                    <HR>{eventDetails.tricastWinOrder}</HR>
                    <HD>{eventDetails.tricastWinValue}</HD>
                  </H>
                )}
                <NR>
                  {eventDetails?.market.map((marketName, mInd) => {
                    return (
                      <React.Fragment key={mInd}>
                        <NRH>
                          {marketName?.raceNRs?.length > 0 &&
                            mInd === 0 &&
                            Translations.get('racing.non.runner')}
                        </NRH>
                        <NRR>{marketName?.raceNRs?.join(',')}</NRR>
                      </React.Fragment>
                    );
                  })}
                </NR>
              </MD>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

RaceOutcomesFavourite.propTypes = {
  getBetslipSelectionList: PropTypes.array,
  callBetslip: PropTypes.func,
  eventDetails: PropTypes.object,
  requestPage: PropTypes.string,
  oddsFormat: PropTypes.string,
};

export const mapStateToProps = state => {
  return {
    getBetslipSelectionList: state.horseRacesList.getBetslipSelectionList,
    requestPage: state.horseRacingData.requestPage,
    oddsFormat: state.horseRacingData.oddsFormat,
  };
};

export const mapDispatchToProps = {
  callBetslip: callBetslip,
};

RaceOutcomesFavourite.defaultProps = {
  getBetslipSelectionList: [],
  requestPage: '',
  oddsFormat: '',
};

export const RaceFavourite = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceOutcomesFavourite);
