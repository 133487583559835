import React from 'react';
import PropTypes from 'prop-types';
import { RB, SRB, RC, TV, HRB } from 'UI/buttons/RacingButtonStyles';
import { RCP as RCP_CORE } from 'CORE__UI/apps/RacingEventsApp/core__raceCarousel';
import { RCP as RCP_LOCAL } from 'UI/apps/RacingEventsApp/RaceCarousel';
import { withTheme } from 'styled-components';
import { getComponent } from 'Services/core__imports';

const RCP = getComponent(RCP_LOCAL, RCP_CORE);

const RacingButton = ({
  time,
  tv,
  flagged,
  onClick,
  selected,
  highlight,
  extraPlace,
  ...props
}) => {
  const btnData = () => {
    return (
      <React.Fragment>
        {time}
        {tv && <TV />}
        {flagged && <RC />}
        {extraPlace && <RCP />}
      </React.Fragment>
    );
  };

  let ButtonComponent = RB;
  if (selected) ButtonComponent = SRB;
  if (highlight) ButtonComponent = HRB;

  return (
    <ButtonComponent onClick={onClick} name={time} {...props}>
      {btnData()}
    </ButtonComponent>
  );
};

RacingButton.propTypes = {
  time: PropTypes.string.isRequired,
  ep: PropTypes.bool,
  tv: PropTypes.bool,
  flagged: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  highlight: PropTypes.bool,
  name: PropTypes.string,
  extraPlace: PropTypes.bool,
};

RacingButton.defaultProps = {
  time: '11:11',
  selected: false,
};

export default withTheme(RacingButton);
