import React from 'react';
import PropTypes from 'prop-types';
import { genraterName } from '../../../../../core__betslip-utils';
import { BL, BH } from 'UI/apps/BetslipUKApp/BetslipContainer/Name';
import { Constants } from '../../../../../core__betslip-constants';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 * @param {object} bet
 * @param {object} betMf
 */

export const OddName = ({ bet, betMf }) => {
  return (
    <BL>
      <BH active={bet.active} isSGL={bet.betTypeRef === Constants.SGL}>
        {bet?.ordinal && '(' + bet.ordinal + ') '}
        {(bet.betTypeRef !== Constants.BBB &&
          (bet.betTypeRef === Constants.SGL
            ? genraterName(bet.name, betMf)
            : Translations.get(
                `bet.type.${genraterName(bet.name, betMf)
                  .toLowerCase()
                  .replace(/ /g, '.')}`
              ))) ||
          Translations.get('text.create.a.bet')}
      </BH>
    </BL>
  );
};
OddName.propTypes = {
  bet: PropTypes.object,
  betMf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
