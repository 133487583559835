import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from 'CORE__UI/apps/RacingEventsApp/core__futureRaces';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

const FRT_EX = styled(FRT)`
  color: ${GREYS.white};
  border-bottom: 1px solid ${FONT.primary};
`;

const FRD_EX = styled(FRD)`
  color: ${FONT.secondary};
  font-size: 12px;
  font-weight: 700;
`;

const FRS_EX = styled(FRS)`
  color: ${FONT.lightMute};
`;

const FRA_EX = styled(FRA)`
  &:after {
    color: ${FONT.lightMute};
  }
`;

const FRMD_EX = styled(FRMD)`
  border-bottom: 1px solid ${FONT.primary};
  background-color: transparent;
`;

const FRSM_EX = styled(FRSM)`
  border-bottom: 1px solid ${FONT.primary};
`;

export {
  RL,
  FRT_EX as FRT,
  FRMD_EX as FRMD,
  FRD_EX as FRD,
  FRSM_EX as FRSM,
  FRS_EX as FRS,
  FRA_EX as FRA,
};
