import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Translations } from '../../../../../core__betslip-UK-app';
import {
  EXPD,
  EXPD_C,
  DDI,
  EA_C,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__expand';

/**
 * @param {Array} betTemplate
 * @param {func} showMoreLess,
 * @param {boolean} isShowless
 */
const ShowExpandDropDown = ({
  showMultipleExpandObj,
  showMoreLess,
  isShowless,
}) => {
  return (
    <>
      {showMultipleExpandObj?.lessMore && (
        <EXPD_C
          onClick={() => {
            showMoreLess(!isShowless);
          }}
        >
          <EXPD>
            {Translations.get(
              (isShowless && 'text.show.more.multiplies') ||
                'text.show.less.multiplies'
            )}
            &nbsp;
          </EXPD>
          <DDI>
            <EA_C active={true} expanded={!isShowless} />
          </DDI>
        </EXPD_C>
      )}
    </>
  );
};
ShowExpandDropDown.propTypes = {
  betTemplate: PropTypes.object,
  showMoreLess: PropTypes.func,
  isShowless: PropTypes.bool,
  lessMore: PropTypes.bool,
  showMultipleExpandObj: PropTypes.object,
};

export const ShowExpand = memo(ShowExpandDropDown);
