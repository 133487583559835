import React from 'react';
import PropTypes from 'prop-types';
import { SYMBOLS } from 'Services/core__services';
import { getComponent } from 'Services/core__imports';
import { RaceInfo as RaceInfo_LOCAL } from 'UI/apps/RacingEventsApp/RaceOutcomesPage';
import { RaceInfo as RaceInfo_CORE } from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesPage';
import { Translations } from '../../../core__racing-events-app';
import {
  formatPlaceTermsTranslationKey,
  RACING_CONSTANTS,
} from '../../../core__racing-events-utils';
import project from '../../../../../../project';

const RaceInfo = getComponent(RaceInfo_LOCAL, RaceInfo_CORE);

const RaceOutcomesInfo = ({ eventDetails, appConfig }) => {
  return (
    <RaceInfo>
      <p>
        {eventDetails?.handicap && (
          <span>
            {Translations.get('racing.handicap')}
            {', '}
          </span>
        )}
        {eventDetails?.prizePosition1 && (
          <span>
            <span>{SYMBOLS[project.currency]}</span>
            {eventDetails?.prizePosition1} (1st)
          </span>
        )}{' '}
      </p>
      {(!appConfig?.hideew &&
        eventDetails?.placeTerms &&
        eventDetails.placeTerms !== RACING_CONSTANTS.WIN_ONLY && (
          <span>
            <p>
              <b>{Translations.get('racing.ew')}</b>{' '}
              {appConfig?.jptranslation
                ? formatPlaceTermsTranslationKey(
                    eventDetails?.placeTerms,
                    Translations
                  )
                : eventDetails?.placeTerms}
            </p>
          </span>
        )) || (
        <span>
          <b>
            {appConfig?.jptranslation
              ? formatPlaceTermsTranslationKey(
                  eventDetails?.placeTerms,
                  Translations
                )
              : eventDetails?.placeTerms}{' '}
          </b>
        </span>
      )}

      {eventDetails?.rule4 && (
        <span>
          <p>
            {appConfig?.istranslated
              ? Translations.get('racing.rule4.may.apply')
              : eventDetails?.rule4}
          </p>
        </span>
      )}
      {eventDetails?.antePost && (
        <span>
          <p>
            {appConfig?.istranslated
              ? Translations.get('racing.ante.post.rules.apply')
              : eventDetails?.antePost}
          </p>
        </span>
      )}
    </RaceInfo>
  );
};

RaceOutcomesInfo.propTypes = {
  eventDetails: PropTypes.object,
  appConfig: PropTypes.object,
};

export default RaceOutcomesInfo;
