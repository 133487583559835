import styled from 'styled-components';
import { BRAND, GREYS, FONT } from 'UI/globals/colours';

export const CMD = styled.div`
  box-sizing: inherit;
  padding: 8px;
  border-bottom: 1px solid ${BRAND.tertiary};
`;
export const CID = styled.div`
  padding: 0 8px;
  margin: 8px;
  display: flex;
  align-items: center;
  position: relative;
  :first-child {
    margin-top: 0;
  }
`;
export const CS = styled.span`
  min-width: 12px;
  color: ${GREYS.silver};
`;
export const CS2 = styled.span`
  width: 100%;
  margin-left: 12px;
`;
export const CSUDAM = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0px;
`;
export const CSUDAD = styled.span`
  width: 12px;
  height: 12px;
  border-bottom: 1px solid ${FONT.dark};
  border-right: 1px solid ${FONT.dark};
  transform: rotate(45deg);
  margin-top: -8px;
  margin-right: 12px;
  position: absolute;
  right: 0px;
  :first-child {
    margin-right: 24px;
  }
`;
export const CSUDAU = styled.span`
  margin-top: 4px;
  width: 12px;
  height: 12px;
  border-bottom: 1px solid ${FONT.dark};
  border-right: 1px solid ${FONT.dark};
  transform: rotate(225deg);
`;
