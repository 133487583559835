import { GREYS, FONT, BRAND, SHADOW, FUNCTIONAL } from 'UI/globals/colours';
import styled, { css } from 'styled-components';
import theme from 'UI/globals/theme';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';

export const BU = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 8px;
  ${({ multiple }) =>
    multiple &&
    css`
      padding-top: 8px;
    `}
`;

export const BD = styled.div`
  display: flex;
  user-select: none;
  align-items: center;
  margin: 8px;
  h5 {
    font-family: ${theme.fontFamily};
    font-size: 12px;
    letter-spacing: 0.25px;
    color: ${FONT.dark};
    user-select: none;
  }
`;

export const BCLC = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 8px;
`;

export const BCL = styled.span`
  background-color: ${BRAND.primaryD};
  color: ${GREYS.white};
  padding: 2px;
  font-size: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BCSM = styled.div`
  display: flex;
  margin-bottom: 1em;
  ${({ accaBonus }) =>
    accaBonus &&
    css`
      position: relative;
      background: rgb(246 159 158 / 9%);
      right: 0;
      top: 0;
      padding: 0 4px;
      border: 1px solid ${FUNCTIONAL.negativeL};
      font-size: 10px;
      color: ${FUNCTIONAL.negativeL};
      border-radius: 4px;
      margin-right: 0;
      left: 2px;
    `}
`;

export const BSMD = styled.div`
  margin: 8px;
  ${({ accaBonus }) =>
    accaBonus &&
    css`
      position: relative;
    `}
  ${({ showBonusText }) =>
    showBonusText &&
    css`
      background: ${FUNCTIONAL.negativeL};
      margin: 0;
      margin-bottom: 12px;
      text-align: center;
      font-size: 12px;
      padding: 4px;
      color: ${FONT.secondary};
      position: relative;
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${FUNCTIONAL.negativeL};
      position: absolute;
      left: 45%;
      top: 15px;
    `}
`;

export const BMD = styled.div`
  margin: 8px 12px 8px;
  border-bottom: 1px solid ${BRAND.tertiary};
  box-shadow: ${SHADOW};
  border-radius: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  ${({ hideBottomBorder }) =>
    hideBottomBorder &&
    css`
      border-bottom: none;
    `}
  @media (max-width: ${BREAKPOINT_M}) {
    // coz in mobile when url tab display bottom section hide
    :last-child {
      margin-bottom: 188px;
    }
  }
  ${({ disappear }) =>
    disappear &&
    css`
      display: none;
    `}
  ${({ betBonus }) =>
    betBonus &&
    css`
      padding-top: 0;
    `}
`;

export const BetslipContentBetBoxPot = styled.span`
  color: ${GREYS.silver};
  font-size: 12px;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      color: ${FONT.dark};
    `};
`;

export const FBPR = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  ${({ freebet }) => {
    return (
      freebet &&
      css`
        flex-direction: column-reverse;
      `
    );
  }}
`;

export const BBh5 = styled.span`
  color: ${FONT.dark};
  font-size: 12px;
`;

export const ENS = styled.span`
  color: ${GREYS.white};
`;

export const MATCH_RESULT = styled.span`
  font-weight: bold;
  margin-right: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const ERR = styled.div`
  padding-left: 16px;
  ${({ hide, isMobile }) => {
    return (
      hide &&
      isMobile &&
      css`
        display: none;
      `
    );
  }}
  ${({ betslip }) => {
    return (
      betslip &&
      css`
        padding-left: 0;
      `
    );
  }}
  ${({ betOpen, hide, isMobile }) => {
    return (
      betOpen &&
      hide &&
      isMobile &&
      css`
        display: block;
      `
    );
  }}
`;
export const BCCM = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const PERIOD_TEXT = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  margin-right: 4px;
`;
