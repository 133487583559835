import {
  BCFR,
  BCFWC,
  BCWCH,
  BCFSS,
  CONF,
  CAN,
  BCFBTH,
  MStyles,
  IRDO,
  FBI,
  BC,
  X,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__freebet';
import styled from 'styled-components';
import { BRAND, FONT, GREYS } from '../../../globals/colours';

const MStyleJN = MStyles;
MStyleJN['modal']['backgroundColor'] = GREYS.silverD;

const BCFRJN = styled(BCFR)`
  background-color: ${BRAND.secondary};
`;

const XJN = styled(X)`
  color: ${GREYS.white};
`;

const BCWCHGW = styled(BCWCH)`
  color: ${FONT.light};
`;

const CANGW = styled(CAN)`
  color: ${FONT.light};
  background-color: ${GREYS.silverD};
`;

export {
  BCFRJN as BCFR,
  BCFWC,
  BCWCHGW as BCWCH,
  BCFSS,
  CONF,
  CANGW as CAN,
  BCFBTH,
  MStyleJN as MStyles,
  IRDO,
  FBI,
  BC,
  XJN as X,
};
