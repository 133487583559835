export const stringsToTranslate = [
  'text.nodata',
  'racing.next.races',
  'racing.view.full.racecard',
  'racing.filter.today',
  'racing.filter.tomorrow',
  'racing.filter.sunday',
  'racing.filter.monday',
  'racing.filter.tuesday',
  'racing.filter.wednesday',
  'racing.filter.thursday',
  'racing.filter.friday',
  'racing.filter.saturday',
  'racing.filter.future races',
  'racing.filter.virtuals',
  'racing.each.way.pays.positions',
  'racing.non.runner',
  'racing.result',
  'racing.dividend',
  'racing.forecast',
  'racing.tricast',
  'racing.sort.by',
  'racing.started',
  'racing.abandoned',
  'racing.results',
  'racing.runners',
  'racing.distance',
  'racing.grade',
  'racing.going',
  'racing.ew',
  'racing.standard.ew',
  'racing.enhanced.ew',
  'racing.live.tv.available',
  'racing.streaming.messagelogin',
  'racing.streaming.videoqualifymessage1',
  'racing.streaming.videoqualifymessage2',
  'racing.streaming.videoqualifymessage3',
  'racing.streaming.videoqualifymessage4',
  'racing.streaming.videoqualifymessage5',
  'racing.streaming.available.message',
  'racing.streaming.videoqualified1',
  'racing.streaming.videoqualified2',
  'racing.filter.racing legends',
  'racing.streaming.messagelogin2',
  'racing.streaming.videoqualifymessage.qn.1',
  'racing.hide',
  'racing.watch',
  'racing.information',
  'racing.lessInformation',
  'racing.moreInformation',
  'racing.owner',
  'racing.sire',
  'racing.dam',
  'text.show.more',
  'text.show.less',
  'racing.form',
  'racing.age',
  'racing.rule4.may.apply',
  'racing.ante.post.rules.apply',
  'racing.sort.odds',
  'racing.sort.racecard',
  'racing.streaming.videoqualifymessage',
  'racing.mins',
  'racing.hours',
  'racing.starting_in',
  'racing.national_hunt_flat',
  'racing.breeder',
  'racing.jockey',
  'racing.trainer',
  'racing.going',
  'racing.trip',
  'racing.atr_info',
  'racing.rmg_info',
  'text.win_by_name',
  'text.win_by_trap',
  'text.race_winner',
  'text.outrights',
  'text.trap',
  'text.all',
  'text.at',
  'text.odds',
  'text.win_only',
  'text.show.info',
  'text.hide.info',
  'racing.handicap',
  'racing.hour',
  'racing.login.towatch',
  'racing.racecard',
  'race.extra.place',
  'race.extra.place.odds',
];
