import {
  RC,
  RCB,
  RCT,
  RCN,
  NR,
  NRT,
  NRD,
  NRP,
  NRI,
  NRH,
  NRB,
  NRBM,
  NRS,
  NRDS,
  NRN,
  NRNE,
  NRJN,
  NRO,
  NRF,
  SC,
  ROB,
  RCV,
  RCP,
  NPTEP,
} from 'CORE__UI/apps/RacingEventsApp/core__raceCarousel';
import styled from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const RCT_EX = styled(RCT)`
  color: unset;
`;

const RCN_EX = styled(RCN)`
  color: unset;
`;

const RC_EX = styled(RC)`
  background-color: ${({ addActive }) =>
    addActive ? BRAND.secondary : BRAND.primary};
  color: ${({ addActive }) => (addActive ? BRAND.primary : GREYS.white)};
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
    padding: 5px 8px;
    width: auto;
    max-width: 127px;
  }
`;

const NRI_EX = styled(NRI)`
  background-color: ${GREYS.silverD};
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    height: auto;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const NRH_EX = styled(NRH)`
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${FONT.primary}` : `1px solid ${FONT.dark}`};
  color: ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const NRNE_EX = styled(NRNE)`
  color: ${GREYS.white};
  font-weight: 700;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const NRJN_EX = styled(NRJN)`
  color: ${({ theme }) => (theme.dark ? GREYS.dove : GREYS.white)};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const NRF_EX = styled(NRF)`
  border-top: ${({ theme }) =>
    theme.dark ? `1px solid ${FONT.primary}` : `1px solid ${FONT.dark}`};
  color: ${GREYS.white};
`;

const NR_EX = styled(NR)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 8px;
  }
`;

const NRB_EX = styled(NRB)`
  @media (max-width: ${BREAKPOINT_S}) {
    height: auto;
  }
`;

const ROB_EX = styled(ROB)`
  @media (max-width: ${BREAKPOINT_S}) {
    height: 46px !important;
    margin-bottom: 0;
  }
`;
const NRO_EX = styled(NRO)`
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 6px 8px;
  }
`;

const RCP_EX = styled(RCP)`
  background-color: ${BRAND.secondary};
`;

const NPTEP_EX = styled(NPTEP)`
  background-color: ${BRAND.secondary};
`;

export {
  RC_EX as RC,
  RCB,
  RCT_EX as RCT,
  RCN_EX as RCN,
  NR_EX as NR,
  NRT,
  NRD,
  NRP,
  NRI_EX as NRI,
  NRH_EX as NRH,
  NRB_EX as NRB,
  NRBM,
  NRS,
  NRDS,
  NRN,
  NRNE_EX as NRNE,
  NRJN_EX as NRJN,
  NRO_EX as NRO,
  NRF_EX as NRF,
  SC,
  ROB_EX as ROB,
  RCV,
  RCP_EX as RCP,
  NPTEP_EX as NPTEP,
};
