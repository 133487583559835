import React from 'react';
import { BottomSection } from './core__bottomsection';
import { Container, priceRecipt } from './core__container';
import { ReceiptHeader } from './core__header';
import { ReceiptTopSection } from './core__topsection';
export const ContainerGW = props => <Container GW={true} {...props} />;
export {
  BottomSection,
  ContainerGW as Container,
  ReceiptHeader,
  ReceiptTopSection,
  priceRecipt,
};
