import styled from 'styled-components';
import { CARD, BRAND, FONT, GREYS, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_S, BREAKPOINT_L } from 'UI/globals/breakpoints';

// Main div to display layout
export const MD = styled.div(CARD);

// Displays race tip/title
export const RP = styled.p`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Race course info display main div
export const RIDM = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;
// Race course name display div
export const RID = styled.div`
  font-weight: bold;
  color: ${BRAND.primary};
`;
// Race started text display div
export const RIR = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.dark ? FUNCTIONAL.warningD : FUNCTIONAL.warningL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  border: 1px solid ${FUNCTIONAL.warning};
  font-weight: 500;
`;
// UL to display horse/dog info
export const IU = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 8px 0;
`;
// IL to display horse/dog info
export const IL = styled.li`
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  b {
    margin-right: 8px;
  }
`;
// Main div to display outcomes
export const MI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  &:nth-child(2) {
    border-top: ${props => props?.isResults && '1px solid' + BRAND.tertiary};
  }
  padding: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px 0px;
    margin-inline: 16px;
    position: relative;
  }
  &:last-of-type {
    border: ${({ theme }) => theme.dark && `none`};
  }

  border-bottom: none;
  @media (max-width: ${BREAKPOINT_S}) {
    align-items: center;
  }
`;
// Trainer info display div
export const TI = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  justify-content: flex-start;
  align-items: center;
`;
// Horse info display div
export const HI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 200px;
  flex-direction: column;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    left: 12px;
    font-size: 12px;
  }
`;
// Odds info display div
export const OI = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
  }
`;
// Horse name display div
export const TIC = styled.div`
  display: block;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
  }
  flex-direction: 'column';
`;
export const TIH = styled.div`
  font-weight: 800;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Jockey name display div
export const TIJ = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  font-size: 12px;
  letter-spacing: 0.25px;
`;
export const TIT = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  font-size: 12px;
  letter-spacing: 0.25px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-left: 4px;
  }
`;

// Horse silk image display div
export const TII = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  width: 30px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  img {
    width: 36px;
    padding-bottom: 8px;
  }
`;
// Dogs position display div
export const TBB = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  justify-content: center;
  border: 1px solid
    ${props =>
      !props?.positionStyle?.color &&
      !props?.positionStyle?.backgroundColor &&
      props?.apptheme === 'dark'
        ? GREYS.white
        : GREYS.black};
  background-color: ${props => props?.positionStyle?.backgroundColor};
  color: ${props =>
    props?.positionStyle?.color || props?.positionStyle?.backgroundColor
      ? props?.positionStyle?.color
      : props?.apptheme === 'dark'
      ? GREYS.white
      : GREYS.black};
  border-color: ${props => props?.positionStyle?.borderColor};
  margin-right: 8px;
  background-image: ${props =>
    props?.positionStyle?.backgroundImage &&
    props?.positionStyle?.backgroundImage};
`;
// Horse last runs display div
export const HIF = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  min-width: 100px;
  max-width: 100px;
  word-break: break-all;
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: unset;
    max-width: unset;
    margin-right: 8px;
  }
`;
// Horse age display div
export const HIA = styled.div`
  color: ${GREYS.silver};
  min-width: 100px;
  display: flex;
  max-width: 100px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    min-width: unset;
    max-width: unset;
    padding-right: 4px;
  }
`;
// Horse/dogs odds display div
export const OIO = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  margin-right: 8px;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.25px;
`;
// Main div to display horse/jockey/trainer info
export const ED = styled.div`
  color: ${FONT.dark};
  position: relative;
  flex: 1.8 1 0%;
`;
// To get border layout
export const EDB = styled(ED)`
  border-block: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  padding-right: 16px;

  border-top: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};

  @media (max-width: ${BREAKPOINT_S}) {
    padding-right: 0;
  }
`;
// Results page div
export const RS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.dark ? FUNCTIONAL.positiveD : FUNCTIONAL.positiveL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  border: 1px solid ${FUNCTIONAL.positive};
  font-weight: 500;
`;
// Race abandoned div
export const RA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${FUNCTIONAL.negativeL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${GREYS.black};
  border: 1px solid ${FUNCTIONAL.negative};
  font-weight: 500;
`;
// Fractional odds display div
export const OIF = styled.span``;
// Horses/dogs outcomes main div
export const HRI = styled.div`
  padding: 16px;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${GREYS.doveD}`};
  /* new style - race card 
     remove local styles related to this
     (if needed)
    */
  background-color: ${GREYS.white};
  &:not(:first-child) {
    padding: 0;
  }
  &:last-of-type {
    border-bottom: none;
    padding-left: 0px;
    cursor: pointer;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    &:last-of-type {
      padding-left: 0px;
    }
  }
`;
// Horses/dogs race streaming main div
export const RST = styled.div`
  display: flex;
  align-items: center;
`;
// Outcomes display top level div
export const TLD = styled.div`
  display: flex;
  align-items: center;
  flex: 4 1 0%;
  flex-direction: row;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
// Horse age and weight display paragraph
export const HIAP = styled.p`
  color: ${({ theme }) => theme.dark && FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
  margin-left: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 2px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
// Odds button display div
export const OB = styled.div`
  margin-right: 8px;
  flex: 1;
  &:last-of-type {
    margin-right: 0;
  }
  button {
    width: 100%;
  }
  max-width: 88px;
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: 46px;
    max-width: 30px;
  }
`;
// Horses/dogs market name
export const HM = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
`;
// Horses/dogs market name
export const H = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: 3;
  }
`;
// Horses/dogs market name
export const HR = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex: 1;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
  }
`;
// EW market text display tag
// Color not available in globals
export const HRB = styled.div`
  display: inline-block;
  color: ${({ theme }) => (theme.dark ? FONT.primary : GREYS.black)};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  }
`;
// Horses/dogs market name
export const HD = styled.div`
  text-align: center;
  flex: 1;
  cursor: pointer;
`;

// Osiros main div to display selection
export const SD = styled.div`
  color: ${FONT.dark};
  margin-top: 16px;
  position: relative;
  flex: 0.25;
`;

// History values display
export const HV = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.35;
  align-items: center;
`;

// Main div to display watch button
export const WB = styled.div`
  margin-right: 8px;
  padding-top: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.dark && FONT.secondary};
`;

// Outcomes sorting filter div
export const OT = styled.p`
  cursor: pointer;
  flex: 3.25;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  @media (max-width: ${BREAKPOINT_L}) {
    display: flex;
    flex: 3.75;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  }
`;

// Odds display main div
export const OMD = styled.div`
  display: flex;
  flex: 1.5;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column-reverse;
    flex: 1;
    flex-basis: auto;
  }
`;

export const SO = styled.div`
  border-radius: 4px;
  background-color: ${BRAND.primary};
`;

export const ST = styled.p`
  width: 50px;
  color: ${FONT.light};
  line-height: 20px;
  text-align: center;
`;

//for streaming icon
export const STC = styled.p`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1em;
  justify-content: space-between;
  background-color: ${BRAND.secondary};
  border-radius: 4px;
  & span {
    padding-left: 0.5em;
    color: ${GREYS.black};
  }
  & i {
    color: ${GREYS.black};
  }
`;
export const TW = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AW = styled.div`
  display: flex;
  margin-left: 8px;
`;
export const HIAW = styled.p`
  color: ${({ theme }) => theme.dark && FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
  margin-left: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 2px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
export const HIAF = styled.p`
  color: ${({ theme }) => theme.dark && FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 2px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
