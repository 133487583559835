import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from 'UI/globals/theme';
import { StyledSelect, styles1 } from 'UI/lines/dropdowns/PriceSelectStyles';

/**
 *  @param {array} options dropdown options
 *  @param {string} defaultValue default value
 *  @param {string}  defaultLabel default label
 *  @param {func} onChange handle CHange
 *  @param {boolean} menuIsOpen boolean to open and close menu
 *  @param {string} menuPlacement alingment of menu
 */

export const PriceDropDown = ({ ...props }) => {
  const {
    options,
    defaultValue,
    defaultLabel,
    onChange,
    menuIsOpen,
    menuPlacement,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <StyledSelect
        isSearchable={false}
        styles={styles1}
        options={options}
        defaultValue={{ value: defaultValue, label: defaultLabel }}
        onChange={onChange && onChange}
        menuPlacement={(menuPlacement && menuPlacement) || `top`}
        menuIsOpen={menuIsOpen}
      ></StyledSelect>
    </ThemeProvider>
  );
};
PriceDropDown.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  menuPlacement: PropTypes.string,
};
