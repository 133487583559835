import React, { useEffect, useState } from 'react';
import { Receipt } from './BetslipReceipt';
import { BS } from 'UI/apps/BetslipUKApp/ReceiptContainer/ReceiptContainer';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

/**
 * container of Receipt
 * @param {object} betSlipRecipt recipt object come to api
 * @param {func} handleReceptCloseButton close butto n
 * @param {string} oddsFormat odd format
 */
export const ReceiptContainer = ({
  oddsFormat,
  betSlipRecipt,
  handleReceptCloseButton,
  appConfig,
  oddsMovementFirstOpened,
  bottomNavData,
}) => {
  const [isSafari, setisSafari] = useState('false');

  useEffect(() => {
    if (appConfig?.hidebetslip) {
      PubSub.emit(PubsubEvents.EventsAppBetslipUpdate, []);
    }
    // handle safari issue
    try {
      setisSafari(
        /Safari/.test(window?.navigator.userAgent) &&
          /Apple Computer/.test(window?.navigator.vendor)
      );
    } catch (error) {
      //no handling required
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BS isSafari={isSafari} oddsMovementFirstOpened={oddsMovementFirstOpened}>
      <Receipt
        appConfig={appConfig}
        oddsFormat={oddsFormat}
        betSlipRecipt={betSlipRecipt}
        handleReceptCloseButton={handleReceptCloseButton}
        bottomNavData={bottomNavData}
      />
    </BS>
  );
};
ReceiptContainer.propTypes = {
  appConfig: PropTypes.object,
  oddsFormat: PropTypes.string,
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  oddsMovementFirstOpened: PropTypes.bool,
  bottomNavData: PropTypes.object,
};
