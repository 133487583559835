import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_PATH } from 'Services/global/core__constants';

export const Silk = ({ src, ...props }) => {
  const [url, setUrl] = useState(src);
  return (
    <img
      {...props}
      src={url}
      onError={() => setUrl(GLOBAL_PATH.DEFAULT_SILK)}
    />
  );
};
Silk.propTypes = {
  src: PropTypes.string,
};
