import styled from 'styled-components';
import { FONT, BRAND, GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Main div to display race markets
export const MH = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    padding: 0;
  }
`;
// Market name display div
export const ED = styled.div`
  color: ${FONT.dark};
  @media (max-width: ${BREAKPOINT_S}) {
    border-bottom: ${({ theme }) =>
      theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
    width: 100%;
    display: flex;
    overflow-x: auto;
  }
`;
// Outcomes sorting filter div
export const OT = styled.p`
  cursor: pointer;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
  }
`;
