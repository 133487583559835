import React from 'react';
import PropTypes from 'prop-types';
import RacingButton from 'UI/buttons/RacingButton';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { getIcon } from 'Services/icons/core__icons';
import { RaceDayFilters } from '../RaceListingDayFilters';
import moment from 'moment';
import { RacesCarousel } from '../RaceCarousel';
import { FutureRacing } from '../FutureRaces';
import {
  RL,
  CD,
  RCD,
  RC,
  RU,
  ED,
  SEAR as SEAR_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceListingPage';
import { EAr as SEAR_CORE } from 'CORE__UI/globals/ExpandArrow/core__expandArrowStyles';

import Constants from '../../../../../../project';
import { Translations } from '../../../core__racing-events-app';
import Spinner from 'UI/spinner/Spinner';
import project from '../../../../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getUrlPathParams } from '../../../core__racing-events-utils';
import Icon from 'UI/globals/Icons';
import { getComponent } from 'Services/core__imports';
import { COUNTRY_DIV } from 'CORE__UI/apps/RacingEventsApp/core__raceListingPage';

const SEAR = getComponent(SEAR_LOCAL, SEAR_CORE);
class RaceListingPage extends React.Component {
  isNavigateToEventPageChecked = false;
  constructor(props) {
    super(props);
    this.state = {
      raceCourseCollapse: {},
    };

    this.checkExtraPlace = this.checkExtraPlace.bind(this);
  }

  toggleData = country => () => {
    this.setState({
      raceCourseCollapse: {
        ...this.state.raceCourseCollapse,
        [country]: !this.state.raceCourseCollapse[country],
      },
    });
  };

  componentDidMount() {
    if (!this.props?.raceListingEventsObject?.TODAY) {
      this.props.loadData(
        null,
        null,
        window.location.href,
        'onLoadCall',
        this.props.nextRacesFilterValue,
        null,
        this.props.appConfig
      );
    } else {
      this.props.disableSpinLoader();
    }
    if (this.props.appConfig?.eventswithlocaltime) {
      this.props.loadData(
        null,
        null,
        window.location.href,
        'onLoadCall',
        this.props.nextRacesFilterValue,
        null,
        this.props.appConfig
      );
    }

    const self = this;
    const refreshTime = this.props?.appConfig?.refreshtime
      ? this.props?.appConfig?.refreshtime * 1000
      : 30000;
    // Api call at regular interval based on CMS
    this.outerTimer = setTimeout(
      function callRaceListingApi() {
        self.props.loadData(
          null,
          null,
          window.location.href,
          '',
          self.props.nextRacesFilterValue,
          self.props.nextRacesData,
          self.props.appConfig
        );
        self.props.filterSubCatRacingData(window.location.href);
        self.props.filterFutureRacesData(self.props.futureRacingEvents);
        self.innerTimer = setTimeout(() => {
          callRaceListingApi();
        }, refreshTime);
      }.bind(this),
      refreshTime
    );
    // PubSub event listen to get selections added in betslip
    this.subBetslipUpdate = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.props.getBetslipSelections(wsData);
      }
    );
    // Get SubCat details
    this.props.filterSubCatRacingData(
      window.location.href,
      this.props.raceListingEventsObject,
      this.props.activeFilter
    );
    // Get Odds format
    this.props.getOddsFormat();
    //listen to pubsub for odds change
    this.subOddsValue = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      this.props.getOddsFormat(wsData);
    });
    this.props.getVideoStreamInfo();
    this.props.emitPubSubEvent();
    // Load Hls.js script tag
    if (this.props?.appConfig?.displaystreamifavailable) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/hls.js@latest';
      script.async = true;
      document.body.appendChild(script);
    }
    if (
      !this.isNavigateToEventPageChecked &&
      Object.keys(this.props.raceListingEventsObject ?? {}).length
    ) {
      this.isNavigateToEventPageChecked = true;
      this.navigateToEventPage();
    }
    if (this.props?.appConfig?.expandedcountries) {
      const raceCourseCollapse = {};
      this.props?.appConfig?.expandedcountries?.split(',').forEach(country => {
        raceCourseCollapse[country] = true;
      });
      this.setState({
        raceCourseCollapse: raceCourseCollapse,
      });
    }
  }

  componentDidUpdate() {
    if (
      !this.isNavigateToEventPageChecked &&
      Object.keys(this.props.raceListingEventsObject ?? {}).length
    ) {
      this.isNavigateToEventPageChecked = true;
      this.navigateToEventPage();
    }
  }

  // ClearTimeout on component unmount
  componentWillUnmount() {
    clearTimeout(this.outerTimer);
    clearTimeout(this.innerTimer);

    this.subBetslipUpdate?.unsubscribe();
    this.subOddsValue?.unsubscribe();
  }

  navigateToEventPage() {
    const params = getUrlPathParams();
    if (params?.[2] && params?.[3]) {
      Object.keys(this.props.raceListingEventsObject || {})?.find(key => {
        const raceListing = this.props.raceListingEventsObject[key];
        raceListing?.find(d => {
          const raceCoursesArray = d?.raceCoursesArray;
          raceCoursesArray?.forEach(raceCourse => {
            const races = raceCourse?.races;
            const race = races?.find(
              race => `${race?.eventId}` === `${params?.[3]}`
            );
            if (race?.sourceKey) {
              this.props.showRaceListPage(
                races,
                race?.sourceKey,
                d.raceCoursesArray,
                d.country
              );
            }
          });
        });
      });
    } else if (this.props?.appConfig?.sourceid) {
      // when event id come from custiodion
      Object.keys(this.props.raceListingEventsObject || {})?.find(key => {
        const raceListing = this.props.raceListingEventsObject[key];
        raceListing?.find(d => {
          const raceCoursesArray = d?.raceCoursesArray;
          raceCoursesArray?.forEach(raceCourse => {
            const races = raceCourse?.races;
            const race = races?.find(race => {
              return (
                `${race?.sourceKey}` === `${this.props?.appConfig.sourceid}`
              );
            });
            if (race?.sourceKey) {
              this.props.showRaceListPage(
                races,
                race?.sourceKey,
                d.raceCoursesArray,
                d.country
              );
            }
          });
        });
      });
    }
  }

  checkExtraPlace(raceSourceKey) {
    const placeTermsType = 'MANUAL';
    const nextRacesData = this.props.nextRacesData;

    const matchingElement = nextRacesData.find(
      element => element.sourceKey === raceSourceKey
    );
    if (matchingElement) {
      if (
        matchingElement?.market?.some(market =>
          market?.book?.some(book => book?.placeTermsType === placeTermsType)
        )
      ) {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    const {
      raceListingEventsObject,
      activeFilter,
      isEnableNextRaces,
      isShowRaceListPage,
      isEnableNextRacesInHome,
      isBackgroundChange,
      isEnableLoader,
      appConfig = {},
      showSATimeZone,
    } = this.props;
    return (
      <React.Fragment>
        {isShowRaceListPage === false && (
          <React.Fragment>
            {isEnableNextRaces && <RacesCarousel {...this.props} />}
            {!isEnableNextRacesInHome && (
              <React.Fragment>
                <RaceDayFilters appConfig={this.props.appConfig} />
                {activeFilter !== GLOBAL_CONSTANTS.FUTURE_RACES &&
                activeFilter !== GLOBAL_CONSTANTS.RACING_LEGENDS &&
                activeFilter !== GLOBAL_CONSTANTS.VIRTUALS ? (
                  raceListingEventsObject[activeFilter] &&
                  Object.keys(raceListingEventsObject[activeFilter]).map(
                    (data, dIndex) => {
                      const country =
                        raceListingEventsObject[activeFilter][data].country;
                      return (
                        <RL key={dIndex}>
                          <CD
                            onClick={
                              appConfig?.expandedcountries &&
                              this.toggleData(country)
                            }
                          >
                            <COUNTRY_DIV>
                              {country && (
                                <img
                                  alt={country}
                                  src={`${Constants.staticPath.basePath}${
                                    Constants.staticPath.bucketImages
                                  }${
                                    Constants.staticPath.folderFlags
                                  }${country.toLowerCase()}.svg`}
                                />
                              )}
                              {country}
                            </COUNTRY_DIV>
                            {appConfig?.expandedcountries && (
                              <SEAR
                                onClick={this.toggleData(country)}
                                expanded={
                                  this.state.raceCourseCollapse[country]
                                }
                              />
                            )}
                          </CD>
                          {appConfig.expandedcountries
                            ? this.state.raceCourseCollapse[country] &&
                              raceListingEventsObject[activeFilter][
                                data
                              ].raceCoursesArray.map((race, rIndex) => {
                                return (
                                  <RCD key={rIndex}>
                                    <RC
                                      data-test={rIndex === 0 && 'btn-raceinfo'}
                                      onClick={() => {
                                        this.props.showRaceListPage(
                                          race.races,
                                          '',
                                          raceListingEventsObject[activeFilter][
                                            data
                                          ].raceCoursesArray,
                                          raceListingEventsObject[activeFilter][
                                            data
                                          ].country,
                                          true
                                        );
                                      }}
                                      key={rIndex}
                                    >
                                      {race.raceCourseName}
                                      {race?.races?.some(
                                        r =>
                                          r.streamingAvailable === true &&
                                          !r.completed
                                      ) &&
                                        appConfig.displaystreamifavailable && (
                                          <span>
                                            <Icon
                                              iconName={getIcon('STREAMING')}
                                              size={22}
                                            />
                                          </span>
                                        )}
                                    </RC>

                                    <RU>
                                      {race.races.map((raceArray, tIndex) => {
                                        return (
                                          <RacingButton
                                            data-test={
                                              tIndex === 0 && 'btn-raceinfo'
                                            }
                                            onClick={() => {
                                              this.props.showRaceListPage(
                                                race.races,
                                                raceArray.sourceKey,
                                                raceListingEventsObject[
                                                  activeFilter
                                                ][data].raceCoursesArray,
                                                raceListingEventsObject[
                                                  activeFilter
                                                ][data].country
                                              );
                                            }}
                                            key={tIndex}
                                            time={
                                              showSATimeZone
                                                ? moment(
                                                    raceArray.scheduledStart
                                                  ).format(
                                                    project.timeFormats.racing
                                                      .quaternary || 'HH:mm'
                                                  )
                                                : moment(
                                                    raceArray.scheduledStart
                                                  ).hours() > 12
                                                ? moment(
                                                    raceArray.scheduledStart
                                                  ).format(
                                                    project.timeFormats.racing
                                                      .quaternary || 'HH:mm'
                                                  )
                                                : raceArray.raceTime
                                            }
                                            flagged={raceArray.completed}
                                            ep={raceArray.earlyPrice}
                                            highlight={
                                              isBackgroundChange &&
                                              raceArray.completed
                                            }
                                            extraPlace={
                                              appConfig.showextraplacesui &&
                                              this.checkExtraPlace(
                                                raceArray.sourceKey
                                              )
                                            }
                                          />
                                        );
                                      })}
                                    </RU>
                                  </RCD>
                                );
                              })
                            : raceListingEventsObject[activeFilter][
                                data
                              ].raceCoursesArray.map((race, rIndex) => {
                                return (
                                  <RCD key={rIndex}>
                                    <RC
                                      data-test={rIndex === 0 && 'btn-raceinfo'}
                                      onClick={() => {
                                        this.props.showRaceListPage(
                                          race.races,
                                          '',
                                          raceListingEventsObject[activeFilter][
                                            data
                                          ].raceCoursesArray,
                                          raceListingEventsObject[activeFilter][
                                            data
                                          ].country,
                                          true
                                        );
                                      }}
                                      key={rIndex}
                                    >
                                      {race.raceCourseName}
                                      {race?.races?.some(
                                        r =>
                                          r.streamingAvailable === true &&
                                          !r.completed
                                      ) &&
                                        appConfig.displaystreamifavailable && (
                                          <span>
                                            <Icon
                                              iconName={getIcon('STREAMING')}
                                              size={22}
                                            />
                                          </span>
                                        )}
                                    </RC>

                                    <RU>
                                      {race.races.map((raceArray, tIndex) => {
                                        return (
                                          <RacingButton
                                            data-test={
                                              tIndex === 0 && 'btn-raceinfo'
                                            }
                                            onClick={() => {
                                              this.props.showRaceListPage(
                                                race.races,
                                                raceArray.sourceKey,
                                                raceListingEventsObject[
                                                  activeFilter
                                                ][data].raceCoursesArray,
                                                raceListingEventsObject[
                                                  activeFilter
                                                ][data].country
                                              );
                                            }}
                                            key={tIndex}
                                            time={
                                              showSATimeZone
                                                ? moment(
                                                    raceArray.scheduledStart
                                                  ).format(
                                                    project.timeFormats.racing
                                                      .quaternary || 'HH:mm'
                                                  )
                                                : moment(
                                                    raceArray.scheduledStart
                                                  ).hours() > 12
                                                ? moment(
                                                    raceArray.scheduledStart
                                                  ).format(
                                                    project.timeFormats.racing
                                                      .quaternary || 'HH:mm'
                                                  )
                                                : raceArray.raceTime
                                            }
                                            flagged={raceArray.completed}
                                            ep={raceArray.earlyPrice}
                                            highlight={
                                              isBackgroundChange &&
                                              raceArray.completed
                                            }
                                            extraPlace={
                                              appConfig.showextraplacesui &&
                                              this.checkExtraPlace(
                                                raceArray.sourceKey
                                              )
                                            }
                                          />
                                        );
                                      })}
                                    </RU>
                                  </RCD>
                                );
                              })}
                        </RL>
                      );
                    }
                  )
                ) : activeFilter === GLOBAL_CONSTANTS.FUTURE_RACES ? (
                  <FutureRacing />
                ) : (
                  <ED>{Translations.get('text.nodata')}</ED>
                )}
                {isEnableLoader && <Spinner display={true} width={25} />}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

RaceListingPage.propTypes = {
  filterRacingData: PropTypes.func,
  raceListingEventsObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  getRaceDetails: PropTypes.func,
  prevStateEventData: PropTypes.array,
  requestPage: PropTypes.string,
  showRaceListPage: PropTypes.func,
  filterSubCatRacingData: PropTypes.func,
  getNextHorseRaces: PropTypes.func,
  getBetslipSelections: PropTypes.func,
  activeFilter: PropTypes.string,
  nextRacesFilterValue: PropTypes.string,
  isEnableNextRaces: PropTypes.bool,
  filterFutureRacesData: PropTypes.func,
  getFutureRaceOutcomes: PropTypes.func,
  isShowRaceListPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getOddsFormat: PropTypes.func,
  subscriptionEvents: PropTypes.array,
  fetchData: PropTypes.func,
  emitPubSubEvent: PropTypes.func,
  isEnableNextRacesInHome: PropTypes.bool,
  getVideoStreamInfo: PropTypes.func,
  sessionData: PropTypes.func,
  isBackgroundChange: PropTypes.bool,
  isEnableLoader: PropTypes.bool,
  loadData: PropTypes.func,
  disableSpinLoader: PropTypes.func,
  appConfig: PropTypes.object,
  showSATimeZone: PropTypes.bool,
  nextRacesData: PropTypes.array,
};

RaceListingPage.defaultProps = {
  isShowRaceListPage: false,
  raceListingEventsObject: {},
  activeFilter: 'TODAY',
  requestSport: '',
  nextRacesFilterValue: 'Today',
  isEnableNextRaces: false,
  subscriptionEvents: [],
  isEnableNextRacesInHome: false,
  isBackgroundChange: false,
  isEnableLoader: true,
  prevStateEventData: [],
  requestPage: '',
};

export default RaceListingPage;
