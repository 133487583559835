import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BCBD,
  BCBC,
  BCBS,
  L,
  S,
  T,
} from 'UI/apps/BetslipUKApp/BetslipContainer/Bonus';
import { Translations } from '../../../../../core__betslip-UK-app';
import { GLOBAL_PATH } from 'Services/global/core__constants';

/**
 * this component contains the table for each category
 * @param {*} handleBonus function handle bonus if user select
 * @param {*} bonus boolen of bonus when user reload
 * @param {*} bet object of current Bet
 * @param {boolean} universal that handle specific design for check box
 */

export const BetSlipBonus = ({
  handleBonus,
  bonus,
  bet,
  universal,
  altText,
}) => {
  const [showBonusCheckbox, setBonusCheckbox] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes(GLOBAL_PATH.VIRTUAL)) {
      setBonusCheckbox(false);
    } else {
      setBonusCheckbox(true);
    }
  }, []);

  if (bet.multiple && bet.betModifierBetCount > 1) {
    return <React.Fragment />;
  } else {
    return (
      showBonusCheckbox && (
        <section>
          {(!universal && (
            <L>
              {altText ? (
                <T>{Translations.get('text.use.bonus')}</T>
              ) : (
                <T>{Translations.get('text.use.bonus.balance')}</T>
              )}
              <BCBC
                type="checkbox"
                onClick={e => handleBonus(e.target.checked, bet)}
                defaultChecked={false}
              />
              <S dc={bonus} />
            </L>
          )) || (
            <BCBD>
              <BCBC
                type="checkbox"
                onClick={e => handleBonus(e.target.checked, bet)}
                defaultChecked={false}
              />
              <BCBS> {Translations.get('text.use.bonus.balance')}</BCBS>
            </BCBD>
          )}
        </section>
      )
    );
  }
};

BetSlipBonus.propTypes = {
  handleBonus: PropTypes.func,
  bonus: PropTypes.bool,
  bet: PropTypes.object,
  universal: PropTypes.bool,
  altText: PropTypes.bool,
};
BetSlipBonus.defaultProps = {
  universal: true,
};
