import { GREYS } from 'UI/globals/colours';
import styled, { css } from 'styled-components';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';

export const BS = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${GREYS.white};
  height: 100%;
`;

export const B = styled.div`
  height: 100%;
  @media (max-width: ${BREAKPOINT_M}) {
    ${({ openBS }) =>
      !openBS &&
      css`
        position: fixed;
        height: auto;
        flex: 0;
        bottom: 0;
      `}
    ${({ adjustBottomHeight }) =>
      adjustBottomHeight &&
      css`
        bottom: 60px;
      `}  
    ${({ betslipExpand }) =>
      betslipExpand &&
      css`
        bottom: initial;
      `}          
    width: 100%;
  }
`;
