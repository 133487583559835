import {
  DChekBx,
  DS,
  DBTN,
  D,
  Ch5,
  CL,
  C,
} from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__bottomSection';
import styled from 'styled-components';
import { GREYS, BRAND } from '../../../globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
const DJN = styled(D)`
  background-color: ${GREYS.silverD};
  border-top: 1px solid ${BRAND.primary};
  @media (max-width: ${BREAKPOINT_S}) {
    padding-bottom: 10vh;
  }
`;
const DSJN = styled(DS)`
  color: ${GREYS.white};
`;
const Ch5JN = styled(Ch5)`
  color: ${GREYS.white};
`;

const DBTN_GDWN = styled(DBTN)`
  background-color: ${BRAND.highlight};
  color: ${GREYS.white};
  border: 0;
`;

const DcheckBx_GDWN = styled(DChekBx)`
  background-color: ${BRAND.primary};
`;
export {
  DcheckBx_GDWN as DChekBx,
  DSJN as DS,
  DBTN_GDWN as DBTN,
  DJN as D,
  Ch5JN as Ch5,
  CL,
  C,
};
