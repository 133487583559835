import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  VD,
  IF,
  RMG_CONTAINER,
} from 'CORE__UI/apps/RacingEventsApp/core__raceStreaming';
import { RACING_CONSTANTS } from '../../../core__racing-events-utils';
import { createRmgScript } from '../../../core__racing-events-utils';

const StreamPlayer = ({ provider }) => {
  const { performStreamUrl, raceSourceKey, streamProviderObject } = useSelector(
    state => state.horseRacesList
  );

  const { flumenResource } = streamProviderObject;

  const newRmgMetadata =
    flumenResource?.length > 0 && flumenResource[0].metadata;

  // useEffect to load rmg script
  useEffect(() => {
    const isRmgDiv = document.getElementById('player-container');
    if (!isRmgDiv) return null;

    const scriptElement = createRmgScript(newRmgMetadata);
    if (scriptElement) {
      isRmgDiv.appendChild(scriptElement);
    }
    return () => {
      scriptElement?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {provider === RACING_CONSTANTS.PERFORM ? (
        <IF src={performStreamUrl}></IF>
      ) : provider === RACING_CONSTANTS.PERFORMWB && newRmgMetadata ? (
        <RMG_CONTAINER id="player-container"></RMG_CONTAINER>
      ) : (
        <VD
          id={'tv-' + raceSourceKey}
          controls
          playsInline
          autoPlay
          muted={true}
        ></VD>
      )}
    </React.Fragment>
  );
};

StreamPlayer.propTypes = {
  provider: PropTypes.string,
  raceSourceKey: PropTypes.string,
  streamProviderObject: PropTypes.object,
  performStreamUrl: PropTypes.string,
};

StreamPlayer.defaultProps = {
  provider: '',
  streamProviderObject: {},
  performStreamUrl: '',
  raceSourceKey: '',
};

export default StreamPlayer;
