import React from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { BetslipCurrency } from '../../../BetSlipContainer/BetslipContent/elements';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import {
  ReceiptGenraterName,
  ReceiptDiscription,
  ReceiptDiscriptionFandT,
  FreeBetSvg,
  priceRecipt,
  formatTimeStamp,
} from '../../../../../core__betslip-utils';
import {
  Ch6,
  C,
  CL,
  Ch5,
  MDC,
  Ch5E,
  Ch5F,
  MULD,
  BCLC,
  BCL,
  TCD,
  CDOWN,
  Ch6S,
  FbImg,
  Ch5N,
} from 'UI/apps/BetslipUKApp/ReceiptContainer/Container';
import { Ch5s } from 'CORE__UI/apps/BetslipUKApp/ReceiptContainer/core__container';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import { Translations } from '../../../../../core__betslip-UK-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { BRAND } from 'UI/globals/colours';
/**
Container handle middle section of Recept
takes bet info
@param bet {object}
*/
export const Container = ({ bet, oddsFormat, GW, freeBetColor }) => {
  const iconName = getIcon('PRICE_BOOST');
  const booster = (bet.betBoostId && true) || false;
  let { betPart } = bet;
  let ordinalValue = 0;
  if (bet.betTypeName === GLOBAL_CONSTANTS.SINGLEBETSLIP) {
    betPart = betPart[0];
    if (
      [GLOBAL_CONSTANTS.DOGS_REF, GLOBAL_CONSTANTS.HORSES_REF].includes(
        betPart['catRef']
      )
    ) {
      ordinalValue = betPart?.selectionOrdinal;
    }
  }

  return (
    <React.Fragment>
      {bet.betTypeName === GLOBAL_CONSTANTS.SINGLEBETSLIP ? (
        <MDC>
          <C>
            <CL>
              <Ch5F>
                {bet?.bogEligible && !bet.creditWallet && (
                  <BCLC>
                    <BCL>{Translations.get('text.bog')}</BCL>
                  </BCLC>
                )}
                {bet.live && (
                  <BCLC>
                    <BCL>{Translations.get('text.live')}</BCL>
                  </BCLC>
                )}
                <TCD>
                  {ordinalValue
                    ? `(${ordinalValue}) ${betPart['selection']}`
                    : betPart['selection']}
                </TCD>
              </Ch5F>
              <Ch6>
                {ReceiptDiscription(
                  formatTimeStamp(betPart['scheduledStartOn']),
                  betPart['subcatName'],
                  betPart['market'],
                  betPart['name'],
                  betPart['catRef']
                )}
              </Ch6>
              {bet?.ewLabel && (
                <Ch6>
                  {'EW Terms '}
                  {bet?.ewLabel && bet?.ewLabel}
                </Ch6>
              )}
            </CL>
            {booster && bet?.originalPriceValue && (
              <CL autoLeftMargin={true}>
                <Ch5F>
                  <TCD>
                    {oddsFormat === Constants.DECIMAL
                      ? parseDecimalPlaces(bet?.originalPriceValue)
                      : oddsFormat === Constants.AMERICAN
                      ? getOdds(bet?.originalPriceValue, true)
                      : oddsFormat === Constants.FRACTIONAL
                      ? bet?.originalFractionalPriceValue
                      : bet?.originalPriceValue}
                    &nbsp;&gt;&nbsp;
                  </TCD>
                </Ch5F>
              </CL>
            )}
            <CL padding={true} booster={booster}>
              <Ch5 booster={booster} GW={GW} stake={true}>
                {booster && <I iconName={iconName} marginR={8} />}
                {betPart['bookType'] === Constants.SP
                  ? Constants.SP
                  : priceRecipt(betPart, oddsFormat)}
              </Ch5>
            </CL>
          </C>
          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.stake')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.betCount > 1 && <MULD>{'  X' + `${bet.betCount}`}</MULD>}
                  {bet.creditWallet && (
                    <React.Fragment>
                      <FbImg>
                        <FreeBetSvg
                          color={
                            (freeBetColor && freeBetColor) || BRAND.secondary
                          }
                        ></FreeBetSvg>
                      </FbImg>
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                  {bet.bonusFunds && (
                    <Ch5N>
                      &nbsp; {'- ' + Translations.get('text.bonus.fund')}
                    </Ch5N>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.potential.returns')}</Ch6>
              <Ch5E>
                {bet.maxPayout <= 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {parseDecimalPlacesPotReturn(parseFloat(bet.maxPayout))}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      ) : bet.betTypeName === GLOBAL_CONSTANTS.CREATEABET ? (
        <MDC>
          <C>
            <CL>
              <Ch5F>
                {bet?.bogEligible && (
                  <BCLC>
                    <BCL>{Translations.get('text.bog')}</BCL>
                  </BCLC>
                )}
                {bet.live && (
                  <BCLC>
                    <BCL>{Translations.get('text.live')}</BCL>
                  </BCLC>
                )}
                <TCD>{bet.betTypeName}</TCD>
              </Ch5F>
              {betPart.map((bp, key) => (
                <Ch6 key={key}>{bp.market + ' - ' + bp.selection}</Ch6>
              ))}
            </CL>
            <CL padding={true}>
              <Ch5 GW={GW}>
                {oddsFormat === Constants.DECIMAL
                  ? bet.indicativeDecimalPrice
                  : oddsFormat === Constants.AMERICAN
                  ? getOdds(bet.indicativeDecimalPrice, true)
                  : bet.indicativeFractionalPrice}
              </Ch5>
            </CL>
          </C>
          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.stake')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg>
                          <FreeBetSvg
                            color={
                              (freeBetColor && freeBetColor) || BRAND.secondary
                            }
                          ></FreeBetSvg>
                        </FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                  {bet.bonusFunds && (
                    <Ch5N>
                      &nbsp; {'- ' + Translations.get('text.bonus.fund')}
                    </Ch5N>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.pr')}</Ch6>
              <Ch5E>
                {bet.maxPayout <= 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {parseDecimalPlacesPotReturn(parseFloat(bet.maxPayout))}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      ) : bet.betTypeName === GLOBAL_CONSTANTS.FORECAST ||
        bet.betTypeName === GLOBAL_CONSTANTS.TRICAST ? (
        <MDC>
          <C>
            <CL>
              <Ch5>
                {ReceiptGenraterName(bet['betModifier'], bet['betTypeName'])}
              </Ch5>
              <Ch6>{ReceiptDiscriptionFandT(betPart)}</Ch6>
            </CL>
          </C>

          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.stake')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake && bet.unitStake.toFixed(2)}
                  {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
                  {bet.betCount > 1 && <MULD>{'  X' + `${bet.betCount}`}</MULD>}
                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg>
                          <FreeBetSvg
                            color={
                              (freeBetColor && freeBetColor) || BRAND.secondary
                            }
                          ></FreeBetSvg>
                        </FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                  {bet.bonusFunds && (
                    <Ch5N>
                      &nbsp; {'- ' + Translations.get('text.bonus.fund')}
                    </Ch5N>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.potential.returns')}</Ch6>
              <Ch5E>
                {bet.maxPayout <= 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {parseDecimalPlacesPotReturn(parseFloat(bet.maxPayout))}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      ) : (
        <MDC>
          {betPart.map((betP, key) => {
            return (
              <C key={key}>
                <CL>
                  <Ch5>{betP['selection']}</Ch5>
                  <Ch6>
                    {ReceiptDiscription(
                      formatTimeStamp(betP['scheduledStartOn']),
                      betP['subcatName'],
                      betP['market'],
                      betP['name'],
                      betP['catRef']
                    )}
                  </Ch6>
                </CL>
                {booster && bet?.originalPriceValue && (
                  <CL autoLeftMargin={true}>
                    <Ch5F>
                      <TCD>
                        {oddsFormat === Constants.DECIMAL
                          ? parseDecimalPlaces(bet?.originalPriceValue)
                          : oddsFormat === Constants.AMERICAN
                          ? getOdds(bet?.originalPriceValue, true)
                          : bet?.originalPriceValue}
                        &nbsp;&gt;&nbsp;
                      </TCD>
                    </Ch5F>
                  </CL>
                )}
                <CL padding={true} booster={booster}>
                  <Ch5 GW={GW} booster={booster}>
                    {booster && <I iconName={iconName} marginR={8} />}
                    {betP['bookType'] === Constants.SP
                      ? Constants.SP
                      : priceRecipt(betP, oddsFormat)}
                  </Ch5>
                </CL>
              </C>
            );
          })}

          <CDOWN>
            <CL>
              <Ch6S>{Translations.get('text.stake')}</Ch6S>
              <Ch5>
                <React.Fragment>
                  <BetslipCurrency symbol={bet['currencyCode']} />
                  {bet.unitStake && bet.unitStake.toFixed(2)}
                  {bet.betCount > 1 && <MULD>{'  X' + `${bet.betCount}`}</MULD>}

                  {bet.creditWallet && (
                    <React.Fragment>
                      {
                        <FbImg>
                          <FreeBetSvg
                            color={
                              (freeBetColor && freeBetColor) || BRAND.secondary
                            }
                          ></FreeBetSvg>
                        </FbImg>
                      }
                      <Ch5s>{' ' + Translations.get('text.token')}</Ch5s>
                    </React.Fragment>
                  )}
                  {bet.bonusFunds && (
                    <Ch5N>
                      &nbsp; {'- ' + Translations.get('text.bonus.fund')}
                    </Ch5N>
                  )}
                </React.Fragment>
              </Ch5>
            </CL>
            <CL>
              <Ch6>{Translations.get('text.pr')}</Ch6>
              <Ch5E>
                {bet.maxPayout <= 0 ? (
                  Constants.N_A
                ) : (
                  <React.Fragment>
                    <BetslipCurrency symbol={bet['currencyCode']} />
                    {parseDecimalPlacesPotReturn(parseFloat(bet.maxPayout))}
                  </React.Fragment>
                )}
              </Ch5E>
            </CL>
          </CDOWN>
        </MDC>
      )}
    </React.Fragment>
  );
};
Container.propTypes = {
  bet: PropTypes.object,
  oddsFormat: PropTypes.string,
  GW: PropTypes.bool,
  freeBetColor: PropTypes.string,
};

Container.defaultProps = {
  GW: false,
  freeBetColor: '',
};
