import styled, { css } from 'styled-components';
import { GREYS } from 'UI/globals/colours';

export const DH5 = styled.div`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  ${({ active }) =>
    !active
      ? css`
          color: ${GREYS.silver};
        `
      : css`
          color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
        `};
`;
