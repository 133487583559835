import React from 'react';
import {
  TBC,
  TBB as TBB_CORE,
  TBD as TBD_CORE,
  TBS as TBS_CORE,
  TBT as TBT_CORE,
  TBTC as TBTC_CORE,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__taxBottomSection';
import {
  TBB as TBB_LOCAL,
  TBD as TBD_LOCAL,
  TBS as TBS_LOCAL,
  TBT as TBT_LOCAL,
  TBTC as TBTC_LOCAL,
} from 'UI/apps/BetslipUKApp/BetslipContainer/TaxBottomSection';
import PropTypes from 'prop-types';
import { Translations } from '../../../../../core__betslip-UK-app';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import { BetslipCurrency } from '../../BetslipContent/elements';
import { Constants } from '../../../../../core__betslip-constants';

const TBB = TBB_LOCAL || TBB_CORE;
const TBD = TBD_LOCAL || TBD_CORE;
const TBS = TBS_LOCAL || TBS_CORE;
const TBT = TBT_LOCAL || TBT_CORE;
const TBTC = TBTC_LOCAL || TBTC_CORE;

export const TaxBottomSection = ({
  tax,
  closeTaxSection,
  potentialReturns,
  fee,
  netReturn,
  currencySymbol,
}) => {
  return (
    <TBC>
      <TBS>
        <TBTC>
          <TBT>{Translations.get('text.gross.returns')}</TBT>
          <TBT>
            {potentialReturns === Constants.N_A ? (
              Translations.get('text.na')
            ) : (
              <React.Fragment>
                <BetslipCurrency symbol={currencySymbol} />
                {parseDecimalPlacesPotReturn(potentialReturns)}
              </React.Fragment>
            )}
          </TBT>
        </TBTC>

        <TBTC>
          <TBT>
            {tax}% {Translations.get('text.of.withholding.tax')}:
          </TBT>
          <TBT>
            <BetslipCurrency symbol={currencySymbol} />
            {parseDecimalPlacesPotReturn(fee)}
          </TBT>
        </TBTC>

        <TBTC>
          <TBT>{Translations.get('text.potential.returns.net')}:</TBT>
          <TBT>
            <BetslipCurrency symbol={currencySymbol} />{' '}
            {parseDecimalPlacesPotReturn(netReturn)}
          </TBT>
        </TBTC>

        <TBTC>
          <TBD>{Translations.get('text.notification.horse.racetax')}</TBD>
        </TBTC>

        <TBB onClick={closeTaxSection}>CLOSE</TBB>
      </TBS>
    </TBC>
  );
};

TaxBottomSection.propTypes = {
  tax: PropTypes.number,
  closeTaxSection: PropTypes.func,
  potentialReturns: PropTypes.number,
  fee: PropTypes.number,
  netReturn: PropTypes.number,
  currencySymbol: PropTypes.string,
};
