import {
  MI,
  TI,
  TIH,
  H,
  HR,
  HD,
  FR,
  MD,
  NR,
  NRH,
  NRR,
  OI,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesFavourite';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const TIH_EX = styled(TIH)`
  color: ${GREYS.white};
`;

const MI_EX = styled(MI)`
  border-bottom: 1px solid ${FONT.primary};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

const HR_EX = styled(HR)`
  color: ${GREYS.white};
`;

const HD_EX = styled(HD)`
  color: ${GREYS.white};
`;

const FR_EX = styled(FR)`
  color: ${GREYS.white};
`;

const NRH_EX = styled(NRH)`
  color: ${GREYS.white};
`;

const NRR_EX = styled(NRR)`
  color: ${GREYS.white};
`;

const MD_EX = styled(MD)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;

export {
  MI_EX as MI,
  TI,
  TIH_EX as TIH,
  H,
  HR_EX as HR,
  HD_EX as HD,
  FR_EX as FR,
  MD_EX as MD,
  NR,
  NRH_EX as NRH,
  NRR_EX as NRR,
  OI,
};
