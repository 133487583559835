import React from 'react';
import PropTypes from 'prop-types';
import { MSSDNF, M3 } from 'UI/apps/BetslipUKApp/BetslipContainer/Notification';
import { Translations } from '../../../../../core__betslip-UK-app';

export const NotificationComponent = ({ showStakePopup }) => {
  return (
    <React.Fragment>
      <MSSDNF>
        <M3>
          {showStakePopup
            ? Translations.get('text.review.stake')
            : Translations.get('text.odd.change.review')}
        </M3>
      </MSSDNF>
    </React.Fragment>
  );
};
NotificationComponent.propTypes = {
  showStakePopup: PropTypes.bool,
};
