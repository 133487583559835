import {
  RL,
  FT,
  FRMD,
  FRTE,
  FB,
  MKN,
  FRSO,
  FROT,
  FROM,
  FRO,
  B,
  FRF,
} from 'CORE__UI/apps/RacingEventsApp/core__futureRacesOutcomes';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

const MKN_EX = styled(MKN)`
  color: ${GREYS.white};
  border-bottom: 1px solid ${FONT.primary};
`;

const FROT_EX = styled(FROT)`
  color: ${GREYS.white};
`;

const FRF_EX = styled(FRF)`
  color: ${GREYS.white};
`;

const FB_EX = styled(FB)`
  color: ${GREYS.white};
`;

const FRTE_EX = styled(FRTE)`
  color: ${GREYS.white};
`;

export {
  RL,
  FT,
  FRMD,
  FRTE_EX as FRTE,
  FB_EX as FB,
  MKN_EX as MKN,
  FRSO,
  FROT_EX as FROT,
  FROM,
  FRO,
  B,
  FRF_EX as FRF,
};
