import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Top level div to display filters
export const TD = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;
  a {
    white-space: nowrap;
    text-align: center;
    margin-right: 12px;
    margin-left: 12px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0px;
    @media (max-width: ${BREAKPOINT_S}) {
      margin: 8px 12px 8px 0px !important;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    margin-bottom: 8px;
    margin-left: 16px;
  }
`;
