import {
  BTU,
  BTL,
  BTSS,
  BTCB,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__tab';
import styled from 'styled-components';
import { GREYS, FONT } from '../../../globals/colours';

const BTCBJN = styled(BTCB)`
  color: ${FONT.lightMute};
`;
const BTSSJN = styled(BTSS)`
  border-bottom: 1px solid ${FONT.dark};
  border-top: 1px solid ${FONT.dark};
  color: ${GREYS.white};
`;

const BTL_GDWN = styled(BTL)`
  color: ${GREYS.white};
`;

export { BTU, BTL_GDWN as BTL, BTSSJN as BTSS, BTCBJN as BTCB };
