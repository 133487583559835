import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';
import { EA } from 'CORE__UI/apps/MarketsEventDropdownApp/core__eventsDropdown';
export const EXPD = styled.div`
  margin: 8px;
  padding: 4px;
`;
export const EXPD_C = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
`;
export const DDI = styled.div`
  align-self: center;
  padding-right: 16px;
`;
export const EA_C = styled(EA)`
  &:after {
    transform: ${({ expanded }) =>
      expanded ? 'rotate(224deg)' : 'rotate(45deg)'};
  }
`;
