import moment from 'moment';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { getInterpolatedParametersInTranslation } from 'Services/globalfunctions/core__global-functions';
import project from '../../project';

class ProductRestriction {
  productRestriction = [];
  currentProductRestrictionInfo = null;

  constructor() {
    if (
      project.productRestrictionConfig?.length &&
      typeof window === 'object'
    ) {
      this.init();
    }
  }

  init() {
    // if user is already login
    this.updateProductRestrictionInfo();

    // when user login
    PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      this.updateProductRestrictionInfo();
    });

    // when user relogin or reload page
    PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      this.updateProductRestrictionInfo();
    });

    // handle when user logout
    PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      this.updateProductRestrictionInfo();
    });
  }

  updateProductRestrictionInfo() {
    const customerSession = FSBCustomerSession.getSession();
    this.productRestriction = customerSession?.raw?.productRestriction ?? [];

    if (this.productRestriction?.length) {
      const pathname = window?.location?.pathname;
      let shouldNotRedirect = false;
      const currentProductRestrictionInfo = this.productRestriction?.find(r => {
        const restrictionConfig = project.productRestrictionConfig?.find(
          c => c.productGroupRef === r?.productGroupRef
        );
        const isArray = Array.isArray(restrictionConfig?.urlToMatch);
        if (
          isArray
            ? restrictionConfig?.urlToMatch?.some(url =>
                pathname?.toLowerCase().includes(url)
              )
            : pathname?.toLowerCase().includes(restrictionConfig?.urlToMatch)
        ) {
          shouldNotRedirect = !!restrictionConfig?.shouldNotRedirect;
          return true;
        }
        return false;
      });

      this.currentProductRestrictionInfo = currentProductRestrictionInfo
        ? {
            ...currentProductRestrictionInfo,
            shouldNotRedirect,
          }
        : currentProductRestrictionInfo;

      if (currentProductRestrictionInfo && !shouldNotRedirect) {
        this.showAlert();
      }
    }
  }

  handleCloseModal = () => {
    const modal = document?.getElementById('product-restriction-alert-modal');
    if (modal?.style) modal.style.display = 'none';
  };

  handleRedirect = () => {
    window.location.href = '/';
  };

  showAlert(descDataKey) {
    const modal = document?.getElementById('product-restriction-alert-modal');

    if (modal?.style) {
      modal.style.display = 'flex';

      const handleClick = this.currentProductRestrictionInfo?.shouldNotRedirect
        ? this.handleCloseModal
        : this.handleRedirect;

      const closeIcon = document.getElementById('restriction-alert-cls');
      if (closeIcon) closeIcon.onclick = handleClick;
      const okButton = document.getElementById('restriction-alert-b');
      if (okButton) okButton.onclick = handleClick;

      const descriptionTextElement = document.getElementById(
        `restriction-alert-d`
      );
      if (descriptionTextElement?.style) {
        descriptionTextElement.innerText = getInterpolatedParametersInTranslation(
          descriptionTextElement?.dataset?.[descDataKey ?? 'alerttext'],
          {
            productTitle: this.currentProductRestrictionInfo?.productGroupRef?.toLowerCase(),
            createdOn: this.getDateStringFromTimeStamp(
              this.currentProductRestrictionInfo?.createdOn
            ),
            restrictedUntil: this.getDateStringFromTimeStamp(
              this.currentProductRestrictionInfo?.restrictedUntil
            ),
          }
        );
      }
    }
  }

  getIsCurrentProductRestricted(isShowAlertWhenRestricted) {
    const isRestricted = !!this.currentProductRestrictionInfo;
    if (isRestricted && isShowAlertWhenRestricted) {
      this.showAlert();
    }
    return isRestricted;
  }

  getIsAllProductRestricted(isShowAlertWhenRestricted) {
    let isAllRestricted = false;
    if (
      project?.productRestrictionConfig?.length > 0 &&
      this.productRestriction?.length > 0 &&
      this.productRestriction.length === project.productRestrictionConfig.length
    ) {
      isAllRestricted = true;
      if (isShowAlertWhenRestricted) this.showAlert('alertdeposittext');
    }

    return isAllRestricted;
  }

  getDateStringFromTimeStamp(timestamp) {
    return moment(timestamp).format(
      project.timeFormats?.accounts?.secondary || 'DD/MM/YYYY'
    );
  }
}
const singleton = new ProductRestriction();

export default singleton;
