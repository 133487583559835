import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledLabel,
  InfoIcon as InfoIcon_LOCAL,
  Wrap as Wrap_LOCAL,
  HoverBox as HoverBox_LOCAL,
  LeftPoint as LeftPoint_LOCAL,
} from 'UI/globals/LabelStyles';
import {
  InfoIcon as InfoIcon_GLOBAL,
  LeftPoint as LeftPoint_GLOBAL,
  Wrap as Wrap_GLOBAL,
  HoverBox as HoverBox_GLOBAL,
} from './core__labelStyles';
import { getComponent } from 'Services/core__imports';

const InfoIcon = getComponent(InfoIcon_LOCAL, InfoIcon_GLOBAL);
const Wrap = getComponent(Wrap_LOCAL, Wrap_GLOBAL);
const HoverBox = getComponent(HoverBox_LOCAL, HoverBox_GLOBAL);
const LeftPoint = getComponent(LeftPoint_LOCAL, LeftPoint_GLOBAL);

const Label = ({ text, extraInfo, ...props }) => {
  const [showInfo, setShowInfo] = useState(false);
  const getExtraInfo = () => (
    <Wrap>
      <StyledLabel {...props}>{text}</StyledLabel>
      <InfoIcon
        onMouseOver={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        <InfoIcon>&#9432;</InfoIcon>
        {showInfo && (
          <HoverBox>
            {extraInfo}
            <LeftPoint />
          </HoverBox>
        )}
      </InfoIcon>
    </Wrap>
  );
  const getInfo = () => <StyledLabel {...props}>{text}</StyledLabel>;
  return (text && (extraInfo ? getExtraInfo() : getInfo())) || '';
};

Label.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  extraInfo: PropTypes.string,
};

export default Label;
