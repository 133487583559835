import styled from 'styled-components';
import OddsButton from 'UI/buttons/OddsButton';
import { GREYS, FONT, BRAND, SHADOW } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Next races blocks display div in racing carousel
export const RC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 127px;
  justify-content: center;
  border-radius: 4px;
  margin-right: 8px;
  padding: 8px;
  box-shadow: ${SHADOW};
  text-align: center;
  cursor: pointer;
  outline: none;
`;
// Next races info display div in race blocks
export const RCB = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
// Next race time display span in race blocks
export const RCT = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme, addActive }) =>
    theme.dark
      ? addActive
        ? GREYS.white
        : FONT.lightMute
      : addActive
      ? BRAND.primary
      : GREYS.black};
  font-weight: ${props => props?.addActive && 'bold'};
`;
// Next races racecourse name display div in race blocks
export const RCN = styled.div`
  display: block;
  height: 20px;
  width: 81px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme, addActive }) =>
    theme.dark
      ? addActive
        ? GREYS.white
        : FONT.lightMute
      : addActive
      ? BRAND.primary
      : GREYS.black};
  font-weight: ${props => props?.addActive && 'bold'};
`;
// Next races main div to show title and day filter dropdown
export const NR = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
`;
// Next races title display
export const NRT = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.secondary)};
  padding: 8px;
  font-weight: 550;
`;
// Next races day filter dropdown display
export const NRD = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  min-width: 120px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
`;
// Next races outcomes info display main div under carousel
export const NRP = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
// Next race individual block outcomes info display div
export const NRI = styled.div`
  border-radius: 4px;
  height: 366px;
  background-color: ${({ theme }) =>
    theme.dark ? FONT.darkMute : GREYS.white};
  margin-top: 8px;
  margin-right: 8px;
  flex: 1;
  box-shadow: ${SHADOW};
`;
// Next race outcomes header display div
export const NRH = styled.div`
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.primary)};
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  img {
    margin-right: 8px;
    width: 24px;
  }
`;
// Next race outcomes body display div
export const NRB = styled.div`
  height: 286px;
`;
// Next race deailed outcomes display main div
export const NRBM = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// Next race horse silk image display div
export const NRS = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 4px;
  img {
    width: 36px;
  }
`;
// Next race dogs postion display div
export const NRDS = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  justify-content: center;
  border: 1px solid
    ${props =>
      !props?.positionStyle?.color &&
      !props?.positionStyle?.backgroundColor &&
      props?.apptheme === 'dark'
        ? GREYS.white
        : GREYS.black};
  background-color: ${props => props?.positionStyle?.backgroundColor};
  color: ${props =>
    props?.positionStyle?.color || props?.positionStyle?.backgroundColor
      ? props?.positionStyle?.color
      : props?.apptheme === 'dark'
      ? GREYS.white
      : GREYS.black};
  border-color: ${props => props?.positionStyle?.borderColor};
  margin: 8px 8px;
  background-image: ${props =>
    props?.positionStyle?.backgroundImage &&
    props?.positionStyle?.backgroundImage};
`;
// Next race horse/dog name display main div
export const NRN = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  flex: 1 1 0%;
`;
// Next race horse/dog name display div
export const NRNE = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Next race horse/dog jockey name display div
export const NRJN = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
`;
// Next race horse/dog odds display div
export const NRO = styled.div`
  padding: 8px;
  @media (max-width: ${BREAKPOINT_S}) {
    button {
      min-width: 80px;
      max-width: 80px;
    }
  }
`;
// Next race footer display div
export const NRF = styled.div`
  border-top: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  display: flex;
  justify-content: center;
  height: 32px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  align-items: center;
  padding: 8px;
  font-weight: 500px;
  cursor: pointer;
`;

// Slick carousel list shadow display div
export const SC = styled.div`
  .race-inner-slider {
    padding: 8px 0;
  }
  .race-slider {
    overflow: hidden;
    max-height: 70px;
  }
`;
// Next races video icon display span
export const RCV = styled.span`
  margin: 0px 0px 0px 4px;
  width: 10px;
  height: 10px;
  opacity: 0.75;
  background-image: url(https://assets.fsbtech.com/video_stream/icon-tv.svg);
  background-size: contain;
  background-repeat: no-repeat;
`;

export const RCP = styled.span`
  margin: 2px 0px 0px 3px;
  width: 12px;
  height: 12px;

  background-color: ${BRAND.primary};
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid ${GREYS.black};
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: ${GREYS.black};
  }

  /* horizontal line */
  &::before {
    width: 6px; /* Width of the horizontal line */
    height: 2px; /* Thickness of the horizontal line */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* vertical line */
  &::after {
    width: 2px; /* Thickness of the vertical line */
    height: 6px; /* Height of the vertical line */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ROB = styled(OddsButton)`
  min-width: 5em;
`;

// Info message display
export const ED = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.secondary)};
  padding-left: 8px;
`;
export const NPT = styled.div`
  display: ${({ active }) => (active ? `-webkit-box` : `flex`)};
  align-items: right;
  margin-right: 4px;
  font-weight: ${({ active }) => (active ? `normal` : `bold`)};
  color: ${GREYS.white};
  margin-right: 8px;
  b {
  }
`;
export const NPTEP = styled.div`
  display: ${({ active }) => (active ? `-webkit-box` : `flex`)};
  align-items: right;
  margin-right: 4px;
  font-weight: ${({ active }) => (active ? `normal` : `bold`)};
  background-color: ${BRAND.primary};
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  margin-right: 8px;
  color: ${GREYS.black};
  b {
    color: ${GREYS.black};
    padding: 4px;
  }
`;

export const FLAG_ROW = styled.div`
  display: flex;
  align-items: center;
`;
