import { bonusValues } from './core__kyc-initial-state';

export const getKycData = (data, kyc) => {
  let files = [];
  if (data) {
    kyc.map(x => {
      return x.proofs.map(x1 => {
        files = [];
        data?.map(y => {
          if (y.type === x1.type) {
            files.push(y);
            x1.document = [...files];
          }
        });
        if (x1.document.length) {
          x1.status = x1?.document?.[0]?.state;
        }
        return x1;
      });
    });
    return kyc;
  } else {
    return kyc;
  }
};
export const checkState = doc => {
  let state = bonusValues.PENDING_STATE;
  const select = doc.proofs.every(x => x.status === bonusValues.SELECT);
  if (select) {
    return bonusValues.SELECT;
  }
  if (
    doc.required === bonusValues.ONE ||
    doc.required === bonusValues.ONE_MORE
  ) {
    doc.proofs?.reduce((acv, proof) => {
      if (proof.status === bonusValues.DEC && !acv) {
        acv = state = bonusValues.DECLINE_STATE;
      }
      if (proof.status === bonusValues.PENDING && acv !== bonusValues.APPROVE) {
        acv = state = bonusValues.PENDING_STATE;
      }
      if (proof.status === bonusValues.APPROVE) {
        acv = state = bonusValues.APPROVE_STATE;
      }
      return acv;
    }, undefined);
    const all = doc.proofs.every(x => x.status === bonusValues.DEC);
    if (all) {
      return bonusValues.DECLINE_STATE;
    } else {
      if (doc.proofs.find(x => x.status === bonusValues.APPROVE)) {
        return bonusValues.APPROVE_STATE;
      }
    }
  }
  if (doc.required === bonusValues.ALL) {
    const allDoc = doc.proofs.every(x => x.status === bonusValues.DEC);
    if (allDoc) {
      return bonusValues.DECLINE_STATE;
    }
    const allDocApp = doc.proofs.every(x => x.status === bonusValues.APPROVE);
    if (allDocApp) {
      return bonusValues.APPROVE_STATE;
    }
  }
  return state;
};

export const decodeHtmlEntity = (html, defaultValue) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html ? html : defaultValue;
  return txt.value;
};
