import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import { GLOBAL_CONSTANTS } from 'Services/core__services';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { getAppConfig as getAppConfig_LOCAL } from './component/helper/racingAppConfig';
import { getAppConfig as getAppConfig_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import { emitSelectionIdForBetSlip } from '../EventsApp/component/core__eventsFunction';
import moment from 'moment';
import { getCookie } from 'Services/cookie/core__cookies';
import {
  subscribeMarket,
  listenMarket,
  listenOutrightMarket,
} from './core__racing-events-wsData';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { __getBreakPoint } from 'Services/globalstyles/core__globalstyles';
import project from '../../../project';
import { BP, RACING_CONSTANTS } from './core__racing-events-utils';
import { getComponent } from 'Services/core__imports';

const getAppConfig = getComponent(getAppConfig_LOCAL, getAppConfig_CORE);
const initialStateTemplate = {
  horseRacingData: {
    raceListingEventsObject: {},
    activeFilter: 'TODAY',
    dateFilters: [
      {
        label: 'Today',
        filter: 'TODAY',
      },
      {
        label: 'Tomorrow',
        filter: 'TOMORROW',
      },
      {
        label: 'Future Races',
        filter: 'FUTURE_RACES',
      },
      {
        label: 'Racing Legends',
        filter: 'RACING_LEGENDS',
      },
      {
        label: 'Virtuals',
        filter: 'VIRTUALS',
      },
    ],
    requestPage: '',
    requestSport: '',
    nextRacesData: [],
    allRaceDetails: [],
    racingDays: [
      {
        value: 'Today',
        label: 'Today',
      },
      {
        value: 'Tomorrow',
        label: 'Tomorrow',
      },
    ],
    nextRacesFilterValue: 'Today',
    filteredRaces: [],
    isEnableNextRaces: false,
    futureRacingEvents: [],
    oddsFormat: '',
    isEnableNextRacesInHome: false,
    selectedCountryName: '',
    showFutureRaces: false,
    sessionInfo: {},
    isEnableLoader: true,
  },

  horseRacesList: {
    isShowRaceListPage: false,
    raceSourceKey: '',
    raceListInfo: [],
    eventData: [],
    raceCourseInfo: {},
    showAllRaceOutcomes: false,
    getBetslipSelectionList: [],
    prevStateEventData: [],
    subscriptionEvents: [],
    raceCoursesList: [],
    countryRaceCoursesList: [],
    isShowDropdownContent: false,
    raceCountry: '',
    videoStreamObj: {},
    isVideoStream: false,
    showVideoStreamIcon: false,
    streamProviderObject: {},
    performStreamUrl: '',
    flumenValue: '',
    isStreamDisplayed: false,
    getFlumenProviders: [],
  },

  futureRacesData: {
    isShowFutureOutcomesPage: false,
    futureRacingOutcomesData: {},
  },
};

export const racingListReducer = (state = {}, action) => {
  switch (action.type) {
    // Storing racecourses along with races info group by country in redux store
    case 'HORSE_FILTER_DATA':
      return { ...state, raceListingEventsObject: action.filterRacesArray };
    // Storing selected day/tabname in redux store
    case 'SELECTED_DATE_TAB':
      return { ...state, activeFilter: action.selectedDay };
    // Storing all days/tabs to be shown in redux store
    case 'UPDATE_DATE_FILTERS':
      return { ...state, dateFilters: action.dateFilters };
    // Storing requested page in redux store
    case 'REQUEST_PAGE':
      return { ...state, requestPage: action.page };
    // Storing requested sport in redux store
    case 'REQUEST_SPORT':
      return { ...state, requestSport: action.sport };
    // Storing all races data in redux store
    case 'STORE_ALL_RACES_DATA':
      return { ...state, allRaceDetails: action.allRaces };
    // Storing all races data in redux store
    case 'NEXT_RACES_DETAILS':
      return { ...state, nextRacesData: action.nextRaces };
    // Storing filtered next races
    case 'FILTERED_NEXT_RACES':
      return { ...state, filteredRaces: action.filteredRaces };
    // Storing selcted next race filter value
    case 'SELECTED_NEXT_RACE_FILTER_VALUE':
      return { ...state, nextRacesFilterValue: action.val };
    // Store next races enbale value in redux store
    case 'IS_SHOW_NEXT_RACES':
      return { ...state, isEnableNextRaces: action.isShow };
    // Store future race events in redux store
    case 'GET_FUTURE_RACE_EVENTS':
      return { ...state, futureRacingEvents: action.futureRaces };
    // Store odds format
    case 'SET_ODDS_FORMAT':
      return { ...state, oddsFormat: action.format };
    // Store next races in home page value in redux store
    case 'IS_SHOW_NEXT_RACES_IN_HOME':
      return { ...state, isEnableNextRacesInHome: action.isShow };
    // Set selected country name
    case 'SET_SELECTED_COUNTRY_NAME':
      return { ...state, selectedCountryName: action.country };
    // Enable Future Races
    case 'ENABLE_FUTURE_RACES':
      return { ...state, showFutureRaces: action.isEnable };
    // Set Session Data
    case 'SET_SESSION_DATA':
      return { ...state, sessionInfo: action.data };
    // Set Loader Value
    case 'LOAD_SPINNER':
      return { ...state, isEnableLoader: action.isLoad };
    default:
      return state;
  }
};

export const racesInforeducer = (state = {}, action) => {
  switch (action.type) {
    // Storing selected race source key in redux store
    case 'GET_SOURCE_KEY':
      return { ...state, raceSourceKey: action.sourceKey };
    // Storing all races of particular race course in redux store
    case 'GET_RACES_INFO':
      return { ...state, raceListInfo: action.raceList };
    // Storing racelsiting page hide/show boolean value in redux store
    case 'SHOW_RACE_LISTING_PAGE':
      return { ...state, isShowRaceListPage: action.isShow };
    // Storing race outcomes info in redux store
    case 'GET_EVENT_DETAILS':
      return { ...state, eventData: action.eventDetails };
    // Storing race date info in redux store
    case 'RACING_DATE_INFO':
      return { ...state, raceCourseInfo: action.raceCourseInfo };
    // Storing All races hide/show boolean value in redux store
    case 'SHOW_ALL_RACE_OUTCOMES':
      return { ...state, showAllRaceOutcomes: action.isEnable };
    // Storing all betslip selections received from pubsub event in redux store
    case 'STORE_BETSLIP_SELECTIONS':
      return { ...state, getBetslipSelectionList: action.selections };
    // Storing previous event details to maintain prev state in redux store
    case 'SET_EVENT_DETAILS':
      return { ...state, prevStateEventData: action.eventDetails };
    // Storing subscribed events in redux store
    case 'UPDATE_SUBSCRIBED_EVENTS':
      return { ...state, subscriptionEvents: action.subEvents };
    // Storing selected country racecourses in redux store
    case 'SELECTED_COUNTRY_RACECOURSES':
      return { ...state, raceCoursesList: action.raceCourses };
    case 'COUNTRY_SPECIFIC_RACECOURSES':
      return { ...state, countryRaceCoursesList: action.rCourses };
    // Storing hide and show boolean variable in redux store
    case 'HIDE_AND_SHOW_CONTENT':
      return { ...state, isShowDropdownContent: action.isShow };
    // Storing race course country name in redux store
    case 'RACECOURSE_COUNTRY':
      return { ...state, raceCountry: action.country };
    // Stroing video stream params in redux store
    case 'SET_VIDEOSTREAM_PARAMS':
      return { ...state, videoStreamObj: action.params };
    // Store stream availablilty value in redux store
    case 'VIDEO_STREAM_AVAILABLE':
      return { ...state, isVideoStream: action.isAvailable };
    // Hide and show video stream
    case 'HIDE_AND_SHOW_VIDEO_STREAM':
      return { ...state, showVideoStreamIcon: action.isShow };
    // Store provider object in redux store
    case 'SET_PROVIDER_OBJECT':
      return { ...state, streamProviderObject: action.obj };
    // Store perform URL in redux store
    case 'PERFORM_LOAD_URL':
      return { ...state, performStreamUrl: action.url };
    // Store flumen info in redux store
    case 'FLUMEN_PROVIDER_INFO':
      return { ...state, flumenValue: action.flumen };
    // Store stream display boolean value in redux store
    case 'STREAM_DISPLAY':
      return { ...state, isStreamDisplayed: action.isStream };
    // Store flumen providers in redux store
    case 'FLUMEN_STREAM_PROVIDERS':
      return { ...state, getFlumenProviders: action.providers };
    default:
      return state;
  }
};

export const futureRaceReducer = (state = {}, action) => {
  switch (action.type) {
    // Storing future race outcomes page hide/show in redux store
    case 'SHOW_FUTURE_OUTCOMES_PAGE':
      return { ...state, isShowFutureOutcomesPage: action.isEnable };
    // Storing future race outcomes data in redux store
    case 'FUTURE_RACES_OUTCOMES_DATA':
      return { ...state, futureRacingOutcomesData: action.data };
    default:
      return state;
  }
};
//Combine reducers into a single reducer
export const reducer = combineReducers({
  horseRacingData: racingListReducer,
  horseRacesList: racesInforeducer,
  futureRacesData: futureRaceReducer,
});
// Racecourses and races info
export const filteredData = filterRacesArray => ({
  type: 'HORSE_FILTER_DATA',
  filterRacesArray,
});
// Selected day filter/tab
export const selectedDataTab = selectedDay => ({
  type: 'SELECTED_DATE_TAB',
  selectedDay,
});
// Update day/tab filters
export const updateDateFilters = dateFilters => ({
  type: 'UPDATE_DATE_FILTERS',
  dateFilters,
});
// Get sourceKey of particular race
export const getSourceKey = sourceKey => ({
  type: 'GET_SOURCE_KEY',
  sourceKey,
});
// Get all races info
export const getRacesInfo = raceList => ({
  type: 'GET_RACES_INFO',
  raceList,
});
// Hide/show racecourse grop by country page
export const showRaceListingPage = isShow => ({
  type: 'SHOW_RACE_LISTING_PAGE',
  isShow,
});
// Get outcome details of particular race
export const getEventDetails = eventDetails => ({
  type: 'GET_EVENT_DETAILS',
  eventDetails,
});
// Get race date
export const racingDateInfo = raceCourseInfo => ({
  type: 'RACING_DATE_INFO',
  raceCourseInfo,
});
// Hide/show all races and individual race
export const showAllRaceOutcomes = isEnable => ({
  type: 'SHOW_ALL_RACE_OUTCOMES',
  isEnable,
});
// Store betslip selections received from pubsub event
export const storeBetslipSelections = selections => ({
  type: 'STORE_BETSLIP_SELECTIONS',
  selections,
});
// Get previous state outcomes data
export const setEventDetails = eventDetails => ({
  type: 'SET_EVENT_DETAILS',
  eventDetails,
});
// Set request page
export const requestPage = page => ({
  type: 'REQUEST_PAGE',
  page,
});
// Set request sport
export const requestSport = sport => ({
  type: 'REQUEST_SPORT',
  sport,
});
// Store all races data
export const storeAllRacesData = allRaces => ({
  type: 'STORE_ALL_RACES_DATA',
  allRaces,
});
// Store next races data
export const nextRacesDetails = nextRaces => ({
  type: 'NEXT_RACES_DETAILS',
  nextRaces,
});
// Store filtered next races data
export const filteredNextRaces = filteredRaces => ({
  type: 'FILTERED_NEXT_RACES',
  filteredRaces,
});
// Store next races selected value
export const selectedNextRaceFilterValue = val => ({
  type: 'SELECTED_NEXT_RACE_FILTER_VALUE',
  val,
});
// Enable next races from CMS
export const isShowNextRaces = isShow => ({
  type: 'IS_SHOW_NEXT_RACES',
  isShow,
});
// Get future races
export const getFutureRaceEvents = futureRaces => ({
  type: 'GET_FUTURE_RACE_EVENTS',
  futureRaces,
});
// Enable/disable future outcomes
export const showFutureOutcomesPage = isEnable => ({
  type: 'SHOW_FUTURE_OUTCOMES_PAGE',
  isEnable,
});
// Get future outcomes data
export const futureRacesOutcomesData = data => ({
  type: 'FUTURE_RACES_OUTCOMES_DATA',
  data,
});
// Get odds format
export const setOddsFormat = format => ({
  type: 'SET_ODDS_FORMAT',
  format,
});
// Store subscribed events
export const updateSubscribedEvents = subEvents => ({
  type: 'UPDATE_SUBSCRIBED_EVENTS',
  subEvents,
});
// Enable next races from CMS in homepage
export const isShowNextRacesInHome = isShow => ({
  type: 'IS_SHOW_NEXT_RACES_IN_HOME',
  isShow,
});
// Set selected country name
export const setSelectedCountryName = country => ({
  type: 'SET_SELECTED_COUNTRY_NAME',
  country,
});
// Selected country racecourses
export const selectedCountryRaceCourses = raceCourses => ({
  type: 'SELECTED_COUNTRY_RACECOURSES',
  raceCourses,
});
// Country specific racecourses
export const countrySpecificRaceCourses = rCourses => ({
  type: 'COUNTRY_SPECIFIC_RACECOURSES',
  rCourses,
});
// Dropdown Hide and Show
export const hideAndShowDropdown = isShow => ({
  type: 'HIDE_AND_SHOW_CONTENT',
  isShow,
});
// Racecourse country name
export const racecourseCountry = country => ({
  type: 'RACECOURSE_COUNTRY',
  country,
});
// Enable Future Races
export const enableFutureRaces = isEnable => ({
  type: 'ENABLE_FUTURE_RACES',
  isEnable,
});
// Store videostraming params
export const setVideoSreamParams = params => ({
  type: 'SET_VIDEOSTREAM_PARAMS',
  params,
});
// Store session data
export const setSessionData = data => ({
  type: 'SET_SESSION_DATA',
  data,
});
// Check video stream
export const videoStreamAvailable = isAvailable => ({
  type: 'VIDEO_STREAM_AVAILABLE',
  isAvailable,
});
// Hide/Show video stream
export const hideAndShowVideoStream = isShow => ({
  type: 'HIDE_AND_SHOW_VIDEO_STREAM',
  isShow,
});
// Set provider object
export const setProviderObject = obj => ({
  type: 'SET_PROVIDER_OBJECT',
  obj,
});
// Perform load URL
export const performLoadUrl = url => ({
  type: 'PERFORM_LOAD_URL',
  url,
});
// Flumen provider info
export const flumenProviderInfo = flumen => ({
  type: 'FLUMEN_PROVIDER_INFO',
  flumen,
});
// Check stream display
export const streamDisplay = isStream => ({
  type: 'STREAM_DISPLAY',
  isStream,
});
// Store Flumen Stream Providers
export const flumenStreamProviders = providers => ({
  type: 'FLUMEN_STREAM_PROVIDERS',
  providers,
});
// Store Spinner boolean value
export const loadSpinner = isLoad => ({
  type: 'LOAD_SPINNER',
  isLoad,
});
/**
 * Method to fetch data from server
 */
export const fetchData = (
  url,
  nodeRequest,
  appConfig,
  call,
  getFilter,
  prevData
) => dispatch => {
  const promises = [];
  let baseUrl = '';
  if (appConfig?.requestURL) {
    baseUrl = appConfig.requestURL;
  } else {
    baseUrl = appConfig;
  }
  if (baseUrl?.indexOf('FUTURE_RACES') > -1) {
    promises.push(getFutureRaceOutcomes(baseUrl, nodeRequest, dispatch));
  } else {
    promises.push(filterRacingData(nodeRequest, appConfig, true, dispatch));
    promises.push(
      getNextHorseRaces(nodeRequest, appConfig, getFilter, dispatch, prevData)
    );
  }
  return Promise.all(promises);
};

/**
 * Method to fetch data from client
 */
export const loadData = (
  url,
  nodeRequest,
  appConfigUrl,
  call,
  getFilter,
  prevData,
  appConfig
) => dispatch => {
  const promises = [];
  let baseUrl = '';
  if (appConfigUrl?.requestURL) {
    baseUrl = appConfigUrl.requestURL;
  } else {
    baseUrl = appConfigUrl;
  }
  if (baseUrl?.indexOf('FUTURE_RACES') > -1) {
    promises.push(getFutureRaceOutcomes(baseUrl, nodeRequest, dispatch));
  } else {
    promises.push(filterRacingData(nodeRequest, appConfig, call, dispatch));
    promises.push(
      getNextHorseRaces(nodeRequest, appConfig, getFilter, dispatch, prevData)
    );
  }
  return Promise.all(promises);
};

/**
 * Method to emit pubsub in initial load
 */
export const emitPubSubEvent = () => () => {
  const getAppConfigObj = getAppConfig();
  let racingPage;
  let racingPageLabel;
  if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
    getAppConfigObj?.category === 'HORSES'
  ) {
    racingPage = 'HORSES';
    racingPageLabel = 'Horse Racing';
  } else if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
    getAppConfigObj?.category === 'DOGS'
  ) {
    racingPage = 'DOGS';
    racingPageLabel = 'Dog Racing';
  } else {
    if (
      window.location.href.indexOf('HORSES') > -1 ||
      window.location.href.indexOf('DOGS') > -1
    ) {
      racingPage = 'HORSES';
      racingPageLabel = 'Horse Racing';
    }
  }
  PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
    category: racingPage,
    subcat: null,
    countryName: null,
    category_label: racingPageLabel,
    subcat_label: null,
  });
};

export const checkFutureRacesData = (nodeRequest, requester) => dispatch => {
  let futureBase;
  let domain;
  if (nodeRequest) {
    domain = '.json';
  } else {
    domain = '.fsb';
  }
  const getAppConfigObj = getAppConfig();
  if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
    getAppConfigObj?.category === 'HORSES'
  ) {
    futureBase = `/fsb-api-rest/bet/category/HORSES` + domain;
  } else if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
    getAppConfigObj?.category === 'DOGS'
  ) {
    futureBase = `/fsb-api-rest/bet/category/DOGS` + domain;
  } else {
    futureBase = `/fsb-api-rest/bet/category/HORSES` + domain;
  }
  const futureParams = '?markets=true&virtual=true';
  const formUrl = futureBase + futureParams;
  return requester(formUrl).then(futureRaces => {
    let isEnableFutureRaces = false;
    if (Array.isArray(futureRaces?.category)) {
      isEnableFutureRaces = true;
    } else {
      isEnableFutureRaces = false;
    }
    dispatch(enableFutureRaces(isEnableFutureRaces));
  });
};

// Method to capitalize first letter
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Method to filter group by country with race courses and races
 */
export const filterRacingData = (nodeRequest, appConfig, call, dispatch) => {
  const requester = !nodeRequest ? getRequest : nodeRequest;
  const getAppConfigObj = appConfig;
  let filterRacesArray = {};
  const days = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ];
  const displayMultipleDays = [];
  let url;
  let domain;
  if (nodeRequest) {
    domain = '.json';
  } else {
    domain = '.fsb';
  }
  if (
    getAppConfigObj?.requestURL?.toLowerCase().indexOf('/horses') > -1 ||
    getAppConfigObj?.category === 'HORSES'
  ) {
    url = `/fsb-api-rest/racing/future/5/HORSES` + domain;
    dispatch(requestPage('horses'));
    dispatch(requestSport('Horse Racing'));
  } else if (
    getAppConfigObj?.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
    getAppConfigObj?.category === 'DOGS'
  ) {
    url = `/fsb-api-rest/racing/future/5/DOGS` + domain;
    dispatch(requestPage('dogs'));
    dispatch(requestSport('Dog Racing'));
  } else {
    url = `/fsb-api-rest/racing/future/5/HORSES` + domain;
    dispatch(requestPage('horses'));
    dispatch(requestSport('Horse Racing'));
  }
  // Check future races data
  dispatch(checkFutureRacesData(nodeRequest, requester));
  return requester(url).then(raceRes => {
    //START Logic update race events according to localTime
    if (getAppConfigObj?.eventswithlocaltime) {
      const raceEventsWithLocalTime = [];
      if (Array.isArray(raceRes?.meeting)) {
        raceRes?.meeting?.map(s => {
          s?.race?.map(j => {
            const m = { ...s };
            j.raceTime = moment(j?.scheduledStart).format('HH:mm');
            m.race = [j];
            m.date = moment(j?.scheduledStart).format('YYYY-MM-DD')?.toString();
            raceEventsWithLocalTime.push(m);
          });
        });
      }
      raceRes.meeting = Object.values(
        raceEventsWithLocalTime.reduce((acc, coord) => {
          const key = `${coord.date}-${coord.subCatName}-${coord.country}`;

          if (!acc[key]) {
            acc[key] = { ...coord };
          } else {
            acc[key].race = acc[key].race.concat(coord.race);
          }

          return acc;
        }, {})
      );
    }
    //END Logic update race events according to localTime
    dispatch(loadSpinner(false));
    if (Array.isArray(raceRes.meeting)) {
      dispatch(storeAllRacesData(raceRes.meeting));
    }

    const todayDate = new Date();
    const tomorrowDate = new Date();
    const nextOneDay = new Date();
    const nextTwoDays = new Date();
    const addDate = tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const addOneDayDate = nextOneDay.setDate(nextOneDay.getDate() + 2);
    const addTwoDaysDate = nextTwoDays.setDate(nextTwoDays.getDate() + 3);
    const nextDayDate = new Date(addDate);
    const nextOneDayDate = new Date(addOneDayDate);
    const nextTwoDaysDate = new Date(addTwoDaysDate);
    if (Array.isArray(raceRes.meeting) && raceRes.meeting.length > 0) {
      for (const raceList of raceRes.meeting) {
        const raceDate = new Date(raceList.date);
        // set day type in dayFilter
        const dayFilter =
          todayDate.getDate() === raceDate.getDate()
            ? 'TODAY'
            : nextDayDate.getDate() === raceDate.getDate()
            ? 'TOMORROW'
            : nextOneDayDate.getDate() === raceDate.getDate()
            ? days[nextOneDayDate.getDay()]
            : nextTwoDaysDate.getDate() === raceDate.getDate()
            ? days[nextTwoDaysDate.getDay()]
            : 'FUTURE';
        if (
          dayFilter !== 'TODAY' &&
          dayFilter !== 'TOMORROW' &&
          dayFilter !== 'FUTURE' &&
          displayMultipleDays.indexOf(dayFilter) === -1
        ) {
          displayMultipleDays.push(dayFilter);
        }
        if (
          !Object.prototype.hasOwnProperty.call(filterRacesArray, dayFilter)
        ) {
          filterRacesArray[dayFilter] = [];
        }
        const getCountires = [];
        if (filterRacesArray[dayFilter].length > 0) {
          for (let c = 0; c < filterRacesArray[dayFilter].length; c++) {
            getCountires.push(filterRacesArray[dayFilter][c].country);
          }
        }
        let ind;
        // Filter all countries
        if (!raceList.country) {
          raceList['country'] = 'ROW';
        }
        if (
          filterRacesArray[dayFilter] &&
          getCountires.indexOf(raceList.country) === -1
        ) {
          filterRacesArray[dayFilter][filterRacesArray[dayFilter].length] = {};
          ind = filterRacesArray[dayFilter].length - 1;
        } else {
          ind = getCountires.indexOf(raceList.country);
        }
        // Filter racecourses based on group by country
        const objCountry = new Object();
        if (
          filterRacesArray[dayFilter] &&
          !Object.prototype.hasOwnProperty.call(
            filterRacesArray[dayFilter][ind],
            'raceCoursesArray'
          )
        ) {
          objCountry.country = raceList.country;
          objCountry.order = '';
          objCountry.raceCoursesArray = [];
          let race = [];
          race = raceList.race;
          const raceCourseArray = {
            raceCourseName: raceList.subCatName,
            races: race.sort(sortByProperty('scheduledStart')),
          };
          objCountry.raceCoursesArray.push(raceCourseArray);
          filterRacesArray[dayFilter][ind] = objCountry;
        } else {
          let races = [];
          races = raceList.race.sort(sortByProperty('scheduledStart'));
          const raceCourseArray = {
            raceCourseName: raceList.subCatName,
            races: races,
          };

          if (filterRacesArray[dayFilter] !== undefined) {
            filterRacesArray[dayFilter][ind].raceCoursesArray.push(
              raceCourseArray
            );
          }
        }
      }
    }
    const dateFilters = [];
    const getFilterKeys = Object.keys(filterRacesArray);
    if (
      getFilterKeys.indexOf('TODAY') > -1 &&
      getFilterKeys.indexOf('TOMORROW') > -1
    ) {
      dateFilters.push(
        { label: 'Today', filter: 'TODAY' },
        { label: 'Tomorrow', filter: 'TOMORROW' }
      );
    } else if (getFilterKeys.indexOf('TODAY') > -1) {
      if (call === true) {
        dateFilters.push(
          { label: 'Today', filter: 'TODAY' },
          { label: 'Tomorrow', filter: 'TOMORROW' }
        );
      } else {
        dateFilters.push({ label: 'Today', filter: 'TODAY' });
      }
    }
    // Enable multiple days based on CMS
    if (getAppConfigObj?.showmultipledays) {
      for (const key of displayMultipleDays) {
        dateFilters.push({
          label: capitalizeFirstLetter(key.toLowerCase()),
          filter: key,
        });
      }
      dateFilters.push(
        { label: 'Future Races', filter: 'FUTURE_RACES' },
        { label: 'Racing Legends', filter: 'RACING_LEGENDS' },
        { label: 'Virtuals', filter: 'VIRTUALS' }
      );
      dispatch(updateDateFilters(dateFilters));
    } else {
      dateFilters.push(
        { label: 'Future Races', filter: 'FUTURE_RACES' },
        { label: 'Racing Legends', filter: 'RACING_LEGENDS' },
        { label: 'Virtuals', filter: 'VIRTUALS' }
      );
      dispatch(updateDateFilters(dateFilters));
    }
    if (
      getAppConfigObj?.allracesfilterbycountry ||
      getAppConfigObj?.allracesfilterbyracecourse
    ) {
      filterRacesArray = filterAllRacesDetailsList(filterRacesArray);
    }

    // Sort countires based on CMS
    if (getAppConfigObj?.sortcountries) {
      const splitCountries = getAppConfigObj?.sortcountries
        .toLowerCase()
        .split(',');
      const getArrayKeys = Object.keys(filterRacesArray);
      for (const dayList of dateFilters) {
        let incInd = splitCountries.length - 1;
        if (getArrayKeys.indexOf(dayList.filter) > -1) {
          for (const list of filterRacesArray[dayList.filter]) {
            if (splitCountries.indexOf(list.country.toLowerCase()) > -1) {
              list.order = splitCountries.indexOf(list.country.toLowerCase());
            } else {
              incInd = incInd + 1;
              list.order = incInd;
            }
          }
          filterRacesArray[dayList.filter].sort(sortByProperty('order'));
        }
      }
    }
    if (call === 'onLoadCall') {
      dispatch(selectedDataTab(dateFilters[0].filter));
    }
    //Osiros logical changes
    if (call === 'onLoadCall' || call === true) {
      const getCountryName =
        dateFilters &&
        dateFilters[0].filter &&
        filterRacesArray[dateFilters[0].filter] &&
        filterRacesArray[dateFilters[0].filter][0]?.country;
      dispatch(setSelectedCountryName(getCountryName));
    } else if (call !== '') {
      const getCountryName =
        call &&
        filterRacesArray[call] &&
        filterRacesArray[call][0] &&
        filterRacesArray[call][0]?.country;
      dispatch(setSelectedCountryName(getCountryName));
    }
    dispatch(filteredData(filterRacesArray));
  });
};

/**
 * Method to set selected day
 * @param { string } selectedDay
 */
export const dateFilterSelection = (
  selectedDay,
  raceListObject,
  virtualsUrl,
  appConfig
) => dispatch => {
  if (selectedDay === 'VIRTUALS') {
    if (virtualsUrl) {
      window.location.href = virtualsUrl;
    } else {
      window.location.href = window.location.origin + '/virtual/HORSES/';
    }
  } else {
    dispatch(selectedDataTab(selectedDay));
    // Osiros logical changes
    if (raceListObject && selectedDay !== 'FUTURE_RACES') {
      const getCountryName =
        raceListObject[selectedDay] && raceListObject[selectedDay][0]?.country;
      dispatch(setSelectedCountryName(getCountryName));
    }
    if (selectedDay === 'FUTURE_RACES') {
      return dispatch(filterFutureRacesData());
    } else {
      // Osiros logical changes
      if (raceListObject && raceListObject[selectedDay]) {
        return filterRacingData(null, appConfig, '', dispatch);
      } else {
        return filterRacingData(null, appConfig, selectedDay, dispatch);
      }
    }
  }
};
/**
 * Method to sort property
 * @param { number } property
 */
function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}

function sortEvensToTop(property) {
  return function (a, b) {
    const isEvensA =
      typeof a[property] === GLOBAL_CONSTANTS.STRING &&
      a[property]?.toLowerCase() === GLOBAL_CONSTANTS.EVEN_ODDS;
    const isEvensB =
      typeof b[property] === GLOBAL_CONSTANTS.STRING &&
      b[property]?.toLowerCase() === GLOBAL_CONSTANTS.EVEN_ODDS;
    if (!isEvensA && isEvensB) return 1;
    else if (isEvensA && !isEvensB) return -1;
    return 0;
  };
}

/**
 * Method to sort property having diffrent data types
 * @param { number,string } property
 */
function sortByPropHvDiffVal(property) {
  return function compareFct(a, b) {
    if (isNaN(a[property])) {
      if (isNaN(b[property])) {
        // a and b are strings
        return a[property].localeCompare(b[property]);
      } else {
        // a string and b number
        return 1; // a > b
      }
    } else {
      if (isNaN(b[property])) {
        // a number and b string
        return -1; // a < b
      } else {
        // a and b are numbers
        return a[property] - b[property];
      }
    }
  };
}

/**
 * Method to add zero as a prefix to date if date is less than 10
 * @param { number } num
 */
export function addZero(num) {
  return num < 10 ? '0' + num : num;
}

/**
 * Method to add sufix to number (Horse rank)
 * @param { number } d
 */
function nth(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

/**
 * Method to open race outcomes page
 * @param { array } raceInfo
 * @param { string } sourceKey
 */
export const showRaceListPage = (
  raceInfo,
  sourceKey,
  raceCoursesArray,
  country,
  showAllRaces = false
) => dispatch => {
  dispatch(selectedCountryRaceCourses(raceCoursesArray));
  dispatch(racecourseCountry(country));
  dispatch(getSourceKey(sourceKey));
  dispatch(getRacesInfo(raceInfo));
  dispatch(showRaceListingPage(true));
  dispatch(showAllRaceOutcomes(showAllRaces));
  dispatch(hideAndShowDropdown(false));
};

/**
 * Method to get back to group by country page
 */
export const gotoRaceListPage = reqFrom => dispatch => {
  const getAppConfigObj = getAppConfig();
  dispatch(showRaceListingPage(false));
  dispatch(hideAndShowVideoStream(false));
  dispatch(streamDisplay(false));
  const splitBaseUrl = window.location.href.split('/');
  let getSubCatRef = '';
  if (reqFrom === 'futureRaces') {
    if (splitBaseUrl[splitBaseUrl.length - 1] === '') {
      getSubCatRef = splitBaseUrl[splitBaseUrl.length - 3];
    } else {
      getSubCatRef = splitBaseUrl[splitBaseUrl.length - 2];
    }
    dispatch(showFutureOutcomesPage(false));
  } else {
    if (splitBaseUrl[splitBaseUrl.length - 1] === '') {
      getSubCatRef = splitBaseUrl[splitBaseUrl.length - 2];
    } else {
      getSubCatRef = splitBaseUrl[splitBaseUrl.length - 1];
    }
  }
  const pathName = window.location.pathname.split('/');
  let paramsLen = '';
  if (pathName[pathName.length - 1] === '') {
    paramsLen = pathName.length - 1;
  } else {
    paramsLen = pathName.length;
  }
  if (
    (window.location.href.indexOf('HORSES') > -1 ||
      window.location.href.indexOf('DOGS') > -1 ||
      getAppConfigObj?.category === 'HORSES' ||
      getAppConfigObj?.category === 'DOGS') &&
    getSubCatRef !== '' &&
    getSubCatRef !== 'HORSES' &&
    getSubCatRef !== 'DOGS' &&
    paramsLen > 3
  ) {
    if (getAppConfigObj.sourceid) {
      const name = pathName[pathName.length - 3];
      window.location.href = GLOBAL_PATH.SPORTS_BOOK + name + '/';
    } else {
      window.history.pushState(
        {},
        document.title,
        window.location.href.substring(
          0,
          window.location.href.indexOf(getSubCatRef)
        )
      );
    }
  }
};

/**
 * Method to filter race outcomes
 * @param { array || string } sourceKey
 */
export const getRaceDetails = (
  sourceKey,
  prevStateData,
  page,
  data,
  subEventsArray,
  raceCoursesList
) => (dispatch, getState) => {
  const oddsFormat = getState && getState()?.horseRacingData?.oddsFormat;
  const checkArray = Array.isArray(sourceKey);
  let eventDetails = [];
  const raceCourseInfo = {
    raceCourseName: '',
    raceCourseDate: '',
    countryCode: '',
    selectedRaceCourse: '',
  };
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const base = `/fsb-api-rest/racing/race/`;
  let url;
  if (checkArray) {
    const keys = [];

    for (let s = 0; s < sourceKey.length; s++) {
      keys.push(sourceKey[s].sourceKey);
    }
    const joinKeys = keys.join();
    url = `${base}${joinKeys}.fsb`;
    dispatch(showAllRaceOutcomes(true));
    dispatch(showRaceListingPage(true));
    dispatch(getRacesInfo(sourceKey));
  } else if (sourceKey.length > 0) {
    dispatch(getSourceKey(sourceKey));
    url = `${base}${sourceKey}.fsb`;
    dispatch(showAllRaceOutcomes(false));
  } else if (getAppConfig().sourceid) {
    // when source id come from custodian
    url = `${base}${getAppConfig().sourceid}.fsb`;
    dispatch(showRaceListingPage(true));
  }
  const getAppConfigObj = getAppConfig();
  let formUrl;
  let splitMarkets;
  let marketParams;
  if (getAppConfigObj.market) {
    splitMarkets = getAppConfigObj.market.split(',');
    marketParams = '?&';
    for (const market of splitMarkets) {
      marketParams = marketParams + 'detail=' + market + '&';
    }
    marketParams = marketParams + 'metadata=true';
    formUrl = url + '' + marketParams;
  } else {
    splitMarkets = [];
    marketParams = '?&metadata=true';
    formUrl = url + '' + marketParams;
  }
  return getRequest(formUrl).then(raceDetails => {
    if (Array.isArray(raceDetails.category)) {
      for (const cat of raceDetails.category) {
        for (const subcat of cat.subcat) {
          raceCourseInfo.raceCourseName = subcat.name;
          raceCourseInfo.countryCode = subcat.countryCode;

          if (subcat.countryCode === 'GB') {
            raceCourseInfo.countryCode = 'UK';
          }
          let raceDateInfo = '';

          if (subcat.event.length > 0) {
            const getRaceDate = new Date(subcat.event[0].scheduledStart);
            raceDateInfo =
              addZero(getRaceDate.getDate()) +
              ' ' +
              months[getRaceDate.getMonth()];
          }
          raceCourseInfo.raceCourseDate = raceDateInfo;
          raceCourseInfo.selectedRaceCourse =
            raceCourseInfo.raceCourseName + '-' + raceCourseInfo.raceCourseDate;
          eventDetails = subcat.event;
          // Filter outcomes data based on event
          for (const [e, event] of eventDetails.entries()) {
            event['raceCourseName'] = subcat.name;
            if (getAppConfigObj?.sortby) {
              event['sortBy'] = getAppConfigObj.sortby;
            } else {
              event['sortBy'] = 'ODDS';
            }
            event['catRef'] = cat.ref;
            event['catName'] = cat.name;
            event['subCatRef'] = subcat.ref;
            event['subCatName'] = subcat.name;
            event['countryName'] = subcat.countryName;
            event['eachwayFilter'] = 'SEW'; // Standard Each Way Odds
            // Update previous selected sortby filter on Api refresh
            if (Array.isArray(prevStateData) && prevStateData.length > 1) {
              for (const prevData of prevStateData) {
                if (
                  event.sourceKey === prevData.sourceKey &&
                  Object.prototype.hasOwnProperty.call(prevData, 'sortBy')
                ) {
                  event['sortBy'] = prevData.sortBy;
                  event['eachwayFilter'] = prevData.eachwayFilter;
                }
              }
            } else if (
              Array.isArray(prevStateData) &&
              prevStateData.length === 1
            ) {
              for (const prevData of prevStateData) {
                if (Object.prototype.hasOwnProperty.call(prevData, 'sortBy')) {
                  event['sortBy'] = prevData.sortBy;
                  event['eachwayFilter'] = prevData.eachwayFilter;
                }
              }
            }
            const flumenProviders = [];
            // Filter event metadata
            for (const metaData of event.metadata) {
              event[metaData.name] =
                metaData.value === 'true' ||
                (metaData.value === 'false' ? false : metaData.value);
              // Checking video stram availability
              if (metaData.name === RACING_CONSTANTS.FLUMEN_AVAILABILITY) {
                dispatch(videoStreamAvailable(true));
                flumenProviders.push(metaData?.value);
              }
              if (
                event.state === 'COMPLETED' &&
                (metaData.name === 'forecastWinOrder' ||
                  metaData.name === 'forecastWinValue' ||
                  metaData.name === 'tricastWinOrder' ||
                  metaData.name === 'tricastWinValue')
              ) {
                event[metaData.name] = metaData.value;
              }
            }
            event['flumenProviders'] = flumenProviders;
            // Assign order to markets based on cms
            for (const market of event?.market) {
              if (splitMarkets.indexOf(market.typeRef) > -1) {
                market.order = splitMarkets.indexOf(market.typeRef);
              }
              let countOfLowestSpDecOdds = 1;
              let lowestSpDecOdds = '';
              const raceNRs = [];
              // Filter selections metadata
              if (Array.isArray(market?.selection)) {
                for (const selection of market?.selection) {
                  if (Array.isArray(selection.metadata)) {
                    for (const metaData of selection.metadata) {
                      selection[metaData.name] = metaData.value;
                      if (metaData.name === RACING_CONSTANTS.WEIGHT) {
                        const horseWeight = metaData.value?.replace(/ /g, '');
                        const splitValue = horseWeight?.split('st');
                        const getWeight = splitValue[0] + 'st ' + splitValue[1];
                        selection[metaData.name] = getWeight
                          ? getWeight
                          : metaData.value;
                      }
                      if (
                        event.state === 'COMPLETED' &&
                        metaData.name === 'position'
                      ) {
                        selection[metaData.name] =
                          metaData.value + nth(metaData.value);
                        selection['rank'] = Number(metaData.value);
                        eventDetails[e]['results'] = true;
                      }
                      if (
                        event.state === 'COMPLETED' &&
                        metaData.name === 'spFract'
                      ) {
                        selection['fractionalOdds'] = metaData.value;
                      }
                      if (
                        event.state === 'COMPLETED' &&
                        metaData.name === 'spDec'
                      ) {
                        selection['decimalOdds'] = metaData.value;
                        if (
                          lowestSpDecOdds === '' ||
                          Number(metaData.value) < Number(lowestSpDecOdds)
                        ) {
                          lowestSpDecOdds = metaData.value;
                        } else if (
                          Number(lowestSpDecOdds) === Number(metaData.value)
                        ) {
                          countOfLowestSpDecOdds = countOfLowestSpDecOdds + 1;
                        }
                      }
                    }
                    if (
                      event.state === 'COMPLETED' &&
                      event.results === true &&
                      !Object.prototype.hasOwnProperty.call(selection, 'rank')
                    ) {
                      selection['rank'] = market.selection.length;
                    }
                  }
                  // Filter odds based on price array from API
                  if (Array.isArray(selection.price)) {
                    if (selection.price.length > 1) {
                      for (const odds of selection.price) {
                        if (odds.bookType === 'PRICE') {
                          selection['fractionalOdds'] = odds.fractional;
                          selection['decimalOdds'] = odds.decimal;
                          if (
                            Array.isArray(odds.historicalValue) &&
                            odds.historicalValue.length > 0
                          ) {
                            selection['historicalOddsValues'] =
                              odds.historicalValue;
                          }
                        }
                      }
                    } else {
                      for (const odds of selection.price) {
                        if (odds.bookType === 'PRICE') {
                          selection['fractionalOdds'] = odds.fractional;
                          selection['decimalOdds'] = odds.decimal;
                          if (
                            Array.isArray(odds.historicalValue) &&
                            odds.historicalValue.length > 0
                          ) {
                            selection['historicalOddsValues'] =
                              odds.historicalValue;
                          }
                        } else if (odds.bookType === 'SP') {
                          selection['fractionalOdds'] = 'SP';
                          selection['decimalOdds'] = 'SP';
                        }
                      }
                    }
                  } else {
                    if (event.state !== 'COMPLETED') {
                      selection['fractionalOdds'] = 'SP';
                      selection['decimalOdds'] = 'SP';
                    }
                  }
                  if (selection.nonRunner) {
                    raceNRs.push(selection.name);
                  }
                  if (
                    market.typeRef === 'LEN_FAV' &&
                    selection.typeRef === 'UNDER'
                  ) {
                    selection['favSelectionDisable'] = 'hideFavSelection';
                  }
                }
              }
              market['raceNRs'] = raceNRs;
              if (market.typeRef === 'FPTP' || market.typeRef === 'DOGS_WIN') {
                event['runners'] = market.selection
                  ? market.selection.length - raceNRs?.length
                  : 0;
              }
              market['lowestSpDecOdds'] = lowestSpDecOdds;
              let favouriteHorse = '';
              if (countOfLowestSpDecOdds === 1) {
                favouriteHorse = 'Fav';
              } else if (countOfLowestSpDecOdds === 2) {
                favouriteHorse = 'JT Fav';
              } else if (countOfLowestSpDecOdds > 2) {
                favouriteHorse = 'CO Fav';
              }
              market['favouriteHorse'] = favouriteHorse;
              // Sort by user selection
              if (
                event.state === 'COMPLETED' &&
                Object.prototype.hasOwnProperty.call(event, 'results')
              ) {
                market?.selection?.sort(sortByProperty('rank'));
              } else {
                if (event.sortBy === 'ODDS') {
                  market?.selection?.sort(sortByProperty('decimalOdds'));
                  if (
                    oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.FRACTIONAL
                  ) {
                    market?.selection?.sort(sortEvensToTop('fractionalOdds'));
                  }
                } else {
                  market?.selection?.sort(sortByProperty('ordinal'));
                }
              }
              market?.selection?.sort(sortByProperty('nonRunner'));
              // EW rules check
              const places = { 1: 'Win Only', 2: '1-2', 3: '1-2-3' };
              const fractions = { 1: 'Full Price' };
              let getPlaces;
              let getFractions;
              let placeTerms;
              if (Array.isArray(market.book)) {
                let book = {};
                for (let b = 0; b < market.book.length; b++) {
                  if (
                    market.book[b].bookType?.toLowerCase() === 'price' &&
                    market.book[b].open
                  ) {
                    book = market.book[b];
                  }
                }
                if (Object.keys(book).length === 0) {
                  for (let k = 0; k < market.book.length; k++) {
                    if (
                      market.book[k].bookType?.toLowerCase() === 'sp' &&
                      market.book[k].open
                    ) {
                      book = market.book[k];
                    }
                  }
                }
                if (book?.placeTerms) {
                  getPlaces =
                    book.places > 3 ? '1-' + book.places : places[book.places];
                  getFractions =
                    book.fraction > 1
                      ? '1/' + book.fraction + ' odds'
                      : (book.places !== 1 && fractions[book.fraction]) || '';
                  placeTerms = getPlaces;
                  placeTerms += (getFractions && ' at ' + getFractions) || '';
                  market['placeTerms'] = placeTerms;
                }

                if (book?.rule4Applicable) {
                  const rule4 = 'Rule 4 May Apply';
                  market['rule4'] = rule4;
                }
                if (book?.antePost) {
                  const antePost = 'Ante Post Rules Apply';
                  market['antePost'] = antePost;
                }
              }
            }
            // Filter rules based on keys
            eventDetails[e].market.sort(sortByProperty('order'));
            if (eventDetails[e].state === 'COMPLETED') {
              eventDetails[e]['selectedRaceMarket'] =
                eventDetails[e].market[0].name;
            } else if (
              eventDetails[e].market[0].typeRef !== 'UFAV' &&
              data &&
              data[e] &&
              !Object.prototype.hasOwnProperty.call(
                data[e],
                'selectedRaceMarket'
              )
            ) {
              eventDetails[e]['selectedRaceMarket'] =
                eventDetails[e].market[0].name;
            } else if (
              eventDetails[e].market[0].typeRef !== 'UFAV' &&
              data &&
              Object.prototype.hasOwnProperty.call(
                data[e],
                'selectedRaceMarket'
              )
            ) {
              for (const dataObj of data) {
                if (
                  dataObj?.code?.toString() ===
                  eventDetails[e]?.code?.toString()
                ) {
                  eventDetails[e].selectedRaceMarket =
                    dataObj.selectedRaceMarket;
                }
              }
            } else if (
              eventDetails[e].market.length > 0 &&
              eventDetails[e].market[0].typeRef === 'UFAV'
            ) {
              eventDetails[e]['selectedRaceMarket'] =
                eventDetails[e].market[1].name;
            } else {
              eventDetails[e]['selectedRaceMarket'] =
                eventDetails[e].market[0].name;
            }
            if (
              Object.prototype.hasOwnProperty.call(
                eventDetails[e].market[0],
                'placeTerms'
              )
            ) {
              eventDetails[e]['placeTerms'] =
                eventDetails[e].market[0].placeTerms;
            }
            if (
              Object.prototype.hasOwnProperty.call(
                eventDetails[e].market[0],
                'rule4'
              )
            ) {
              eventDetails[e]['rule4'] = eventDetails[e].market[0].rule4;
            }
            if (
              Object.prototype.hasOwnProperty.call(
                eventDetails[e].market[0],
                'antePost'
              )
            ) {
              eventDetails[e]['antePost'] = eventDetails[e].market[0].antePost;
            }
          }
          eventDetails.sort(sortByProperty('scheduledStart'));
        }
      }
      const marketIds = [];
      const subscribedEvents = subEventsArray;
      for (const event of eventDetails) {
        for (const market of event.market) {
          if (market.id !== -1) {
            if (subscribedEvents?.indexOf(market.id) === -1) {
              subscribedEvents.push(market.id);
              marketIds.push(market.id);
            }
          }
        }
      }
      dispatch(updateSubscribedEvents(subscribedEvents));
      if (marketIds.length > 0) {
        listenMarket(dispatch);
        subscribeMarket(marketIds);
      }

      if (eventDetails.length > 0) {
        let countryName = '';
        if (eventDetails[0]?.countryName) {
          countryName = eventDetails[0].countryName;
        }
        if (prevStateData === '') {
          PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
            category: eventDetails[0].catRef,
            subcat: eventDetails[0].subCatRef,
            countryName,
            category_label: eventDetails[0].catName,
            subcat_label: eventDetails[0].subCatName,
          });
        }
      }
      let mktIndex = -1;
      for (const events of eventDetails) {
        for (const [mk, markets] of events?.market?.entries()) {
          if (markets?.typeRef === 'FPTP') {
            mktIndex = mk;
          }
        }
        for (const marketsEW of events?.market) {
          if (marketsEW?.typeRef === 'EFPTP' && mktIndex >= 0) {
            events.market[mktIndex]['selectionEW'] = marketsEW?.selection;
            events['isEWAvailable'] = true;
            events['placeTermsEW'] = marketsEW?.placeTerms;
          }
        }
      }
      dispatch(getEventDetails(eventDetails));
      dispatch(racingDateInfo(raceCourseInfo));
    }
    const getAllRaceCourses = [];
    if (Array.isArray(raceCoursesList)) {
      for (const rCourse of raceCoursesList) {
        const obj = {};
        obj.value = rCourse?.raceCourseName;
        obj.label = rCourse?.raceCourseName;
        getAllRaceCourses.push(obj);
      }
    }
    dispatch(countrySpecificRaceCourses(getAllRaceCourses));
    if (data === undefined) {
      dispatch(hideAndShowVideoStream(false));
      dispatch(streamDisplay(false));
    }
  });
};

/**
 * Method to trigger on market selection in outcomes page
 * @param { array } eventDetails
 * @param { string } ref
 * @param { number } ind
 */
export const setSelectedRaceMarket = (eventDetails, ref, ind) => dispatch => {
  // Filter selected race market
  eventDetails[ind].selectedRaceMarket = ref;
  for (const market of eventDetails[ind].market) {
    if (ref === market.typeRef) {
      if (Object.prototype.hasOwnProperty.call(market, 'placeTerms')) {
        eventDetails[ind].placeTerms = market.placeTerms;
      } else {
        eventDetails[ind].placeTerms = '';
      }
      if (Object.prototype.hasOwnProperty.call(market, 'rule4')) {
        eventDetails[ind].rule4 = market.rule4;
      } else {
        eventDetails[ind].rule4 = '';
      }
      if (Object.prototype.hasOwnProperty.call(market, 'antePost')) {
        eventDetails[ind].antePost = market.antePost;
      } else {
        eventDetails[ind].antePost = '';
      }
    }
  }
  dispatch(getEventDetails(JSON.parse(JSON.stringify(eventDetails))));
};

/**
 * Method to sort outcomes based on selection
 * @param { array } eventDetails
 * @param { string } sortType
 * @param { number } ind
 */
export const changeSortFilter = (
  eventDetails,
  sortType,
  ind,
  showAllRaceOutcomes
) => (dispatch, getState) => {
  const oddsFormat = getState && getState()?.horseRacingData?.oddsFormat;
  // Sort based on user selection
  let sortBy;
  if (sortType === 'RACECARD') {
    sortBy = 'ODDS';
    for (const event of eventDetails) {
      for (const market of event.market) {
        market.selection.sort(sortByProperty('decimalOdds'));
        if (oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.FRACTIONAL) {
          market?.selection?.sort(sortEvensToTop('fractionalOdds'));
        }
        market.selection.sort(sortByProperty('nonRunner'));
      }
      if (!showAllRaceOutcomes) {
        event.sortBy = sortBy;
      }
    }
  } else {
    sortBy = 'RACECARD';
    for (const event of eventDetails) {
      for (const market of event.market) {
        market.selection.sort(sortByProperty('ordinal'));
        market.selection.sort(sortByProperty('nonRunner'));
      }
      if (!showAllRaceOutcomes) {
        event.sortBy = sortBy;
      }
    }
  }
  if (showAllRaceOutcomes) {
    eventDetails[ind].sortBy = sortBy;
  }
  dispatch(getEventDetails(JSON.parse(JSON.stringify(eventDetails))));
  dispatch(setEventDetails(JSON.parse(JSON.stringify(eventDetails))));
};

/**
 * Method to add/remove bets
 * @param { number } selectionId
 */
export const callBetslip = (selectionId, ordinalData) => () => {
  emitSelectionIdForBetSlip(selectionId, ordinalData);
};

/**
 * Method to get betslip selections
 * @param { array } data
 */
export const getBetslipSelections = data => dispatch => {
  dispatch(storeBetslipSelections(data));
};
/**
 * Method to get sub cat details
 * @param { string } ref
 */
export const filterSubCatRacingData = (
  ref,
  raceListingEventsObject,
  activeFilter
) => dispatch => {
  const splitBaseUrl = ref.split('/');
  let getSubCatRef = '';
  const getAppConfigObj = getAppConfig();
  if (getAppConfigObj.sourceid) {
    //  when source id comes from custodian
    const sportName = splitBaseUrl[splitBaseUrl.length - 3];
    const eventid = getAppConfigObj.sourceid;
    dispatch(getRaceDetails(eventid, '', sportName, '', [], []));
  } else if (splitBaseUrl[splitBaseUrl.length - 1] === '') {
    getSubCatRef = splitBaseUrl[splitBaseUrl.length - 2];
  } else {
    getSubCatRef = splitBaseUrl[splitBaseUrl.length - 1];
  }
  const pathName = window.location.pathname.split('/');
  let paramsLen = '';
  if (pathName[pathName.length - 1] === '') {
    paramsLen = pathName.length - 1;
  } else {
    paramsLen = pathName.length;
  }
  if (
    (ref.indexOf('HORSES') > -1 ||
      ref.indexOf('DOGS') > -1 ||
      getAppConfigObj?.category === 'HORSES' ||
      getAppConfigObj?.category === 'DOGS') &&
    isNaN(getSubCatRef) &&
    getSubCatRef !== '' &&
    getSubCatRef !== 'HORSES' &&
    getSubCatRef !== 'DOGS' &&
    paramsLen > 3
  ) {
    let base;
    const getAppConfigObj = getAppConfig();
    if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
      getAppConfigObj?.category === 'HORSES'
    ) {
      base = `/fsb-api-rest/bet/category/HORSES/`;
    } else if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
      getAppConfigObj?.category === 'DOGS'
    ) {
      base = `/fsb-api-rest/bet/category/DOGS/`;
    } else {
      base = `/fsb-api-rest/bet/category/HORSES/`;
    }
    const url = `${base}${getSubCatRef}.fsb`;
    const marketsParam = '?markets=true&metadata=true';
    const formUrl = url + marketsParam;
    const sourceKeyArray = [];
    let racesInfo = {};
    let sourceKey = '';
    let countryCode = '';
    return getRequest(formUrl).then(data => {
      if (Array.isArray(data?.category)) {
        for (const cat of data?.category) {
          for (const subcat of cat?.subcat) {
            if (
              subcat.name !== 'Future Racing' &&
              Array.isArray(subcat.event)
            ) {
              countryCode = subcat?.countryCode;
              if (countryCode === 'GB') {
                countryCode = 'UK';
              }
              for (const eve of subcat?.event) {
                if (eve.finished) {
                  racesInfo['completed'] = eve.finished;
                } else {
                  racesInfo['completed'] = false;
                }
                racesInfo['eventId'] = eve.id;
                racesInfo['raceTime'] = eve.name;
                racesInfo['scheduledStart'] = eve.scheduledStart;
                racesInfo['sourceKey'] = eve.sourceKey;

                if (Array.isArray(eve.metadata)) {
                  for (const metaData of eve?.metadata) {
                    if (metaData.name === 'ep') {
                      if (
                        metaData.value === 'false' ||
                        metaData.value === false
                      ) {
                        racesInfo['earlyPrice'] = false;
                      } else if (
                        metaData.value === 'true' ||
                        metaData.value === true
                      ) {
                        racesInfo['earlyPrice'] = true;
                      }
                    }
                    if (metaData.name === 'status') {
                      racesInfo['status'] = metaData.value;
                    }
                  }
                }
                sourceKeyArray.push(racesInfo);
                racesInfo = {};
              }
              sourceKeyArray.sort(sortByProperty('scheduledStart'));
              for (const sKey of sourceKeyArray) {
                if (sourceKey === '' && sKey.completed !== 'true') {
                  sourceKey = sKey.sourceKey;
                }
              }
              let racecourseArray = [];
              if (
                raceListingEventsObject &&
                activeFilter &&
                raceListingEventsObject[activeFilter]
              ) {
                for (const filter of raceListingEventsObject[activeFilter]) {
                  if (countryCode === filter.country) {
                    racecourseArray = filter.raceCoursesArray;
                  }
                }
              }
              dispatch(
                showRaceListPage(
                  sourceKeyArray,
                  sourceKey,
                  racecourseArray,
                  countryCode
                )
              );
            }
          }
        }
      }
    });
  }
};
/**
 * Method to get next horse races
 * @param { string } getValue
 */
export const getNextHorseRaces = (
  nodeRequest,
  appConfig,
  getValue,
  dispatch,
  prevData
) => {
  const getAppConfigObj = appConfig;
  const requester = !nodeRequest ? getRequest : nodeRequest;
  if (getValue === undefined) {
    getValue = 'Today';
  }
  let dateIso;
  if (getValue === 'Today') {
    dateIso =
      moment(new Date())
        .utcOffset(0)
        .subtract(12, 'hours')
        .format(project.timeFormats.racing.primary || 'YYYYMMDDTHHmmss') + 'Z';
  } else if (getValue === 'Tomorrow') {
    const tomorrowDate = new Date();
    const addDate = tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const nextDayDate = new Date(addDate);
    dateIso =
      moment(nextDayDate)
        .utcOffset(0)
        .format(project.timeFormats.racing.primary || 'YYYYMMDDTHHmmss') + 'Z';
  } else {
    dateIso =
      moment(new Date())
        .utcOffset(0)
        .format(project.timeFormats.racing.primary || 'YYYYMMDDTHHmmss') + 'Z';
  }
  let domain;
  let base;
  let params;
  let market;
  let nextRacesInfo = [];
  dispatch(isShowNextRaces(getAppConfigObj.shownextraces));
  if (getAppConfigObj?.shownextracesinhomepage) {
    dispatch(isShowNextRacesInHome(getAppConfigObj.shownextracesinhomepage));
  }
  if (getAppConfigObj.shownextraces) {
    if (nodeRequest) {
      domain = '.json';
    } else {
      domain = '.fsb';
    }
    const subcatref =
      (getAppConfigObj.subcatref && `/${getAppConfigObj.subcatref}`) || '';

    if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
      getAppConfigObj?.category === 'HORSES'
    ) {
      base = `/fsb-api-rest/bet/category/HORSES${subcatref}` + domain;
      market = 'FPTP';
    } else if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
      getAppConfigObj?.category === 'DOGS'
    ) {
      base = `/fsb-api-rest/bet/category/DOGS${subcatref}` + domain;
      market = 'DOGS_WIN';
    } else {
      base = `/fsb-api-rest/bet/category/HORSES${subcatref}` + domain;
      market = 'FPTP';
    }
    let getLimit = 6;

    if (getAppConfigObj.nextraceslimit && !getAppConfigObj.maxracescalled) {
      getLimit = getAppConfigObj.nextraceslimit;
    } else {
      getLimit = getAppConfigObj.maxracescalled;
    }
    params =
      '?detail=' +
      market +
      '&state=SCHEDULED&metadata=true&eventLimit=' +
      getLimit +
      '&eventSortField=scheduledStartOn&&scheduledFrom=' +
      dateIso;
    const formUrl = base + params;
    return requester(formUrl).then(nextRaces => {
      if (Array.isArray(nextRaces?.category)) {
        for (const cat of nextRaces?.category) {
          if (Array.isArray(cat?.subcat)) {
            for (const subcat of cat?.subcat) {
              if (Array.isArray(subcat?.event)) {
                for (const eve of subcat?.event) {
                  for (const metaData of eve.metadata) {
                    // Checking video stram availability
                    if (metaData.name === 'flumen_availability') {
                      eve['videoStreamAvailable'] = true;
                    }
                  }
                  if (eve.state === 'SCHEDULED') {
                    eve['racecorseName'] = subcat.name;
                    eve['countryCode'] = subcat.countryCode;
                    eve['ref'] = subcat.ref;
                    for (const market of eve?.market) {
                      for (const selection of market?.selection) {
                        if (Array.isArray(selection.price)) {
                          if (selection.price.length > 1) {
                            for (const odds of selection.price) {
                              if (odds.bookType === 'PRICE') {
                                selection['fractionalOdds'] = odds.fractional;
                                selection['decimalOdds'] = odds.decimal;
                                if (
                                  Array.isArray(odds.historicalValue) &&
                                  odds.historicalValue.length > 0
                                ) {
                                  selection['historicalOddsValues'] =
                                    odds.historicalValue;
                                }
                              }
                            }
                          } else {
                            for (const odds of selection.price) {
                              if (odds.bookType === 'PRICE') {
                                selection['fractionalOdds'] = odds.fractional;
                                selection['decimalOdds'] = odds.decimal;
                                if (
                                  Array.isArray(odds.historicalValue) &&
                                  odds.historicalValue.length > 0
                                ) {
                                  selection['historicalOddsValues'] =
                                    odds.historicalValue;
                                }
                              } else if (odds.bookType === 'SP') {
                                selection['fractionalOdds'] = 'SP';
                                selection['decimalOdds'] = 'SP';
                              }
                            }
                          }
                        } else {
                          if (eve.state !== 'COMPLETED') {
                            selection['fractionalOdds'] = 'SP';
                            selection['decimalOdds'] = 'SP';
                          }
                        }
                        if (Array.isArray(selection.metadata)) {
                          for (const metaData of selection.metadata) {
                            if (
                              metaData.name === 'drawn' ||
                              metaData.name === 'trainer' ||
                              metaData.name === 'jockey' ||
                              metaData.name === 'silk' ||
                              metaData.name === 'silkSvg'
                            ) {
                              selection[metaData.name] = metaData.value;
                            }
                          }
                        }
                      }
                      // EW rules check
                      const places = { 1: 'Win Only', 2: '1-2', 3: '1-2-3' };
                      const fractions = { 1: 'Full Price' };
                      let getPlaces;
                      let getFractions;
                      let placeTerms;
                      if (Array.isArray(market.book)) {
                        for (const book of market.book) {
                          if (book.placeTerms) {
                            getPlaces =
                              book.places > 3
                                ? '1-' + book.places
                                : places[book.places];
                            getFractions =
                              book.fraction > 1
                                ? '1/' + book.fraction + ' odds'
                                : (book.places !== 1 &&
                                    fractions[book.fraction]) ||
                                  '';

                            placeTerms = getPlaces;
                            placeTerms +=
                              (getFractions && ' at ' + getFractions) || '';
                            eve['placeTerms'] = placeTerms;
                          }
                        }
                      }
                      market?.selection?.sort(sortByProperty('decimalOdds'));
                    }
                    nextRacesInfo.push(eve);
                  }
                }
              }
            }
          }
          nextRacesInfo.sort(sortByProperty('scheduledStart'));
        }
      }
      let index = 0;
      if (Array.isArray(prevData) && prevData.length > 0) {
        for (const [p, prevObj] of prevData.entries()) {
          if (prevObj.active) {
            index = p;
            break;
          }
        }
      }
      if (
        getAppConfig()?.racecardfilterbyracecourse ||
        getAppConfig()?.racecardfilterbycountry
      ) {
        nextRacesInfo = filterByraceCourseAndCountry(nextRacesInfo);
      }

      dispatch(nextRacesDetails(nextRacesInfo));
      dispatch(selectedNextRaceFilterValue(getValue));
      dispatch(
        filterNextRacesData(nextRacesInfo, index, BP[__getBreakPoint()] + 1)
      );
    });
  }
};

/**
 * Method to filter next horse races
 * @param { array } nextRaces
 * @param { number } ind
 */
export const filterNextRacesData = (
  nextRaces,
  ind,
  carouselCount
) => dispatch => {
  const filterNextRaces = [];
  const sliceSelections = 4;
  const getNextRaces = nextRaces;
  const breakPoint1 = __getBreakPoint() === GLOBAL_CONSTANTS.BREAKPOINT_XL;
  const breakPoint2 = __getBreakPoint() === GLOBAL_CONSTANTS.BREAKPOINT_L;
  if (Array.isArray(nextRaces)) {
    for (const [e, event] of nextRaces.entries()) {
      getNextRaces[e]['active'] = false;
      getNextRaces[e]['mobileActive'] = false;

      if (ind + 2 < nextRaces.length) {
        if (
          (breakPoint1 && (e === ind || e === ind + 1 || e === ind + 2)) ||
          (breakPoint2 && (e === ind || e === ind + 1)) ||
          e === ind
        ) {
          getNextRaces[e]['active'] = true;
          for (const market of event.market) {
            market?.selection?.sort(sortByProperty('nonRunner'));
            market?.selection?.sort(sortByProperty('decimalOdds'));
            market.selection = market.selection.slice(0, sliceSelections);
          }
          filterNextRaces.push(event);
        }
      } else if (ind + 1 < nextRaces.length) {
        if (
          (breakPoint1 && (e === ind - 1 || e === ind || e === ind + 1)) ||
          (breakPoint2 && (e === ind || e === ind + 1)) ||
          e === ind
        ) {
          getNextRaces[e]['active'] = true;
          for (const market of event.market) {
            market?.selection?.sort(sortByProperty('nonRunner'));
            market?.selection?.sort(sortByProperty('decimalOdds'));
            market.selection = market.selection.slice(0, sliceSelections);
          }
          filterNextRaces.push(event);
        }
      } else if (ind < nextRaces.length) {
        if (
          (breakPoint1 && (e === ind - 2 || e === ind - 1 || e === ind)) ||
          (breakPoint2 && (e === ind - 1 || e === ind)) ||
          e === ind
        ) {
          getNextRaces[e]['active'] = true;
          for (const market of event.market) {
            market?.selection?.sort(sortByProperty('nonRunner'));
            market?.selection?.sort(sortByProperty('decimalOdds'));
            market.selection = market.selection.slice(0, sliceSelections);
          }
          filterNextRaces.push(event);
        }
      }
    }
  }

  // Race Card Highlight for different screen size device
  if (!isNaN(carouselCount)) {
    if (ind + carouselCount - 1 < nextRaces.length) {
      for (let i = ind; i < ind + carouselCount; i++) {
        if (getNextRaces[i]?.active === true) {
          getNextRaces[i]['mobileActive'] = true;
        }
      }
    } else if (ind + carouselCount - 2 < nextRaces.length) {
      for (let i = ind - 1; i < ind + carouselCount - 1; i++) {
        if (getNextRaces[i]?.active === true) {
          getNextRaces[i]['mobileActive'] = true;
        }
      }
    } else if (ind + carouselCount - 3 < nextRaces.length) {
      for (let i = ind - 2; i < ind + carouselCount - 2; i++) {
        if (getNextRaces[i]?.active === true) {
          getNextRaces[i]['mobileActive'] = true;
        }
      }
    }
  }

  dispatch(nextRacesDetails(getNextRaces));
  dispatch(filteredNextRaces(filterNextRaces));
};

/**
 * Method to populate race card from next races
 * @param { object } eventData
 * @param { array } allRaces
 */
export const populateRaceCard = (
  eventData,
  allRaces,
  raceListingEvents
) => dispatch => {
  let raceFilter = [];
  const sourceKey = eventData.sourceKey;
  for (const races of allRaces) {
    if (races?.subCatRef === eventData?.ref) {
      for (const filterRace of races?.race) {
        if (filterRace.sourceKey === eventData.sourceKey) {
          raceFilter = races.race;
        }
      }
    }
  }
  // delete this raceFilter.length > 0 &&  to let the user pass
  if (sourceKey) {
    dispatch(
      showRaceListPage(
        raceFilter,
        sourceKey,
        raceListingEvents?.raceCoursesArray,
        raceListingEvents?.country
      )
    );
  }
};

/**
 * Method to populate race card from next races
 * @param { string } value
 */
export const onChangeNextRaceFilter = value => dispatch => {
  return getNextHorseRaces(null, null, value, dispatch, '');
};

/**
 * Method to filter future races data
 */
export const filterFutureRacesData = futureRacingEvents => dispatch => {
  const filterFutureRaces = [];
  let base;
  const getAppConfigObj = getAppConfig();
  if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
    getAppConfigObj?.category === 'HORSES'
  ) {
    base = `/fsb-api-rest/bet/category/HORSES.fsb`;
  } else if (
    getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
    getAppConfigObj?.category === 'DOGS'
  ) {
    base = `/fsb-api-rest/bet/category/DOGS.fsb`;
  } else {
    base = `/fsb-api-rest/bet/category/HORSES.fsb`;
  }
  const params = '?markets=true&virtual=true';
  const formUrl = base + params;
  return getRequest(formUrl).then(futureRaces => {
    if (Array.isArray(futureRaces?.category)) {
      for (const cat of futureRaces?.category) {
        for (const subcat of cat?.subcat) {
          if (Array.isArray(subcat?.event)) {
            for (const eve of subcat?.event) {
              const storeRaceStartDate = [];
              if (filterFutureRaces.length > 0) {
                for (let f = 0; f < filterFutureRaces.length; f++) {
                  storeRaceStartDate.push(filterFutureRaces[f].scheduledStart);
                }
              }
              let ind;
              // Filter by date
              let dateMatchCount = 0;
              for (let d = 0; d < storeRaceStartDate.length; d++) {
                const currDate = moment(storeRaceStartDate[d]).format(
                  'YYYY-MM-DD'
                );
                const gameDate = moment(eve.scheduledStart).format(
                  'YYYY-MM-DD'
                );
                if (moment(currDate).diff(moment(gameDate), 'days') === 0) {
                  ind = d;
                  dateMatchCount = dateMatchCount + 1;
                  break;
                }
              }
              if (dateMatchCount === 0) {
                filterFutureRaces[filterFutureRaces.length] = {};
                ind = filterFutureRaces.length - 1;
              }

              // Filter future races based on scheduled start
              const objEvent = new Object();
              if (
                !Object.prototype.hasOwnProperty.call(
                  filterFutureRaces[ind],
                  'eventsArray'
                )
              ) {
                objEvent.scheduledStart = eve.scheduledStart;
                objEvent.startDate = moment(eve.scheduledStart).format(
                  project.timeFormats.racing.secondary || 'dddd, D MMMM YYYY'
                );
                if (futureRacingEvents?.length > 0) {
                  for (const fRace of futureRacingEvents) {
                    if (
                      fRace?.scheduledStart?.toString() ===
                        eve?.scheduledStart?.toString() &&
                      fRace.isCollapsed
                    ) {
                      objEvent['isCollapsed'] = fRace.isCollapsed;
                      break;
                    } else {
                      objEvent['isCollapsed'] = false;
                    }
                  }
                } else {
                  objEvent['isCollapsed'] = false;
                }
                objEvent.eventsArray = [];
                objEvent.eventsArray.push(eve);
                filterFutureRaces[ind] = objEvent;
              } else {
                filterFutureRaces[ind].eventsArray.push(eve);
              }
            }
          }
        }
      }
    }
    const getAppConfigObj = getAppConfig();
    if (getAppConfigObj.sortfutureracesby === 'scheduledStart') {
      filterFutureRaces.sort(sortByProperty('scheduledStart'));
    }
    dispatch(getFutureRaceEvents(filterFutureRaces));
  });
};

/**
 * Method to collapse/show future races
 * @param { array } futureRaces
 * @param { number } ind
 */
export const futureRaceCollapse = (futureRaces, ind) => dispatch => {
  futureRaces[ind].isCollapsed = !futureRaces[ind].isCollapsed;
  dispatch(getFutureRaceEvents(JSON.parse(JSON.stringify(futureRaces))));
};

/**
 * Method to show outcomes page of future races
 * @param { number } id
 * @param { string } sport
 */
export const gotoFutureRaceOutcomesPage = id => () => {
  const params = window.location.href + 'FUTURE_RACES/' + id + '/';
  window.location.href = params;
};

/**
 * Method to get outcomes of future races
 * @param { string } url
 */
export const getFutureRaceOutcomes = (url, nodeRequest, dispatch) => {
  const requester = !nodeRequest ? getRequest : nodeRequest;
  let domain = '.fsb';
  if (nodeRequest) {
    domain = '.json';
  }
  let outcomeDetails = {};
  let splitBaseUrl;
  let getEventId = '';
  const getAppConfigObj = getAppConfig();
  if (url?.indexOf('FUTURE_RACES') > -1) {
    splitBaseUrl = url.split('FUTURE_RACES/');
    getEventId = splitBaseUrl[1].replace('/', '');
  }

  if (getEventId !== '') {
    const base = `/fsb-api-rest/bet/event/`;
    const getUrl = `${base}${getEventId}${domain}`;
    const Params = '?detail=OUT&metadata=true';
    const formUrl = getUrl + Params;
    if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/horses') > -1 ||
      getAppConfigObj?.category === 'HORSES'
    ) {
      dispatch(requestPage('horses'));
      dispatch(requestSport('Horse Racing'));
    } else if (
      getAppConfigObj.requestURL.toLowerCase().indexOf('/dogs') > -1 ||
      getAppConfigObj?.category === 'DOGS'
    ) {
      dispatch(requestPage('dogs'));
      dispatch(requestSport('Dog Racing'));
    } else {
      dispatch(requestPage('horses'));
      dispatch(requestSport('Horse Racing'));
    }
    return requester(formUrl).then(data => {
      if (Array.isArray(data?.category)) {
        for (const cat of data?.category) {
          for (const subcat of cat?.subcat) {
            if (Array.isArray(subcat?.event)) {
              for (const eve of subcat?.event) {
                eve['catName'] = subcat.name;
                eve['catRef'] = cat.ref;
                eve['catName'] = cat.name;
                eve['subCatRef'] = subcat.ref;
                eve['subCatName'] = subcat.name;
                eve['countryName'] = subcat.countryName;
                for (const market of eve?.market) {
                  for (const selection of market?.selection) {
                    // Filter odds based on price array from API
                    if (Array.isArray(selection?.price)) {
                      if (selection?.price.length > 1) {
                        for (const odds of selection?.price) {
                          if (odds.bookType === 'PRICE') {
                            selection['fractionalOdds'] = odds.fractional;
                            selection['decimalOdds'] = odds.decimal;

                            if (
                              Array.isArray(odds.historicalValue) &&
                              odds.historicalValue.length > 0
                            ) {
                              selection['historicalOddsValues'] =
                                odds.historicalValue;
                            }
                          }
                        }
                      } else {
                        for (const odds of selection.price) {
                          if (odds.bookType === 'PRICE') {
                            selection['fractionalOdds'] = odds.fractional;
                            selection['decimalOdds'] = odds.decimal;
                          } else if (odds.bookType === 'SP') {
                            selection['fractionalOdds'] = 'SP';
                            selection['decimalOdds'] = 'SP';
                          }
                        }
                      }
                    } else {
                      if (eve.state !== 'COMPLETED') {
                        selection['fractionalOdds'] = 'SP';
                        selection['decimalOdds'] = 'SP';
                      }
                    }
                    if (selection?.active) {
                      selection['activeSelection'] = true;
                    } else {
                      selection['activeSelection'] = false;
                    }
                  }
                  // EW rules check
                  const places = { 1: 'Win Only', 2: '1-2', 3: '1-2-3' };
                  const fractions = { 1: 'Full Price' };
                  let getPlaces;
                  let getFractions;
                  let placeTerms;

                  if (Array.isArray(market.book)) {
                    for (const book of market.book) {
                      if (book?.placeTerms) {
                        getPlaces =
                          book.places > 3
                            ? '1-' + book.places
                            : places[book.places];
                        getFractions =
                          book.fraction > 1
                            ? '1/' + book.fraction + ' odds'
                            : (book.places !== 1 && fractions[book.fraction]) ||
                              '';
                        placeTerms = getPlaces;
                        placeTerms +=
                          (getFractions && ' at ' + getFractions) || '';
                        market['placeTerms'] = placeTerms;
                      }

                      if (book.rule4Applicable) {
                        const rule4 = 'Rule 4 May Apply';
                        eve['rule4'] = rule4;
                      }
                      if (book.antePost) {
                        const antePost = 'Ante Post Rules Apply';
                        eve['antePost'] = antePost;
                      }
                    }
                  }
                  market?.selection?.sort(sortByPropHvDiffVal('decimalOdds'));
                }
                outcomeDetails = eve;
              }
            }
          }
        }
      }
      dispatch(futureRacesOutcomesData(outcomeDetails));
      dispatch(showFutureOutcomesPage(true));
    });
  }
};

/**
 * Method to get odds Format
 */
export const getOddsFormat = format => dispatch => {
  let oddsFormat;
  if (format) {
    oddsFormat = format;
  } else {
    oddsFormat = getCookie('selectedOdds')
      ? getCookie('selectedOdds')
      : 'fractional';
  }
  dispatch(setOddsFormat(oddsFormat));
};

/**
 * Method to update odds
 */
export const updateFutureRacingOdds = (data, subEventsArray) => dispatch => {
  const marketIds = [];
  const subscribedEvents = subEventsArray;
  for (const market of data?.market) {
    if (market.id !== -1) {
      if (subscribedEvents?.indexOf(market.id) === -1) {
        subscribedEvents.push(market.id);
        marketIds.push(market.id);
      }
    }
  }
  dispatch(updateSubscribedEvents(subscribedEvents));
  if (marketIds.length > 0) {
    subscribeMarket(marketIds);
    listenOutrightMarket(dispatch);
  }
  if (data) {
    let countryName = '';

    if (data?.countryName) {
      countryName = data.countryName;
    }
    PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
      category: data.catRef,
      subcat: data.subCatRef,
      countryName,
      category_label: data.catName,
      subcat_label: data.subCatName,
    });
  }
};
/**
 * Method to set selected country
 * Osiros logic handling
 */
export const showSelectedCountryRaces = country => dispatch => {
  dispatch(setSelectedCountryName(country));
};
/**
 * Method to set selected racecourse
 */
export const onChangeSelectedRacecourse = (
  data,
  raceListingEventsObject,
  activeFilter,
  raceCountry,
  requestPage,
  subscriptionEvents
) => dispatch => {
  let races = [];
  let sourceKey = '';
  let racecourseArray = [];
  for (const filter of raceListingEventsObject[activeFilter]) {
    if (raceCountry === filter.country) {
      racecourseArray = filter.raceCoursesArray;
      for (const filterRaceCourses of filter.raceCoursesArray) {
        if (data.label === filterRaceCourses?.raceCourseName) {
          races = filterRaceCourses.races;
          break;
        }
      }
    }
  }

  if (Array.isArray(races)) {
    for (const race of races) {
      sourceKey = race.sourceKey;
      break;
    }
  }

  // If there are ongoing races set first one as default
  const ongoingRaces = races.filter(item => !item.completed);
  if (ongoingRaces.length > 0) {
    sourceKey = ongoingRaces[0].sourceKey;
  }

  if (races.length > 0 && sourceKey !== '' && racecourseArray.length > 0) {
    dispatch(showRaceListPage(races, sourceKey, racecourseArray, raceCountry));
    dispatch(
      getRaceDetails(
        races.filter(item => item.completed).length === races.length
          ? races
          : sourceKey,
        '',
        requestPage,
        '',
        subscriptionEvents,
        racecourseArray
      )
    );
    dispatch(hideAndShowDropdown(false));
  }
};

// Method to hide and show content
export const hideAndShowRacingDropdown = isShow => dispatch => {
  const isShowContent = !isShow;
  dispatch(hideAndShowDropdown(isShowContent));
};

/**
 * Method to get video streaming params from CMS
 */
export const getVideoStreamInfo = () => dispatch => {
  const videoStreamParams = {};
  const getAllAtributes = document.querySelectorAll(
    '[data-app="VideoStreamApp"]'
  );
  const params = getAllAtributes[0]?.dataset;
  if (params) {
    videoStreamParams['videologinmessage'] = params.videologinmessage;
    videoStreamParams['videoqualifymessage'] = params.videoqualifymessage;
    videoStreamParams['videoqualifymessage1'] = params.videoqualifymessage1;
    videoStreamParams['videoqualifymessage2'] = params.videoqualifymessage2;
    videoStreamParams['videoqualified'] = params.videoqualified;
    videoStreamParams['videoqualified1'] = params.videoqualified1;
    videoStreamParams['streamingavailablemessage'] =
      params.streamingavailablemessage;
    videoStreamParams['stakeamount'] = params.stakeamount;
    videoStreamParams['backgroundimage'] = params.backgroundimage;
    videoStreamParams['showstreaminginfobox'] = params.showstreaminginfobox;
  }
  dispatch(setVideoSreamParams(videoStreamParams));
};

/**
 * Method to get session info
 */
export const sessionData = data => dispatch => {
  dispatch(setSessionData(data));
};

export const hideAndShowStreamIcon = (
  isShow,
  sKey,
  flumen,
  flumenProviders
) => dispatch => {
  const showStream = !isShow;
  dispatch(hideAndShowVideoStream(showStream));
  dispatch(flumenProviderInfo(flumen));
  dispatch(flumenStreamProviders(flumenProviders));
  if (showStream) {
    dispatch(flumenCall(sKey, flumen, flumenProviders));
  }
};

export const flumenCall = (
  sKey,
  flumen,
  flumenProviders,
  isStream,
  token
) => dispatch => {
  const sessionToken = getCookie('AppSession');
  if (!isStream) {
    const flumenVal = flumenProviders?.join('&provider=');
    let getProviderObj = {};
    const baseUrl = `/fsb-api-rest/flumen.fsb`;
    let params;
    let getToken;
    if (sessionToken) {
      getToken = sessionToken;
    } else if (token) {
      getToken = token;
    }
    if (getToken) {
      params =
        '?access_token=' +
        getToken +
        '&sourceKey=' +
        sKey +
        '&providerRef=' +
        flumen +
        '&provider=' +
        flumenVal;
    } else {
      params =
        '?sourceKey=' +
        sKey +
        '&providerRef=' +
        flumen +
        '&provider=' +
        flumenVal;
    }
    const url = baseUrl + params;
    let timeout;
    return getRequest(url).then(getProvider => {
      if (Array.isArray(getProvider.flumenEvent)) {
        let userQualify = false;
        for (const event of getProvider.flumenEvent) {
          if (event.userQualified === true) {
            const quali = ['medium', 'standard', 'high', 'adaptive', 'low'];
            let matchQuality = null;
            let found = false;
            if (Array.isArray(event?.flumenResource)) {
              quali.forEach(checkQualit => {
                if (!found) {
                  event?.flumenResource.forEach(resource => {
                    if (!found) {
                      if (resource.quality.toLowerCase() === checkQualit) {
                        matchQuality = [resource];
                        found = true;
                      }
                    }
                  });
                }
              });
            }
            event.flumenResource = matchQuality;
            getProviderObj = event;
            userQualify = true;
          }
        }
        if (!userQualify) {
          if (getToken) {
            getProviderObj.sessionAvaiable = true;
            getProviderObj.userQualified = false;
          } else {
            getProviderObj.sessionAvaiable = false;
          }
        } else {
          if (getProviderObj?.videoStreamingAvailable) {
            if (!Array.isArray(getProviderObj?.flumenResource)) {
              const minutesDiff = moment(getProviderObj?.startDate).diff(
                moment(),
                'minutes'
              );
              if (Number(minutesDiff) > 10) {
                const callDiff = Number(minutesDiff) - 10;
                const callTime = callDiff * 60 * 1000;
                timeout = setTimeout(() => {
                  clearTimeout(timeout);
                  dispatch(flumenCall(sKey, flumen, flumenProviders));
                }, callTime);
              } else {
                timeout = setTimeout(() => {
                  clearTimeout(timeout);
                  dispatch(flumenCall(sKey, flumen, flumenProviders));
                }, 60000);
              }
            }
          } else {
            getProviderObj.videoStreamingAvailable = false;
          }
        }
      }
      dispatch(setProviderObject(getProviderObj));
      if (Array.isArray(getProviderObj?.flumenResource)) {
        clearTimeout(timeout);
        dispatch(startStreaming(getProviderObj, sKey));
      }
    });
  }
};
// Method to start streaming
export const startStreaming = (getProviderObj, sKey) => dispatch => {
  if (Array.isArray(getProviderObj?.flumenResource)) {
    for (const resource of getProviderObj?.flumenResource) {
      let iframeLoadUrl;
      if (!resource?.url?.includes('https')) {
        iframeLoadUrl = resource?.url?.replace('http', 'https');
      } else {
        iframeLoadUrl = resource?.url;
      }
      if (resource?.provider === getProviderObj?.provider) {
        if (resource?.url?.indexOf('m3u') === -1) {
          dispatch(performLoadUrl(iframeLoadUrl));
          dispatch(streamDisplay(true));
        } else {
          const video = document.getElementById(`tv-${sKey}`);
          if (window.Hls && window.Hls.isSupported()) {
            let config = {
              xhrSetup: function (xhr) {
                xhr.withCredentials = !getAppConfig().disablewithcredentials;
              },
            };
            config = resource?.provider === 'sis' ? {} : config;
            const hls = new window.Hls(config);
            if (iframeLoadUrl) hls.loadSource(iframeLoadUrl);
            hls.attachMedia(video);
            dispatch(streamDisplay(true));
            hls.on(window.Hls.Events.ERROR, function (data) {
              if (data.fatal) {
                return this.destroy();
              }
            });
          } else if (video?.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = iframeLoadUrl;
            dispatch(streamDisplay(true));
            video.onerror = function () {
              const httpStatus = this && this.error && this.error.code === 4;
              if (httpStatus) {
                const MnHls = window[`hlsview${sKey}`];
                if (MnHls) MnHls();
                if (this.destroyStreamingFromMn) this.destroyStreamingFromMn();
                return this.destroy();
              }
            };
            video.onended = function () {
              const MnHls = window[`hlsview${sKey}`];
              if (MnHls) MnHls();
              if (this.destroyStreamingFromMn) this.destroyStreamingFromMn();
              return this.destroy();
            };
          }
        }
      }
    }
  }
};

/**
 * Method to sort eachway odds based on selection
 * @param { array } eventDetails
 * @param { string } sortType
 * @param { number } ind
 */
export const changeEachWayFilter = (
  eventDetails,
  sortType,
  ind
) => dispatch => {
  // Sort based on user selection
  let sortBy;
  if (sortType === 'EEW') {
    sortBy = 'SEW';
    for (const event of eventDetails) {
      if (!showAllRaceOutcomes) {
        event.eachwayFilter = sortBy;
      }
    }
  } else {
    sortBy = 'EEW';
    for (const event of eventDetails) {
      if (!showAllRaceOutcomes) {
        event.eachwayFilter = sortBy;
      }
    }
  }
  if (showAllRaceOutcomes) {
    eventDetails[ind].eachwayFilter = sortBy;
  }
  dispatch(getEventDetails(JSON.parse(JSON.stringify(eventDetails))));
  dispatch(setEventDetails(JSON.parse(JSON.stringify(eventDetails))));
};

// Method to dispatch loader
export const disableSpinLoader = () => dispatch => {
  dispatch(loadSpinner(false));
};

const composeEnhancers = getComposeEnhancers({ name: 'racing-events-store' });

export default initialState => {
  const state = JSON.parse(JSON.stringify(initialState));
  Object.keys(initialStateTemplate.horseRacingData).forEach(key => {
    if (state?.horseRacingData && state?.horseRacingData[key]) {
      initialStateTemplate.horseRacingData[key] = state.horseRacingData[key];
    }
  });
  Object.keys(initialStateTemplate.horseRacesList).forEach(key => {
    if (state?.horseRacesList && state?.horseRacesList[key]) {
      initialStateTemplate.horseRacesList[key] = state.horseRacesList[key];
    }
  });
  Object.keys(initialStateTemplate.futureRacesData).forEach(key => {
    if (state?.futureRacesData && state?.futureRacesData[key]) {
      initialStateTemplate.futureRacesData[key] = state.futureRacesData[key];
    }
  });
  return createStore(
    reducer,
    initialStateTemplate,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
};
// Get Country wise data
export const getCountryDropDown = (
  eventDetails,
  activeFilter,
  raceListingEventsObject
) => {
  let { countryCode } = eventDetails;
  if (countryCode === 'GB') {
    countryCode = 'UK';
  }
  if (!countryCode) {
    const eventName = eventDetails.racecorseName;
    const raceCourses = raceListingEventsObject?.[activeFilter].filter(r =>
      r.raceCoursesArray?.some(e => e.raceCourseName === eventName)
    );
    return raceCourses?.[0];
  }
  const countryDropdown = raceListingEventsObject?.[activeFilter]?.filter(
    raceListingEvent => {
      if (raceListingEvent?.country === countryCode) {
        return true;
      }
    }
  );
  // If there is no country after filtering return the first one
  if (!countryDropdown.length) {
    return raceListingEventsObject?.[activeFilter]?.[0];
  }
  return countryDropdown?.[0];
};

//filter raceCard on the basis of raceCourse and Country filter values get from CMS
export const filterByraceCourseAndCountry = races => {
  if (getAppConfig()?.racecardfilterbyracecourse) {
    const filteredRaceCourseNames = getAppConfig()?.racecardfilterbyracecourse?.split(
      ','
    );
    races = races?.filter(s =>
      filteredRaceCourseNames.includes(s?.racecorseName)
    );
  }
  if (getAppConfig()?.racecardfilterbycountry) {
    const filteredCountryNames = getAppConfig()?.racecardfilterbycountry?.split(
      ','
    );

    races = races?.filter(s => filteredCountryNames.includes(s?.countryCode));
  }
  return races;
};

//filter allRaces Details on the basis of raceCourse and Country value got from CMS
export const filterAllRacesDetailsList = races => {
  const racesDays = Object.keys(races);
  if (getAppConfig()?.allracesfilterbycountry) {
    const filteredCountryNames = getAppConfig()?.allracesfilterbycountry?.split(
      ','
    );

    racesDays.forEach(s => {
      races[s] = races[s]?.filter(q =>
        filteredCountryNames.includes(q?.country)
      );
    });
  }

  if (getAppConfig()?.allracesfilterbyracecourse) {
    const filteredRaceCourseNames = getAppConfig()?.allracesfilterbyracecourse?.split(
      ','
    );

    let filteredArray = [];
    racesDays.forEach(s => {
      races[s]?.forEach(q => {
        filteredArray = [];
        q?.raceCoursesArray.forEach(p => {
          if (filteredRaceCourseNames.includes(p?.raceCourseName)) {
            filteredArray.push(p);
          }
        });

        q.raceCoursesArray = filteredArray;
      });
    });
  }

  //if not getting any filtered raceCourse or raceCountry should deltete that key from the list
  racesDays.forEach(s => {
    if (races[s]?.length <= 0) {
      delete races[s];
    }

    const filteredArray = [];
    races[s]?.forEach(q => {
      if (!q?.raceCoursesArray.length <= 0) {
        filteredArray.push(q);
      }
    });
    races[s] = filteredArray;
  });

  return races;
};
