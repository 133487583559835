import {
  MI,
  TI,
  HI,
  OI,
  TIH,
  TIJ,
  TII,
  TBB,
  HIF,
  HIA,
  OIO,
  ED,
  TLD,
  HIAP,
  HV,
  OMD,
} from 'CORE__UI/apps/RacingEventsApp/core__raceNonRunners';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

const TIH_EX = styled(TIH)`
  color: ${GREYS.white};
`;

const TIJ_EX = styled(TIJ)`
  color: ${FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const HIF_EX = styled(HIF)`
  color: ${FONT.lightMute};
`;

const HIAP_EX = styled(HIAP)`
  color: ${FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const OIO_EX = styled(OIO)`
  color: ${FONT.lightMute};
  font-size: 12px;
  letter-spacing: 0.25px;
`;

const MI_EX = styled(MI)`
  border-bottom: 1px solid ${FONT.primary};
  &:last-of-type {
    border-bottom: none;
  }
`;

export {
  MI_EX as MI,
  TI,
  HI,
  OI,
  TIH_EX as TIH,
  TIJ_EX as TIJ,
  TII,
  TBB,
  HIF_EX as HIF,
  HIA,
  OIO_EX as OIO,
  ED,
  TLD,
  HIAP_EX as HIAP,
  HV,
  OMD,
};
