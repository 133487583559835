import {
  BCBD,
  BCBC,
  BCBS,
  L,
  S,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__ew';
import styled from 'styled-components';
import { FONT } from '../../../globals/colours';

const BCBDFK = styled(BCBD)`
  margin: 8px;
  border-radius: 4px;
`;
const BCBSFK = styled(BCBS)`
  color: ${FONT.lightMute};
`;
const SFK = styled(S)`
  background-color: ${FONT.primary} !important;
  border-color: ${FONT.primary} !important;
  &:after {
    background-color: ${FONT.primary} !important;
  }
`;
export { BCBDFK as BCBD, BCBC, BCBSFK as BCBS, L, SFK as S };
