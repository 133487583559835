import {
  SBSD,
  SBSU,
  SBSL,
  SBSLH6,
  SBSLH6B,
  SBSNPB,
  SBSPB,
  SpinnerObj,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__bottomSection';
import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS } from '../../../globals/colours';
import { BREAKPOINT_S } from '../../../globals/breakpoints';

const SBSDJN = styled(SBSD)`
  background-color: ${GREYS.silverD};
  border-color: ${BRAND.primary};
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const SBSLH6JN = styled(SBSLH6)`
  color: ${GREYS.white};
`;
const SBSLH6BJN = styled(SBSLH6B)`
  color: ${GREYS.white};
`;
const SBSNPBJN = styled(SBSNPB)`
  background-color: ${BRAND.highlight};
  color: ${FONT.light};
  border: none;
`;
const SBSPBJN = styled(SBSPB)`
  background-color: ${BRAND.highlight};
  border: none;
  color: ${FONT.light};
  ${({ active }) =>
    !active &&
    css`
      background-color: ${BRAND.highlight};
      color: ${GREYS.doveD};
      border-radius: 4px;
      border: none;
      cursor: not-allowed;
    `};
`;
export {
  SBSDJN as SBSD,
  SBSU,
  SBSL,
  SBSLH6JN as SBSLH6,
  SBSLH6BJN as SBSLH6B,
  SBSNPBJN as SBSNPB,
  SBSPBJN as SBSPB,
  SpinnerObj,
};
