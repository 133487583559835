import {
  BCD,
  BetslipContentBetBoxPot,
  RTNS,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__potReturn';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const BetslipContentBetBoxPotJN = styled(BetslipContentBetBoxPot)`
  color: ${GREYS.white};
`;
const RTNSJN = styled(RTNS)`
  color: ${GREYS.white};
`;
export {
  BCD,
  BetslipContentBetBoxPotJN as BetslipContentBetBoxPot,
  RTNSJN as RTNS,
};
