import React from 'react';
import PropTypes from 'prop-types';
import { BTL, BTSS, BTCB } from 'UI/apps/BetslipUKApp/BetslipContainer/Tab';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 * @param {array} tabs
 * @param {object} props
 */
export const BetslipTabsUK = ({ tabs, props, showClearBetslipTab }) => {
  return (
    <BTSS key={tabs[0].actTab}>
      <BTL c2={true} active={props.actTab === tabs[0].actTab}>
        {tabs[0].display}
      </BTL>
      {showClearBetslipTab && tabs[0].delete && (
        <BTCB
          onClick={() => {
            props.handleDelete({ type: 'deleteall' });
          }}
        >
          {Translations.get('text.clear')}
        </BTCB>
      )}
    </BTSS>
  );
};
BetslipTabsUK.propTypes = {
  tabs: PropTypes.array,
  props: PropTypes.shape({
    handleActTab: PropTypes.func,
    handleDelete: PropTypes.func,
    showMoreLess: PropTypes.func,
    actTab: PropTypes.string,
    isShowless: PropTypes.bool,
  }),
  handleActTab: PropTypes.func,
  handleDelete: PropTypes.func,
  showMoreLess: PropTypes.func,
  actTab: PropTypes.string,
  isShowless: PropTypes.bool,
  showClearBetslipTab: PropTypes.bool,
};

BetslipTabsUK.defaultProps = {
  showClearBetslipTab: true,
};
