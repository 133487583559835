import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Favourite horse info display main div
export const MI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  padding: 16px;
  &:last-child {
    border-bottom: 0;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px;
  }
`;
// Favourite name display main div
export const TI = styled.div`
  display: flex;
  align-items: center;
  flex: 3 1 0%;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
// Favourite name display div
export const TIH = styled.div`
  font-weight: 500;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Result page text display div
export const H = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
`;
// Horses/dogs final result display div
export const HR = styled.div`
  width: 140px;
  text-align: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Horses/dogs final dividend display div
export const HD = styled.div`
  width: 140px;
  text-align: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Horses/dogs forecast text display div
export const FR = styled.div`
  flex: 1 1 0%;
  font-weight: 700;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Results display main div
export const MD = styled.div`
  padding: 32px 0;
`;
// Non runners display main div
export const NR = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
// Non runners text display div
export const NRH = styled.div`
  padding-right: 16px;
  font-weight: 700;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Non runners names display div
export const NRR = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Odds info display div
export const OI = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
  }
`;
export const TII = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  width: 30px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Odds display main div
export const OMD = styled.div`
  display: flex;
  flex: 1.5;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column-reverse;
    flex: 2.6;
  }
`;
// History values display
export const HV = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.35;
  align-items: center;
}
`;
// Odds button display div
export const OB = styled.div`
  margin-right: 8px;
  flex: 1;
  &:last-of-type {
    margin-right: 0;
  }
  button {
    width: 100%;
  }
`;
