import React, { useEffect, useState } from 'react';
import {
  WSPOPUP,
  WSP,
  WSUL,
  WSLI1BTN,
  WSLI2BTN,
  TDIV,
  TLI,
} from 'UI/apps/BetslipUKApp/BetslipContainer/SocketMessage';
import PropTypes from 'prop-types';
import { detectMob, getDataOfPrice } from '../../../../../core__betslip-utils';
import { Constants } from '../../../../../core__betslip-constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { Translations } from '../../../../../core__betslip-UK-app';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';

/**
 * @param {Array} bet
 * @param {func} handleWsAcceptDecline,
 * @param {string} oddsFormat
 */

export const SocketMessage = ({
  bet,
  handleWsAcceptDecline,
  oddsFormat,
  hidebetslip,
}) => {
  const [ewprice, setEwprice] = useState([]);

  useEffect(() => {
    setEwprice(getDataOfPrice(bet.price));
  }, [bet]);

  useEffect(() => {
    const isMobile = detectMob();

    const betslipContainer = document.getElementById('betslip_container');
    const IFrameHeight = document.querySelector('main')?.clientHeight;

    if (
      betslipContainer &&
      IFrameHeight &&
      IFrameHeight < betslipContainer.clientHeight
    ) {
      if (hidebetslip && !isMobile)
        PubSub.emit(
          PubsubEvents.RESET_IFRAME_HEIGHT,
          betslipContainer.clientHeight
        );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <WSPOPUP>
        {oddsFormat === Constants.DECIMAL ? (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              parseDecimalPlaces(bet.previousPriceDecimal) +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              parseDecimalPlaces(ewprice['decimal'])}
          </WSP>
        ) : oddsFormat === Constants.AMERICAN ? (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              getOdds(bet.previousPriceDecimal, true) +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              getOdds(ewprice['decimal'], true)}
          </WSP>
        ) : (
          <WSP>
            {Translations.get('text.odd.change.from') +
              ' ' +
              bet.previousPriceFractional +
              ' ' +
              Translations.get('text.to') +
              ' ' +
              ewprice['fractional']}
          </WSP>
        )}

        <WSUL>
          <TLI>
            <WSLI1BTN
              onClick={() => {
                handleWsAcceptDecline('accept', bet);
              }}
            >
              {Translations.get('text.accept')}
            </WSLI1BTN>
          </TLI>
          <TLI>
            <WSLI2BTN
              onClick={() => {
                handleWsAcceptDecline('decline', bet);
              }}
            >
              {Translations.get('text.decline')}
            </WSLI2BTN>
          </TLI>
        </WSUL>
      </WSPOPUP>
      <TDIV></TDIV>
    </React.Fragment>
  );
};

SocketMessage.propTypes = {
  bet: PropTypes.object,
  handleWsAcceptDecline: PropTypes.func,
  oddsFormat: PropTypes.string,
  hidebetslip: PropTypes.bool,
};
