import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BS,
  SMM,
  SB,
  MDI,
  FDMD,
  BSM,
} from 'UI/apps/BetslipUKApp/BetslipContainer/BetSlipContainer';
import { Header } from './BetslipHeader';
import { BetslipRemainingBoosts } from './BetslipRemainingBoosts';
import { ContentTab } from './BetslipContent';
import { MessageComponent } from './BetslipError';
import { Summary } from './BetslipSummary';
import { OddMovements } from './BetslipOddMovements';
import { ShowExpand } from './BetslipContent/elements/core__showExpand';
import { detectMob } from '../../../core__betslip-utils';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { Constants } from '../../../core__betslip-constants';

/**
 @param {object} errorObj
 @param {array} betTemplate
 @param {object} UserAuthData
 @param {object} setErrorObj
 @param {object} properties
 */

export const BetSlipContainer = ({
  errorObj,
  betTemplate,
  UserAuthData,
  setErrorObj,
  properties,
  appConfig,
  countAccaBet,
}) => {
  const showMultipleExpandObj = properties.betTemplate?.find(
    bet => bet.tab && bet.lessMore
  );
  useEffect(() => {
    //pubsub emit to parent to calculate betslip view height
    if (appConfig?.hidebetslip) {
      PubSub.emit(PubsubEvents.SET_CONTENT_HEIGHT);
    }
  }, [betTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [showFB, setShowFB] = useState(false);
  const adjustBottomHeight = properties?.bottomNavData?.loginLinks;
  const betObj = betTemplate.find(item => !item.isValid);
  properties['setShowFB'] = setShowFB;

  //to check if bet exceeds max limit
  const checkMaxStakeExceeded = (err = {}) => {
    const errMsg = err?.message?.toLowerCase() || '';
    let checked = errMsg.includes(Constants.MAXSTAKE);
    if (checked) {
      const arr = err?.betMsg.split(',');
      const selection = betTemplate.find(bet => arr.includes(bet.betTypeRef));
      checked = selection && (selection.name || selection.text);
    }
    return checked;
  };

  const [isSafari, setisSafari] = useState(false);

  useEffect(() => {
    try {
      setisSafari(
        /Safari/.test(window?.navigator.userAgent) &&
          /Apple Computer/.test(window?.navigator.vendor)
      );
    } catch (error) {
      //no handling required
    }
  }, []);
  return (
    <BS isSafari={isSafari} openBS={properties.openBS}>
      <Header appConfig={appConfig} {...properties} />

      {betTemplate.length >= 0 && (
        <BSM id="betslip_view" openBS={properties.openBS} isSafari={isSafari}>
          <SB
            id="bet_content"
            openBS={properties.openBS}
            isMobile={detectMob()}
          >
            {betTemplate.length > 0 &&
              UserAuthData?.raw?.availableBetBoostTokens > 0 && (
                <BetslipRemainingBoosts {...properties} />
              )}
            {betTemplate.length > 0 && (
              <ContentTab
                {...properties}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                countAccaBet={countAccaBet}
                allowFreebetsOnEachway={appConfig.allowfreebetoneachway}
                displayMultiplesFirst={appConfig.displaymultiplesfirst}
                secondaryOddsBoostButton={appConfig?.secondaryoddsboostbutton}
              />
            )}
            {!appConfig.displaymultiplesfirst && (
              <ShowExpand
                showMultipleExpandObj={showMultipleExpandObj}
                showMoreLess={properties.showMoreLess}
                isShowless={properties.isShowless}
              />
            )}
            {/* when showOddMovements true for custodian */}
            {properties.showOddMovements && properties.isLogin && (
              <OddMovements
                handleActTabNotificationPopup={
                  properties.handleActTabNotificationPopup
                }
                notificationParam={properties.notificationParam}
                revokeScroll={appConfig?.revokescroll}
              />
            )}
          </SB>

          <SMM
            isSafari={isSafari}
            openBS={properties.openBS}
            id="betslip-bottom"
            adjustBottomHeight={adjustBottomHeight}
          >
            {(errorObj.isError === true && (
              <MessageComponent
                useChatWithBroker={appConfig.usechatwithbroker}
                isError={errorObj.isError}
                message={errorObj.message}
                betTemplate={betTemplate}
                setErrorObj={setErrorObj}
                isMaxBetExceed={checkMaxStakeExceeded(errorObj)}
              />
            )) ||
              (betTemplate.length > 0 &&
                properties.suspended === false &&
                errorObj.isError !== true &&
                betObj && (
                  <MessageComponent
                    useChatWithBroker={appConfig.usechatwithbroker}
                    betTemplate={betTemplate}
                    isError={false}
                    message=""
                    setErrorObj={setErrorObj}
                    isValidStake={betObj?.isValid}
                  />
                ))}

            {betTemplate.length > 0 && (
              <Summary
                appConfig={appConfig}
                isMaxBetError={checkMaxStakeExceeded(errorObj)}
                {...properties}
              />
            )}
          </SMM>
          <FDMD showFB={showFB}>
            <MDI id="freebetpopup"></MDI>
          </FDMD>
        </BSM>
      )}
    </BS>
  );
};
BetSlipContainer.propTypes = {
  properties: PropTypes.object,
  betTemplate: PropTypes.array,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  errorObj: PropTypes.object,
  setErrorObj: PropTypes.func,
  appConfig: PropTypes.object,
  countAccaBet: PropTypes.number,
  secondaryOddsBoostButton: PropTypes.bool,
};
