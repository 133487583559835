import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PubSub, PubsubEvents } from 'Services/core__services';
import {
  DChekBx,
  DS,
  DBTN,
  D,
  Ch5,
  CL,
  C,
} from 'UI/apps/BetslipUKApp/ReceiptContainer/BottomSection';
import { BetslipCurrency } from '../../../BetSlipContainer/BetslipContent/elements';
import { Translations } from '../../../../../core__betslip-UK-app';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { SpinnerObj } from 'UI/apps/BetslipUKApp/BetslipContainer/BottomSection';
import Spinner from 'UI/spinner/Spinner';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';

/**
 * BottomSection genrate total amount and retain option
 * @param betSlipRecipt {object}
 * @param handleReceptCloseButton {function}
 */

export const BottomSection = ({
  betSlipRecipt,
  handleReceptCloseButton,
  bottomRef,
  hideSpinnerOnReceipt,
  bottomNavData,
}) => {
  const [retainOption, setRetainOption] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const handleRetain = check => {
    setRetainOption(check);
    window.retainCheck = check;
    const checkVal = check ? ['CHECKED'] : ['UNCHECKED'];
    setterGetterFromLocalstorage({
      keyName: 'retainbettemplate',
      keyValue: checkVal,
      action: 'set',
    });
  };

  useEffect(() => {
    PubSub.emit(PubsubEvents.CLOSE_RECEIPT, true);
  }, []);

  useEffect(() => {
    window.retainCheck = false;
    setterGetterFromLocalstorage({
      keyName: 'retainbettemplate',
      keyValue: ['UNCHECKED'],
      action: 'set',
    });
    if (!enableButton) {
      setTimeout(() => {
        setEnableButton(true);
      }, 1500);
    }
  }, [enableButton]);

  useEffect(() => {
    window.retainBet = retainOption;
  }, [retainOption]);

  return (
    <D
      ref={bottomRef}
      id="betreceipt-bottom"
      bottomNavEnabled={bottomNavData?.loginLinks}
    >
      <label>
        <DChekBx
          type="checkbox"
          onChange={e => {
            handleRetain(e.target.checked);
          }}
        />
        <DS>{Translations.get('text.retain.selections.on.the.betslip')}</DS>
      </label>
      <C>
        <CL>
          <Ch5>{Translations.get('text.remaining.balance')}</Ch5>
        </CL>
        <CL>
          <Ch5>
            <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
            {parseDecimalPlaces(betSlipRecipt?.balance)}
          </Ch5>
        </CL>
      </C>
      {(enableButton && (
        <DBTN
          type="button"
          onClick={() => handleReceptCloseButton(retainOption)}
          disable={false}
        >
          {Translations.get('text.close.receipt')}
        </DBTN>
      )) || (
        <DBTN disable={true}>
          {!hideSpinnerOnReceipt && (
            <Spinner display={true} width={24} style={SpinnerObj} />
          )}
          {Translations.get('text.close.receipt')}
        </DBTN>
      )}
    </D>
  );
};
BottomSection.propTypes = {
  bottomRef: PropTypes.any,
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  hideSpinnerOnReceipt: PropTypes.bool,
  bottomNavData: PropTypes.object,
};
