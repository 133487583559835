import styled from 'styled-components';
import { CARD, BRAND, FONT, GREYS, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import EA from 'UI/globals/ExpandArrow';

// Main div to display layout
const CARD_DIV = styled.div(CARD);
export const MD = styled(CARD_DIV)`
  /* new style - race card 
     remove local styles related to this
     (if needed)
  */
  box-shadow: none;
  background: none;

  &:empty {
    box-shadow: none;
  }
`;

// Displays race tip/title
export const RP = styled.p`
  margin-top: 16px;
  margin-bottom: 6px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Race course info display main div
export const RIDM = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;
// Race course name display div
export const RID = styled.div`
  font-weight: bold;
  color: ${BRAND.primary};
`;
// Race started text display div
export const RIR = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.dark ? FUNCTIONAL.warningD : FUNCTIONAL.warningL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  border: 1px solid ${FUNCTIONAL.warning};
  font-weight: 500;
`;
// UL to display horse/dog info
export const IU = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 8px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 0px;
  }
`;
// IL to display horse/dog info
export const IL = styled.li`
  display: flex;
  align-items: flex-start;
  margin-right: 8px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  b {
    margin-right: 16px;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 4px;
  }
`;
// ILM to display horse/dog info in margin
export const ILM = styled.div`
  margin-right: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 45px;
  }
`;
// Main div to display outcomes
export const MI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  &:nth-child(2) {
    border-top: ${props => props?.isResults && '1px solid' + BRAND.tertiary};
  }
  padding: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px;
    position: relative;
    padding-bottom: 24px;
  }
  &:last-of-type {
    border: ${({ theme }) => theme.dark && `none`};
  }
`;
// Trainer info display div
export const TI = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  justify-content: flex-start;
`;
// Horse info display div
export const HI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 200px;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    position: absolute;
    bottom: -2px;
    left: 12px;
  }
`;
// Odds info display div
export const OI = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: unset;
  }
`;
// Horse name display div
export const TIH = styled.div`
  font-weight: 500;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Jockey name display div
export const TIJ = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
`;
// Horse silk image display div
export const TII = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  width: 30px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  img {
    width: 36px;
    padding-bottom: 8px;
  }
`;
// Dogs position display div
export const TBB = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  justify-content: center;
  border: 1px solid
    ${props =>
      !props?.positionStyle?.color &&
      !props?.positionStyle?.backgroundColor &&
      props?.apptheme === 'dark'
        ? GREYS.white
        : GREYS.black};
  background-color: ${props => props?.positionStyle?.backgroundColor};
  color: ${props =>
    props?.positionStyle?.color || props?.positionStyle?.backgroundColor
      ? props?.positionStyle?.color
      : props?.apptheme === 'dark'
      ? GREYS.white
      : GREYS.black};
  border-color: ${props => props?.positionStyle?.borderColor};
  margin-right: 8px;
  background-image: ${props =>
    props?.positionStyle?.backgroundImage &&
    props?.positionStyle?.backgroundImage};
`;
// Horse last runs display div
export const HIF = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  min-width: 100px;
  max-width: 100px;
  word-break: break-all;
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: unset;
    max-width: unset;
    margin-right: 8px;
  }
`;
// Horse age display div
export const HIA = styled.div`
  color: ${GREYS.silver};
  min-width: 100px;
  max-width: 100px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    min-width: unset;
    max-width: unset;
    padding-right: 4px;
  }
`;
// Horse/dogs odds display div
export const OIO = styled.div`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  margin-right: 8px;
  text-align: right;
`;
// Main div to display horse/jockey/trainer info
export const ED = styled.div`
  color: ${FONT.dark};
  position: relative;
`;
// To get border layout
export const EDB = styled(ED)`
  /* new style - race card */
  border: none;
  /* new style - race card */
  border-block: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  padding-right: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-right: 0;
  }
`;
// Results page div
export const RS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.dark ? FUNCTIONAL.positiveD : FUNCTIONAL.positiveL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  border: 1px solid ${FUNCTIONAL.positive};
  font-weight: 500;
`;
// Race abandoned div
export const RA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${FUNCTIONAL.negativeL};
  width: 77px;
  height: 24px;
  border-radius: 4px;
  color: ${GREYS.black};
  border: 1px solid ${FUNCTIONAL.negative};
  font-weight: 500;
`;
// Fractional odds display div
export const OIF = styled.span``;
// Horses/dogs outcomes main div
export const HRI = styled.div`
  padding: 16px 16px 6px 16px;
  /* new style - race card 
     change local style (if needed)
  */
  background-color: ${GREYS.white};
`;
// Horses/dogs race streaming main div
export const RST = styled.div`
  display: flex;
  align-items: center;
`;
// Outcomes display top level div
export const TLD = styled.div`
  display: flex;
  align-items: center;
  flex: 3 1 0%;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
// Horse age and weight display paragraph
export const HIAP = styled.p`
  color: ${({ theme }) => theme.dark && FONT.lightMute};
  word-break: break-all;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
// Odds button display div
export const OB = styled.div`
  margin-right: 16px;
  flex: 1;
  &:last-of-type {
    margin-right: 20px;
  }
  button {
    width: 100%;
  }
`;
// Horses/dogs market name
export const HM = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  /* new style - race card 
     change local (if needed) */
  border-top: none;
  background-color: ${GREYS.warning};
`;
// Horses/dogs market name
export const H = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: 3;
  }
`;
// Horses/dogs market name
export const HR = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex: 1;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
  }
`;
// EW market text display tag
// Color not available in globals
export const HRB = styled.div`
  display: inline-block;
  color: ${({ theme }) => (theme.dark ? FONT.primary : GREYS.black)};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
  }
`;
// Horses/dogs market name
export const HD = styled.div`
  text-align: center;
  flex: 1;
  cursor: pointer;
`;

export const EAEP = styled(EA)`
  margin: 2px;
  color: ${GREYS.black};

  &:after {
    border-color: ${GREYS.black};
  }
`;

// Osiros main div to display selection
export const SD = styled.div`
  color: ${FONT.dark};
  margin-top: 16px;
  position: relative;
  flex: 0.25;
`;

// History values display
export const HV = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.35;
  align-items: center;
`;

// Main div to display watch button
export const WB = styled.div`
  margin-right: 8px;
  padding-top: 4px;
  cursor: ${({ info }) => !info && 'pointer'};
  color: ${({ theme }) => theme.dark && FONT.secondary};
`;

// Outcomes sorting filter div
export const OT = styled.p`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  height: 32px;
  min-width: 70px;
  font-size: 12px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

// Odds display main div
export const OMD = styled.div`
  display: flex;
  flex: 1.5;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column-reverse;
    flex: 2.6;
  }
`;

export const SO = styled.div`
  border-radius: 4px;
  background-color: ${BRAND.primary};
`;

export const ST = styled.p`
  color: ${FONT.light};
  line-height: 20px;
  text-align: center;
  width: fit-content;
  padding-inline: 4px;
`;

//for streaming icon
export const STC = styled.p`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1em;
  justify-content: space-between;
  background-color: ${BRAND.secondary};
  border-radius: 4px;
  & span {
    padding-left: 0.5em;
    color: ${GREYS.black};
  }
  & i {
    color: ${GREYS.black};
  }
`;

//for streaming
export const STS = styled.span`
  font-size: 14px;
`;

// winning distance
export const WD = styled.div`
  font-size: 12px;
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : FONT.darkMute)};
  margin-top: 4px;
  text-align: center;
`;
export const CourseMetaImg = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
export const RaceInfo = styled.div`
  text-align: right;
  margin-top: -8px;
  p {
    margin-bottom: 10px;
  }
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : FONT.darkMute)};
  @media (max-width: ${BREAKPOINT_S}) {
    text-align: left;
    margin-bottom: 8px;
    margin-top: 10px;
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;
export const PlaceTermsBox = styled.div`
  margin-right: 12px;
  b {
    margin-right: 4px;
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 10px;
    width: 60px;
  }
`;
