import React from 'react';
import RaceListingPage from './components/RaceListingPage';
import RaceOutcomesPage from './components/RaceOutcomesPage';
import { FutureRacingOutcomes } from './components/FutureRacesOutcomes';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';
import {
  FSBCustomerSession,
  PubSub,
  PubsubEvents,
} from 'Services/core__services';

class RaceEventsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props.theme,
    };
  }
  componentDidMount() {
    const UserAuthData = FSBCustomerSession.getSession();
    if (UserAuthData && UserAuthData['accessToken']) {
      this.props.sessionData(UserAuthData);
    }

    // Pubsub event listen for any update in the session
    this.subSessionUpdated = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      wsData => {
        if (wsData?.accessToken) {
          // Function for sending the updated session data of customer
          this.props.sessionData(wsData);
        }
      }
    );
    // Pubsub event listen when the session is destroyed
    this.subSessionDestroyed = PubSub.listen(
      PubsubEvents.SESSION_DESTROY,
      () => {
        // Function for deleting session data of customer
        this.props.sessionData(null);
      }
    );
    // Pubsub event listen when the session is being created
    this.subSessionCreate = PubSub.listen(
      PubsubEvents.SESSION_CREATE,
      wsData => {
        // Function for sending the session data of customer
        this.props.sessionData(wsData);
      }
    );
    //listen to pubsub for odds change
    this.oddsValueListener = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      this.props.getOddsFormat(wsData);
    });
  }

  componentWillUnmount() {
    this.subSessionUpdated?.unsubscribe();
    this.subSessionDestroyed?.unsubscribe();
    this.subSessionCreate?.unsubscribe();
    this.oddsValueListener?.unsubscribe();
  }
  render() {
    const { isShowRaceListPage, isShowFutureOutcomesPage } = this.props;
    return (
      <FSBTheme theme={this.state.theme}>
        {isShowFutureOutcomesPage ? (
          <FutureRacingOutcomes {...this.props} />
        ) : isShowRaceListPage ? (
          <RaceOutcomesPage {...this.props} />
        ) : (
          <RaceListingPage {...this.props} />
        )}
      </FSBTheme>
    );
  }
}
RaceEventsApp.propTypes = {
  isShowRaceListPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isShowFutureOutcomesPage: PropTypes.bool,
  theme: PropTypes.string,
  sessionData: PropTypes.func,
  getOddsFormat: PropTypes.func,
};

export default RaceEventsApp;
