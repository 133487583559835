import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const OutcomesWrapperDiv = styled.div`
  font-weight: 500;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  padding: 0 16px;
  margin-left: 48px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 0 8px;
  } ;
`;

export const OutcomesFlex = styled.div`
  padding-bottom: ${props => (props?.isContent ? '4px' : '0px')};
  margin-right: 2px;
  display: flex;
  &:last-of-type {
    padding-bottom: 16px;
    margin-left: 16px;
  }
  span:last-child {
    margin-top: 2px;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 12px;
    flex-direction: row;
  } ;
`;

export const OutcomesContainer = styled.div`
  display: flex;
  margin-right: 5px;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-left: 16px;
  span {
    margin-right: 5px;
    text-transform: capitalize;
    font-weight: 600;
  }
`;
export const OutcomesFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 16px 0;
`;
export const OT = styled.div`
  line-height: 14px;
  font-weight: 800;
  margin-right: 2px;
`;
export const OV = styled.div`
  font-weight: 300;
  line-height: 14px;
  margin-left: 2px;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
  } ;
`;
export const Star = styled.img`
  width: 12px;
  height: 12px;
`;
export const Tick = styled.img`
  height: 12px;
  width: 12px;
  margin-top: 2px;
  margin-right: 2px;
`;
