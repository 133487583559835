import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import drawnPosition from '../../helper/position-color.json';
import {
  MI,
  TI,
  HI,
  OI,
  TIH,
  TIJ,
  TII,
  TBB,
  HIA,
  OIO,
  ED,
  TLD,
  HIAP,
  HV,
  OMD,
} from 'UI/apps/RacingEventsApp/RaceNonRunners';
import { Translations } from '../../../core__racing-events-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getAppConfig as getAppConfig_LOCAL } from '../../helper/racingAppConfig';
import { getAppConfig as getAppConfig_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import project from '../../../../../../project';
import { Silk } from 'CORE__UI/globals/Silk/core__silk';

const getAppConfig = getAppConfig_LOCAL || getAppConfig_CORE;

/**
 * this component dispays non runners
 * @param {*} eventDetails array to get all non runners
 * @param {*} requestPage string to get requested page
 * @param {*} oddsFormat string to get odds type
 * */

export class RaceNonRunners extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { eventDetails, requestPage, oddsFormat, theme } = this.props;
    return (
      <React.Fragment>
        {(eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED ||
          !Object.prototype.hasOwnProperty.call(eventDetails, 'results')) &&
          eventDetails.status !== 'A' &&
          eventDetails?.market.length > 0 &&
          eventDetails?.market.map(marketName => {
            return (
              marketName?.name === eventDetails.selectedRaceMarket &&
              marketName?.selection?.map((outcomes, oIndex) => {
                const { silkSvg, silk } = outcomes;
                const imageUrl = silkSvg || silk;
                return (
                  <React.Fragment key={oIndex}>
                    {outcomes.nonRunner && (
                      <MI addDisabled={outcomes.nonRunner}>
                        {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
                          <TII>
                            {imageUrl && (
                              <Silk
                                alt={outcomes.name || 'outcomes'}
                                src={
                                  project.racingSilksUrl
                                    ? `${project.racingSilksUrl}${imageUrl}`
                                    : `https://silks.fsbtech.com/${imageUrl}`
                                }
                              />
                            )}
                            <div>
                              {outcomes.drawn &&
                                outcomes.drawn !== 'null' &&
                                '(' + outcomes.drawn + ')'}{' '}
                            </div>
                          </TII>
                        ) : (
                          outcomes.ordinal &&
                          marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV && (
                            <TBB
                              positionStyle={
                                drawnPosition &&
                                drawnPosition['__' + outcomes.ordinal]
                              }
                              apptheme={theme}
                            >
                              {outcomes.ordinal}
                            </TBB>
                          )
                        )}
                        <TLD>
                          <TI>
                            <ED>
                              {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
                                <TIH>
                                  {outcomes.ordinal &&
                                    marketName?.typeRef !==
                                      GLOBAL_CONSTANTS.UFAV &&
                                    outcomes.ordinal}{' '}
                                  {outcomes.name}
                                </TIH>
                              ) : (
                                <TIH>{outcomes.name}</TIH>
                              )}
                              {outcomes?.trainer && (
                                <TIJ>T: {outcomes.trainer}</TIJ>
                              )}
                              {outcomes?.jockey && (
                                <TIJ>J: {outcomes.jockey}</TIJ>
                              )}
                            </ED>
                          </TI>
                          <HI>
                            {marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV && (
                              <HIA>
                                <HIAP>
                                  {outcomes?.lastRuns &&
                                    (getAppConfig()?.istranslated
                                      ? Translations.get('racing.form') + ':'
                                      : 'Form:')}
                                </HIAP>
                                <HIAP>
                                  {outcomes?.lastRuns && outcomes.lastRuns}
                                </HIAP>
                              </HIA>
                            )}
                            <HIA>
                              {outcomes.age && (
                                <HIAP>
                                  {getAppConfig()?.istranslated
                                    ? Translations.get('racing.age') + ':'
                                    : 'Age:'}{' '}
                                  {outcomes.age}
                                </HIAP>
                              )}
                              {outcomes.weight && (
                                <HIAP>{outcomes.weight}</HIAP>
                              )}
                            </HIA>
                          </HI>
                        </TLD>
                        <OMD>
                          <HV>
                            {marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
                            outcomes?.historicalOddsValues?.length > 1 ? (
                              <React.Fragment>
                                <OIO>
                                  {oddsFormat?.toLowerCase() ===
                                  GLOBAL_CONSTANTS.DECIMAL
                                    ? outcomes.historicalOddsValues[1].decimal
                                    : outcomes.historicalOddsValues[1]
                                        .fractional}
                                </OIO>
                                <OIO>
                                  {oddsFormat?.toLowerCase() ===
                                  GLOBAL_CONSTANTS.DECIMAL
                                    ? outcomes.historicalOddsValues[0].decimal
                                    : outcomes.historicalOddsValues[0]
                                        .fractional}
                                </OIO>
                              </React.Fragment>
                            ) : (
                              marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
                              outcomes?.historicalOddsValues?.length > 0 && (
                                <OIO>
                                  {oddsFormat?.toLowerCase() ===
                                  GLOBAL_CONSTANTS.DECIMAL
                                    ? outcomes.historicalOddsValues[0].decimal
                                    : outcomes.historicalOddsValues[0]
                                        .fractional}
                                </OIO>
                              )
                            )}
                          </HV>
                          <OI isEW={marketName?.selectionEW?.length > 0}>
                            <OIO>{Translations.get('racing.non.runner')}</OIO>
                          </OI>
                        </OMD>
                      </MI>
                    )}
                  </React.Fragment>
                );
              })
            );
          })}
      </React.Fragment>
    );
  }
}

RaceNonRunners.propTypes = {
  eventDetails: PropTypes.object,
  requestPage: PropTypes.string,
  oddsFormat: PropTypes.string,
  theme: PropTypes.string,
};

export const mapStateToProps = state => {
  return {
    eventData: state.horseRacesList.eventData,
    requestPage: state.horseRacingData.requestPage,
    oddsFormat: state.horseRacingData.oddsFormat,
  };
};

export const mapDispatchToProps = {};

RaceNonRunners.defaultProps = {
  requestPage: '',
  oddsFormat: '',
};

export const RaceNR = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceNonRunners);
