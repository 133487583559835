import {
  OD,
  ODUL,
  TL,
  TS,
  Button,
  MO,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__oddMovements';
import { BREAKPOINT_M } from 'CORE__UI/globals/core__breakpoints';
import styled from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

const ODULJK = styled(ODUL)`
  color: ${GREYS.white};
  padding: 16px 12px;
  @media (max-width: ${BREAKPOINT_M}) {
    padding-bottom: 280px;
  }
`;

const Button_JK = styled(Button)`
  color: ${GREYS.white};
`;
const TL_JK = styled(TL)`
  color: ${({ isSelected }) => (isSelected ? FONT.light : FONT.lightMute)};
`;

export { OD, ODULJK as ODUL, TL_JK as TL, TS, Button_JK as Button, MO };
