import React from 'react';
import PropTypes from 'prop-types';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';
import { Constants } from './core__betslip-constants';
import moment from 'moment';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';
import { capitalizeFirstLetter } from 'Services/globalfunctions/core__global-functions';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import project from '../../../project';
import {
  parseDecimalPlaces,
  parseDecimalPlacesPotReturn,
} from 'Services/global/core__odds-format';
import { Translations } from './core__betslip-UK-app';
import { getOdds } from 'Services/global/core__american-format';

/** 
for geting correct selectionIDs
@param localstorage object
*/

export const getSelectionIds = localStorageData => {
  let bsid = '';
  let bbid = '';
  if (localStorageData && localStorageData.length > 0) {
    bsid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] !== Constants.BBB &&
          (isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);

    bbid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] === Constants.BBB &&
          (!isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);
  }
  return { bsid, bbid };
};
/**
for geting correct priceIds
@params localstorage object
*/

export const getPriceIds = localStorageData =>
  (localStorageData &&
    localStorageData
      .map(item => {
        if (
          item &&
          typeof item['price'] !== 'undefined' &&
          item.betTypeRef !== Constants.BBB
        ) {
          return item?.priceId || item['price'][0]['id'];
        }
      })
      .filter(item => item)) ||
  '';

/**
get single data of selection bet
@params : dynmic id
*/
export const localstorageSelectedIddata = (id, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });
  if (localData) {
    const data = localData.filter(itm => itm['id'] === id);
    if (data.length > 0) {
      return data[0];
    } else {
      return {};
    }
  } else return {};
};

/** 
this function update when user reload or reopen page old data is show 
if data in not Avalable then default data which is comming to api is set 
@params betTemplate
*/
export const mergeDataWithLocalStorage = (betTemplate, localStorageKey) => {
  const { wallet, raw } = FSBCustomerSession.getSession();
  let freebetsIds = [];
  if (Array.isArray(wallet)) {
    freebetsIds = wallet.map(w => w.id).filter(d => d);
  }

  for (let i = 0; i < Object.keys(betTemplate).length; i++) {
    const id = betTemplate[i]['id'];

    let { price, betModifier } = betTemplate[i];
    let getDataOfPrice = {};
    const OldDatafromLS = localstorageSelectedIddata(id, localStorageKey);
    if (price) {
      price = price[0];
      getDataOfPrice = {
        priceBookId: price?.bookId || '',
        priceBookType: price?.bookType || '',
        priceCurrent: price?.current || '',
        priceId: price?.id || '',
        priceChange: false,
        priceDecimal: price?.decimal || '',
        priceFractional: price?.fractional || '',
      };
    }
    let getDataofModifier = {};
    if (betModifier) {
      betModifier = OldDatafromLS.betModifierFlag
        ? betModifier[1]
        : betModifier[0];
      getDataofModifier = {
        betModifierBetCount: betModifier?.betCount || '',
        betModifierType: betModifier?.type || '',
        fractionalReturns: betModifier?.fractionalReturns || '',
        betModifierReturns: betModifier?.returns || '',
        boostedFractionalReturns: betModifier?.boostedFractionalReturns || '',
        boostedReturns: betModifier?.boostedReturns || '',
      };
    }
    const bet = betTemplate[i];
    bet['id'] = OldDatafromLS?.id || id;
    bet['isValid'] = OldDatafromLS.isValid;
    bet['status'] = true;
    bet['ordinal'] = (bet.ordinal && bet.ordinal) || OldDatafromLS?.ordinal;
    bet['_returns'] = OldDatafromLS?._returns || '';
    bet['_returnsFormatted'] = OldDatafromLS?._returnsFormatted || '';
    bet['errorStakeNotification'] = OldDatafromLS?._returnsFormatted || false;
    bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
    bet['returnsText'] = OldDatafromLS?.returnsText || '';

    const { betCount } = bet.betModifier?.[0] || [];
    const { betCount: oldDataBetCount } = OldDatafromLS.betModifier?.[0] || [];

    if (bet?.multiple && betCount !== oldDataBetCount) {
      bet['totalStake'] = '';
      bet['userStake'] = '';
    } else {
      bet['totalStake'] = OldDatafromLS?.totalStake || '';
      bet['userStake'] = OldDatafromLS?.userStake || '';
    }

    if (OldDatafromLS?.bbFlag) {
      bet['priceDecimal'] =
        OldDatafromLS?.priceDecimal || getDataofModifier.boostedReturns;
      bet['priceFractional'] =
        OldDatafromLS?.priceFractional ||
        getDataofModifier.boostedFractionalReturns;
    } else {
      bet['priceDecimal'] =
        OldDatafromLS?.priceDecimal || getDataOfPrice.priceDecimal;
      bet['priceFractional'] =
        OldDatafromLS?.priceFractional || getDataOfPrice.priceFractional;
    }
    bet['bbFlag'] = OldDatafromLS?.bbFlag || false;
    bet['hasFreebet'] = OldDatafromLS?.hasFreebet || false;
    bet['selectedFreebetId'] = OldDatafromLS?.selectedFreebetId || '';
    bet['freebetCredit'] = OldDatafromLS?.freebetCredit || '';
    bet['freebetCreditFormatted'] = OldDatafromLS?.freebetCreditFormatted || '';
    bet['freebetRestrictions'] = OldDatafromLS?.freebetRestrictions || '';
    bet['bonusFunds'] = OldDatafromLS?.bonusFunds || '';
    bet['priceBookId'] =
      OldDatafromLS?.priceBookId || getDataOfPrice.priceBookId;
    bet['priceBookType'] =
      OldDatafromLS?.priceBookType || getDataOfPrice.priceBookType;
    bet['priceCurrent'] =
      OldDatafromLS?.priceCurrent || getDataOfPrice.priceCurrent;
    bet['priceId'] = OldDatafromLS?.priceId || getDataOfPrice.priceId;
    bet['priceChange'] =
      OldDatafromLS?.priceChange || getDataOfPrice.priceChange;
    bet['betModifierBetCount'] =
      getDataofModifier?.betModifierBetCount ||
      OldDatafromLS.betModifierBetCount;
    // for horse and dog EW flag

    if (OldDatafromLS.betModifierFlag) {
      bet['betModifierBetCount'] = OldDatafromLS.betModifierBetCount;
    }

    if (bet['betTypeRef'] === 'SGL') {
      bet['castPosition'] = OldDatafromLS?.castPosition || i + 1;
    }
    bet['betModifierType'] =
      OldDatafromLS?.betModifierType || getDataofModifier.betModifierType;
    bet['betModifierFlag'] = OldDatafromLS?.betModifierFlag || false;
    bet['spFlag'] = OldDatafromLS?.spFlag || false;
    bet['betModifierFractional'] =
      OldDatafromLS?.betModifierFractional ||
      getDataofModifier.fractionalReturns;
    bet['betModifierReturns'] =
      OldDatafromLS?.betModifierReturns || getDataofModifier.betModifierReturns;
    bet['priceChangeNotification'] =
      OldDatafromLS.priceChangeNotification || false;
    bet['previousPriceDecimal'] =
      (OldDatafromLS?.updateNewPrice && bet?.previousPriceDecimal) ||
      OldDatafromLS?.previousPriceDecimal ||
      '';
    bet['previousPriceFractional'] =
      (OldDatafromLS?.updateNewPrice && bet?.previousPriceFractional) ||
      OldDatafromLS?.previousPriceFractional ||
      '';

    bet['notificationPriceChangeType'] = bet?.notificationPriceChangeType || '';
    bet['updateNewPrice'] = OldDatafromLS?.updateNewPrice || false;
    // if ws data is true
    if (bet.updateNewPrice) {
      bet['priceDecimal'] = OldDatafromLS?.bbFlag
        ? parseDecimalPlaces(getDataofModifier.boostedReturns)
        : parseDecimalPlaces(getDataOfPrice.priceDecimal);
      bet['priceFractional'] = OldDatafromLS?.bbFlag
        ? getDataofModifier.boostedFractionalReturns
        : getDataOfPrice.priceFractional;
      bet['priceId'] = getDataOfPrice.priceId;
    }

    if (!bet['hasFreebet'] && bet.betModifierFlag && bet.userStake) {
      const { betModifier } = bet;
      if (bet.betModifierFlag) {
        let p1 = betModifier.filter(bmf => {
          return bmf.type === 'EACHWAY';
        });
        p1 = p1[0];
        const tempBet = parseFloat(bet.userStake);
        const retAmt = tempBet * p1.returns;
        bet['_returns'] = retAmt;
        bet['_returnsFormatted'] = retAmt;
      }
    }

    if (bet['multiple'] && bet['userStake']) {
      let retAmt = '';
      const tempBet = parseFloat(bet.userStake);
      retAmt = tempBet * getDataofModifier.betModifierReturns;
      bet['_returns'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
    }

    // when we successfully post free bet and in wallet id not comes we need to remove applyed freebet amount bet
    if (bet['hasFreebet'] && !freebetsIds.includes(bet['selectedFreebetId'])) {
      bet['hasFreebet'] = false;
      bet['selectedFreebetId'] = '';
      bet['freebetCredit'] = '';
      bet['freebetCreditFormatted'] = '';
      bet['_returns'] = '';
      bet['_returnsFormatted'] = '';
    } else if (bet['hasFreebet'] && bet['updateNewPrice']) {
      // when user update his price with ws
      const freebetRtn = bet['freebetCredit'] * bet['priceDecimal'];
      bet['_returns'] = freebetRtn - bet['freebetCredit'];
      bet['_returnsFormatted'] = freebetRtn - bet['freebetCredit'];
    }
    // if user logout and bbflag selected
    if (
      typeof raw === 'undefined' &&
      !raw?.availableBetBoostTokens &&
      bet['bbFlag']
    ) {
      bet['bbFlag'] = false;
      bet['priceDecimal'] = getDataOfPrice['priceDecimal'];
      bet['priceFractional'] = getDataOfPrice['priceFractional'];
    }

    if (!bet['userStake'] && !bet['freebetCredit']) {
      bet['_returns'] = '';
      bet['_returnsFormatted'] = '';
    }

    if (bet.betTypeRef === Constants.BBB) {
      let retAmt = '';
      if (bet.hasFreebet) {
        retAmt = bet.price * bet.freebetCredit;
        retAmt = retAmt - bet.freebetCredit;
      } else {
        retAmt = parseFloat(bet.userStake) * bet.price;
      }
      bet['returnsText'] = retAmt;
      bet['_returns'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
    }

    const builderLocalData = setterGetterFromLocalstorage({
      keyName: 'buideruseData',
      action: 'get',
    });
    if (Array.isArray(builderLocalData) && builderLocalData.length > 0) {
      for (const bd of builderLocalData) {
        if (bet.id === bd.id) {
          let retAmt = '';
          if (bet['hasFreebet']) {
            bet['freebetCredit'] = bd.freebetCredit;
            bet['freebetCreditFormatted'] = bd.freebetCreditFormatted;
            bet['hasFreebet'] = bd.hasFreebet;
            bet['selectedFreebetId'] = bd.selectedFreebetId;
            const tempBet = parseFloat(bd.freebetCredit);
            retAmt = tempBet * bet.price;
            retAmt = retAmt - tempBet;
          } else {
            const tempBet = parseFloat(bet.userStake);
            retAmt = tempBet * bet.price;
          }
          bet['returnsText'] = retAmt;
          bet['_returns'] = retAmt;
          bet['_returnsFormatted'] = retAmt;
          bet['totalStake'] = bd.totalStake;
          bet['userStake'] = bd.userStake;
        }
      }
    }
  }
  return betTemplate;
};
/**
genrate it merger localstorage data to Main api Data with active flag 
if bet is inactive flag become active = false
@params betTemplate
*/
export const checkSuspendedBet = (betTemplate, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  betTemplate.forEach(nb => {
    const existingSelection = localData.find(l => l.id === nb.id);
    if (!existingSelection) {
      nb.addedToBetslipAt = new Date().getTime();
      nb.addedToBetslipDate = moment(nb.addedToBetslipAt).format(
        'YYYY-DD-MM HH:mm:ss'
      );
    } else {
      nb.addedToBetslipAt = existingSelection.addedToBetslipAt;
      nb.addedToBetslipDate = existingSelection.addedToBetslipDate;
    }
  });

  let returnBets = betTemplate;
  let isSuspended = false;

  if (betTemplate.length === 0 && localData.length > 0) {
    const tempBet = [];
    for (const bet of localData) {
      bet['active'] = false;
      tempBet.push(bet);
    }
    returnBets = tempBet;
  } else if (betTemplate.length > 0 && localData.length > 0) {
    const suspendedBet = localData.filter(
      ({ id: id1 }) => !betTemplate.some(({ id: id2 }) => id2 === id1)
    );

    if (suspendedBet.length > 0) isSuspended = true;

    for (const s_bet of suspendedBet) {
      if (s_bet['betTypeRef'] === 'SGL') {
        s_bet['active'] = false;
        betTemplate.push(s_bet);
      }
    }
  }

  return isSuspended
    ? returnBets.sort(bet => (bet.multiple ? 1 : -1))
    : returnBets;
};

/**
 * filter out selections that are more than 3 hours in betslip and are suspended
 * @param {array} selections
 */
export const filterSuspendedSelections = selections => {
  return selections.filter(item => {
    let isSuspended = false;

    if (
      item.active === false &&
      item['addedToBetslipAt'] !== 'undefined' &&
      Number(item['addedToBetslipAt']) + 3 * 60 * 60 * 1000 <
        Number(new Date().getTime())
    )
      isSuspended = true;
    return !isSuspended;
  });
};

/** 
Placing bet logic 
that make user
@params betTemplate
*/
export const placingBetUser = betTemplate => {
  const priceIds = getPriceIds(betTemplate);
  const priceIdsCombine = getPriceIdsbookType(betTemplate, 'SP');
  const priceIdsSCT = getPriceIdsbookType(betTemplate, null);

  const placeBetArr = [];

  for (const bet of betTemplate) {
    const { betModifier, price } = bet;
    const initalObj = {
      priceIds: '',
      betTypeRef: '',
      unitStake: '',
      totalStake: '',
      betModifier: '',
      betBoost: false,
      bonusFunds: false,
    };
    let type = betModifier[0] ? betModifier[0]['type'] : 'WIN';
    if (bet.betModifierFlag) {
      type = betModifier[1] ? betModifier[1]['type'] : 'WIN';
    }
    if (bet.betTypeRef === Constants.SGL) {
      const priceId =
        bet.priceBookType === 'SP' && price.length >= 2
          ? price[1]['id']
          : price[0]['id'];
      initalObj.priceIds = [priceId];
      initalObj.betTypeRef = bet.betTypeRef;
      initalObj.betModifier = bet?.betModifierType || type;
      initalObj.betBoost = bet.bbFlag ? bet.bbFlag : false;
      if (bet.userStake && bet.userStake > 0) {
        const { betModifierFlag } = bet;
        initalObj.unitStake = bet.userStake;
        if (betModifierFlag) {
          const { betCount } = betModifier[1];
          initalObj.totalStake = !isNaN(bet.totalStake)
            ? parseDecimalPlaces(parseFloat(bet.totalStake))
            : betCount * Number(bet.userStake);
        } else {
          initalObj.totalStake = !isNaN(bet.totalStake)
            ? parseDecimalPlaces(parseFloat(bet.totalStake))
            : bet.totalStake;
        }
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      } else if (bet.freebetCredit && bet.hasFreebet === true) {
        initalObj.unitStake =
          bet.betModifierType === Constants.EACHWAY
            ? bet.freebetCredit / bet.betModifierBetCount
            : bet.freebetCredit;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.bonusFunds = false;
        placeBetArr.push(initalObj);
      }
    } else if (bet.multiple === true) {
      initalObj.priceIds =
        Constants.CAST && bet.betTypeRef === Constants.SCT
          ? priceIdsSCT
          : bet.category === Constants.CAST
          ? priceIdsCombine
          : priceIds;
      initalObj.betTypeRef = bet.betTypeRef;
      initalObj.betModifier = bet?.betModifierType || type;
      initalObj.betBoost = bet.bbFlag ? bet.bbFlag : false;
      if (bet.userStake && bet.userStake > 0) {
        const { betModifierFlag } = bet;
        initalObj.unitStake = bet.userStake;
        if (betModifierFlag) {
          const { betCount } = betModifier[1];
          initalObj.totalStake = isNaN(bet.totalStake)
            ? parseDecimalPlacesPotReturn(betCount * Number(bet.userStake))
            : parseDecimalPlacesPotReturn(bet.totalStake);
        } else {
          const { betCount } = betModifier[0];
          initalObj.totalStake = isNaN(bet.totalStake)
            ? parseDecimalPlacesPotReturn(betCount * Number(bet.userStake))
            : parseDecimalPlacesPotReturn(bet.totalStake);
        }
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      } else if (bet.freebetCredit && bet.hasFreebet === true) {
        initalObj.unitStake =
          bet.betModifierType === Constants.EACHWAY
            ? bet.freebetCredit / bet.betModifierBetCount
            : bet.freebetCredit;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.bonusFunds = false;
        placeBetArr.push(initalObj);
      }
    } else if (bet.betTypeRef === Constants.BBB) {
      if (bet.hasFreebet) {
        initalObj.priceIds = bet.sentence.map(s => s.priceId).filter(p => p);
        initalObj.betTypeRef = bet.betTypeRef;
        initalObj.totalStake = bet.freebetCredit;
        initalObj.unitStake =
          bet.betModifierType === Constants.EACHWAY
            ? bet.freebetCredit / bet.betModifierBetCount
            : bet.freebetCredit;
        initalObj.walletId = bet.selectedFreebetId;
        initalObj.betModifier = bet.betModifier;
        placeBetArr.push(initalObj);
      } else if (bet.totalStake && bet.userStake > 0) {
        initalObj.priceIds = bet.sentence.map(s => s.priceId).filter(p => p);
        initalObj.betTypeRef = bet.betTypeRef;
        initalObj.totalStake = bet.totalStake;
        initalObj.unitStake = bet.userStake;
        initalObj.betModifier = bet.betModifier;
        initalObj.bonusFunds = bet.bonusFunds ? bet.bonusFunds : false;
        placeBetArr.push(initalObj);
      }
    }
  }
  return placeBetArr;
};

/** 
this validation function is use for freebet 
if any free bet is already selected or if user
reslected same free bet in other one is that case first 
one is remove from selected element 
@param data content object
*/
export const validateFreeBet = (data, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  for (const itm of localData) {
    if (itm['selectedFreebetId'] === data['selectedFreebetId']) {
      itm['hasFreebet'] = false;
      itm['freebetCredit'] = '';
      itm['freebetCreditFormatted'] = '';
      itm['selectedFreebetId'] = '';
      itm['returnsText'] = 0;
      itm['_returnsFormatted'] = 0;
      itm['_returns'] = 0;
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: localData,
    action: 'set',
  });
};

/**
genrate Uk bet template 
this fun add multiple and cast tab on starting position 
@param betTemplate
*/

export const genrateUkBetTemplate = (
  betTemplate,
  displaymultiplesfirst,
  showmultipleexpandable,
  multiplebetsTranslate
) => {
  //put the multiple on top handle from CMS
  const tricastForecast = [Constants.TRICAST, Constants.FORCAST];
  const containsTricastOrForecast = betTemplate.some(a =>
    tricastForecast.includes(a.name)
  );
  if (displaymultiplesfirst && !containsTricastOrForecast) {
    betTemplate.sort(function (a, b) {
      return b.multiple - a.multiple;
    });
  }

  // for handle acca bet on top in multiple section .
  betTemplate.sort((a, b) => {
    if (a.multiple && b.multiple) {
      if (
        a.betModifierBetCount === 1 ||
        (a.betModifier?.length > 0 && a.betModifier[0]?.betCount === 1)
      )
        return -1;
      else return 1;
    }
  });

  let slpiceKey = null;
  let singleSlpiceKey = null;
  let ukBetTemplate = new Array();
  ukBetTemplate = [...betTemplate];
  let onlySingle = {};
  let onlyMultiple = {};
  const castOrder = {};
  let castName = '';
  let multiplebetCount = 0;
  for (const bet of ukBetTemplate) {
    if (bet['multiple'] && bet.name === 'Forecast') {
      castName = multiplebetsTranslate
        ? Translations.get(`${'text.bet.' + bet.name}`)
        : Constants.FORCAST;
    } else if (bet['multiple'] && bet.name === 'Tricast') {
      castName = multiplebetsTranslate
        ? Translations.get(`${'text.bet.' + bet.name}`)
        : Constants.FORCASTTricast;
    }
    multiplebetCount += bet['multiple'] && 1;
  }

  ukBetTemplate.forEach((bet, key) => {
    if (singleSlpiceKey === null && !bet['multiple']) {
      singleSlpiceKey = key;
      onlySingle = {
        tab: Constants.SINGLE_C,
        actTab: Constants.SINGLE_C,
        display: Translations.get('text.single'),
        delete: (key === 0 && true) || false,
      };
    }

    if (
      slpiceKey === null &&
      bet['multiple'] &&
      ['Forecast', 'Tricast'].includes(bet['name'])
    ) {
      slpiceKey =
        displaymultiplesfirst && !containsTricastOrForecast ? key : key + 1;
      onlyMultiple = {
        tab: 'multiple',
        actTab: Constants.MULTIPLE,
        display: castName,
        delete: (key === 0 && true) || false,
      };

      // cast-order
      castOrder['description'] = bet['description'];
      castOrder['key'] = slpiceKey + 1;
      castOrder['type'] = 'castorder';
      castOrder['catref'] = bet.catRef;
    } else if (slpiceKey === null && bet['multiple']) {
      slpiceKey =
        displaymultiplesfirst && !containsTricastOrForecast ? key : key + 1;
      onlyMultiple = {
        tab: 'multiple',
        actTab: Constants.MULTIPLE,
        display: Translations.get('text.multiple'),
        delete: (key === 0 && true) || false,
        lessMore:
          (showmultipleexpandable && multiplebetCount > 1 && true) || false,
      };
    }
  });
  if (singleSlpiceKey !== null)
    ukBetTemplate.splice(singleSlpiceKey, 0, onlySingle);

  if (slpiceKey !== null) ukBetTemplate.splice(slpiceKey, 0, onlyMultiple);

  if (Object.keys(castOrder).length > 0) {
    ukBetTemplate.splice(castOrder['key'], 0, castOrder);
  }
  return ukBetTemplate;
};

/**
calculate total count of single bets
*/
export const CalCount = betTemplate => {
  try {
    return betTemplate.map(itm => itm.selectionId).filter(itm => itm).length;
  } catch (error) {
    return betTemplate?.length || 0;
  }
};

/* that calculator useStake and  potentialReturns  */

export const genrateSlips = (bets, tax) => {
  let totalStake = Constants.DEFAULT_AMT;
  let potentialReturns = Constants.DEFAULT_AMT;
  let fee = Constants.DEFAULT_AMT;
  let netReturn = Constants.DEFAULT_AMT;
  let freeStake = Constants.DEFAULT_AMT;
  let hasFreebet = false;
  let bonusFund = false;
  let betBonusAmount = Constants.DEFAULT_AMT;
  let bonusPercentage = 0;
  let hasBonusBet = false;
  let multiplyOfPrices = 1;
  if (bets && bets.length > 0) {
    for (const bet of bets) {
      if (bet.betTypeRef === Constants.SGL) {
        multiplyOfPrices = multiplyOfPrices * bet.priceDecimal;
      }
    }
    for (const bet of bets) {
      const betCount = bet.betModifierBetCount
        ? bet.betModifierBetCount
        : Constants.DEFAULT_MODIFIERBETCOUNT;
      /* 
      total stake
      */
      if (bet['userStake']) {
        const ts = parseFloat(bet['userStake']) * betCount;
        totalStake += Math.round(1000 * ts) / 1000;
      }

      /* 
      total free bet
      */
      if (bet['freebetCredit']) {
        freeStake += isNaN(parseFloat(bet['freebetCredit']))
          ? 0
          : parseFloat(bet['freebetCredit']);
        if (bet['hasFreebet']) {
          hasFreebet = bet['hasFreebet'];
        }
      }

      /* 
      total return Gross 
      */

      const betPotentialReturn = calculatePotentialReturns(bet);

      if (
        betPotentialReturn !== Constants.N_A &&
        potentialReturns !== Constants.N_A &&
        !isAnySPbet()
      ) {
        if (betPotentialReturn && !isNaN(betPotentialReturn)) {
          potentialReturns +=
            parseFloat(betPotentialReturn) + parseFloat(betBonusAmount);
        }
      } else {
        potentialReturns = Constants.N_A;
      }

      // check if user select bonus fund

      if (bet.bonusFunds) {
        bonusFund = bet.bonusFunds;
      }
      // if bet has bet bonus
      if (bet?.betBonus && bet['userStake'] && multiplyOfPrices > 0) {
        const ts =
          parseFloat(bet['userStake']) * multiplyOfPrices -
          parseFloat(bet['userStake']);
        bonusPercentage = bet.betBonus?.percentage;
        hasBonusBet = true;
        if (ts) {
          betBonusAmount = parseFloat(ts * (bet.betBonus.percentage / 100));
        }
      }
    }
    /* 
      Fee
      */
    if (potentialReturns > 0 && !isNaN(tax)) {
      fee = Number((tax / 100) * potentialReturns);
    }
    /* 
    net pot. Return  
    */
    if (potentialReturns > 0) {
      netReturn = potentialReturns - fee;
    }
  }
  const a = {
    totalStake: totalStake,
    potentialReturns: potentialReturns,
    fee: fee,
    netReturn: netReturn,
    bonusFund: bonusFund,
    freeStakeData: {
      freeStake: freeStake,
      hasFreebet: hasFreebet,
    },
    betBonusData: {
      bonusPercentage: bonusPercentage,
      betBonusAmount: betBonusAmount,
      hasBonusBet: hasBonusBet,
    },
  };
  return a;
};

/* 
return data of Price 
*/
export const getDataOfPrice = (price, id) => {
  if (price) {
    if (id) {
      let pr = price.filter(p => {
        return p.id === parseInt(id);
      });
      pr = pr[0];
      return pr;
    } else {
      return price[0];
    }
  } else return {};
};

/* 
return data of betModifier 
*/
export const getDataofModifier = (modifier, chkVal) => {
  if (modifier) {
    if (chkVal === true) {
      return modifier[1];
    } else {
      return modifier[0];
    }
  } else return {};
};

/**
 * for Recipt
 * genrate Discription for single type bets
 * @param s1 {string}
 * @param s2 {string}
 * @param s3 {string}
 */
export const ReceiptDiscription = (s1, s2, s3, s4, catRef) => {
  if ([Constants.HORSES, Constants.DOGS].includes(catRef)) {
    return s1 + ' ' + s2 + ' - ' + s3;
  } else {
    return s3 + ' - ' + s4 + ' - ' + s1;
  }
};

/**
 * for Recipt
 * genrate Discription for Forcast and tricast type bets
 * @param betPart {object}
 */

export const ReceiptDiscriptionFandT = betPart => {
  let selectionArr = betPart.map(bp => {
    return bp.selection;
  });
  selectionArr = selectionArr.toString();
  try {
    const name = betPart[0]['name'];
    const subcatName = betPart[0]['subcatName'];
    return selectionArr + ' - ' + name + ' ' + subcatName;
  } catch (error) {
    return selectionArr;
  }
};

/**
 * for Recipt
 * genrate Name for Forcast and tricast type bets
 * @param betModifier {object}
 * @param name {string}
 */
export const ReceiptGenraterName = (betModifier, name) => {
  if (name === 'Forecast') {
    return betModifier === 'WIN'
      ? 'Straight Forecast'
      : capitalizeFirstLetter(betModifier) + ` Forecast`;
  } else if (name === 'Tricast') {
    return betModifier === 'WIN'
      ? 'Straight Tricast'
      : capitalizeFirstLetter(betModifier) + ` Tricast`;
  } else {
    return betModifier + ' ' + name;
  }
};

/**
 * it genrate TotalStakes And TotalPotReturn and PlaceOn
 * @param {object} betTemplate
 * @returns {object}
 */

export const genTotalStakesAndTotalPotReturn = (betTemplate, appConfig) => {
  let placeOn = '';
  let totalStake = 0;
  let potStake = 0;
  let freeBet = 0;
  for (const bet of betTemplate) {
    placeOn = moment(parseInt(bet.placedOn)).format(
      project.timeFormats.betslip.primary || 'DD/MM/YYYY, hh:mm'
    );
    if (appConfig?.hidebetslip) {
      placeOn = appConfig.american
        ? getAmericanDate(bet.placedOn)
        : getDate(bet.placedOn);
    }
    totalStake += !bet.creditWallet && parseFloat(bet.totalStake);
    freeBet += bet.creditWallet && parseFloat(bet.totalStake);
    if (bet.maxPayout > 0 && potStake !== Constants.N_A) {
      //when credit wallet become true remove userstake
      const maxpay = bet.maxPayout || 0;

      potStake = parseFloat(potStake) + parseFloat(maxpay);
    } else {
      potStake = Constants.N_A;
    }
  }
  return {
    placeOn: placeOn,
    totalStake: totalStake,
    potStake: potStake,
    freeBet: freeBet,
  };
};

/**
 * makeArrayOfRecept manipulate orignal response of betSlip which come to api
 * @params betSlip {object}
 */
export const makeArrayOfRecept = betSlip => {
  const single = [];
  const multiple = [];
  const forcast = [];
  const tricast = [];
  const allReceptBet = [];
  const forcasttricast = [];
  let ForcastTricast = false;

  const itemF = betSlip.find(item => item.betTypeName === Constants.FORCAST);
  const itemT = betSlip.find(item => item.betTypeName === Constants.TRICAST);

  if (itemF && itemT) {
    ForcastTricast = true;
  }
  for (const bet of betSlip) {
    // handle freebets (remove stake amount )
    if (
      bet['betTypeName'].toLowerCase() === Constants.SINGLE ||
      bet['betTypeName'].toLowerCase() === 'create a bet'
    ) {
      single.push(bet);
    } else if (bet['betTypeName'] === Constants.FORCAST && !ForcastTricast) {
      forcast.push(bet);
    } else if (bet['betTypeName'] === Constants.TRICAST && !ForcastTricast) {
      tricast.push(bet);
    } else if (
      (bet['betTypeName'] === Constants.FORCAST ||
        bet['betTypeName'] === Constants.TRICAST) &&
      ForcastTricast
    ) {
      forcasttricast.push(bet);
    } else {
      multiple.push(bet);
    }

    const { betPart } = bet;
    for (const bp of betPart) {
      const { ewPlaces, ewFraction } = bp;
      if (ewPlaces && ewFraction) {
        const places = { 1: '1', 2: '1-2', 3: '1-2-3' };
        const fractions = { 1: '1/1' };
        const getPlaces = ewPlaces > 3 ? '1 - ' + ewPlaces : places[ewPlaces];
        const getFractions =
          ewFraction > 1 ? '1/' + ewFraction : fractions[ewFraction];
        bet.ewLabel = getPlaces + ' @ ' + getFractions;
      }
    }
  }

  if (single.length > 0) {
    allReceptBet['single'] = single;
  }
  if (multiple.length > 0) {
    allReceptBet['multiple'] = multiple;
  }
  if (forcast.length > 0) {
    allReceptBet['forcast'] = forcast;
  }
  if (tricast.length > 0) {
    allReceptBet['tricast'] = tricast;
  }
  if (forcasttricast.length > 0) {
    allReceptBet['forcasttricast'] = forcasttricast;
  }
  return allReceptBet;
};

/**
 * handle odds change message for SP to oddsPrice
 * @param {*} price
 * @param {*} priceBookType
 * @returns
 */
const previousPrice = (price, priceBookType) =>
  !price && priceBookType === Constants.SP ? Constants.SP : price;

/**
 * handle socket data for betslip
 * @param wsobject
 * */
export const wsSocketDataManipulation = (
  data,
  betTemplate,
  notificationParam
) => {
  const { selections } = data;
  const tempBetData = new Array();
  for (const bet of betTemplate) {
    let set = false;
    for (const sdata of selections) {
      if (bet.selectionId === sdata.id && !sdata.active) {
        bet.active = false;
        tempBetData.push(bet);
        set = true;
      } else if (
        bet.marketSequence !== data.sequence &&
        data.sequence !== false
      ) {
        bet.active = false;
        bet.newMarketSequence = data.sequence;
      } else if (bet.selectionId === sdata.id) {
        if (
          sdata.price.decimal !== undefined &&
          sdata.price.fractional !== undefined
        ) {
          const tempBet = Object.assign({}, bet);
          const x = { ...bet };
          x.active = true;
          if (
            notificationParam === 'lower' &&
            tempBet.priceDecimal > sdata.price.decimal
          ) {
            //  when notification param is lower than mean when price is decress
            x.priceChangeNotification = true;
            x.updateNewPrice = false;
            x.previousPriceDecimal = previousPrice(
              tempBet.priceDecimal,
              tempBet.priceBookType
            );
            x.previousPriceFractional = previousPrice(
              tempBet.priceFractional,
              tempBet.priceBookType
            );
            x.notificationPriceChangeType = Constants.DEC;
          } else if (notificationParam === 'block') {
            //  when notification param is block than mean when price is decress | Increases
            x.priceChangeNotification = true;
            x.updateNewPrice = false;
            x.previousPriceDecimal = previousPrice(
              tempBet.priceDecimal,
              tempBet.priceBookType
            );
            x.previousPriceFractional = previousPrice(
              tempBet.priceFractional,
              tempBet.priceBookType
            );
            x.notificationPriceChangeType =
              tempBet.priceDecimal > sdata.price.decimal
                ? Constants.DEC
                : Constants.INC;
          } else {
            // when notification parma is default then no notification directly price decress | Increases
            // when notificationParam === 'lower' or Price going higher in that case it update in BG.
            x.updateNewPrice = true;
            x.priceChangeNotification = false;
          }
          x.price[0] = sdata.price;
          x.priceDecimal = sdata.price.decimal;
          x.priceFractional = sdata.price.fractional;
          x.priceId = sdata.price.id;
          tempBetData.push(x);
          set = true;
        } else if (sdata.active) {
          // when bet is deactive and some active or change price
          const tempBet = Object.assign({}, bet);
          const x = { ...bet };
          x.active = true;
          x.priceChangeNotification = true;
          x.updateNewPrice = false;
          x.previousPriceDecimal = previousPrice(
            tempBet.priceDecimal,
            tempBet.priceBookType
          );
          x.previousPriceFractional = previousPrice(
            tempBet.priceFractional,
            tempBet.priceBookType
          );
          x.notificationPriceChangeType =
            tempBet.priceDecimal > sdata.price.decimal
              ? Constants.DEC
              : Constants.INC;
          x.priceDecimal = sdata.price.decimal;
          x.priceFractional = sdata.price.fractional;
          x.priceId = sdata.price.id;
          x.price[0] = sdata.price;
          tempBetData.push(x);
          set = true;
        }
      }
    }
    if (!set) {
      tempBetData.push(bet);
    }
  }

  return tempBetData;
};

/**
 *  genrate Casting tabs
 * @param {Array} betTemplate
 */

export const genrateCastingForUS = betTemplate => {
  let slpiceKey = null;
  let ukBetTemplate = new Array();
  ukBetTemplate = [...betTemplate];
  let onlyMultiple = {};
  const castOrder = {};
  let castName = '';
  for (const bet of ukBetTemplate) {
    if (bet['multiple'] && bet.name === 'Forecast') {
      castName = Constants.FORCAST;
    } else if (bet['multiple'] && bet.name === 'Tricast') {
      castName = Constants.FORCASTTricast;
    }
  }

  ukBetTemplate.forEach((bet, key) => {
    if (
      !slpiceKey &&
      bet['multiple'] &&
      ['Forecast', 'Tricast'].includes(bet['name'])
    ) {
      slpiceKey = key;
      onlyMultiple = {
        tab: 'multiple',
        actTab: Constants.MULTIPLE,
        display: castName,
        delete: false,
        multiple: true,
        catRef: bet.catRef,
      };

      // cast-order
      castOrder['description'] = bet['description'];
      castOrder['key'] = slpiceKey + 1;
      castOrder['type'] = 'castorder';
      castOrder['catRef'] = bet.catRef;
      castOrder['multiple'] = true;
    }
  });

  if (slpiceKey) ukBetTemplate.splice(slpiceKey, 0, onlyMultiple);
  if (Object.keys(castOrder).length > 0) {
    ukBetTemplate.splice(castOrder['key'], 0, castOrder);
  }
  return ukBetTemplate;
};
/**
 * Get data from Hash Params
 * @param {Array} betTemplate array of bets
 * @param {string} localStorageKey storage key
 */
export const removeUserDependentItems = (betTemplate, localStorageKey) => {
  for (const bet of betTemplate) {
    if (bet['hasFreebet']) {
      bet['hasFreebet'] = false;
      bet['freebetCredit'] = '';
      bet['freebetCreditFormatted'] = '';
      bet['selectedFreebetId'] = '';
      bet['returnsText'] = 0;
      bet['_returnsFormatted'] = 0;
      bet['_returns'] = 0;
      bet['totalStake'] = '';
      bet['userStake'] = '';
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: betTemplate,
    action: 'set',
  });
  return betTemplate;
};

/**
 * Get data from Hash Params
 * @param {object} window window Object
 */

export const dataFromHashParams = window => {
  if (window.location.hash.indexOf('sid') !== -1) {
    let hashData = window.location.hash;
    hashData = hashData.replace('#sid=', '');
    const amtObj = [];
    hashData = hashData.split(',').map(i => {
      // check | user stake
      if (i.indexOf('|') > -1) {
        const SidStk = i.split('|');
        i = SidStk[0];
        const a = {};
        a[i] = Number(SidStk[1]);
        amtObj.push(a);
      }
      return Number(i);
    });
    return { sid: hashData, amtObj: amtObj };
  } else {
    return null;
  }
};

/**
 * Get data from Query Params
 * @param {object} window window Object
 */

export const dataFromQueryParams = window => {
  if (window.location.search.indexOf('sid') !== -1) {
    const queryString = new URLSearchParams(window.location.search);
    let selectionId = queryString.get('sid');
    const betslipOpen = queryString.get('betslipOpen');
    const amtObj = [];
    selectionId = selectionId.split(',').map(i => {
      // check | user stake
      if (i.indexOf('|') > -1) {
        const SidStk = i.split('|');
        i = SidStk[0];
        const a = {};
        a[i] = Number(SidStk[1]);
        amtObj.push(a);
      }
      return Number(i);
    });
    return {
      sid: selectionId,
      amtObj: amtObj,
      betslipOpen: betslipOpen === 'true',
    };
  } else {
    return null;
  }
};

// detect Mobile device
export const detectMob = () => {
  try {
    return window.innerWidth <= parseInt(BREAKPOINT_M);
  } catch (error) {
    return null;
  }
};

/**
 * @param {string} name string
 * @param {object} betMf betModifier Object
 */
export const genraterName = (name, betMf) => {
  if (name === 'Forecast' && betMf?.type) {
    return betMf.type === 'WIN'
      ? 'Straight Forecast'
      : capitalizeFirstLetter(betMf.type) + ` Forecast`;
  } else if (name === 'Tricast' && betMf?.type) {
    return betMf.type === 'WIN'
      ? 'Straight Tricast'
      : capitalizeFirstLetter(betMf.type) + ` Tricast`;
  } else {
    return name;
  }
};

/**
for geting correct priceIds
 @params localstorage object
*/

export const getPriceIdsbookType = (localStorageData, bookType) => {
  let arr = [];
  const singleBets = localStorageData
    .filter(itm => {
      return itm.betTypeRef === 'SGL' && itm;
    })
    .sort((a, b) => {
      return a.castPosition - b.castPosition;
    });

  try {
    for (const item of singleBets) {
      if (item.betTypeRef === 'SGL') {
        const { price } = item;
        for (const pr of price) {
          if (pr && pr['bookType'] === bookType) {
            arr.push(pr['id']);
          } else if (!bookType) {
            // for straight scorecast card bets
            arr.push(pr['id']);
          }
        }
      }
    }
  } catch (error) {
    arr = [];
  }

  return arr;
};

/**
 *  Find market id exist in bets
 * @param {Array} betTemplate
 * @param {object} wsData
 */
export const findMarket = (betTemplate, wsData) => {
  let mB = false;
  if (betTemplate) {
    for (const bet of betTemplate) {
      if (bet.marketId === wsData.id) {
        mB = true;
      }
    }
  }
  return mB;
};

/**
 * find  suspendSelectedMarket for horse and dog
 * @param {Array} betTemplate
 * @param {object} wsData
 */

export const suspendSelectedMarket = (wsData, betTemplate) => {
  const onlySingleBet = [];
  if (betTemplate) {
    for (const bet of betTemplate) {
      if (bet.marketId === wsData.id && bet.betTypeRef === Constants.SGL) {
        bet.active = false;

        onlySingleBet.push(bet);
      }
    }
  }
  return onlySingleBet.length > 0 ? onlySingleBet : betTemplate;
};

/**
 * handle Builder Object
 *  @param {object} apiData data to api
 */

export const generateBetBuilderObject = apiData => {
  let sentenceStr = '';
  if (apiData.sentence.length > 0)
    sentenceStr =
      '&sentence=' + apiData.sentence.map(sen => sen.key).join('&sentence=');
  const builderObj = {
    multiple: false,
    active: true,
    eventId: apiData.eventId,
    eventName: apiData.eventName,
    fractional: apiData.fractional,
    price: apiData.price,
    sentence: apiData.sentence,
    text: apiData.text,
    betMode: 'betbuilder',
    betTypeRef: Constants.BBB,
    betModifier: 'WIN',
    catRef: 'SOCCER',
    category: 'SOCCER',
    subcatRef: apiData.subCatRef,
    returnsText: '',
    hasFreebet: false,
    selectedFreebetId: '',
    freebetCredit: null,
    selectedIndex: null,
    freebetCreditFormatted: null,
    id: apiData.eventId + sentenceStr,
    marketActive: true,
    allowCreditWallet: true,
    bbFlag: '',
    freebetId: false,
    userStake: '',
    betModifierReturns: '',
    betModifierBetCount: 1,
    selectionId: apiData.eventId + sentenceStr,
    fetchSentence: sentenceStr,
    _returns: 0,
    _returnsFormatted: '',
    castPosition: 1,
    allowBonusFunds: apiData.allowBonusFunds,
  };
  return builderObj;
};

/**
 * find builder data from LocalStorage
 *  @param {string} key localstorage key
 */

export const getBetBuilderData = key => {
  const localData = setterGetterFromLocalstorage({
    keyName: key,
    action: 'get',
  });
  return localData
    .map(item => {
      if (
        item &&
        typeof item['selectionId'] !== 'undefined' &&
        item['betTypeRef'] === Constants.BBB
      ) {
        return item;
      }
    })
    .filter(item => item);
};

/**
 * merge data in BetTemplate Array when data come to bet builder
 * @param {Array} betTemplate prev state array od BT
 * @param {Array} builderData next state of builder data
 */
export const mergeBuilderDataInTemplate = (betTemplate, builderData) => {
  if (betTemplate.length > 0) {
    return mergeByProperty(betTemplate, builderData, 'id');
  } else {
    return betTemplate.concat(builderData);
  }
};
/**
 *  helper function of data merge
 * @param {Array} target main array {bettemplate}
 * @param {Array} source new array {source}
 * @param {string} prop key name  {id}
 */
const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    const targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement);
  });

  return target;
};

/**
 * sort data for CreateBet
 * @param {Array} betTemplate
 */
export const handleSortCreateBet = betTemplate => {
  let k = 1;
  let BT = new Array();
  BT = betTemplate
    .map((item, key) => {
      if (item.tab === Constants.SINGLE) {
        k = key;
      }
      if (item.betTypeRef === Constants.SINGLE) {
        k = key + k;
      }
      if (item && item['betTypeRef'] !== Constants.BBB) {
        return item;
      }
    })
    .filter(item => item);

  const build = betTemplate
    .map(item => {
      if (
        item &&
        typeof item['selectionId'] !== 'undefined' &&
        item['betTypeRef'] === Constants.BBB
      ) {
        return item;
      }
    })
    .filter(item => item);

  BT.splice(k, 0, ...build);
  return BT;
};

/**
 * update Upcoming FavouritesAmt
 * @param {Array} item
 * @param {number} upcomingFavAmt
 */

export const upcomingFavouritesAmount = (item, upcomingFavAmt) => {
  item['userStake'] = Number(upcomingFavAmt);
  item['totalStake'] = Number(upcomingFavAmt);
  item['returnsText'] = parseDecimalPlaces(
    item.betModifierReturns * Number(upcomingFavAmt)
  );
  item['_returns'] = item.betModifierReturns * Number(upcomingFavAmt);
  item['_returnsFormatted'] = item.betModifierReturns * Number(upcomingFavAmt);

  return item;
};

/**
 * update amount comes form Url
 * @param {Array} item
 * @param {Array} element
 * @param {object} priceData
 */

export const updateAmountComeToUrl = (item, priceData, element) => {
  item['userStake'] = Number(element[item['id']]);
  item['totalStake'] = Number(element[item['id']]);
  item['returnsText'] = parseDecimalPlaces(
    priceData.decimal * Number(element[item['id']])
  );
  item['_returns'] = priceData.decimal * Number(element[item['id']]);
  item['_returnsFormatted'] = priceData.decimal * Number(element[item['id']]);
  return item;
};

/**
 * blank when retain bet
 */
export const retainCleanupBet = bet => {
  bet['_returns'] = 0;
  bet['_returnsFormatted'] = 0;
  bet['returnsText'] = 0;
  bet['userStake'] = '';
  bet['totalStake'] = 0;
  bet['freebetCredit'] = 0;
  bet['freebetCreditFormatted'] = 0;
  bet['hasFreebet'] = false;
  bet['bbFlag'] = false;
  bet['priceDecimal'] = '';
  bet['priceFractional'] = '';
  return bet;
};

/**
 * find SP from betTemplate array
 */
export const isAnySPbet = () => {
  const localData = setterGetterFromLocalstorage({
    keyName: Constants.LSKEY_UK,
    action: 'get',
  });
  let SPit = false;
  for (const bet of localData) {
    if (
      bet.priceBookType === Constants.SP &&
      bet.betTypeRef === Constants.SGL
    ) {
      SPit = true;
    }
  }

  return SPit;
};

/**
 * free bet svg
 * color "#FEE30E"
 * height
 * width
 */
export const FreeBetSvg = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 12 12"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M8.902.846a.777.777 0 0 0-1.159 0c-.243.253-.478.687-.647 1.1L7 2.135c-.123.401-.19.749-.107.839l.01.026h.131c.53 0 1.538-.576 1.868-.925a.905.905 0 0 0 0-1.23zM3.678.6a.796.796 0 0 0-.58.253.901.901 0 0 0 0 1.225c.33.348 1.338.922 1.867.922h.133l.009-.026c.082-.09.016-.437-.107-.836l-.096-.188c-.17-.412-.404-.846-.647-1.097A.795.795 0 0 0 3.677.6zM7.096.458c.587-.61 1.611-.611 2.2 0a1.653 1.653 0 0 1 0 2.275c-.082.086-.192.18-.296.267l-.123.08h2.373c.391 0 .712.309.75.702V5a.387.387 0 0 1-.324.396l-.051.004H6.75V3.853h-1.5V5.4H.375A.385.385 0 0 1 0 5.065V3.853c0-.403.3-.735.682-.771l.068-.003h2.373c-.17-.12-.317-.24-.42-.346A1.655 1.655 0 0 1 2.706.458c.587-.61 1.611-.61 2.2 0C5.216.782 6.03 2.068 6 3l-.027.08h.054C5.907 2.163 6.77.793 7.096.457zM11.429 6.6v4.8c0 .238-.298.554-.524.6H6.857V6.6zm-6.286 0V12h-4c-.214 0-.526-.3-.567-.548L.57 11.4V6.6z"
          />
        </g>
      </g>
    </svg>
  );
};

FreeBetSvg.propTypes = {
  color: PropTypes.string,
};

export const setBetslipHeight = open => {
  const innerHeight = setterGetterFromLocalstorage({
    keyName: Constants.INNER_Y,
    action: 'get',
  });
  const bottomNav = document.getElementById('bottomNav');
  const openBetslip = document.getElementsByClassName('mr-active');
  const betslipContainer = document.getElementById('betslip_container');
  const betslipBottom = document.getElementById('betslip-bottom');
  const isLoginHeight = innerHeight?.offsetY || 0;
  const bottomHeight = 0;
  const posY = innerHeight?.posY || 0;

  if (bottomNav) {
    bottomNav.style.zIndex = '9999';
  }
  if (open) {
    if (bottomNav) {
      bottomNav.style.zIndex = '9';
    }
    if (openBetslip && openBetslip.length > 0) {
      const height =
        innerHeight.innerY -
        posY -
        bottomHeight -
        isLoginHeight +
        innerHeight.y;
      openBetslip[0].style.height =
        height && !isNaN(height) ? `${height}px` : 'inherit';
    }
    if (betslipContainer) {
      betslipContainer.style.position = 'relative';
      betslipContainer.style.bottom = 0;
      betslipContainer.style.height = `${
        innerHeight.innerY - posY - bottomHeight - isLoginHeight + innerHeight.y
      }px`;
      betslipContainer.style.zIndex = '9999';
    }
    if (betslipBottom) {
      betslipBottom.style.position = 'absolute';
    }
  } else {
    if (openBetslip && openBetslip.length > 0) {
      openBetslip[0].style.height = `initial`;
    }
    if (betslipContainer) {
      betslipContainer.style.position = 'fixed';
      betslipContainer.style.bottom = '66px';
      betslipContainer.style.height = `initial`;
      betslipContainer.style.zIndex = '9';
    }
    if (betslipBottom) {
      betslipBottom.style.position = 'fixed';
    }
  }
};

const dateOrdinal = d =>
  d +
  (31 === d || 21 === d || 1 === d
    ? Translations.get('number.st')
    : 22 === d || 2 === d
    ? Translations.get('number.nd')
    : 23 === d || 3 === d
    ? Translations.get('number.rd')
    : Translations.get('number.th'));

const getDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('h');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);

  const time = `${hour}:${minutes}`;

  return `${month} ${day}, ${time}`;
};

//For Getting date and time in american layout
export const getAmericanDate = scheduledStart => {
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('HH');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);
  const time = `${hour}:${minutes} `;
  if (project?.dateFormatChange) {
    return `${month} ${day}, ${time}`;
  } else {
    return `${day} ${month}, ${time}`;
  }
};

export const checkIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

/**
 * Added event name in virtuals descrption
 * @param {string} catRef
 * @param {string} descTime
 */
export const virtualsDescription = (catRef, descTime) => {
  const regex = new RegExp(Constants.HHMM);
  let result = '';
  if (project.JpTimeFormat && catRef === Constants.VIRTUAL) {
    const isTimePresent = descTime?.split(' ')?.slice(-1);
    if (regex.test(isTimePresent)) {
      result = `${descTime?.split(' ')?.slice(0, -1).join(' ')} `;
    } else {
      result = `${descTime} `;
    }
  }
  return result;
};
/**
 * for updated time in betslip
 * @param {string} timeStamp
 */
export const formatTimeStamp = timeStamp => {
  return moment(timeStamp).format(
    project.timeFormats.racing.quaternary || 'HH:mm'
  );
};
/**
 * get only which selection id which is active or single
 * @param {object} localStorageData
 */
export const getSelectionIdsWithActiveStatus = localStorageData => {
  let bsid = '';
  let bbid = '';
  if (localStorageData && localStorageData.length > 0) {
    bsid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] !== Constants.BBB &&
          (isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);

    bbid = localStorageData
      .map(item => {
        if (
          item &&
          typeof item['selectionId'] !== 'undefined' &&
          item['betTypeRef'] === Constants.BBB &&
          (!isNaN(item.newMarketSequence) ||
            typeof item['newMarketSequence'] === 'undefined')
        ) {
          return item['selectionId'];
        }
      })
      .filter(item => item);
  }
  return { bsid, bbid };
};

// add dynamic string to the text
export const getTranslation = (bet, string, countAccaBet) => {
  const countBetsRemaining = bet?.nextBetBonus?.winCount - countAccaBet;
  const nextBonusPercentage = bet?.nextBetBonus?.percentage;
  const adaptedString = string
    .replace('{0}', countBetsRemaining)
    .replace('{1}', nextBonusPercentage);

  return adaptedString;
};

export const priceRecipt = (betPart, oddsFormat) => {
  if (oddsFormat === Constants.DECIMAL) {
    return parseDecimalPlaces(betPart['price']);
  } else if (oddsFormat === Constants.AMERICAN) {
    return getOdds(betPart['price'], true);
  } else {
    return betPart['fractional'];
  }
};

export const notificationValues = param => {
  let value = '';
  switch (param) {
    case Constants.BLOCK: {
      value = Constants.REQUESTED;
      break;
    }
    case Constants.DEFAULT: {
      value = Constants.CURRENT;
      break;
    }
    case Constants.LOWER: {
      value = Constants.HIGHER_C;
      break;
    }
    case Constants.HIGHER: {
      value = Constants.LOWER_C;
      break;
    }
    // reverse
    case Constants.REQUESTED: {
      value = Constants.BLOCK;
      break;
    }
    case Constants.CURRENT: {
      value = Constants.DEFAULT;
      break;
    }
    case Constants.HIGHER_C: {
      value = Constants.LOWER;
      break;
    }
    case Constants.LOWER_C: {
      value = Constants.HIGHER;
      break;
    }
  }
  return value;
};

export const stakeLimit = (limitsObj, type) => {
  const stakeValue = limitsObj?.find(i => i.type === type);
  let val = 0;
  if (stakeValue) {
    val = stakeValue.value;
  }
  return val;
};

export const checkIsValidBet = betTemplate => {
  let stakeValidate = true;
  betTemplate.forEach(bet => {
    if (bet?.isValid !== undefined && !bet.isValid && bet.active) {
      stakeValidate = false;
    }
  });
  return stakeValidate;
};

/**
 * for validation of booster bets
 * @param {object} localStorageData
 * @param {object} data
 */
export const validateBetBooster = (data, localStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: localStorageKey,
    action: 'get',
  });

  for (const itm of localData) {
    let { price } = itm;
    price = price?.[0] || {};

    if (itm['bbFlag'] && itm['eventId'] === data['eventId']) {
      itm['bbFlag'] = false;
      itm['priceDecimal'] = price['decimal'];
      itm['priceFractional'] = price['fractional'];
    }
  }

  setterGetterFromLocalstorage({
    keyName: localStorageKey,
    keyValue: localData,
    action: 'set',
  });
};

/**
 *
 * @param {array} betTemplate
 * @returns lowest bet count value from bet template array
 */

export const getMinimalBetModifierBetCount = betTemplate => {
  const sortedBetTemplate = [...betTemplate.filter(b => b.multiple)];
  sortedBetTemplate.sort((a, b) => {
    const [aBetModifier] = a.betModifier || [];
    const [bBetModifier] = b.betModifier || [];

    if (aBetModifier && bBetModifier)
      return aBetModifier.betCount - bBetModifier.betCount;
    return 0;
  });

  let minBetCount = 1;
  if (
    sortedBetTemplate?.length > 0 &&
    sortedBetTemplate[0]?.betModifier?.length > 0 &&
    sortedBetTemplate[0].betModifier[0].betCount
  ) {
    minBetCount = sortedBetTemplate[0].betModifier[0].betCount;
  }

  return minBetCount;
};

const calculatePotentialReturns = bet => {
  let retAmt = '';
  if (
    bet.betModifierBetCount &&
    (bet.priceDecimal || bet.betModifierBetCount) &&
    bet.betTypeRef !== Constants.BBB
  ) {
    let tempBet = 0;
    if (bet.hasFreebet) {
      tempBet = parseFloat(bet.freebetCredit);
      const bmf = bet.betModifier[0];
      if (bet.multiple) {
        retAmt =
          ((bmf?.returns ? bmf?.returns : bet.betModifierReturns) -
            bmf.betCount) *
          tempBet;
      } else {
        retAmt = (bet.priceDecimal - bmf.betCount) * tempBet;
      }
    } else {
      tempBet = parseFloat(bet.userStake);
      if (bet.multiple) {
        const bmf = bet.betModifier[0];
        retAmt =
          tempBet * (bmf?.returns ? bmf?.returns : bet.betModifierReturns);
      } else {
        if (bet.betModifierFlag) {
          const bmf = bet.betModifier[1];
          retAmt = tempBet * bmf.returns;
        } else {
          retAmt = tempBet * bet.priceDecimal;
        }
      }
    }
  } else if (bet.betTypeRef === Constants.BBB) {
    if (bet.hasFreebet) {
      retAmt = bet.price * parseFloat(bet.freebetCredit);
      retAmt = retAmt - parseFloat(bet.freebetCredit);
    } else {
      retAmt = bet.price * parseFloat(bet.userStake);
    }
  }
  if (isNaN(retAmt)) {
    return '';
  } else {
    return retAmt;
  }
};

export const getBetCountByTypeRef = bet => {
  const betTypeRefCount = {
    SGL: 1,
    DBL: 2,
    TBL: 3,
    '4FD': 4,
    '5FD': 5,
    '6FD': 6,
    '7FD': 7,
    '8FD': 8,
    '9FD': 9,
    '10F': 10,
    '11F': 11,
    '12F': 12,
    '13F': 13,
    '14F': 14,
    '15F': 15,
  };
  return betTypeRefCount[bet.betTypeRef];
};

export const marketRefCount = (betTemplate, marketTypeRef) => {
  return betTemplate
    .filter(itm => itm.betTypeRef === 'SGL')
    .reduce(
      (count, bet) =>
        marketTypeRef?.includes(bet.marketTypeRef) ? count + 1 : count,
      0
    );
};
