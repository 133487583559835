import styled from 'styled-components';
import { BRAND, FONT, GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const MSSDNF = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${BRAND.tertiaryL};
  width: 100%;
`;

export const M = styled.div`
  font-family: ${theme.fontFamily};
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  font-size: 12px;
  padding: 8px 16px;
  line-height: 20px;
  user-select: none;
`;

export const M3 = styled(M)`
  background-color: ${BRAND.tertiaryL};
  color: ${FONT.dark};
  word-break: break-all;
  width: 100%;
`;
