import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '../../../../../core__betslip-constants';
import { BetslipCurrency } from '../../../BetSlipContainer/BetslipContent/elements';
import { genTotalStakesAndTotalPotReturn } from '../../../../../core__betslip-utils';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { getCookie } from 'Services/cookie/core__cookies';
import {
  ADIV,
  AS,
  AD,
  Ah3,
  Apdate,
  C3,
  C31,
  CL,
  Ch6,
  Ch5,
} from 'UI/apps/BetslipUKApp/ReceiptContainer/TopSection';
import { Translations } from '../../../../../core__betslip-UK-app';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import project from '../../../../../../../../project';

/**
 * Receipt Top section
 * @param {object} betSlipRecipt
 * @param {func} handleReceptCloseButton
 *  */
export const ReceiptTopSection = ({
  betSlipRecipt,
  handleReceptCloseButton,
  appConfig,
  betBonusPercent,
  betBonusAmount,
}) => {
  const [tStake, setTStake] = useState(0);
  const [tfStake, setTFStake] = useState(0);
  const [date, setDate] = useState(0);
  const [pRtn, setpRtn] = useState('');

  const bet = betSlipRecipt?.bet?.length > 0 && betSlipRecipt.bet[0];

  useEffect(() => {
    const result = genTotalStakesAndTotalPotReturn(
      betSlipRecipt['bet'],
      appConfig
    );

    PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
      event: TRACKING_CONSTANTS.BETCONFIRM,
      data: { customerId: getCookie('custId'), totalStake: result.totalStake },
    });

    setTStake(result.totalStake);
    setDate(result.placeOn);
    setpRtn(result.potStake);
    setTFStake(result.freeBet);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ADIV>
      <AD>
        <Ah3>{Translations.get('text.betslip.receipt')}</Ah3>
        <AS
          onClick={() => {
            handleReceptCloseButton(window.retainCheck);
          }}
        >
          &times;
        </AS>
      </AD>
      <C3>
        <li>
          <Apdate>{date}</Apdate>
        </li>
      </C3>
      <C31>
        <CL>
          <Ch6>{Translations.get('text.total.stake')}</Ch6>
        </CL>
        <CL>
          <Ch5>
            <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
            {tStake && parseDecimalPlacesPotReturn(parseFloat(tStake))}
            {bet?.bonusFunds && ` ${Translations.get('text.bonus')} `}
          </Ch5>
        </CL>
      </C31>
      {project.JpTimeFormat && betBonusPercent && betBonusAmount && (
        <C31>
          <CL>
            <Ch6>
              {betBonusPercent}% {Translations.get('text.acca.bonus')}{' '}
              {Translations.get('text.amount')}
            </Ch6>
          </CL>
          <CL>
            <Ch5>
              <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
              {parseDecimalPlacesPotReturn(parseFloat(betBonusAmount))}
            </Ch5>
          </CL>
        </C31>
      )}

      {(tfStake && (
        <C31>
          <CL>
            <Ch6>{Translations.get('text.ifb')}</Ch6>
          </CL>
          <CL>
            <Ch5>
              <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
              {tfStake && parseFloat(tfStake).toFixed(2)}
            </Ch5>
          </CL>
        </C31>
      )) ||
        ''}
      <C31>
        <CL>
          <Ch6>{Translations.get('text.total.potential.returns')}</Ch6>
        </CL>
        <CL>
          <Ch5>
            {pRtn === Constants.N_A ? (
              pRtn
            ) : (
              <React.Fragment>
                <BetslipCurrency symbol={betSlipRecipt['currencyCode']} />
                {parseDecimalPlacesPotReturn(parseFloat(pRtn))}
              </React.Fragment>
            )}
          </Ch5>
        </CL>
      </C31>
    </ADIV>
  );
};
ReceiptTopSection.propTypes = {
  isbetgtmtag: PropTypes.bool,
  appConfig: PropTypes.object,
  betSlipRecipt: PropTypes.object,
  handleReceptCloseButton: PropTypes.func,
  betBonusPercent: PropTypes.number,
  betBonusAmount: PropTypes.number,
};
