import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { stringsToTranslate } from './core__racing-events-translations';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('RacingEventsApp');

import { connect } from 'react-redux';
import createStore, {
  filterRacingData,
  dateFilterSelection,
  showRaceListPage,
  gotoRaceListPage,
  getRaceDetails,
  setSelectedRaceMarket,
  changeSortFilter,
  callBetslip,
  getBetslipSelections,
  filterSubCatRacingData,
  getNextHorseRaces,
  populateRaceCard,
  onChangeNextRaceFilter,
  filterFutureRacesData,
  futureRaceCollapse,
  gotoFutureRaceOutcomesPage,
  getFutureRaceOutcomes,
  getOddsFormat,
  fetchData,
  emitPubSubEvent,
  updateFutureRacingOdds,
  showSelectedCountryRaces,
  onChangeSelectedRacecourse,
  hideAndShowRacingDropdown,
  getVideoStreamInfo,
  sessionData,
  hideAndShowStreamIcon,
  flumenCall,
  changeEachWayFilter,
  loadData,
  disableSpinLoader,
} from './core__racing-events-store';
import ScDecoder from 'Services/json/core__decoder';
import RaceEventsApp from './component/core__racingEventsReact';
import { setAppConfig as setAppConfig_LOCAL } from './component/helper/racingAppConfig';
import { setAppConfig as setAppConfig_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import { getComponent } from 'Services/core__imports';
const setAppConfig = getComponent(setAppConfig_LOCAL, setAppConfig_CORE);

class RacingEventsApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations?.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations?.getAll?.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();

    Translations.setAll(data);
    setAppConfig(this.appConfig);

    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance appConfig={this.appConfig} />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    setAppConfig(this.appConfig);
    const mapStateToProps = state => ({
      raceListingEventsObject: state.horseRacingData.raceListingEventsObject,
      dateFilters: state.horseRacingData.dateFilters,
      activeFilter: state.horseRacingData.activeFilter,
      raceSourceKey: state.horseRacesList.raceSourceKey,
      raceListInfo: state.horseRacesList.raceListInfo,
      isShowRaceListPage: state.horseRacesList.isShowRaceListPage,
      eventData: state.horseRacesList.eventData,
      raceCourseInfo: state.horseRacesList.raceCourseInfo,
      showAllRaceOutcomes: state.horseRacesList.showAllRaceOutcomes,
      getBetslipSelectionList: state.horseRacesList.getBetslipSelectionList,
      prevStateEventData: state.horseRacesList.prevStateEventData,
      requestPage: state.horseRacingData.requestPage,
      requestSport: state.horseRacingData.requestSport,
      nextRacesData: state.horseRacingData.nextRacesData,
      allRaceDetails: state.horseRacingData.allRaceDetails,
      racingDays: state.horseRacingData.racingDays,
      filteredRaces: state.horseRacingData.filteredRaces,
      nextRacesFilterValue: state.horseRacingData.nextRacesFilterValue,
      isEnableNextRaces: state.horseRacingData.isEnableNextRaces,
      futureRacingEvents: state.horseRacingData.futureRacingEvents,
      isShowFutureOutcomesPage: state.futureRacesData.isShowFutureOutcomesPage,
      futureRacingOutcomesData: state.futureRacesData.futureRacingOutcomesData,
      oddsFormat: state.horseRacingData.oddsFormat,
      subscriptionEvents: state.horseRacesList.subscriptionEvents,
      isEnableNextRacesInHome: state.horseRacingData.isEnableNextRacesInHome,
      selectedCountryName: state.horseRacingData.selectedCountryName,
      raceCoursesList: state.horseRacesList.raceCoursesList,
      countryRaceCoursesList: state.horseRacesList.countryRaceCoursesList,
      isShowDropdownContent: state.horseRacesList.isShowDropdownContent,
      raceCountry: state.horseRacesList.raceCountry,
      showFutureRaces: state.horseRacingData.showFutureRaces,
      videoStreamObj: state.horseRacesList.videoStreamObj,
      sessionInfo: state.horseRacingData.sessionInfo,
      isVideoStream: state.horseRacesList.isVideoStream,
      showVideoStreamIcon: state.horseRacesList.showVideoStreamIcon,
      streamProviderObject: state.horseRacesList.streamProviderObject,
      performStreamUrl: state.horseRacesList.performStreamUrl,
      flumenValue: state.horseRacesList.flumenValue,
      isStreamDisplayed: state.horseRacesList.isStreamDisplayed,
      getFlumenProviders: state.horseRacesList.getFlumenProviders,
      isEnableLoader: state.horseRacingData.isEnableLoader,
      theme: this.appConfig?.theme,
    });
    const mapDispatchToProps = {
      filterRacingData: filterRacingData,
      dateFilterSelection: dateFilterSelection,
      showRaceListPage: showRaceListPage,
      gotoRaceListPage: gotoRaceListPage,
      getRaceDetails: getRaceDetails,
      setSelectedRaceMarket: setSelectedRaceMarket,
      changeSortFilter: changeSortFilter,
      callBetslip: callBetslip,
      getBetslipSelections: getBetslipSelections,
      filterSubCatRacingData: filterSubCatRacingData,
      getNextHorseRaces: getNextHorseRaces,
      populateRaceCard: populateRaceCard,
      onChangeNextRaceFilter: onChangeNextRaceFilter,
      filterFutureRacesData: filterFutureRacesData,
      futureRaceCollapse: futureRaceCollapse,
      gotoFutureRaceOutcomesPage: gotoFutureRaceOutcomesPage,
      getFutureRaceOutcomes: getFutureRaceOutcomes,
      getOddsFormat: getOddsFormat,
      fetchData: fetchData,
      emitPubSubEvent: emitPubSubEvent,
      updateFutureRacingOdds: updateFutureRacingOdds,
      showSelectedCountryRaces: showSelectedCountryRaces,
      onChangeSelectedRacecourse: onChangeSelectedRacecourse,
      hideAndShowRacingDropdown: hideAndShowRacingDropdown,
      getVideoStreamInfo: getVideoStreamInfo,
      sessionData: sessionData,
      hideAndShowStreamIcon: hideAndShowStreamIcon,
      flumenCall: flumenCall,
      changeEachWayFilter: changeEachWayFilter,
      loadData: loadData,
      disableSpinLoader: disableSpinLoader,
    };

    RaceEventsApp.serverFetch = {
      createStore: createStore,
      fetchData,
      strToTranslate: stringsToTranslate,
    };

    return connect(mapStateToProps, mapDispatchToProps)(RaceEventsApp);
  }
}

export default RacingEventsApp;
