import React from 'react';
import PropTypes from 'prop-types';
import ModalButton from 'UI/buttons/ModalButton';
import { SB, BA, BIT } from 'UI/apps/BetslipUKApp/BetslipContainer/ToolTip';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 * tooltip html
 * @param {Function} setIsOpen for handle open and close func.
 */
export const ToolTipHtml = ({
  setIsOpen,
  tokens,
  boosterinfoextended,
  removeReadMore,
}) => (
  <React.Fragment>
    <BA />
    <BIT onMouseOver={() => setIsOpen(true)}>
      <SB>
        {Translations.get('text.yhqf')}{' '}
        <b>
          <span>{tokens}</span>
        </b>{' '}
        {Translations.get('text.ttext')}
      </SB>
      {!removeReadMore && (
        <ModalButton
          onClick={() => {
            window.location.href = boosterinfoextended;
          }}
          rank="neutral"
          label={Translations.get('text.read.more')}
          noanimation={true}
          betBooster={true}
        />
      )}
    </BIT>
  </React.Fragment>
);

ToolTipHtml.propTypes = {
  setIsOpen: PropTypes.func,
  tokens: PropTypes.number,
  boosterinfoextended: PropTypes.string,
  removeReadMore: PropTypes.bool,
};
