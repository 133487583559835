import React from 'react';
import PropTypes from 'prop-types';
import { BBS } from 'UI/apps/BetslipUKApp/BetslipContainer/Text';

/** 
genrate builder strings:
split by (/) and print the description

@param {*} text (this is coming via api bets.description and data is | seprated)
@param {bool} active 
*/

export const Text = ({ text, active }) => {
  const TextArr = text && text.split('/');

  return (
    <ul>
      {TextArr.map((txt, key) => {
        return (
          <li key={key}>
            <BBS active={active}>{txt}</BBS>
          </li>
        );
      })}
    </ul>
  );
};

Text.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
};
