import {
  BA as BA_CORE,
  PT as PT_CORE,
  Wrap as Wrap_CORE,
} from 'CORE__UI/globals/PageTitle/core__pageTitleStyles';
import {
  BA as BA_LOCAL,
  PT as PT_LOCAL,
  Wrap as Wrap_LOCAL,
} from 'UI/globals/PageTitleStyles';
import replaceAnd from 'Services/translations/core__replaceAnd';
import I from 'UI/globals/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import { getComponent } from 'Services/core__imports';
import { withTheme } from 'styled-components';

const BA = getComponent(BA_LOCAL, BA_CORE);
const PT = getComponent(PT_LOCAL, PT_CORE);
const Wrap = getComponent(Wrap_LOCAL, Wrap_CORE);

const PageTitleBackIcon = ({
  showBack,
  minimal,
  backClick,
  backIconSize,
  backText,
}) => {
  return (
    <React.Fragment>
      {showBack && (
        <BA minimal={minimal} onClick={backClick}>
          <I
            iconName="icon-Back---Arrow"
            size={(backIconSize && backIconSize) || 24}
          />{' '}
          {backText !== '&nbsp;' ? <span>{backText}</span> : <span></span>}
        </BA>
      )}
    </React.Fragment>
  );
};

const PageTitle = ({
  showBack,
  backClick,
  backText,
  minimal,
  title,
  smallSize,
  titleColor,
  gcLogoPath,
  hideTitle,
  backIconSize,
  hideBoxShadow,
  backIconWithName,
  backIconWithNameRaceCadStyle,
  leftPadding,
}) => {
  return (
    <Wrap smallSize={smallSize} backIconWithName={backIconWithName}>
      <PageTitleBackIcon
        showBack={!backIconWithName && showBack}
        minimal={minimal}
        backClick={backClick}
        backIconSize={backIconSize}
        backText={backText}
      />
      {!hideTitle && (
        <PT
          minimal={minimal}
          titleColor={titleColor}
          gcLogo={!!gcLogoPath}
          hideBoxShadow={hideBoxShadow}
          backIconWithNameRaceCadStyle={backIconWithNameRaceCadStyle}
          leftPadding={leftPadding}
        >
          <PageTitleBackIcon
            showBack={backIconWithName && showBack}
            minimal={minimal}
            backClick={backClick}
            backIconSize={backIconSize}
            backText={backText}
          />
          {gcLogoPath && <img src={gcLogoPath} />}
          {typeof title === 'string' ? replaceAnd(title) : title}
          {/* Since in some places we are getting title as non string so making a validation check so that code does not break */}
        </PT>
      )}
    </Wrap>
  );
};

PageTitle.propTypes = {
  showBack: PropTypes.bool,
  backClick: PropTypes.func,
  backText: PropTypes.string,
  minimal: PropTypes.bool,
  title: PropTypes.node.isRequired,
  smallSize: PropTypes.bool,
  hideTitle: PropTypes.bool,
  titleColor: PropTypes.string,
  gcLogoPath: PropTypes.string,
  backIconSize: PropTypes.string,
  hideBoxShadow: PropTypes.bool,
  backIconWithName: PropTypes.bool,
  backIconWithNameRaceCadStyle: PropTypes.bool,
  leftPadding: PropTypes.bool,
};

PageTitleBackIcon.propTypes = {
  showBack: PropTypes.bool,
  minimal: PropTypes.bool,
  backClick: PropTypes.func,
  backIconSize: PropTypes.string,
  backText: PropTypes.string,
};

PageTitle.defaultProps = {
  gcLogoPath: '',
  leftPadding: false,
};

export default withTheme(PageTitle);
