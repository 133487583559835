import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import createStore, {
  getUrl,
  fetchData,
  postData,
  postDataAsync,
  postUserOddPreferences,
} from './core__betslip-store';
import BetslipReact from './component/core__betslipReact';
import ScDecoder from 'Services/json/core__decoder';
import { getCookie } from 'Services/cookie/core__cookies';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationString } from './core__Betslip-UK-translation';
export const Translations = new TranslationsProvider('BetslipApp');

class BetslipUKApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    if (!this.appConfig.region) {
      // if region not come to custodian params then we set default
      this.appConfig.region = 'UK';
    }

    this.url = getUrl(this.appConfig);
    const oddsFormat = getCookie('selectedOdds');
    const mapStateToProps = state => {
      const betTemplate = state.data.betTemplate;
      const builderData = state.data.builderData;
      const placedBet = state.data.placedBet;
      const apiRes = state.data;
      return {
        apiRes: apiRes,
        betTemplate: betTemplate ? betTemplate : [],
        builderData: builderData ? builderData : [],
        useBet: state.data.useBet ? state.data.useBet : {},
        placedBet: placedBet ? placedBet : {},
        url: this.url,
        appConfig: this.appConfig,
        oddsFormat: oddsFormat,
        countAccaBet: state.data?.countAccaBet || 0,
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      fetchData: fetchData,
      postData: postData,
      postDataAsync: postDataAsync,
      postUserOddPreferences: postUserOddPreferences,
    };

    BetslipReact.serverFetch = {
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: translationString, // add all the keys you need to translate client side here, put in separate file and import here
    };

    return connect(mapStateToProps, mapDispatchToProps)(BetslipReact);
  }
}

export default BetslipUKApp;
