import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/core__services';
import { updateOdds, updateOutrightOdds } from './core__racingSocketUpdates';

let wsMarketListener;
let wsOutrightMarketListener;

/**
 * subscribe to markets by market id
 * @param {Object} marketId, markets array with details
 */
export const subscribeMarket = marketId => {
  PubSub.emit(PubsubEvents.SOCKET_MARKET_SUBSCRIBER, marketId);
};

/**
 * function listens to websocket market data and update selections/odds
 */
export const listenMarket = dispatch => {
  if (!wsMarketListener) {
    wsMarketListener = PubSub.listen(
      PubsubEvents.SOCKET_MARKET_LISTEN,
      wsData => {
        dispatch(updateOdds(wsData));
      }
    );
  }
  return wsMarketListener;
};

/**
 * function listens to websocket market data and update selections/odds
 */
export const listenOutrightMarket = dispatch => {
  if (!wsOutrightMarketListener) {
    wsOutrightMarketListener = PubSub.listen(
      PubsubEvents.SOCKET_MARKET_LISTEN,
      wsData => {
        dispatch(updateOutrightOdds(wsData));
      }
    );
  }
  return wsOutrightMarketListener;
};
