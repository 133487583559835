import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS } from 'UI/globals/colours';

export const Ch6 = styled.h6`
  font-size: 12px;
  color: ${FONT.dark};
  margin: 8px 0px;
`;
export const C = styled.ul`
  list-style: none;
  margin: 8px 8px 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 8px;
`;
export const CL = styled.li`
  margin: 0;
  padding: 0;
  ${({ padding }) =>
    padding &&
    css`
      padding-top: 4px;
    `}
  ${({ booster }) =>
    booster &&
    css`
      background-color: ${BRAND.secondary};
      border-radius: 4px;
      align-items: center;
      padding: 4px;
    `}
  ${({ autoLeftMargin }) =>
    autoLeftMargin &&
    css`
      margin-left: auto;
    `}
`;

export const Ch5 = styled.h5`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  box-align: center;
  align-items: center;
  ${({ booster }) =>
    booster &&
    css`
      align-items: center;
      flex-direction: rows;
    `}
`;
export const MDC = styled.div`
  margin-bottom: 8px;
  border-top: 1px solid ${BRAND.tertiary};
`;
export const Ch5E = styled.h5`
  font-size: 14px;
  font-weight: bold;
  text-align: end;
`;

export const Ch5F = styled(Ch5)`
  display: flex;
`;

export const MULD = styled.span`
    font-size: 12px;
    margin: 4px;
    display: inline-block;
    color: ${GREYS.doveD};
}`;
export const BCLC = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 8px;
`;

export const BCL = styled.span`
  background-color: ${BRAND.primaryD};
  color: ${GREYS.white};
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TCD = styled.div`
  margin-top: 4px;
`;
export const CDOWN = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Ch6S = styled(Ch6)`
  font-size: 12px;
  color: ${FONT.dark};
  margin: 0px 0px 8px 0px;
`;
export const FbImg = styled.div`
  padding: 4px;
  margin-left: 4px;
`;

export const Ch5N = styled.h5`
  display: flex;
  box-align: center;
  align-items: center;
`;
export const Ch5s = styled.h5`
  font-weight: normal;
  font-size: 14px;
`;
