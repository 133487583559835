import {
  BL,
  BCS,
  BSSEL,
  OI,
  BLS,
  BH,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__price';
import styled, { css } from 'styled-components';
import { BRAND, FUNCTIONAL, GREYS } from '../../../globals/colours';

const BHJN = styled(BH)`
  color: ${FUNCTIONAL.negative};
`;

const BL_T = styled(BL)`
  ${({ bbFlag }) =>
    bbFlag
      ? css`
          padding: 2px;
          border-radius: 2px;
          color: ${GREYS.black};
          background: ${BRAND.secondary};
        `
      : css`
          color: ${BRAND.secondary};
        `};
`;

export { BL_T as BL, BCS, BSSEL, OI, BLS, BHJN as BH };
