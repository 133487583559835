import { PT, BA } from 'CORE__UI/globals/PageTitle/core__pageTitleStyles';
import styled, { css } from 'styled-components';
import { GREYS } from './colours';
import { BREAKPOINT_S } from './breakpoints';
const PT_GDWN = styled(PT)`
  color: ${GREYS.white};
  background-color: ${GREYS.silverD};
  box-shadow: none;
  ${({ smallSize }) =>
    smallSize &&
    css`
      width: 100%;
      max-width: 1024px;
      margin: 16px auto;
    `}
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 16px;
    line-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 0;
  }
`;
export { PT_GDWN as PT, BA };
