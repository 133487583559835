import React from 'react';
import { NoBet, NB } from 'UI/apps/BetslipUKApp/BetslipContainer/NoBet';
import PropTypes from 'prop-types';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import { QuickLink } from './elements';
import { Translations } from '../../../../core__betslip-UK-app';

/** when betslip array is blank 
  @param {boolean} showquickLink  
  @param {string} quickLink  
  @param {string} quickName  
  
*/

export const NoBetComponent = ({ showquickLink, quickLink, quickName }) => {
  const iconName = getIcon('NOBET');
  if (quickName && quickName.indexOf('{') >= 0 && quickName.indexOf('}') >= 0)
    quickName = quickName
      .split(',')
      .map(x =>
        Translations.get(
          x.replace(/[{}]/g, '').replace(/ /g, '.').toLowerCase()
        )
      )
      .join(',');
  return (
    <NB>
      <NoBet>
        {showquickLink && <I iconName={iconName} />}
        {Translations.get('text.empty')}
      </NoBet>
      {showquickLink && (
        <QuickLink quickLink={quickLink} quickName={quickName} />
      )}
    </NB>
  );
};

NoBetComponent.propTypes = {
  showquickLink: PropTypes.bool,
  quickLink: PropTypes.string,
  quickName: PropTypes.string,
};

NoBetComponent.defaultProps = {
  showquickLink: false,
  quickLink: '',
  quickName: '',
};
