import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CSUDAU,
  CSUDAD,
  CSUDAM,
  CS2,
  CS,
  CID,
  CMD,
} from 'UI/apps/BetslipUKApp/BetslipContainer/CastOrder';
import { Translations } from '../../../../../core__betslip-UK-app';

/**
 * this component for Cast list
 * if list is less then 3 then it show up down arrow user also can change positions
 * if list is greater then 3 it show Any option
 */

export const CastOrderComponent = ({
  callSetUserBetInLocalStorage,
  betTemplate,
  description,
}) => {
  const isTriCast = useMemo(() => {
    return betTemplate.map(itm => itm.betTypeRef).includes('TRI');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const count = isTriCast ? 3 : 2;

  const [descriptionArr, setDescriptionArr] = useState(
    dataSetOnupdate(betTemplate, description)
  );

  /** 
  manipulate array to set index of castPosition in Local Storage
  @params arr Array of cast
  */
  const handleCast = arr => {
    arr.forEach((iterate, k) => {
      betTemplate.map(bet => {
        if (bet.name === iterate) {
          bet['castPosition'] = 1 + k;
          callSetUserBetInLocalStorage(bet);
        }
      });
    });
  };

  /**
   * handleing Up arrow and down arrow
   * if descriptionArr is less and equal to 3 either Any
   * in localstorage set origin key position
   */

  const handleDownUp = (oldIndex, newIndex) => {
    const descArrtemp = [...descriptionArr];
    if (newIndex >= descArrtemp.length) {
      newIndex = descArrtemp.length - 1;
    }
    descArrtemp.splice(newIndex, 0, descArrtemp.splice(oldIndex, 1)[0]);
    setDescriptionArr(descArrtemp);
    handleCast(descArrtemp);
  };

  return (
    <CMD>
      {descriptionArr &&
        descriptionArr.length > 0 &&
        descriptionArr.map((desc, key) => {
          return (
            <CID key={key}>
              <CS>
                {descriptionArr.length <= count
                  ? ordinalSuffixOf(key + 1)
                  : Translations.get('text.any')}{' '}
              </CS>
              <CS2>{desc}</CS2>
              {descriptionArr.length <= count && (
                <CSUDAM>
                  {key !== descriptionArr.length - 1 && (
                    <CSUDAD
                      onClick={() => {
                        handleDownUp(key, key + 1);
                      }}
                    ></CSUDAD>
                  )}
                  {key !== 0 && (
                    <CSUDAU
                      onClick={() => {
                        handleDownUp(key, key - 1);
                      }}
                    ></CSUDAU>
                  )}
                </CSUDAM>
              )}
            </CID>
          );
        })}
    </CMD>
  );
};

CastOrderComponent.propTypes = {
  description: PropTypes.string,
  callSetUserBetInLocalStorage: PropTypes.func,
  betTemplate: PropTypes.array,
};

/**
handle suffix of indexing
@params i number retrun wth suffix data
 */

export const ordinalSuffixOf = i => {
  i = parseInt(i);
  const j = i % 10,
    k = i % 100;

  // regex to check presence of curly braces {} at the beginning and end of the string
  const regex = /^\{.*\}$/;

  const firstTextGamesys = Translations.get('text.st.alt');
  const secondTextGamesys = Translations.get('text.nd.alt');
  const thirdTextGamesys = Translations.get('text.rd.alt');
  const fourthTextGamesys = Translations.get('text.th.alt');

  const firstText = regex.test(firstTextGamesys)
    ? Translations.get('text.st')
    : firstTextGamesys;

  const secondText = regex.test(secondTextGamesys)
    ? Translations.get('text.nd')
    : secondTextGamesys;

  const thirdText = regex.test(thirdTextGamesys)
    ? Translations.get('text.rd')
    : thirdTextGamesys;

  const fourthText = regex.test(fourthTextGamesys)
    ? Translations.get('text.th')
    : fourthTextGamesys;

  if (j === 1 && k !== 11) {
    return i + firstText;
  }
  if (j === 2 && k !== 12) {
    return i + secondText;
  }
  if (j === 3 && k !== 13) {
    return i + thirdText;
  }
  return i + fourthText;
};

/**
handle data on Update or page Reload
@params betTemplate data of all bets which set on state 
@params description string pipe seprated data it come tho multiple bet first index
 */

export const dataSetOnupdate = (betTemplate, description) => {
  try {
    return betTemplate
      .filter(bet => {
        return bet.betTypeRef === 'SGL';
      })
      .sort((a, b) => {
        return a.castPosition - b.castPosition;
      })
      .map(bet => {
        return bet.name;
      });
  } catch (error) {
    return description.split('|');
  }
};
