import React from 'react';
import PropTypes from 'prop-types';
import { getComponent } from 'Services/core__imports';
import { ILR } from 'CORE__UI/buttons/RacingButton/core__racingButtonStyles';
import {
  ILM,
  IL as IL_CORE,
  PlaceTermsBox as PlaceTermsBox_CORE,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesPage';
import {
  IL as IL_LOCAL,
  PlaceTermsBox as PlaceTermsBox_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceOutcomesPage';
import {
  RACING_CONSTANTS,
  formatPlaceTermsTranslationKey,
} from '../../../core__racing-events-utils';
import { Translations } from '../../../core__racing-events-app';

const IL = getComponent(IL_LOCAL, IL_CORE);
const PlaceTermsBox = getComponent(PlaceTermsBox_LOCAL, PlaceTermsBox_CORE);

const splitPlaceTerms = terms => {
  const splitTerms = terms?.split('at');
  const [part1, part2] = splitTerms || [];
  return { part1, part2 };
};

const PlaceTermsInfo = ({ eventDetails, appConfig }) => {
  const { part1: placeTermsL1, part2: placeTermsL2 } = splitPlaceTerms(
    eventDetails?.placeTerms
  );

  const { part1: placeTermsEWL1, part2: placeTermsEWL2 } = splitPlaceTerms(
    eventDetails?.placeTermsEW
  );

  return (
    <ILR>
      {eventDetails?.selectedRaceMarket === RACING_CONSTANTS.RACE_WINNER &&
        (eventDetails?.isEWAvailable ? (
          <>
            <IL>
              <ILM>
                {appConfig?.jptranslation ? (
                  <b>
                    {formatPlaceTermsTranslationKey(
                      eventDetails?.placeTerms,
                      Translations
                    )}
                  </b>
                ) : (
                  <div>
                    {placeTermsL1 && (
                      <PlaceTermsBox>
                        <b>{placeTermsL1}</b>
                        <b>{placeTermsL2}</b>
                      </PlaceTermsBox>
                    )}
                  </div>
                )}
              </ILM>
            </IL>

            <IL>
              <ILM>
                <b>
                  {appConfig?.jptranslation ? (
                    formatPlaceTermsTranslationKey(
                      eventDetails?.placeTerms,
                      Translations
                    )
                  ) : (
                    <div>
                      {placeTermsEWL1 && (
                        <PlaceTermsBox>
                          <b>{placeTermsEWL1}</b>
                          <b>{placeTermsEWL2}</b>
                        </PlaceTermsBox>
                      )}
                    </div>
                  )}
                </b>
              </ILM>
            </IL>
          </>
        ) : (
          (!appConfig?.hideew &&
            eventDetails?.placeTerms !== RACING_CONSTANTS.WIN_ONLY && (
              <IL>
                <b>{Translations.get('racing.ew')} </b>
                <ILM>
                  <b>
                    {appConfig?.jptranslation
                      ? formatPlaceTermsTranslationKey(
                          eventDetails?.placeTerms,
                          Translations
                        )
                      : eventDetails?.placeTerms}
                  </b>
                </ILM>
              </IL>
            )) || (
            <IL>
              <b>
                {appConfig?.jptranslation
                  ? formatPlaceTermsTranslationKey(
                      eventDetails?.placeTerms,
                      Translations
                    )
                  : eventDetails?.placeTerms}{' '}
              </b>
              <p> &nbsp; &nbsp; &nbsp; &nbsp;</p>
            </IL>
          )
        ))}
    </ILR>
  );
};

PlaceTermsInfo.propTypes = {
  eventDetails: PropTypes.object,
  appConfig: PropTypes.object,
};

export default PlaceTermsInfo;
