import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import {
  RACE_COMPLETED,
  US_MARKET,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesAppConfig';
import drawnPosition from '../../helper/position-color.json';
import OddsButton from 'UI/buttons/OddsButton';
import RaceOutcomesAdditionalInfo from '../RaceOutcomesAdditionalInfo/core__raceOutcomesAdditionalInfo';
import {
  MI,
  TI,
  HI,
  OI,
  TIC as TIC_CORE,
  TIH as TIH_CORE,
  TIJ as TIJ_CORE,
  TIT as TIT_CORE,
  TII,
  TBB,
  HIA,
  OIO as OIO_CORE,
  ED,
  HRI as HRI_CORE,
  TLD,
  HIAP as HIAP_CORE,
  OB as OB_CORE,
  HV as HV_CORE,
  OMD,
  TW as TW_CORE,
  HIAW,
  HIAF,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesHorsesDogs';
import { SMRBD as SMRBD_LOCAL } from 'UI/buttons/RacingButtonStyles';
import { SMRBD as SMRBD_CORE } from 'CORE__UI/buttons/RacingButton/core__racingButtonStyles';
import {
  TIC as TIC_LOCAL,
  TIH as TIH_LOCAL,
  TIJ as TIJ_LOCAL,
  TIT as TIT_LOCAL,
  HIAP as HIAP_LOCAL,
  OIO as OIO_LOCAL,
  HRI as HRI_LOCAL,
  TW as TW_LOCAL,
  OB as OB_LOCAL,
  HV as HV_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceOutcomesHorsesDogs';
import { getComponent } from 'Services/core__imports';
import { Translations } from '../../../core__racing-events-app';
import {
  formatTrapTranslationKey,
  RACING_CONSTANTS,
  checkInfoAvailability,
} from '../../../core__racing-events-utils';
import project from '../../../../../../project';
import { Silk } from 'CORE__UI/globals/Silk/core__silk';

const TIC = getComponent(TIC_LOCAL, TIC_CORE);
const TIH = getComponent(TIH_LOCAL, TIH_CORE);
const TIJ = getComponent(TIJ_LOCAL, TIJ_CORE);
const TIT = getComponent(TIT_LOCAL, TIT_CORE);
const HIAP = getComponent(HIAP_LOCAL, HIAP_CORE);
const OIO = getComponent(OIO_LOCAL, OIO_CORE);
const HRI = getComponent(HRI_LOCAL, HRI_CORE);
const TW = getComponent(TW_LOCAL, TW_CORE);
const OB = getComponent(OB_LOCAL, OB_CORE);
const HV = getComponent(HV_LOCAL, HV_CORE);

/**
 * this component dispays list of outcomes
 * @param {*} outcomes object of each outcomes
 * @param {*} theme string to of a theme
 * @param {*} requestPage string to get requested page
 * @param {*} oddsFormat string to get odds type
 * @param {*} marketName object to get market name
 * @param {*} eventDetails object to get event details
 * @param {*} getBetslipSelectionList array to get list of betslip
 * @param {*} callBetslip function to get betslip
 * */
const SMRBD = getComponent(SMRBD_LOCAL, SMRBD_CORE);
const RaceOutcomesHorsesDogs = props => {
  const {
    outcomes,
    theme,
    oIndex,
    oddsFormat,
    requestPage,
    marketName,
    eventDetails,
    getBetslipSelectionList,
    callBetslip,
    isExpanded,
    handleAllOpened,
    appConfig,
  } = props;
  const horseRaceCountry = useSelector(
    state => state.horseRacesList.raceCountry
  );
  const { silkSvg, silk } = outcomes;
  const [additionalInfo, setAdditionalInfo] = useState(false);

  const imageUrl = silkSvg || silk;
  return (
    <HRI>
      <MI
        hasAdditionalInfo={horseRaceCountry === US_MARKET}
        isExpanded={isExpanded}
      >
        {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
          <TII>
            {imageUrl && (
              <Silk
                key={imageUrl}
                src={
                  project.racingSilksUrl
                    ? `${project.racingSilksUrl}${imageUrl}`
                    : `https://silks.fsbtech.com/${imageUrl}`
                }
                alt={`${outcomes.name} racing colours`}
              />
            )}
            <div>
              {outcomes.drawn &&
                outcomes.drawn !== RACING_CONSTANTS.NULL &&
                '(' + outcomes.drawn + ')'}
            </div>
          </TII>
        ) : (
          outcomes.ordinal &&
          marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV && (
            <TBB
              positionStyle={
                drawnPosition && drawnPosition['__' + outcomes.ordinal]
              }
              apptheme={theme}
            >
              {outcomes.ordinal}
            </TBB>
          )
        )}
        <TLD>
          <TI>
            <ED>
              {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
                <TW>
                  <TIH>
                    {outcomes.ordinal &&
                      marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
                      outcomes.ordinal}{' '}
                    {outcomes.name}{' '}
                  </TIH>{' '}
                </TW>
              ) : (
                <TIH>
                  {appConfig?.jptranslation &&
                  eventDetails?.selectedRaceMarket ===
                    RACING_CONSTANTS.DOGS_TRAPS
                    ? formatTrapTranslationKey(outcomes?.name, Translations)
                    : outcomes?.name}
                </TIH>
              )}
              <TIC>
                {outcomes?.jockey && <TIJ>J: {outcomes.jockey}</TIJ>}
                {outcomes?.trainer && <TIT>T: {outcomes.trainer}</TIT>}
              </TIC>
            </ED>
          </TI>
          <HI>
            {marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
              outcomes?.lastRuns && (
                <HIA>
                  <HIAF>
                    {outcomes?.lastRuns &&
                      (appConfig?.istranslated
                        ? Translations.get('racing.form') + ' : '
                        : ' F: ')}
                  </HIAF>
                  <HIAP>{outcomes?.lastRuns && outcomes.lastRuns}</HIAP>
                </HIA>
              )}
            <HIA>
              {outcomes.age && (
                <HIAF>
                  {appConfig?.istranslated
                    ? Translations.get('racing.age') + ' : '
                    : ' A:'}
                  {'  '}
                  {outcomes.age}
                </HIAF>
              )}
              {outcomes.weight && <HIAW>{outcomes.weight}</HIAW>}
            </HIA>
          </HI>

          {requestPage !== GLOBAL_CONSTANTS.DOGS &&
            appConfig?.isadditionalinfoforhorses &&
            outcomes.metadata && (
              <SMRBD
                onClick={() =>
                  appConfig?.showextrainfo
                    ? setAdditionalInfo(!additionalInfo)
                    : handleAllOpened(outcomes)
                }
              >
                {additionalInfo || isExpanded
                  ? `- ${Translations.get('text.show.less')}`
                  : `+ ${Translations.get('text.show.more')}`}
              </SMRBD>
            )}
        </TLD>

        <OMD>
          <OI>
            <HV>
              {marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
              outcomes?.historicalOddsValues?.length > 1 ? (
                <React.Fragment>
                  <OIO>
                    {oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.DECIMAL
                      ? parseDecimalPlaces(
                          outcomes.historicalOddsValues[1].decimal
                        )
                      : oddsFormat?.toLowerCase() ===
                        GLOBAL_CONSTANTS.FRACTIONAL
                      ? outcomes.historicalOddsValues[1].fractional
                      : outcomes.historicalOddsValues[1].decimal !==
                        GLOBAL_CONSTANTS.SP
                      ? getOdds(outcomes.historicalOddsValues[1].decimal, true)
                      : outcomes.historicalOddsValues[1].decimal}
                    {'>'}
                    {oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.DECIMAL
                      ? parseDecimalPlaces(
                          outcomes.historicalOddsValues[0].decimal
                        )
                      : oddsFormat?.toLowerCase() ===
                        GLOBAL_CONSTANTS.FRACTIONAL
                      ? outcomes.historicalOddsValues[0].fractional
                      : outcomes.historicalOddsValues[0].decimal !==
                        GLOBAL_CONSTANTS.SP
                      ? getOdds(outcomes.historicalOddsValues[0].decimal, true)
                      : outcomes.historicalOddsValues[0].decimal}
                  </OIO>
                </React.Fragment>
              ) : (
                marketName?.typeRef !== GLOBAL_CONSTANTS.UFAV &&
                outcomes?.historicalOddsValues?.length > 0 && (
                  <OIO>
                    {oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.DECIMAL
                      ? parseDecimalPlaces(
                          outcomes.historicalOddsValues[0].decimal
                        )
                      : oddsFormat?.toLowerCase() ===
                        GLOBAL_CONSTANTS.FRACTIONAL
                      ? outcomes.historicalOddsValues[0].fractional
                      : outcomes.historicalOddsValues[0].decimal !==
                        GLOBAL_CONSTANTS.SP
                      ? getOdds(outcomes.historicalOddsValues[0].decimal, true)
                      : outcomes.historicalOddsValues[0].decimal}
                  </OIO>
                )
              )}
            </HV>
            {(requestPage === GLOBAL_CONSTANTS.DOGS ||
              eventDetails.eachwayFilter === 'SEW') && (
              <OB>
                <OddsButton
                  data-test={oIndex === 0 && 'btn-oddsclick'}
                  size={'default'}
                  odds={
                    outcomes.nonRunner
                      ? GLOBAL_CONSTANTS.NR
                      : outcomes.fractionalOdds &&
                        oddsFormat?.toLowerCase() ===
                          GLOBAL_CONSTANTS.FRACTIONAL
                      ? outcomes.fractionalOdds
                      : outcomes.decimalOdds &&
                        oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.DECIMAL
                      ? parseDecimalPlaces(outcomes.decimalOdds)
                      : outcomes.decimalOdds &&
                        outcomes.decimalOdds !== GLOBAL_CONSTANTS.SP &&
                        oddsFormat?.toLowerCase() === GLOBAL_CONSTANTS.AMERICAN
                      ? getOdds(outcomes.decimalOdds, true)
                      : GLOBAL_CONSTANTS.SP
                  }
                  inactive={
                    eventDetails.state === GLOBAL_CONSTANTS.COMPLETED ||
                    !outcomes?.active
                  }
                  selected={getBetslipSelectionList.indexOf(outcomes.id) > -1}
                  onClick={() => {
                    eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED &&
                      callBetslip(outcomes.id, outcomes.ordinal);
                  }}
                  oddsTrend={outcomes?.oddsStatus}
                  disabled={
                    eventDetails.state === RACE_COMPLETED || !outcomes?.active
                  }
                />
              </OB>
            )}
            {(requestPage !== GLOBAL_CONSTANTS.DOGS ||
              eventDetails.eachwayFilter === 'EEW') &&
              marketName?.selectionEW?.map((outcomesEW, oIndexEW) => {
                return (
                  outcomes.name === outcomesEW.name && (
                    <OB key={oIndexEW}>
                      <OddsButton
                        data-test={'btn-oddsclickEW'}
                        size={'default'}
                        odds={
                          outcomesEW.nonRunner
                            ? GLOBAL_CONSTANTS.NR
                            : outcomesEW.fractionalOdds &&
                              oddsFormat?.toLowerCase() ===
                                GLOBAL_CONSTANTS.FRACTIONAL
                            ? outcomesEW.fractionalOdds
                            : outcomesEW.decimalOdds &&
                              oddsFormat?.toLowerCase() ===
                                GLOBAL_CONSTANTS.DECIMAL
                            ? parseDecimalPlaces(outcomesEW.decimalOdds)
                            : outcomesEW.decimalOdds &&
                              outcomesEW.decimalOdds !== GLOBAL_CONSTANTS.SP &&
                              oddsFormat?.toLowerCase() ===
                                GLOBAL_CONSTANTS.AMERICAN
                            ? getOdds(outcomesEW.decimalOdds, true)
                            : GLOBAL_CONSTANTS.SP
                        }
                        inactive={
                          eventDetails.state === GLOBAL_CONSTANTS.COMPLETED ||
                          !outcomesEW?.active
                        }
                        selected={
                          getBetslipSelectionList.indexOf(outcomesEW.id) > -1
                        }
                        onClick={() => {
                          eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED &&
                            callBetslip(outcomesEW.id);
                        }}
                        oddsTrend={outcomesEW?.oddsStatus}
                        disabled={
                          eventDetails.state === RACE_COMPLETED ||
                          !outcomesEW?.active
                        }
                      />
                    </OB>
                  )
                );
              })}
          </OI>
        </OMD>
      </MI>
      {requestPage !== GLOBAL_CONSTANTS.DOGS &&
        appConfig?.isadditionalinfoforhorses &&
        (additionalInfo || isExpanded) && (
          <RaceOutcomesAdditionalInfo
            metadata={outcomes.metadata}
            extraInfo={
              appConfig.showextrainfo && checkInfoAvailability(outcomes)
            }
          />
        )}
    </HRI>
  );
};

export default RaceOutcomesHorsesDogs;

RaceOutcomesHorsesDogs.propTypes = {
  outcomes: PropTypes.object,
  oIndex: PropTypes.number,
  theme: PropTypes.string,
  oddsFormat: PropTypes.string,
  requestPage: PropTypes.string,
  marketName: PropTypes.object,
  eventDetails: PropTypes.object,
  getBetslipSelectionList: PropTypes.array,
  callBetslip: PropTypes.func,
  isExpanded: PropTypes.bool,
  handleAllOpened: PropTypes.func,
  appConfig: PropTypes.object,
};

RaceOutcomesHorsesDogs.defaultProps = {
  outcomes: {},
  oIndex: 0,
  theme: '',
  oddsFormat: '',
  requestPage: '',
  marketName: {},
  eventDetails: {},
  getBetslipSelectionList: [],
};
