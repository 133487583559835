import styled from 'styled-components';
import { CARD, BRAND, GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import EA from 'UI/globals/ExpandArrow';

// Div to get layout
export const Card = styled.div(CARD);
// Top header div in outcomes page
export const HD = styled(Card)`
  color: ${BRAND.primary};
  padding: 16px;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }

  /* new style - race card 
     remove local styles related to this
     (if needed)
    */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
`;

export const HDEP = styled(Card)`
  color: ${GREYS.black};
  padding: 16px;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${BRAND.primary};
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  b {
    margin: 4px;
    color: ${GREYS.black};
  }
  div {
    color: ${GREYS.black};
    white-space: nowrap;
  }
`;
export const HDEPH = styled.div`
  margin-left: 16px;
`;
// Div to display race times
export const RU = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  flex-direction: row;
  div {
    min-width: 77px;
    max-width: 77px;
    min-height: 32px;
    max-height: 32px;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    padding-bottom: 4px;
  }
`;
// Main div to display back button
export const SD = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;
// Race course name display div
export const RCD = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 4px;
  font-size: 18px;
  img {
    margin-right: 8px;
    width: 24px;
  }
  cursor: pointer;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  @media (max-width: ${BREAKPOINT_S}) {
    margin-bottom: 20px;
  }
`;

// Osiros specific back label class
export const RBB = styled.div`
  margin-right: 8px;
  padding-right: 8px;
`;

// Div to display content
export const CD = styled.div`
  width: 300px;
  box-shadow: ${CARD.boxShadow};
  border-radius: 4px;
  background-color: ${GREYS.white};
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 8px;
`;
// Div to dropdown content
export const ED = styled.div`
  overflow: auto;
  max-height: 400px;
`;
// Div to display content data
export const DCD = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
  }
  font-weight: ${props => props?.addActiveClass && 'bold'};
`;
// Div to display racecourse names
export const DCN = styled.div`
  color: ${GREYS.black};
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
`;

// colouring the expandable arrow
export const SEAr = styled(EA)`
  &:after {
    border-color: ${FONT.primary} !important;
  }
`;

// Div to display race course and date
export const RDC = styled.span`
  padding-right: 8px;
`;

export const EHD = styled.div`
  width: max-content;
  padding-right: 12px;
`;

export const RCDC = styled.div`
  display: flex;
`;

export const Back = styled.p`
  line-height: 36px;
  margin-block: 20px;
  cursor: pointer;
  color: ${GREYS.black};
  @media (max-width: ${BREAKPOINT_S}) {
    margin-block: 0px;
    margin-bottom: 20px;
  }
`;
