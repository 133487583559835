import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const M = styled.div`
  font-family: ${theme.fontFamily};
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  font-size: 12px;
  padding: 8px 16px;
  line-height: 20px;
  user-select: none;
`;
