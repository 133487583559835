import {
  QLM,
  QLMH4,
  QLUL,
  QLLI,
  QLA,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__quickLink';
import styled from 'styled-components';
import { GREYS, BRAND } from '../../../globals/colours';

const QLAJN = styled(QLA)`
  background-color: ${GREYS.silver};
  color: ${GREYS.white};
`;

const QLM_GDWN = styled(QLM)`
  border-top: 1px solid ${BRAND.primary};
`;

const QLMH4_GDWN = styled(QLMH4)`
  color: ${GREYS.white};
`;
export { QLM_GDWN as QLM, QLMH4_GDWN as QLMH4, QLUL, QLLI, QLAJN as QLA };
