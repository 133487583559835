import {
  BS,
  SMM,
  SB,
  MDI,
  FDMD,
  BSM,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__betslipContainer';
import styled, { css } from 'styled-components';
import { GREYS } from '../../../globals/colours';

import { BREAKPOINT_M } from 'CORE__UI/globals/core__breakpoints';

const SMM_EX = styled(SMM)`
  @media (max-width: ${BREAKPOINT_M}) {
    width: 98%;
  }
`;
const BS_EX = styled(BS)`
  background-color: ${GREYS.silverD};
  height: 100%;
  ${({ isSafari }) => {
    if (isSafari) {
      return css`
        height: 90%;
        @media (min-width: ${BREAKPOINT_M}) {
          width: 100%;
          position: absolute;
        }
      `;
    }
  }}
`;

const BSM_EX = styled(BSM)`
  @media (min-width: ${BREAKPOINT_M}) {
    overflow-y: scroll;
  }
`;

const SB_EX = styled(SB)`
  overscroll-behavior-y: none;
`;

export { BS_EX as BS, SMM_EX as SMM, SB_EX as SB, MDI, FDMD, BSM_EX as BSM };
