import {
  MH,
  ED,
  OT,
} from 'CORE__UI/apps/RacingEventsApp/core__raceOutcomesMarkets';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const OT_EX = styled(OT)`
  color: ${GREYS.white};
`;

const MH_EX = styled(MH)`
  padding: 0;
`;

const ED_EX = styled(ED)`
  @media (max-width: ${BREAKPOINT_S}) {
    border-bottom: 1px solid ${FONT.primary};
  }
`;

export { MH_EX as MH, ED_EX as ED, OT_EX as OT };
