import React, { useRef } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import project from '../../../project';

export const TDM = styled('div')`
  position: absolute;
  left: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      visibility: hidden;
      pointer-event: none;
    `}
  ${({ hideReadmore }) =>
    hideReadmore &&
    css`
      translate(0px, 27px)
    `}
`;
/**
 * ToolTip Component
 * @param {object} boxRef main div ref corresponding div for that tooltip genrate
 * @param {object} properties properties of Popper in object ref.  https://popper.js.org/docs/v2/modifiers/
 * @param {boolean} isOpen is basic flag that handle visibility of tooltip
 * @param {object} children HTML object .
 * @param {Function} setIsOpen basic function which handle Popper(tooltip) display on mouseover
 */

export const ToolTip = ({
  boxRef,
  properties,
  isOpen,
  children,
  setIsOpen,
}) => {
  const tooltipRef = useRef();
  const { styles, attributes } = usePopper(
    boxRef.current,
    tooltipRef.current,
    properties
  );
  return (
    <TDM
      isOpen={isOpen}
      ref={tooltipRef}
      style={styles.popper}
      {...attributes.popper}
      onMouseOver={() => setIsOpen(true)}
      hideReadmore={project.hideReadmore}
    >
      {children}
    </TDM>
  );
};

ToolTip.propTypes = {
  boxRef: PropTypes.object,
  properties: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.object,
};
