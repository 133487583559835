import {
  SB,
  BIT,
  BA,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__tooltip';
import styled from 'styled-components';
import { GREYS } from '../../../globals/colours';

const BITJN = styled(BIT)`
  border: 1px solid ${GREYS.silver};
  background-color: ${GREYS.silverD};
`;
const SBJN = styled(SB)`
  color: ${GREYS.white};
`;
const BAJN = styled(BA)`
  &:after {
    background-color: ${GREYS.silverD};
    border: 1px solid ${GREYS.silver};
  }
`;

export { SBJN as SB, BITJN as BIT, BAJN as BA };
