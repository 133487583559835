import styled from 'styled-components';
import { Select } from '../../../globals/Select/core__select';
import theme from 'UI/globals/theme';
import { GREYS, FONT } from 'UI/globals/colours';

export const StyledSelect = styled(Select)`
  width: 88px;
  border-radius: 4px;
  background-color: ${GREYS.white};
  font-family: ${theme.fontFamily};
  font-size: 14px;
  margin: 4px 0px 4px 20px;
  flex: 1;
  text-transform: capitalize;
  div[class*='singleValue'] {
    cursor: pointer;
    padding-right: 8px;
    overflow: initial;
    &:after {
      content: '';
      border: 1px solid ${FONT.dark};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      top: 3px;
      left: 58px;
    }
  }
`;

export const styles1 = {
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    justifyContent: 'left',
    minHeight: 'auto',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${GREYS.black}`,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    textAlign: 'center',
  }),
  menu: provided => ({
    ...provided,
    width: '8em',
  }),
  valueContainer: provided => ({
    ...provided,
    justifyContent: 'left',
  }),
};
