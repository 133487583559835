import { BRAND, GREYS } from 'UI/globals/colours';
import styled from 'styled-components';

export const WSPOPUP = styled.div`
  height: 56px;
  background-color: ${BRAND.tertiaryL};
  border: 1px ${BRAND.tertiaryL};
  border-left: 1px solid ${GREYS.white};
  border-right: 1px solid ${GREYS.white};
`;

export const WSP = styled.p`
  text-align: center;
  font-size: 12px;
`;

export const WSUL = styled.ul`
  display: flex;
  justify-content: space-around;
`;
export const WSLI1BTN = styled.button`
  margin: 4px 8px;
  width: 100%;
  display: block;
  background-color: ${GREYS.white};
  border: none;
  padding: 4px 0px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
`;
export const WSLI2BTN = styled.button`
  margin: 4px 8px 0px 0px;
  width: 100%;
  display: block;
  background-color: ${GREYS.white};
  border: none;
  padding: 4px 0px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
`;
export const TDIV = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid ${BRAND.tertiaryL};
  display: block;
  margin: 0px auto;
`;
export const TLI = styled.li`
  width: 50%;
  padding: 0px 8px;
`;
