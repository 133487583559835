import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TopSection, BottomSection } from './elements';
import { Constants } from '../../../../core__betslip-constants';
import { genrateSlips } from '../../../../core__betslip-utils';
import PROJECTS_GLOBAL_CONSTANTS from '../../../../../../../project';
import { TaxBottomSection } from './elements/core__taxBottomSection';
import { BTCBSB } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__tab';
import I from 'UI/globals/Icons';
import { Translations } from '../../../../core__betslip-UK-app';

/* that for generated summary calculation   */

/**
 @param {array} betTemplate
 @param {func} handleDelete
 @param {bool} suspended
 @param {func} handlePlaceBet
 @param {object} UserAuthData
 @param {string} region
 @param {bool} isError
 @param {number} tax
 @param {func} handleWsAcceptDecline
 @param {func} postApiCall
 @param {string} taxbycountry
 */
export const Summary = ({
  betTemplate,
  handleDelete,
  suspended,
  handlePlaceBet,
  UserAuthData,
  region,
  isError,
  tax,
  handleWsAcceptDecline,
  postApiCall,
  taxbycountry,
  card,
  appConfig,
  showBetslipButton,
  isMaxBetError,
  bottomNavData,
}) => {
  const [freebetStake, setfreebetStake] = useState({
    freeStake: Constants.DEFAULT_AMT,
    hasFreebet: false,
  });
  const [totalStake, setTotalStake] = useState(Constants.DEFAULT_AMT);
  const [potentialReturns, setPotentialReturns] = useState(
    Constants.DEFAULT_AMT
  );
  const [fee, setFee] = useState(Constants.DEFAULT_AMT);
  const [netReturn, setnetReturn] = useState(Constants.DEFAULT_AMT);
  const [checkChanges, handleCheckChanges] = useState(false);
  const [openTaxSection, setOpenTaxSection] = useState(false);
  const [SB, setSB] = useState(false);
  const [bonusStake, setBonusStake] = useState();

  const { countryCode } = PROJECTS_GLOBAL_CONSTANTS;
  const betObj = betTemplate.find(item => !item.isValid);
  const betCategoriesWithTax = Constants.TAXED_CATEGORIES;
  let hasBetCategoriesWithTax = false;
  // Variable from appConfig, so only a specific brand can use it (Boolean value)
  const appConfigBetCategoryTax = appConfig.hasbetcategorieswithtax;
  if (appConfigBetCategoryTax) {
    for (const bet of betTemplate) {
      if (betCategoriesWithTax.includes(bet.catRef)) {
        hasBetCategoriesWithTax = true;
      }
    }
  }

  //  check if tax is not avalable so find value to tabycountry
  if ((!tax || tax === undefined) && taxbycountry) {
    const countryTaxArr = taxbycountry.split('-');
    if (countryTaxArr.length > 0 && countryTaxArr[1]) {
      tax = countryTaxArr[1];
    }
  }

  function handleTaxSection(value = false) {
    setOpenTaxSection(() => value);
  }

  useEffect(() => {
    // check odds change
    let nf = false;

    const calc = genrateSlips(betTemplate, tax);
    setTotalStake(calc['totalStake']);
    setPotentialReturns(calc['potentialReturns']);
    setfreebetStake(calc['freeStakeData']);
    setFee(calc['fee']);
    setnetReturn(calc['netReturn']);
    setSB(calc['bonusFund']);
    setBonusStake(calc?.betBonusData);
    for (const bet of betTemplate) {
      if (bet.priceChangeNotification) {
        nf = true;
      }
    }
    handleCheckChanges(nf);
  }, [betTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {showBetslipButton && (
        <BTCBSB onClick={() => handleDelete({ type: 'deleteall' })}>
          <I iconName="icon-Bin" size={16} />
          {Translations.get('text.clear')}
        </BTCBSB>
      )}
      <TopSection
        handleDelete={handleDelete}
        suspended={suspended}
        region={region}
      />
      {hasBetCategoriesWithTax && openTaxSection && (
        <TaxBottomSection
          tax={tax}
          potentialReturns={potentialReturns}
          fee={fee}
          currencySymbol={UserAuthData?.currencyCode || null}
          netReturn={netReturn}
          closeTaxSection={() => handleTaxSection(false)}
        />
      )}
      <BottomSection
        currencySymbol={UserAuthData?.currencyCode || null}
        handleDelete={handleDelete}
        betTemplate={betTemplate}
        potentialReturns={potentialReturns}
        totalStake={totalStake}
        suspended={suspended}
        handlePlaceBet={handlePlaceBet}
        isLogin={UserAuthData?.accessToken}
        freebetStake={freebetStake}
        isError={isError}
        netReturn={netReturn}
        fee={fee}
        tax={tax}
        checkChanges={checkChanges}
        handleWsAcceptDecline={handleWsAcceptDecline}
        postApiCall={postApiCall}
        userbalance={UserAuthData?.balance || null}
        card={card}
        SB={SB}
        countryCode={countryCode}
        hasBetCategoriesWithTax={hasBetCategoriesWithTax}
        openTaxSection={() => handleTaxSection(true)}
        bonusStake={bonusStake}
        isValidStake={betObj?.isValid}
        isMaxBetError={isMaxBetError}
        isOddsAcceptance={appConfig?.isoddsacceptance}
        useChatWithBroker={appConfig?.usechatwithbroker}
        bottomNavData={bottomNavData}
      />
    </React.Fragment>
  );
};

Summary.propTypes = {
  betTemplate: PropTypes.array,
  handleDelete: PropTypes.func,
  handlePlaceBet: PropTypes.func,
  suspended: PropTypes.bool,
  UserAuthData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  region: PropTypes.string,
  isError: PropTypes.bool,
  tax: PropTypes.number,
  handleWsAcceptDecline: PropTypes.func,
  postApiCall: PropTypes.bool,
  taxbycountry: PropTypes.string,
  card: PropTypes.bool,
  appConfig: PropTypes.object,
  showBetslipButton: PropTypes.bool,
  isMaxBetError: PropTypes.bool,
  bottomNavData: PropTypes.object,
};

Summary.defaultProps = {
  showBetslipButton: false,
};
