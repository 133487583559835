import {
  BU,
  BD,
  BCLC,
  BCL,
  BCSM,
  BSMD,
  BMD,
  BetslipContentBetBoxPot,
  FBPR,
  BBh5,
  ENS,
  ERR,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__betBox';
import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS } from '../../../globals/colours';

const BMDJN = styled(BMD)`
  margin: initial;
  border-bottom: 1px solid ${FONT.dark};
  box-shadow: initial;
  border-radius: inherit;
  ${({ hideBottomBorder }) =>
    hideBottomBorder &&
    css`
      border-bottom: none;
    `}
`;
const BCSMJN = styled(BCSM)``;

const BCLJN = styled(BCL)`
  margin: 0px 0px 0px 8px;
  background-color: ${BRAND.highlight};
  color: ${FONT.light};
`;
const BUJN = styled(BU)`
  margin: 0px 8px 0px 8px;
`;

const FBPRJN = styled(FBPR)`
  align-items: flex-end;
  flex-direction: unset;
`;

const BBh5JN = styled(BBh5)`
  margin: 0px 0px 0px 8px;
  color: ${GREYS.white};
`;
const ENSJN = styled(ENS)`
  margin-left: 8px;
`;
const BSMDJN = styled(BSMD)`
  ${({ showBonusText }) =>
    showBonusText &&
    css`
      color: ${FONT.dark};
      background-color: ${FONT.secondary};
    `}
  ${({ arrow }) =>
    arrow &&
    css`
      border-top: 8px solid ${BRAND.secondaryL};
    `}
`;

export {
  BUJN as BU,
  BD,
  BCLC,
  BCLJN as BCL,
  BCSMJN as BCSM,
  BSMDJN as BSMD,
  BMDJN as BMD,
  BetslipContentBetBoxPot,
  FBPRJN as FBPR,
  BBh5JN as BBh5,
  ENSJN as ENS,
  ERR,
};
