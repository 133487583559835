import styled from 'styled-components';
import { CARD, BRAND, FONT, GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'CORE__UI/globals/core__breakpoints';
// Race Listing main layout div
export const RL = styled.div(CARD);
// Group by country display div
export const CD = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${BRAND.tertiary};
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.primary)};
  font-weight: bold;
  padding: 0 16px;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `none`};
  img {
    margin-right: 8px;
    width: 24px;
    height: 20px;
  }
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  cursor: pointer;
`;
// Group by race course display main div under country
export const RCD = styled.div`
  display: flex;
  flex: 1;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-bottom: 4px;
  &:last-of-type {
    border-bottom: none;
  }
`;
// Race course name display div
export const RC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.dark)};
  cursor: pointer;
`;
// Ul to display race time buttons
export const RU = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT_S}) {
    padding-bottom: 4px;
    overflow: auto;
    flex-wrap: nowrap;
    div {
      min-width: 77px;
      max-width: 77px;
      min-height: 32px;
      max-height: 32px;
    }

    ::-webkit-scrollbar {
      display: none;
    }
    html {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
`;
// Empty div to display no data text
export const ED = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Display list of countries
export const CFD = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: visible;
  padding-bottom: 16px;
  padding-top: 8px;
`;
// Flag display div
export const FDD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  max-width: 88px;
  height: 52px;
  box-shadow: ${props => props?.background && CARD.boxShadow};
  border-radius: 4px;
  background-color: ${props => props?.background && GREYS.white};
  margin-right: 8px;
  cursor: pointer;
  flex-direction: column;
  img {
    min-width: 24px;
    max-width: 24px;
    height: 18px;
    margin-bottom: 4px;
  }
`;
// Country display div
export const CDD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => props?.background && 700};
`;

export const COUNTRY_DIV = styled.div`
  display: flex;
  align-items: center;
`;
