import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollCarousel } from 'Services/carousel/core__scroll-carousel';
import moment from 'moment';
import {
  callBetslip,
  filterNextRacesData,
  populateRaceCard,
  onChangeNextRaceFilter,
  getCountryDropDown,
} from '../../../core__racing-events-store';
import drawnPosition from '../../helper/position-color.json';
import {
  carouselSettings as carouselSettings__LOCAL,
  filters as filters__LOCAL,
} from '../../helper/racingAppConfig';
import {
  carouselSettings as carouselSettings__CORE,
  filters as filters__CORE,
} from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import {
  RC,
  RCB,
  RCT,
  RCN,
  NR,
  NRT,
  NRP,
  NRI,
  NRH,
  NRB,
  NRBM,
  NRS,
  NRDS,
  NRN,
  NRNE,
  NRJN,
  NRO,
  NRF,
  SC,
  ROB,
  RCV,
  NPT as NPT_LOCAL,
  NPTEP as NPTEP_LOCAL,
  RCP as RCP_LOCAL,
} from 'UI/apps/RacingEventsApp/RaceCarousel';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { Translations } from '../../../core__racing-events-app';
import { getOdds } from 'Services/global/core__american-format';

import { getAppConfig as getAppConfig_LOCAL } from '../../helper/racingAppConfig';
import { getAppConfig as getAppConfig_CORE } from 'CORE__UI/apps/RacingEventsApp/core__racing-carousel-config';
import project from '../../../../../../project';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import Constants from '../../../../../../project';
import {
  ED,
  NPT as NPT_CORE,
  FLAG_ROW,
  RCP as RCP_CORE,
  NPTEP as NPTEP_CORE,
} from 'CORE__UI/apps/RacingEventsApp/core__raceCarousel';
import { __getBreakPoint } from 'Services/globalstyles/core__globalstyles';

import { detectMob } from '../../../../BetslipUKApp/core__betslip-utils';

import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getComponent } from 'Services/core__imports';
import {
  BP,
  formatPlaceTermsTranslationKey,
} from '../../../core__racing-events-utils';
import { Silk } from 'CORE__UI/globals/Silk/core__silk';

const NPT = getComponent(NPT_LOCAL, NPT_CORE);
const RCP = getComponent(RCP_LOCAL, RCP_CORE);
const NPTEP = getComponent(NPTEP_LOCAL, NPTEP_CORE);
const carouselSettings = getComponent(
  carouselSettings__LOCAL,
  carouselSettings__CORE
);
const filters = getComponent(filters__LOCAL, filters__CORE);
const getAppConfig = getComponent(getAppConfig_LOCAL, getAppConfig_CORE);

export class RaceCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselArrowState: false,
      isMobile: false,
      betslipSelectionIds: props?.getBetslipSelectionList || [],
      selectionIds: [],
      screenSize: '',
      screenWidth: '',
    };
  }

  componentDidMount() {
    const isMobile = detectMob();
    isMobile && this.setState({ carouselArrowState: true });
    this.props.filterNextRacesData(
      this.props.nextRacesData,
      0,
      BP[__getBreakPoint()] + 1
    );
    this.setState({
      ...this.setState,
      isMobile: isMobile,
      screenSize: __getBreakPoint(),
      screenWidth: window.innerWidth,
    });
    this.subBreakPoint = PubSub.listen(PubsubEvents.BreakPoint, breakPoint => {
      this.setState({
        ...this.state,
        isMobile:
          breakPoint !== 'BREAKPOINT_XL' && breakPoint !== 'BREAKPOINT_L',
        screenSize: breakPoint,
      });
    });
    window.addEventListener('resize', this.screenWidthUpdate);
  }

  static getDerivedStateFromProps(prevProps, nextProps) {
    if (nextProps?.selectionIds) {
      return {
        betslipSelectionIds: [...nextProps.selectionIds],
      };
    }
    return {};
  }

  screenWidthUpdate = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  componentWillUnmount() {
    this.subBreakPoint?.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.screenSize !== prevState.screenSize) {
      let ind;
      if (
        Array.isArray(this.props.nextRacesData) &&
        this.props.nextRacesData.length > 0
      ) {
        for (const [p, prevObj] of this.props.nextRacesData.entries()) {
          if (prevObj.active) {
            ind = p;
            break;
          }
        }
      }
      this.props.filterNextRacesData(
        this.props.nextRacesData,
        ind,
        BP[this.state.screenSize] + 1
      );
    }
  }

  render() {
    const {
      nextRacesData,
      nextRacesFilterValue,
      allRaceDetails,
      requestPage,
      theme,
      raceListingEventsObject,
      activeFilter,
      appConfig,
    } = this.props;

    const props = this.props;
    const settings = carouselSettings();
    const selectionIds = this.state.selectionIds;
    const betslipSelectionIds = this.state.betslipSelectionIds;

    const toggleBetslipSelections = (selectionId, ordinal) => {
      const index = betslipSelectionIds.indexOf(selectionId);
      if (index > -1) {
        betslipSelectionIds.splice(index, 1);
      } else {
        betslipSelectionIds.push(selectionId);
      }
      this.setState({ betslipSelectionIds: [...betslipSelectionIds] });
      props.callBetslip(selectionId, ordinal);
    };

    const raceCarouselSlider = (props, settings) => {
      return (
        <React.Fragment>
          <SC
            data-test={'test-hover'}
            onMouseOver={() =>
              !this.state.isMobile &&
              this.setState({ carouselArrowState: true })
            }
            onMouseOut={() =>
              !this.state.isMobile &&
              this.setState({ carouselArrowState: false })
            }
          >
            <ScrollCarousel
              arrows={this.state.carouselArrowState}
              {...settings}
            >
              {props.nextRacesData &&
                props.nextRacesData
                  .slice(0, getAppConfig().nextraceslimit)
                  .map((obj, ind) => {
                    return (
                      <RC
                        data-test={ind === 0 && 'tab-carouseldata'}
                        key={ind}
                        addActive={
                          this.state.isMobile && obj.mobileActive
                            ? obj.active
                            : !this.state.isMobile && obj.active
                        }
                        onClick={() => {
                          props.filterNextRacesData(
                            props.nextRacesData,
                            ind,
                            BP[this.state.screenSize] + 1
                          );
                        }}
                      >
                        <RCB>
                          <RCT
                            addActive={
                              this.state.isMobile && obj.mobileActive
                                ? obj.active
                                : !this.state.isMobile && obj.active
                            }
                          >
                            {moment(obj.scheduledStart).format(
                              project.timeFormats.racing.quaternary || 'HH:mm'
                            )}
                          </RCT>
                          {getAppConfig().displaystreamifavailable &&
                            obj?.videoStreamAvailable && <RCV></RCV>}

                          {appConfig?.showextraplacesui &&
                            obj?.market?.some(market =>
                              market.book?.some(
                                book => book.placeTermsType === 'MANUAL'
                              )
                            ) && <RCP />}
                        </RCB>
                        <RCN
                          addActive={
                            this.state.isMobile && obj.mobileActive
                              ? obj.active
                              : !this.state.isMobile && obj.active
                          }
                        >
                          {obj.racecorseName}
                        </RCN>
                      </RC>
                    );
                  })}
            </ScrollCarousel>
          </SC>
          <NRP>
            {props.filteredRaces &&
              props.filteredRaces.map((eventDetails, eIndex) => {
                return (
                  <NRI key={eIndex}>
                    <NRH
                      onClick={() => {
                        props.populateRaceCard(
                          eventDetails,
                          allRaceDetails,
                          getCountryDropDown(
                            eventDetails,
                            activeFilter,
                            raceListingEventsObject
                          )
                        );
                      }}
                    >
                      <NPT>
                        <FLAG_ROW>
                          {eventDetails?.countryCode && (
                            <img
                              alt={eventDetails?.countryCode}
                              src={`${Constants.staticPath.basePath}${
                                Constants.staticPath.bucketImages
                              }${
                                Constants.staticPath.folderFlags
                              }${eventDetails?.countryCode.toLowerCase()}.svg`}
                            />
                          )}
                          {moment(eventDetails?.scheduledStart).format(
                            project.timeFormats.racing.quaternary || 'HH:mm'
                          )}{' '}
                          {eventDetails?.racecorseName}
                        </FLAG_ROW>
                      </NPT>
                      {!appConfig?.hideew &&
                      getAppConfig().showextraplacesui &&
                      eventDetails?.market?.some(market =>
                        market?.book?.some(
                          book => book?.placeTermsType === 'MANUAL'
                        )
                      ) ? (
                        <NPTEP active={true}>
                          <b>{Translations.get('racing.ew')} </b>
                          <p>
                            {getAppConfig()?.jptranslation
                              ? formatPlaceTermsTranslationKey(
                                  eventDetails?.placeTerms,
                                  Translations
                                )
                              : eventDetails?.placeTerms}
                          </p>
                        </NPTEP>
                      ) : (
                        (!appConfig?.hideew &&
                          eventDetails?.placeTerms !== 'Win Only' && (
                            <NPT active={true}>
                              <b>{Translations.get('racing.ew')} </b>
                              <p>
                                {getAppConfig()?.jptranslation
                                  ? formatPlaceTermsTranslationKey(
                                      eventDetails?.placeTerms,
                                      Translations
                                    )
                                  : eventDetails?.placeTerms}
                              </p>
                            </NPT>
                          )) || (
                          <NPT active={true}>
                            <b>
                              {getAppConfig()?.jptranslation
                                ? formatPlaceTermsTranslationKey(
                                    eventDetails?.placeTerms,
                                    Translations
                                  )
                                : eventDetails?.placeTerms}{' '}
                            </b>
                            <p></p>
                          </NPT>
                        )
                      )}
                    </NRH>

                    <NRB>
                      {eventDetails?.market.length > 0 &&
                        eventDetails?.market.map(marketName => {
                          return marketName?.selection?.map(
                            (outcomes, oIndex) => {
                              const { silkSvg, silk, id } = outcomes;
                              const imageUrl = silkSvg || silk;
                              selectionIds.push([outcomes.id]);
                              return (
                                <NRBM key={id}>
                                  {requestPage !== GLOBAL_CONSTANTS.DOGS ? (
                                    <NRS>
                                      {imageUrl && (
                                        <Silk
                                          key={imageUrl}
                                          src={
                                            project.racingSilksUrl
                                              ? `${project.racingSilksUrl}${imageUrl}`
                                              : `https://silks.fsbtech.com/${imageUrl}`
                                          }
                                          alt={`${outcomes.name} racing colours`}
                                        />
                                      )}
                                    </NRS>
                                  ) : (
                                    <NRDS
                                      positionStyle={
                                        drawnPosition &&
                                        drawnPosition['__' + outcomes.ordinal]
                                      }
                                      apptheme={theme}
                                    >
                                      {outcomes.ordinal}
                                    </NRDS>
                                  )}
                                  <NRN>
                                    <NRNE>{outcomes.name}</NRNE>
                                    <NRJN>{outcomes.jockey}</NRJN>
                                  </NRN>
                                  <NRO>
                                    <ROB
                                      data-test={
                                        oIndex === 0 && 'btn-oddsclick'
                                      }
                                      size={'default'}
                                      odds={
                                        outcomes.nonRunner
                                          ? GLOBAL_CONSTANTS.NR
                                          : outcomes.fractionalOdds &&
                                            props.oddsFormat?.toLowerCase() ===
                                              GLOBAL_CONSTANTS.FRACTIONAL
                                          ? outcomes.fractionalOdds
                                          : outcomes.decimalOdds &&
                                            props.oddsFormat?.toLowerCase() ===
                                              GLOBAL_CONSTANTS.DECIMAL
                                          ? parseDecimalPlaces(
                                              outcomes.decimalOdds
                                            )
                                          : outcomes.decimalOdds &&
                                            outcomes.decimalOdds !==
                                              GLOBAL_CONSTANTS.SP &&
                                            props.oddsFormat?.toLowerCase() ===
                                              GLOBAL_CONSTANTS.AMERICAN
                                          ? getOdds(outcomes.decimalOdds, true)
                                          : GLOBAL_CONSTANTS.SP
                                      }
                                      inactive={
                                        eventDetails?.state ===
                                          GLOBAL_CONSTANTS.COMPLETED ||
                                        !outcomes?.active
                                      }
                                      selected={
                                        props.getBetslipSelectionList.indexOf(
                                          outcomes.id
                                        ) > -1
                                      }
                                      onClick={() => {
                                        eventDetails?.state !==
                                          GLOBAL_CONSTANTS.COMPLETED &&
                                          toggleBetslipSelections(
                                            outcomes.id,
                                            outcomes.ordinal
                                          );
                                      }}
                                      disabled={
                                        eventDetails?.state ===
                                          GLOBAL_CONSTANTS.COMPLETED ||
                                        !outcomes?.active
                                      }
                                    />
                                  </NRO>
                                </NRBM>
                              );
                            }
                          );
                        })}
                    </NRB>
                    <NRF
                      data-test={eIndex === 0 && 'btn-racecard'}
                      onClick={() => {
                        props.populateRaceCard(
                          eventDetails,
                          allRaceDetails,
                          getCountryDropDown(
                            eventDetails,
                            activeFilter,
                            raceListingEventsObject
                          )
                        );
                      }}
                    >
                      {Translations.get('racing.view.full.racecard')}
                    </NRF>
                  </NRI>
                );
              })}
          </NRP>
        </React.Fragment>
      );
    };
    return (
      <React.Fragment>
        <NR>
          <NRT>{Translations.get('racing.next.races')}</NRT>
        </NR>
        {nextRacesData?.length > 0 ? (
          <React.Fragment>
            {nextRacesFilterValue === filters.today &&
              raceCarouselSlider(props, settings)}
            {nextRacesFilterValue === filters.tomorrow &&
              raceCarouselSlider(props, settings)}
          </React.Fragment>
        ) : (
          <ED>{Translations.get('text.nodata')}</ED>
        )}
      </React.Fragment>
    );
  }
}

RaceCarousel.propTypes = {
  eventDetails: PropTypes.array,
  onChangeNextRaceFilter: PropTypes.func,
  nextRacesData: PropTypes.array,
  racingDays: PropTypes.array,
  getBetslipSelectionList: PropTypes.array,
  filteredRaces: PropTypes.array,
  nextRacesFilterValue: PropTypes.string,
  allRaceDetails: PropTypes.array,
  requestPage: PropTypes.string,
  filterNextRacesData: PropTypes.func,
  callBetslip: PropTypes.func,
  populateRaceCard: PropTypes.func,
  oddsFormat: PropTypes.string,
  theme: PropTypes.string,
  selectionIds: PropTypes.array,
  raceListingEventsObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  activeFilter: PropTypes.string,
  appConfig: PropTypes.object,
};

export const mapStateToProps = state => {
  return {
    nextRacesData: state.horseRacingData.nextRacesData,
    racingDays: state.horseRacingData.racingDays,
    getBetslipSelectionList: state.horseRacesList.getBetslipSelectionList,
    filteredRaces: state.horseRacingData.filteredRaces,
    nextRacesFilterValue: state.horseRacingData.nextRacesFilterValue,
    allRaceDetails: state.horseRacingData.allRaceDetails,
    requestPage: state.horseRacingData.requestPage,
    oddsFormat: state.horseRacingData.oddsFormat,
    raceListingEventsObject: state.horseRacingData.raceListingEventsObject,
    activeFilter: state.horseRacingData.activeFilter,
  };
};

export const mapDispatchToProps = {
  callBetslip: callBetslip,
  filterNextRacesData: filterNextRacesData,
  populateRaceCard: populateRaceCard,
  onChangeNextRaceFilter: onChangeNextRaceFilter,
};

RaceCarousel.defaultProps = {
  requestPage: '',
  nextRacesData: [],
  allRaceDetails: [],
  racingDays: [
    {
      value: 'Today',
      label: 'Today',
    },
    {
      value: 'Tomorrow',
      label: 'Tomorrow',
    },
  ],
  nextRacesFilterValue: 'Today',
  filteredRaces: [],
  oddsFormat: '',
  getBetslipSelectionList: [],
  isMobile: false,
  raceListingEventsObject: {},
  activeFilter: 'Today',
};

export const RacesCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceCarousel);
