import React from 'react';
import { BetslipCurrency } from '../elements';
import PropTypes from 'prop-types';
import { PPD } from 'UI/apps/BetslipUKApp/BetslipContainer/FreeBetStake';
import { Translations } from '../../../../../core__betslip-UK-app';
import { Constants } from '../../../../../core__betslip-constants';

export const UserFreeBetStake = ({
  currencySymbol,
  freebetCredit,
  isEachWay,
}) => {
  return (
    <PPD>
      <div>
        <BetslipCurrency symbol={currencySymbol} />
        {freebetCredit} {Translations.get('text.free.bet')}
      </div>
      {isEachWay && <span>{Constants.FREEBETBYTWO}</span>}
    </PPD>
  );
};
UserFreeBetStake.propTypes = {
  currencySymbol: PropTypes.string,
  freebetCredit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEachWay: PropTypes.bool,
};
