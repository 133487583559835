import React from 'react';
import PropTypes from 'prop-types';
import { EventsLink } from 'UI/navigation/TopLink';
import { connect } from 'react-redux';
import {
  setSelectedRaceMarket,
  changeSortFilter,
} from '../../../core__racing-events-store';
import { MH, ED } from 'UI/apps/RacingEventsApp/RaceOutcomesMarkets';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { Translations } from '../../../core__racing-events-app';
import {
  formatTranslationKey,
  RACING_CONSTANTS,
} from '../../../core__racing-events-utils';

export class RaceOutcomesMarkets extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      eventData,
      eventDetails,
      eIndex,
      isTranslated,
      showDogsMarketNameAsRacecard,
    } = this.props;
    return (
      <React.Fragment>
        {(eventDetails.state !== GLOBAL_CONSTANTS.COMPLETED ||
          !Object.prototype.hasOwnProperty.call(eventDetails, 'results')) &&
          eventDetails.status !== 'A' && (
            <MH>
              <ED>
                {eventDetails?.market.length > 0 &&
                  eventDetails?.market
                    ?.filter(market => market?.active)
                    .map((marketName, mnIndex) => {
                      return (
                        marketName.typeRef !== GLOBAL_CONSTANTS.UFAV &&
                        marketName.typeRef !== GLOBAL_CONSTANTS.EFPTP && (
                          <EventsLink
                            data-test={mnIndex === 0 && 'tab-racemarket'}
                            key={mnIndex}
                            label={
                              isTranslated
                                ? Translations.get(
                                    `text.${formatTranslationKey(
                                      marketName.name
                                    )}`
                                  )
                                : showDogsMarketNameAsRacecard &&
                                  marketName.name?.toLowerCase() ===
                                    RACING_CONSTANTS.WIN_BY_NAME
                                ? Translations.get('racing.racecard')
                                : marketName.name
                            }
                            active={
                              marketName?.name ===
                              eventDetails?.selectedRaceMarket
                            }
                            onClick={() => {
                              this.props.setSelectedRaceMarket(
                                eventData,
                                marketName.name,
                                eIndex
                              );
                            }}
                          />
                        )
                      );
                    })}
              </ED>
            </MH>
          )}
      </React.Fragment>
    );
  }
}

RaceOutcomesMarkets.propTypes = {
  setSelectedRaceMarket: PropTypes.func,
  changeSortFilter: PropTypes.func,
  eventData: PropTypes.array,
  eventDetails: PropTypes.object,
  eIndex: PropTypes.number,
  isTranslated: PropTypes.bool,
  showDogsMarketNameAsRacecard: PropTypes.bool,
};

export const mapStateToProps = state => {
  return {
    eventData: state.horseRacesList.eventData,
  };
};

export const mapDispatchToProps = {
  setSelectedRaceMarket: setSelectedRaceMarket,
  changeSortFilter: changeSortFilter,
};

RaceOutcomesMarkets.defaultProps = {
  eventData: [],
};

export const RaceMarkets = connect(
  mapStateToProps,
  mapDispatchToProps
)(RaceOutcomesMarkets);
