import { DH5 } from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__description';
import styled, { css } from 'styled-components';
import { GREYS } from '../../../globals/colours';

const DH5JN = styled(DH5)`
  margin: 0px 0px 0px 8px;
  ${({ active }) =>
    !active
      ? css`
          color: ${GREYS.silver};
        `
      : css`
          color: ${GREYS.white};
        `};
`;
export { DH5JN as DH5 };
