import styled from 'styled-components';
import { BRAND, GREYS, SHADOW, FONT } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const OD = styled.div`
  width: 100%;
`;
export const ODUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  position: relative;
`;
export const TL = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 8px 0px 8px;
  font-family: ${theme.fontFamily};
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  width: 100px;
  height: 40px;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  color: ${({ isSelected }) => (isSelected ? BRAND.primary : GREYS.white)};
  &:not(:last-child):after {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0px;
    bottom: 0;
    background-color: ${({ theme }) =>
      theme.dark ? BRAND.primary : BRAND.tertiary};
  }
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      theme.dark ? GREYS.silver : GREYS.doveD};
  }
`;

export const TS = styled.span`
  color: ${FONT.darkMute};
`;
export const TI = styled.input``;

export const Button = styled.button`
  background-color: transparent;
  box-shadow: none;
  font-size: 20px;
  cursor: pointer;
  border: none;
  text-shadow: none;
`;

export const MO = styled.div`
  display: flex;
  height: 120px;
  right: 16px;
  top: 48px;
  flex-flow: column wrap;
  background-color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.dove)};
  align-content: flex-end;
  position: absolute;
  border-radius: 4px;
  box-shadow: ${SHADOW};
`;
