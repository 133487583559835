import React from 'react';
import PropTypes from 'prop-types';
import { BBS } from 'UI/apps/BetslipUKApp/BetslipContainer/Booster';
import {
  BB,
  BBC,
  BBA,
  BBP,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__booster';
import MB from 'UI/buttons/ModalButton';
import { Constants } from '../../../../../core__betslip-constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import { getIcon } from 'Services/icons/core__icons';
import { Translations } from '../../../../../core__betslip-UK-app';
import { decodeHtmlEntity } from '../../../../../../KYCApp/core__kyc-utils';
/**
 * @param {*} boosterkey string unique key
 * @param {*} fractionalReturns string return getting in betModifier
 * @param {*} boostedFractionalReturns string getting in boostedFractionalReturns
 * @param {*} boostedReturns number getting from booster return
 * @param {*} bbFlag boolen
 * @param {*} price object of current price which come form API
 */
export const BetslipContentBooster = ({
  boosterkey,
  fractionalReturns,
  boostedFractionalReturns,
  boostedReturns,
  handleBooster,
  bbFlag,
  oddsFormat,
  betBooster,
  price,
  betModifier,
  betModifierFlag,
  iconMarginR,
  secondaryOddsBoostButton,
}) => {
  const iconNameBoost = getIcon('PRICE_BOOST');
  const iconNameUnboost = getIcon('PRICE_UNBOOST');
  const unboostPrice =
    (oddsFormat === Constants.DECIMAL && parseDecimalPlaces(price.decimal)) ||
    (oddsFormat === Constants.AMERICAN && getOdds(price.decimal, true)) ||
    price.fractional;

  let boostPrice = '';
  if (betModifierFlag && betModifier[0]) {
    boostPrice =
      (oddsFormat === Constants.DECIMAL &&
        parseDecimalPlaces(betModifier[0]['boostedReturns'])) ||
      (oddsFormat === Constants.AMERICAN &&
        getOdds(betModifier[0]['boostedReturns'], true)) ||
      betModifier[0]['boostedFractionalReturns'];
  } else {
    boostPrice =
      (oddsFormat === Constants.DECIMAL &&
        parseDecimalPlaces(boostedReturns)) ||
      (oddsFormat === Constants.AMERICAN && getOdds(boostedReturns, true)) ||
      boostedFractionalReturns;
  }

  return (
    <BBS key={boosterkey} bbFlag={bbFlag}>
      {bbFlag ? (
        <MB
          bbFlag={true}
          onClick={() => {
            handleBooster({
              fretn: fractionalReturns,
              retn: price.decimal,
              bbFlag: false,
            });
          }}
          label={
            secondaryOddsBoostButton ? (
              <BBC>
                <BBP>{unboostPrice}</BBP>
                <BBA>{decodeHtmlEntity(false, `&#171;`)} </BBA>
                <BB>{Translations.get('text.unboost')} </BB>
              </BBC>
            ) : (
              `${Translations.get('text.unboost.odds.to')} ${unboostPrice}`
            )
          }
          rank={`secondary`}
          iconName={!secondaryOddsBoostButton && iconNameUnboost}
          betBooster={betBooster}
          unboost={true}
          iconMarginR={iconMarginR}
        ></MB>
      ) : (
        <MB
          onClick={() => {
            handleBooster({
              fretn: boostedFractionalReturns,
              retn: boostedReturns,
              bbFlag: true,
            });
          }}
          label={
            secondaryOddsBoostButton ? (
              <BBC>
                <BB>{Translations.get('text.boost')} </BB>
                <BBA>{decodeHtmlEntity(false, `&#187;`)} </BBA>
                <BBP boost={true}>{boostPrice}</BBP>
              </BBC>
            ) : (
              `${Translations.get('text.boost.odds.to')} ${boostPrice}`
            )
          }
          rank={`secondary`}
          iconName={!secondaryOddsBoostButton && iconNameBoost}
          iconMarginR={8}
          betBooster={betBooster}
          boost={true}
        ></MB>
      )}
    </BBS>
  );
};

BetslipContentBooster.propTypes = {
  boosterkey: PropTypes.string,
  fractionalReturns: PropTypes.string,
  boostedFractionalReturns: PropTypes.string,
  boostedReturns: PropTypes.number,
  handleBooster: PropTypes.func,
  bbFlag: PropTypes.bool,
  oddsFormat: PropTypes.string,
  betBooster: PropTypes.bool,
  price: PropTypes.object,
  betModifier: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  betModifierFlag: PropTypes.bool,
  iconMarginR: PropTypes.string,
  secondaryOddsBoostButton: PropTypes.bool,
};
