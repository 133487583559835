import React, { useEffect, useState } from 'react';
import { BetslipContentBetBox } from './core__betbox';
import {
  getDataofModifier,
  getDataOfPrice,
  stakeLimit,
  getBetCountByTypeRef,
  marketRefCount,
} from '../../../../core__betslip-utils';
import { SocketMessage } from './elements';
import { Constants } from '../../../../core__betslip-constants';
import { parseDecimalPlacesPotReturn } from 'Services/global/core__odds-format';
import project from '../../../../../../../project';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
/**
   BetBoxComponent:
   Return JSX for beting stuff
   @param {*} betStuff (accept whole object of bet , key )
   ** for future if we need then we Increases the params 
   */
export const BetBoxLayout = betStuff => {
  const {
    bet,
    handleDelete,
    actTab,
    callSetUserBetInLocalStorage,
    wallet,
    currencySymbol,
    balance,
    handleWsAcceptDecline,
    oddsFormat,
    isLogin,
    setShowFB,
    setErrorObj,
    isError,
    hidebetslip,
    countAccaBet,
    hideNextBetBonus,
    errorObj,
    hideBottomBorder,
    secondaryOddsBoostButton,
    betTemplate,
  } = betStuff;
  const [userStakeBet, setUserStakeBet] = useState(bet?.userStake || '');
  const [potReturn, setpotReturn] = useState(reCalcPotRtn(bet));
  const [bonus, setBonus] = useState(
    bet['bonusFunds'] ? bet['bonusFunds'] : false
  );

  const [priceFractional, setPriceFractional] = useState(
    bet?.priceFractional || bet.betModifierFractional
  );
  const [priceDecimal, setPriceDecimal] = useState(
    bet?.priceDecimal || bet.betModifierReturns
  );
  const [bbFlag, setBbFlag] = useState(bet.bbFlag);
  const [userfreeBet, setUserFreeBet] = useState(
    (bet?.hasFreebet && bet) || {}
  );
  const betModifier =
    bet.betTypeRef !== Constants.BBB
      ? getDataofModifier(bet.betModifier, bet.betModifierFlag)
      : { price: bet.price, betTypeRef: bet.betTypeRef, betCount: 1 };

  const price =
    bet.betTypeRef !== Constants.BBB ? getDataOfPrice(bet.price) : bet.price;
  const eventId = bet.eventId;
  /* 
here we remove Restriction category form wallet
  */

  const newWallet = [];
  if (wallet && wallet.length > 0) {
    for (const fbW of wallet) {
      let catRefRes, subCatRefRes;
      const betTypeRef = fbW?.['betTypeRestriction'];
      const minSelection = fbW?.['minSelectionsRestriction'];
      const eventId = fbW?.['eventIdRestriction'];
      const marketTypeRef = fbW?.['marketTypeRestriction']?.includes('1x2')
        ? ['1x2']
        : fbW?.['marketTypeRestriction']
        ? fbW['marketTypeRestriction'].split('/')
        : undefined;
      const countByTypeRef = getBetCountByTypeRef(bet);
      const countByMarketRef = marketRefCount(betTemplate, marketTypeRef);
      const betNumCount =
        bet.betTypeRef === Constants.BBB ? bet.sentence.length : countByTypeRef;
      if (
        fbW?.['catRefRestriction'] &&
        fbW['catRefRestriction'].indexOf('/') > -1
      ) {
        const catSubcatArr = fbW['catRefRestriction'].split('/');
        catRefRes = catSubcatArr[0];
        subCatRefRes = catSubcatArr[1];
      }
      if (
        (!fbW['catRefRestriction'] ||
          fbW['catRefRestriction'] === bet.catRef) &&
        (!betTypeRef || betTypeRef === bet.betTypeRef) &&
        (!minSelection ||
          (bet.betTypeRef === Constants.BBB
            ? minSelection <= betNumCount
            : minSelection <= betNumCount && bet.betModifierBetCount === 1)) &&
        (!eventId || eventId === bet.eventId) &&
        (!marketTypeRef ||
          (bet.betTypeRef === 'SGL'
            ? marketTypeRef?.some(marketRef => marketRef === bet.marketTypeRef)
            : minSelection
            ? minSelection <= countByMarketRef
            : countByTypeRef <= countByMarketRef))
      ) {
        newWallet.push(fbW);
      } else if (
        catRefRes &&
        subCatRefRes &&
        catRefRes === bet.catRef &&
        subCatRefRes === bet.subcatRef &&
        (!betTypeRef || betTypeRef === bet.betTypeRef) &&
        (!minSelection ||
          (bet.betTypeRef === Constants.BBB
            ? minSelection <= betNumCount
            : minSelection <= betNumCount && bet.betModifierBetCount === 1)) &&
        (!eventId || eventId === bet.eventId) &&
        (!marketTypeRef ||
          (bet.betTypeRef === 'SGL'
            ? marketTypeRef?.some(marketRef => marketRef === bet.marketTypeRef)
            : minSelection
            ? minSelection <= countByMarketRef
            : countByTypeRef <= countByMarketRef))
      ) {
        newWallet.push(fbW);
      }
    }
  }

  /* 
  Handle Booster 
  */
  const handleBooster = values => {
    setPriceDecimal(values.retn);
    setPriceFractional(values.fretn);
    setBbFlag(values.bbFlag);
    handleBetStake(
      userStakeBet,
      {
        retn: values.retn,
        fretn: values.fretn,
        bbFlag: values.bbFlag,
        betCount: betModifier.betCount || 1,
        type: betModifier.type,
        priceBookType: price.bookType,
        priceBookId: price.bookId,
        priceId: price.id,
      },
      bet.selectionId ? bet.selectionId : bet.name,
      callSetUserBetInLocalStorage
    );
  };

  /* 
    Handle FreeBets
  */
  const handleFreeBet = values => {
    let retAmt = '';
    if (bet.betTypeRef === Constants.BBB) {
      retAmt = values.credit * bet?.price;
      retAmt = retAmt - values.credit;
      bet['userStake'] = '';
      bet['totalStake'] = 0;
      bet['_returns'] = retAmt;
      bet['_returns'] = retAmt;
      bet['returnsText'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
      bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
      bet['bbFlag'] = false;
      bet['hasFreebet'] = Object.keys(values).length > 0;
      bet['selectedFreebetId'] = values.id && values.id;
      bet['freebetCredit'] = values.credit && values.credit;
      bet['freebetCreditFormatted'] = values.credit && values.credit;
      bet['freebetRestrictions'] =
        values.catRefRestriction && values.catRefRestriction;
    } else {
      if (!bet.betModifierReturns && bet?.betModifier) {
        const betModifier = bet.betModifier[0];
        bet.betModifierReturns = betModifier.betCount;
      }

      if (!bet.priceDecimal && bet?.price) {
        const price = bet.price[0];
        bet.priceDecimal = price.decimal;
      }
      if (
        bet.betModifierBetCount &&
        (bet.priceDecimal || bet.betModifierReturns)
      ) {
        const tempBet = values.credit ? values.credit : 0;
        if (bet.multiple) {
          retAmt = (bet.betModifierReturns - betModifier.betCount) * tempBet;
        } else {
          retAmt = (bet.priceDecimal - betModifier.betCount) * tempBet;
        }
      }

      retAmt =
        (retAmt && parseDecimalPlacesPotReturn(parseFloat(retAmt))) ||
        Constants.N_A;
      bet['userStake'] = '';
      bet['totalStake'] = 0;
      bet['_returns'] = retAmt;
      bet['returnsText'] = retAmt;
      bet['_returnsFormatted'] = retAmt;
      bet['lastModified'] = JSON.parse(JSON.stringify(new Date()));
      bet['bbFlag'] = false;
      bet['hasFreebet'] = Object.keys(values).length > 0;
      bet['selectedFreebetId'] = values.id && values.id;
      bet['freebetCredit'] = values.credit && values.credit;
      bet['freebetCreditFormatted'] = values.credit && values.credit;
      bet['freebetRestrictions'] =
        values.catRefRestriction && values.catRefRestriction;
    }

    setUserFreeBet(bet);
    setpotReturn(retAmt);
    callSetUserBetInLocalStorage(bet);
  };

  /*
    Handle user Bet Stake
  */
  const handleBetStake = (bet, betMf, id, callSetUserBetInLocalStorage) => {
    var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    var regexExp = /\d+\.\d{2}/;
    const customerSession = FSBCustomerSession.getSession();
    if (
      customerSession &&
      customerSession.currencyCode === project.defaultCurrency &&
      project?.decimalStake
    ) {
      regex = /^[1-9]\d*$/;
      regexExp = /\d+/;
    } else {
      if (project?.decimalStake && !customerSession) {
        regex = /^[1-9]\d*$/;
        regexExp = /\d+/;
      }
    }
    try {
      if (bet && !bet.toString().match(regex)) {
        if (bet < 0) {
          setUserStakeBet('');
          bet = 0;
        } else if (isNaN(bet / 1)) {
          bet = parseFloat(bet);
          if (isNaN(bet)) {
            bet = '';
            regexExp;
          }
          setUserStakeBet(bet);
        } else if (!isNaN(bet)) {
          const rX = new RegExp(regexExp);
          bet = parseFloat(bet.toString().match(rX)[0]);
          setUserStakeBet(bet);
        }
      }
    } catch (error) {
      bet = userStakeBet;
    }
    let retAmt = '';
    const raw = {};
    const Obj = new Array();
    if (betMf?.betTypeRef === Constants.BBB) {
      retAmt = bet * betMf.retn;
      raw['id'] = id;
      raw['isValid'] = betMf.isValid;
      raw['userStake'] = bet;
      raw['totalStake'] = bet;
      raw['_returns'] = retAmt;
      raw['_returns'] = retAmt;
      raw['returnsText'] = retAmt;
      raw['_returnsFormatted'] = retAmt;
      raw['lastModified'] = JSON.parse(JSON.stringify(new Date()));
    } else {
      if (betMf.betCount && betMf.retn) {
        const tempBet = bet;
        if (betMf.ewretn) {
          retAmt = tempBet * betMf.ewretn;
        } else {
          retAmt = tempBet * betMf.retn;
        }
      }

      retAmt = retAmt && parseDecimalPlacesPotReturn(retAmt);

      raw['id'] = id;
      raw['isValid'] = betMf.isValid;
      raw['hasFreebet'] = false;
      raw['betModifierFlag'] = betMf.betModifierFlag;
      raw['userStake'] = bet;
      //multiply by betcount
      raw['totalStake'] = betMf.betCount * bet;
      raw['_returns'] = isNaN(retAmt) ? Constants.N_A : retAmt;
      raw['returnsText'] = isNaN(retAmt) ? Constants.N_A : retAmt;
      raw['_returnsFormatted'] = isNaN(retAmt) ? Constants.N_A : retAmt;
      raw['lastModified'] = JSON.parse(JSON.stringify(new Date()));
      raw['priceDecimal'] = betMf.retn;
      raw['priceFractional'] = betMf.fretn;
      raw['bbFlag'] = betMf.bbFlag;
      raw['betModifierBetCount'] = betMf.betCount
        ? betMf.betCount
        : betModifier.betCount;
      raw['bonusFunds'] = bonus;
      raw['eventId'] = eventId;
      raw['priceChangeNotification'] = betMf?.priceChangeNotification;
    }
    Obj[id] = raw;

    // if error component on view port
    if (isError) {
      setErrorObj({
        isError: false,
        message: '',
      });
    }
    callSetUserBetInLocalStorage(raw);
    if (!isNaN(retAmt)) {
      setpotReturn(retAmt);
    }
  };
  /*
    set User data in Stake 
  */
  const setUserStake = (
    userBet,
    betMf,
    price,
    isInavlid = true,
    priceChangeNotification
  ) => {
    setUserStakeBet(userBet);
    const valueRtn = {};
    if (betMf?.betTypeRef === Constants.BBB) {
      valueRtn['retn'] = price;
      valueRtn['betTypeRef'] = Constants.BBB;
    } else {
      if (bbFlag) {
        valueRtn['retn'] = betMf.boostedReturns;
      } else {
        valueRtn['retn'] = price?.decimal || betMf.returns;
      }
      if (bbFlag) {
        valueRtn['fretn'] = betMf.boostedFractionalReturns;
      } else {
        if (bet.betModifierFlag) {
          valueRtn['ewretn'] = betMf.returns;
          valueRtn['fretn'] = price?.fractional;
        } else {
          valueRtn['fretn'] = price?.fractional || betMf.fractionalReturns;
        }
      }

      valueRtn['betCount'] = betMf?.betCount || 1;
      valueRtn['chkVal'] = betMf?.chkVal || false;
      valueRtn['type'] = betMf?.type;
      valueRtn['priceBookType'] = price.bookType;
      valueRtn['priceBookId'] = price.bookId;
      valueRtn['priceId'] = price.id;
      valueRtn['bbFlag'] = bbFlag;
      valueRtn['betModifierFlag'] = bet.betModifierFlag;
      valueRtn['isValid'] = isInavlid;
      valueRtn['priceChangeNotification'] = priceChangeNotification;
    }
    handleBetStake(
      userBet,
      valueRtn,
      bet.selectionId ? bet.selectionId : bet.name,
      callSetUserBetInLocalStorage
    );
  };
  /* 
Handle Bonus Bets
*/
  const handleBonus = (data, bet) => {
    setBonus(data);
    bet['bonusFunds'] = data;
    callSetUserBetInLocalStorage(bet);
  };

  const changeNewPrice = bet => {
    bet.updateNewPrice = false;
    callSetUserBetInLocalStorage(bet);
  };

  useEffect(() => {
    if (bet?.priceChangeNotification || bet?.updateNewPrice) {
      const updatedPrice = getDataOfPrice(bet.price);

      setPriceFractional(updatedPrice?.fractional);
      setPriceDecimal(updatedPrice?.decimal);

      setpotReturn(reCalcPotRtn(bet));
      if (bet?.updateNewPrice) {
        changeNewPrice(bet);
      }
    } else {
      setUserFreeBet((bet?.hasFreebet && bet) || {});
      setBbFlag(bet.bbFlag);
    }
  }, [userStakeBet, bet, potReturn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setpotReturn(reCalcPotRtn(bet));
    setUserStakeBet(bet?.userStake);
  }, [bet]);

  const handleEwData = (chkVal, betModifier) => {
    let modifierObj = {};
    if (chkVal === true) {
      // if freebet selected this it diselect and hide freebet button
      bet.hasFreebet = false;
      bet.freebetCredit = '';
      bet.freebetRestrictions = '';
      bet.freebetCreditFormatted = '';
      bet._returns = '';
      bet._returnsFormatted = '';
      bet.returnsText = '';
      bet.betModifierFlag = true;
      setUserFreeBet(bet);
      modifierObj = betModifier[1];
      modifierObj['chkVal'] = chkVal;
    } else {
      bet.betModifierFlag = false;
      bet.priceDecimal = price['decimal'];
      modifierObj = betModifier[0];
      modifierObj['chkVal'] = chkVal;
    }

    if (userStakeBet) {
      setUserStake(userStakeBet, modifierObj, price);
    } else {
      //    if user add amount and check o  setUserStake(userStakeBet, modifierObj, price);n checkbox
      bet.betModifierBetCount = modifierObj.betCount;
      bet.betModifierFlag = chkVal;
      bet.betModifierType = modifierObj.type;
      callSetUserBetInLocalStorage(bet);
    }
  };
  const handleFocusOut = (userBet, betMf, price, priceChangeNotification) => {
    let isInavlid = true;
    if (
      userBet?.length === 0 ||
      (userBet > 0 &&
        userBet >=
          stakeLimit(
            bet?.limit,
            (bet.multiple && 'MULTIPLE_MIN_STAKE') || 'SINGLE_MIN_STAKE'
          ))
    ) {
      isInavlid = true;
    } else {
      userBet !== undefined ? (isInavlid = false) : (isInavlid = true);
    }
    setUserStake(userBet, betMf, price, isInavlid, priceChangeNotification);
  };
  return (
    <React.Fragment key={bet.id}>
      {bet?.priceChangeNotification && bet.active && (
        <SocketMessage
          bet={bet}
          handleWsAcceptDecline={handleWsAcceptDecline}
          oddsFormat={oddsFormat}
          hidebetslip={hidebetslip}
        />
      )}
      <BetslipContentBetBox
        secondaryOddsBoostButton={secondaryOddsBoostButton}
        handleFocusOut={handleFocusOut}
        bet={bet}
        handleDelete={handleDelete}
        actTab={actTab}
        priceFractional={priceFractional}
        setUserStake={setUserStake}
        userStakeBet={userStakeBet}
        potReturn={
          (potReturn === Constants.N_A && potReturn) || parseFloat(potReturn)
        }
        currencySymbol={currencySymbol}
        handleBooster={handleBooster}
        bbFlag={bbFlag}
        handleFreeBet={handleFreeBet}
        userfreeBet={userfreeBet}
        newWallet={newWallet}
        handleBonus={handleBonus}
        bonus={bonus}
        balance={balance}
        betMf={betModifier}
        price={price}
        oddsFormat={oddsFormat}
        isLogin={isLogin}
        pD={priceDecimal}
        setShowFB={setShowFB}
        handleEwData={handleEwData}
        countAccaBet={countAccaBet}
        hideNextBetBonus={hideNextBetBonus}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        hideBottomBorder={hideBottomBorder}
      />
    </React.Fragment>
  );
};

const reCalcPotRtn = bet => {
  let retAmt = '';
  if (
    bet.betModifierBetCount &&
    (bet.priceDecimal || bet.betModifierBetCount) &&
    bet.betTypeRef !== Constants.BBB
  ) {
    let tempBet = 0;
    if (bet.hasFreebet) {
      tempBet = parseFloat(bet.freebetCredit);
      const bmf = bet.betModifier[0];
      if (bet.multiple) {
        retAmt =
          ((bmf?.returns ? bmf?.returns : bet.betModifierReturns) -
            bmf.betCount) *
          tempBet;
      } else {
        retAmt = (bet.priceDecimal - bmf.betCount) * tempBet;
      }
    } else {
      tempBet = parseFloat(bet.userStake);
      if (bet.multiple) {
        const bmf = bet.betModifier[0];
        retAmt =
          tempBet * (bmf?.returns ? bmf?.returns : bet.betModifierReturns);
      } else {
        if (bet.betModifierFlag) {
          const bmf = bet.betModifier[1];
          retAmt = tempBet * bmf.returns;
        } else {
          retAmt = tempBet * bet.priceDecimal;
        }
      }
    }
  } else if (bet.betTypeRef === Constants.BBB) {
    if (bet.hasFreebet) {
      retAmt = bet.price * parseFloat(bet.freebetCredit);
      retAmt = retAmt - parseFloat(bet.freebetCredit);
    } else {
      retAmt = bet.price * parseFloat(bet.userStake);
    }
  }
  if (isNaN(retAmt)) {
    return '';
  } else {
    return retAmt;
  }
};
