import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { CARD, BRAND, FONT, GREYS } from 'UI/globals/colours';
import OddsButton from 'UI/buttons/OddsButton';

// Race Listing main layout div
export const RL = styled.div(CARD);

// Future races page title
export const FT = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 4px;
  font-size: 18px;
  cursor: pointer;
`;
// Future races title main div
export const FRMD = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;
// Future races time display div
export const FRTE = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.dark)};
`;
// Main div to display back button
export const FB = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

// Market name display div
export const MKN = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
  height: 48px;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.primary)};
  font-weight: bold;
  span {
    position: absolute;
    right: 16px;
  }
`;

// Future races outcomes display div
export const FRSO = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 4px;
  color: ${FONT.dark};
  cursor: pointer;
  width: 100%;
`;
// Future races outcome name display div
export const FROT = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

// Future race outcomes display main div
export const FROM = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// Future race odds button display main div
export const FRO = styled.div`
  width: 417px;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 166px;
  }
`;

export const B = styled(OddsButton)`
  flex: 1;
  width: 100%;
`;

// Future races footer div
export const FRF = styled.div`
  text-align: right;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.silver)};
  padding: 0 16px 16px;
`;

//for streaming icon
export const STC = styled.p`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1em;
  justify-content: space-between;
  & span {
    padding-left: 0.5em;
  }
`;

export const BC = styled.a`
  cursor: pointer;
`;
