import { FONT, GREYS, SHADOW } from 'UI/globals/colours';
import styled, { css } from 'styled-components';

import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import theme from 'UI/globals/theme';

export const PT = styled.h1`
  font-family: ${theme.fontFamily};
  padding: 16px;
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  margin: 16px 0;
  font-size: 18px;
  color: ${({ theme, titleColor }) =>
    titleColor ? titleColor : theme.dark ? GREYS.white : FONT.primary};
  font-weight: bold;
  box-shadow: ${SHADOW};
  border-radius: 4px;
  ${({ gcLogo }) => {
    // for safer gambling logo
    return (
      gcLogo &&
      css`
        display: flex;
        align-items: center;
        img {
          width: 45px;
          padding-right: 8px;
        }
      `
    );
  }}

  ${({ minimal }) =>
    minimal &&
    css`
      text-align: center;
      box-shadow: none;
      background-color: transparent;
      border: none;
    `}
    
  /* new style - race card 
     remove local styles related to this
     (if needed)

     These styles are applied when we want the back to be next to the title.
     This corresponds to the new styles of the race-card v2
    */
     ${({ backIconWithNameRaceCadStyle }) =>
    backIconWithNameRaceCadStyle &&
    css`
      height: 20px;
      background-color: transparent;
      border: none;
      font-size: 13px;
    `}
`;

export const BA = styled.button`
  background: transparent;
  border: none;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};

  ${({ minimal }) =>
    minimal &&
    css`
      position: absolute;
      z-index: 1;
      height: 100%;
    `}
`;

export const Wrap = styled.div`
  position: relative;
  margin-inline: auto;
  margin: 16px auto;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    margin: auto;
  }
  ${({ smallSize }) =>
    smallSize &&
    css`
      width: 1024px;
      margin: 16px auto;
    `}

  ${({ backIconWithName }) =>
    backIconWithName &&
    css`
      h1 {
        width: 100%;
        display: flex;
        align-items: center;
      }

      button {
        margin: 0px;
        padding: 0px;
        margin-right: 10px;
      }
    `}
`;
